import {
  ActionIcon,
  Button,
  Flex,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconArrowLeft,
  IconArticleOff,
  IconCalendar,
  IconRefresh,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import DateSelectModal from '../../components/Charts/DateSelectModal';
import { convertTimeToFormattedString } from '../../components/Charts/helper';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import COLORS from '../../constants/colors';
import { fetchErrorLogs } from '../../services/errorLogs';
import { isArrayAndHasContent } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Error Logs';

const ErrorLogs = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  useEffect(() => {
    document.title = 'Error Logs | Armada';
  }, []);

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: ['errorLogs', page, searchKey, null, startDate, endDate],
    queryFn: fetchErrorLogs,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const changeStartDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setStartDate(time);
    } else {
      setStartDate(null);
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setEndDate(time);
    } else {
      setEndDate(null);
    }
  };

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setEndDate(null);
    setStartDate(null);
  };

  const { total, errorLogs } = data.data.data;

  return (
    <div>
      <DateSelectModal
        opened={opened}
        close={close}
        open={open}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={changeStartDate}
        changeEndDate={changeEndDate}
        exportFunc={close}
        exportBtnTitle="Filter"
      />
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={() => handleRefresh()}
                handleSearch={handleSearch}
                placeholder="Search Error Logs"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Filter by Date">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    open();
                  }}
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  variant="filled">
                  <IconCalendar size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(errorLogs) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Error Message</th>
                      <th>Error Stack</th>
                      <th>Context</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {errorLogs.map((actionLog, index) => (
                      <tr key={index}>
                        <td>{actionLog.errorMessage}</td>
                        <td>{actionLog.errorStack}</td>
                        <td>{actionLog.context}</td>
                        <td>
                          {dayjs(actionLog?.createdAt).format(
                            'DD MMM, YYYY, hh:mm A',
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No logs found"
                  subtext={' '}
                  icon={<IconArticleOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="end">
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default ErrorLogs;
