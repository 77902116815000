import {
  Box,
  Button,
  Card,
  Flex,
  Grid,
  Group,
  Input,
  Modal,
  NumberInput,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconCalendar, IconPackages } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { brandSegmentConst, userRoles } from '../../../constants/const';
import { updateProductDetailsById } from '../../../services/product';
import { NotificationUtil } from '../../../utils/notifications';
import { handleErrorResponse } from '../../../utils/utils';

const ProductDetailsModal = ({ opened, close, productDetails }) => {
  const queryClient = useQueryClient();

  const appUser = useSelector((state) => state.auth.user);

  const [editableProductDetails, setEditableProductDetails] =
    useState(productDetails);

  const handlePackagesChange = (e) => {
    const { name, value } = e.target;

    setEditableProductDetails((prevObject) => ({
      ...prevObject,
      packages: {
        ...prevObject.packages,
        [name]: value,
      },
    }));
  };

  const handleCancel = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      centered: true,
      children: <Text size="sm">Are you sure you want to Cancel?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        close();
      },
    });
  };

  const refetchProductList = async () => {
    await queryClient.refetchQueries({
      queryKey: ['products-list'],
      type: 'active',
    });
  };

  const { mutate: updateMutate, isLoading: isMutating } = useMutation({
    mutationFn: (value) => updateProductDetailsById(value),
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: () => {
      refetchProductList();
      setEditableProductDetails(null);
      NotificationUtil({
        success: true,
        title: 'Product Updated!',
        message: 'Product Updated Successfully',
      });
      close();
    },
  });

  //blocking the selected cvcs
  const handleUpdate = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      centered: true,

      children: (
        <Text size="sm">Are you sure you want to Save the Changes?</Text>
      ),
      confirmProps: { color: 'green' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        updateMutate(editableProductDetails);
      },
    });
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Product Details"
        size="xl"
        shadow="sm"
        padding="sm">
        <Stack spacing={0}>
          <Flex gap={5} py="xs" justify="space-between" align="center">
            <Flex justify="center" align="center" gap={2}>
              <IconCalendar size="1.4rem" />
              <Text style={{ transform: 'translateY(2px)' }}>
                {dayjs(editableProductDetails?.createdAt).format(
                  'MMM DD, YYYY',
                )}
              </Text>
            </Flex>
            <Box>
              <Switch
                onLabel="active"
                offLabel="deactive"
                size="lg"
                checked={editableProductDetails?.isActive}
                onChange={() => {
                  setEditableProductDetails({
                    ...editableProductDetails,
                    isActive: !editableProductDetails.isActive,
                  });
                }}
              />
            </Box>
          </Flex>
          <Grid py="xs">
            <Grid.Col>
              <Input.Wrapper label="Product Name" gap={2}>
                <TextInput
                  placeholder="Product Name"
                  value={editableProductDetails?.name}
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      name: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Grid py="xs" gap={2}>
            <Grid.Col md={7} lg={7} sm={12}>
              <Input.Wrapper label="Shipment Format Name" gap={2}>
                <TextInput
                  placeholder="Ex: B&H SF 20HL"
                  value={editableProductDetails?.shipmentFormatName}
                  sx={{ width: '100%' }}
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      shipmentFormatName: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col md={5} lg={5} sm={12}>
              <Input.Wrapper label="Category" gap={2}>
                <TextInput
                  placeholder="Product Category"
                  value={editableProductDetails?.category}
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      category: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Grid py="xs" gap={2}>
            <Grid.Col md={7} lg={7} sm={12}>
              <Input.Wrapper label="Symphony Reference Id" gap={2}>
                <TextInput
                  placeholder="Symphony Reference Id"
                  value={editableProductDetails?.symphonyReferenceId}
                  sx={{ minWidth: '400px' }}
                  required
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      symphonyReferenceId: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col md={5} lg={5} sm={12}>
              <Input.Wrapper label="SKU" gap={2}>
                <TextInput
                  placeholder="Sku"
                  value={editableProductDetails?.sku}
                  required
                  disabled={appUser.role !== userRoles['SUPER ADMIN']}
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      sku: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Grid py="xs" gap={2}>
            <Grid.Col md={7} lg={4} sm={12}>
              <Input.Wrapper label="Brand" gap={2}>
                <TextInput
                  placeholder="Ex: B&H Alchemy 7mg"
                  value={editableProductDetails?.brand}
                  required
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      brand: e.target.value,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col md={5} lg={4} sm={12}>
              <Input.Wrapper label="Brand Segment" gap={2}>
                <Select
                  placeholder="Pick Brand Segment"
                  data={brandSegmentConst.map((item) => {
                    return { value: item, label: item };
                  })}
                  value={editableProductDetails?.brandSegment}
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      brandSegment: e,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>

            <Grid.Col md={5} lg={4} sm={12}>
              <Input.Wrapper label="Pack Size" gap={2}>
                <NumberInput
                  placeholder="Ex: 20"
                  value={editableProductDetails?.outerPackSize || 0}
                  required
                  onChange={(e) => {
                    setEditableProductDetails({
                      ...editableProductDetails,
                      outerPackSize: e,
                    });
                  }}
                />
              </Input.Wrapper>
            </Grid.Col>
          </Grid>
          <Flex py="xs">
            <Card
              //shadow="sm"
              padding="xs"
              radius="md"
              withBorder
              sx={{ width: '100%' }}>
              <Group position="apart">
                <Text weight={500}>Package Details</Text>
                <IconPackages color="blue" />
              </Group>

              <Grid py="xs" gap={2}>
                <Grid.Col md={7} lg={7} sm={12}>
                  {' '}
                  <Input.Wrapper label="Product Id" gap={2}>
                    <TextInput
                      placeholder="Product Id"
                      value={editableProductDetails?.packages?.productId}
                      sx={{ minWidth: '400px' }}
                      required
                      disabled
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col md={5} lg={5} sm={12}>
                  <Input.Wrapper label="Package Type" gap={2}>
                    <TextInput
                      placeholder="Ex. CARTON"
                      value={editableProductDetails?.packages?.type}
                      required
                      name="type"
                      onChange={handlePackagesChange}
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>

              <Grid py="xs" gap={2}>
                <Grid.Col md={7} lg={7} sm={12}>
                  <Input.Wrapper label="Package Name" gap={2}>
                    <TextInput
                      placeholder="Package Name"
                      name="name"
                      value={editableProductDetails?.packages?.name}
                      sx={{ minWidth: '400px' }}
                      required
                      onChange={handlePackagesChange}
                    />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col md={5} lg={5} sm={12}>
                  <Input.Wrapper label="CBC Size" gap={2}>
                    <TextInput
                      placeholder="Ex. 10000"
                      value={editableProductDetails?.packages?.size}
                      required
                      name="size"
                      onChange={handlePackagesChange}
                      type="number"
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>

              <Grid py="xs" gap={2}>
                <Grid.Col md={12} lg={12} sm={12}>
                  <Input.Wrapper label="Size Unit" gap={2}>
                    <TextInput
                      placeholder="Ex. PIECE.."
                      value={editableProductDetails?.packages?.sizeUnit}
                      required
                      name="sizeUnit"
                      onChange={handlePackagesChange}
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </Card>
          </Flex>
          <Flex gap={5} py="xs" justify="flex-end">
            <Button onClick={handleCancel} color="red">
              Cancel
            </Button>
            <Button onClick={handleUpdate} color="blue">
              Save Changes
            </Button>
          </Flex>
        </Stack>
      </Modal>
    </>
  );
};

export default ProductDetailsModal;
