import {
  ActionIcon,
  Autocomplete,
  Badge,
  Flex,
  HoverCard,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  IconAlertTriangle,
  IconBoxOff,
  IconCalendar,
  IconListDetails,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import {
  orderPartialReceiveStatuses,
  orderStatusBadgeColors,
  orderStatusConst,
  userRoles,
} from '../../constants/const';
import { fetchOrders } from '../../services/orders';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  userWiseAutocompleteLocationDataWithId,
} from '../../utils/utils';
import ExportCsvXls from '../../components/Global/ExportCsvXlsx';
import exportFromJSON from 'export-from-json';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import { useDisclosure } from '@mantine/hooks';
import { convertTimeToFormattedString } from '../../components/Charts/helper';
import DateSelectModal from '../../components/Charts/DateSelectModal';
import { fetchLocations } from '../../services/location';
import FilterBadgeClearable from '../../components/Global/FilterBadgeClearable';
import { OrderFilterActions } from '../../store/reducers/orderFiltersReducer';

const PAGE_TITLE = 'Inbound Orders';

const PAGE_LIMIT = 15;

const InboundOrders = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const appUser = useSelector((state) => state.auth.user);

  const orderFilters = useSelector((state) => state.orderFilters.filters);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);

  const [sourceSearchKey, setSourceSearchKey] = useState(null);
  const [destinationSearchKey, setDestinationSearchKey] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [missingCbcToggler, setMissingCbcToggler] = useState(false);

  const [opened, { open, close }] = useDisclosure(false);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  useEffect(() => {
    document.title = `${
      location.pathname.split('/').includes('inbound-orders')
        ? 'Inbound Orders'
        : 'Orders'
    } | Armada`;
  }, []);

  useEffect(() => {
    if (orderFilters && orderFilters.filterSource === 'inbound-orders') {
      setPage(orderFilters.page);
      setSearchKey(orderFilters.searchKey);
      setOrderStatus(orderFilters.orderStatus);
      setSource(orderFilters.source);
      // setDestination(orderFilters.destination);
      setMissingCbcToggler(orderFilters.missingCbcToggler);
      setSourceSearchKey(orderFilters.sourceSearchKey);
      setStartDate(orderFilters.startDate);
      setEndDate(orderFilters.endDate);
    }
  }, []);

  useEffect(() => {
    const filters = {
      page,
      searchKey,
      orderStatus,
      source,
      destination,
      searchByorderType: null,
      missingCbcToggler,
      sourceSearchKey,
      destinationSearchKey: null,
      startDate,
      endDate,
      filterSource: 'inbound-orders',
    };
    dispatch(OrderFilterActions.setFilters({ filters }));
  }, [
    page,
    searchKey,
    orderStatus,
    source,
    destination,
    missingCbcToggler,
    sourceSearchKey,
    startDate,
    endDate,
  ]);

  const changeStartDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setStartDate(time);
    } else {
      setStartDate(null);
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setEndDate(time);
    } else {
      setEndDate(null);
    }
  };

  useEffect(() => {
    if (
      userRoles[appUser?.role] === userRoles['GODOWN MANAGER'] ||
      userRoles[appUser?.role] === userRoles.MANAGER ||
      userRoles[appUser?.role] === userRoles.OPERATOR
    ) {
      let toLocation = [];
      appUser.locations.map((location) => {
        toLocation.push(parseInt(location.id));
      });
      setDestination(toLocation);
    }
  }, []);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'inbound-orders',
      page,
      searchKey,
      orderStatus,
      null,
      source,
      destination,
      null,
      startDate,
      endDate,
      missingCbcToggler,
      PAGE_LIMIT,
    ],
    queryFn: fetchOrders,
    enabled:
      isArrayAndHasContent(destination) ||
      userRoles[appUser?.role] === userRoles.ADMIN ||
      userRoles[appUser?.role] === userRoles['SUPER ADMIN'],
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: ordersPagelessData,
    isLoading: ordersPagelessLoading,
    isFetching: ordersPagelessFetching,
    refetch: ordersPagelessRefetch,
    error: ordersPagelessError,
  } = useQuery({
    queryKey: [
      'outbound-orders-pageless',
      null,
      searchKey,
      orderStatus,
      null,
      source,
      destination,
      true,
      startDate,
      endDate,
      missingCbcToggler,
    ],
    queryFn: fetchOrders,
    enabled: exportToggler,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const { orders } = response.data.data;

      const fileName = `INBOUND-ORDERS-${appUser.name}-${dayjs(
        new Date(),
      ).format('DD-MM-YYYY')}`;
      const data = orders.map((order) => {
        return {
          'Order Date': dayjs(order.created_at).format('DD-MM-YYYY'),
          'Order Type': order.orderType,
          'Order Number': order.orderNumber,
          'Total Quantiy (TH)':
            order?.inventory?.reduce(
              (acc, obj) => acc + obj.quantity * obj.product.packages.size,
              0,
            ) / 1000,
          Status: order?.orderStatus,
          Source: order?.sourceInformation?.name,
          Destination: order?.destinationInformation?.name,
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: ['location', 1, null, null, null, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  if (isLoading)
    return (
      <div>
        <Title order={1} size="h2">
          {location.pathname.split('/').includes('inbound-orders')
            ? PAGE_TITLE
            : 'Orders'}
        </Title>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setStartDate(null);
    setEndDate(null);
    setSource(null);
    setSourceSearchKey(null);
    setMissingCbcToggler(false);
    if (userRoles[appUser?.role] === userRoles.MANAGER) {
      setDestinationSearchKey(null);
      let toLocation = [];
      appUser.locations.map((location) => {
        toLocation.push(parseInt(location.id));
      });
      setDestination(toLocation);
    }
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      ordersPagelessRefetch();
    } else {
      ordersPagelessRefetch();
    }
  };

  const { orders, total } = data.data.data;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <DateSelectModal
        opened={opened}
        close={close}
        open={open}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={changeStartDate}
        changeEndDate={changeEndDate}
        exportFunc={close}
        exportBtnTitle="Filter"
      />
      {/* CUSTOM MODAL AND DRAWER END */}
      <Title order={1} size="h2">
        {location.pathname.split('/').includes('inbound-orders')
          ? PAGE_TITLE
          : 'Orders'}
      </Title>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div
              style={{
                maxWidth: '200px',
              }}>
              <SearchInput
                handleRefresh={() => {
                  setInvokingRefreshForSearchInput(
                    !invokingRefreshForSearchInput,
                  );
                  setSearchKey('');
                }}
                handleSearch={handleSearch}
                placeholder="Search Orders"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>
            <Select
              value={orderStatus}
              onChange={(value) => {
                setOrderStatus(value);
              }}
              placeholder="Filter by status"
              data={Object.keys(orderStatusConst).map((key) => ({
                label: orderStatusConst[key],
                value: key,
              }))}
              sx={{
                maxWidth: '200px',
              }}
            />
            <Autocomplete
              placeholder="Search by source"
              onChange={(value) => {
                if (sourceSearchKey && sourceSearchKey.length === 0) {
                  setSourceSearchKey(null);
                } else {
                  setSourceSearchKey(value);
                }
              }}
              limit={locationData?.data?.data?.locations.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={sourceSearchKey || ''}
              onItemSubmit={(e) => {
                setSource([e.id]);
              }}
              rightSection={
                source ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setSourceSearchKey(null);
                      setSource(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                locationData?.data?.data.locations?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
              sx={{
                maxWidth: '250px',
              }}
            />
            {(userRoles[appUser?.role] === userRoles.MANAGER ||
              userRoles[appUser?.role] === userRoles['GODOWN MANAGER']) && (
              <Autocomplete
                placeholder="Search by destination"
                onChange={(value) => {
                  if (
                    destinationSearchKey &&
                    destinationSearchKey.length === 0
                  ) {
                    setDestinationSearchKey(null);
                  } else {
                    setDestinationSearchKey(value);
                  }
                }}
                limit={locationData?.data?.data?.locations.length || 0}
                maxDropdownHeight="200px"
                dropdownPosition="bottom"
                nothingFound="No options"
                value={destinationSearchKey || ''}
                onItemSubmit={(e) => {
                  setDestination([e.id]);
                }}
                rightSection={
                  destination?.length === 1 ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        if (userRoles[appUser?.role] === userRoles.MANAGER) {
                          setDestinationSearchKey(null);
                          let toLocation = [];
                          appUser.locations.map((location) => {
                            toLocation.push(parseInt(location.id));
                          });
                          setDestination(toLocation);
                        } else {
                          setDestinationSearchKey(null);
                          setDestination(null);
                        }
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                data={
                  userWiseAutocompleteLocationDataWithId({
                    appUser: appUser,
                    locationData: locationData?.data?.data?.locations || [],
                  }) || []
                }
                sx={{
                  maxWidth: '250px',
                }}
              />
            )}

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Filter by Date">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    open();
                  }}
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  variant="filled">
                  <IconCalendar size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    setOrderStatus(null);

                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <Switch
            size="lg"
            onLabel="Missing CBC On"
            offLabel="Missing CBC Off"
            checked={missingCbcToggler}
            onChange={(event) =>
              setMissingCbcToggler(event.currentTarget.checked)
            }
          />
        </Flex>
        <Flex my={5} gap={5} align="center">
          <Text fz="xs">Showing {total || 0} orders</Text>
          {searchKey ||
          orderStatus ||
          source ||
          startDate ||
          endDate ||
          destination ||
          missingCbcToggler ? (
            <Text fz="xs">with filters</Text>
          ) : null}
          {searchKey && (
            <FilterBadgeClearable
              text={`search: ${searchKey}`}
              func={() => {
                setInvokingRefreshForSearchInput(
                  !invokingRefreshForSearchInput,
                );
                setSearchKey('');
              }}
              color="blue"
            />
          )}
          {orderStatus && (
            <FilterBadgeClearable
              text={`status: ${orderStatus}`}
              func={() => {
                setOrderStatus(null);
              }}
              color="orange"
            />
          )}

          {source && (
            <FilterBadgeClearable
              text={`source: ${sourceSearchKey}`}
              func={() => {
                setSourceSearchKey(null);
                setSource(null);
              }}
              color="pink"
            />
          )}
          {(userRoles[appUser?.role] === userRoles.MANAGER ||
            userRoles[appUser?.role] === userRoles['GODOWN MANAGER']) &&
            destinationSearchKey && (
              <FilterBadgeClearable
                text={`destination: ${destinationSearchKey}`}
                func={() => {
                  if (userRoles[appUser?.role] === userRoles.MANAGER) {
                    setDestinationSearchKey(null);
                    let toLocation = [];
                    appUser.locations.map((location) => {
                      toLocation.push(parseInt(location.id));
                    });
                    setDestination(toLocation);
                  } else {
                    setDestinationSearchKey(null);
                    setDestination(null);
                  }
                }}
                color="pink"
              />
            )}

          {startDate && (
            <FilterBadgeClearable
              text={`start date: ${dayjs(startDate).format('DD-MM-YYYY')}`}
              func={() => {
                setStartDate(null);
              }}
              color="green"
            />
          )}
          {endDate && (
            <FilterBadgeClearable
              text={`end date: ${dayjs(endDate).format('DD-MM-YYYY')}`}
              func={() => {
                setEndDate(null);
              }}
              color="cyan"
            />
          )}
          {missingCbcToggler && (
            <FilterBadgeClearable
              text={`missing CBC: ${missingCbcToggler ? 'On' : 'Off'}`}
              func={() => {
                setMissingCbcToggler(false);
              }}
              color="lime"
            />
          )}
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(orders) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Order Date</th>
                      <th>Created At</th>
                      <th>Order Type</th>
                      <th>Order #</th>
                      <th>Total Quantity - TH (sticks)</th>
                      <th>Status</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      verticalAlign: 'baseline',
                    }}>
                    {orders.map((order, index) => (
                      <tr key={index}>
                        <td>
                          {dayjs(order?.orderDate).format('MMM DD, YYYY')}
                        </td>
                        <td>
                          {dayjs(order?.createdAt).format('MMM DD, YYYY')}
                        </td>
                        <td>{order?.orderType}</td>
                        <td>
                          <Flex gap={2} align="center">
                            <Text>{order?.orderNumber}</Text>
                            {order?.partialReceiveStatus ===
                              orderPartialReceiveStatuses.PARTIALLY_RECEIVED && (
                              <Tooltip label="Flagged">
                                <IconAlertTriangle size={20} color="red" />
                              </Tooltip>
                            )}
                          </Flex>
                          {order?.orderType !== 'SO' && (
                            <Badge color={order?.isApproved ? 'green' : 'red'}>
                              {order?.isApproved ? 'Approved' : 'Not Approved'}
                            </Badge>
                          )}
                        </td>
                        <td>
                          {order?.inventory?.reduce(
                            (acc, obj) =>
                              acc +
                              obj?.quantity *
                                (obj?.product?.packages?.size || 10000),
                            0,
                          ) / 1000 || 0}
                        </td>
                        <td>
                          <Stack spacing={2} align="start">
                            <Badge
                              color={orderStatusBadgeColors[order?.orderStatus]}
                              variant="filled"
                              radius="sm">
                              {order?.orderStatus}
                            </Badge>
                            {order?.isPartialUnloaded && (
                              <Badge color="red" variant="filled" radius="sm">
                                CBC missing
                              </Badge>
                            )}
                          </Stack>
                        </td>
                        <td>
                          <HoverCard key={index} width={280} shadow="md">
                            <HoverCard.Target>
                              <Stack
                                spacing="xs"
                                sx={{
                                  maxWidth: '120px',
                                }}>
                                <Badge
                                  color="dark"
                                  variant="filled"
                                  radius="sm">
                                  {order?.sourceInformation?.name}
                                </Badge>
                              </Stack>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Text size="md">
                                {' '}
                                {order?.sourceInformation?.name}
                              </Text>
                              <Text size="sm">
                                {order?.sourceInformation?.district || 'N/A'},{' '}
                                {order?.sourceInformation?.division || 'N/A'}
                              </Text>
                              <Text size="sm">
                                {order?.sourceInformation?.address}
                              </Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td>
                        <td>
                          <HoverCard key={index} width={280} shadow="md">
                            <HoverCard.Target>
                              <Stack
                                spacing="xs"
                                sx={{
                                  maxWidth: '120px',
                                }}>
                                <Badge
                                  color="dark"
                                  variant="outline"
                                  radius="sm">
                                  {order?.destinationInformation?.name}
                                </Badge>
                              </Stack>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Text size="md">
                                {' '}
                                {order?.destinationInformation?.name}
                              </Text>
                              <Text size="sm">
                                {order?.destinationInformation?.district ||
                                  'N/A'}
                                ,{' '}
                                {order?.destinationInformation?.division ||
                                  'N/A'}
                              </Text>
                              <Text size="sm">
                                {order?.destinationInformation?.address}
                              </Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td>

                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Details'}>
                              <ActionIcon
                                variant="light"
                                color="cyan"
                                onClick={() => {
                                  navigate(
                                    `order-details/${order?.orderNumber}`,
                                  );
                                }}>
                                <IconListDetails size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No order found"
                  subtext={' '}
                  icon={<IconBoxOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={ordersPagelessFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / PAGE_LIMIT)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default InboundOrders;
