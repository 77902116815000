import {
  ActionIcon,
  Badge,
  Card,
  Flex,
  Grid,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { IconDatabaseExport, IconFaceIdError } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import ExcelExport from 'export-xlsx';
import React, { useState } from 'react';
import COLORS from '../../../constants/colors';
import { fetchCbcListFromProductList } from '../../../services/orders';
import { fetchProductCBC } from '../../../services/product';
import {
  handleErrorResponse,
  isArrayAndHasContent,
} from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';

const CbcBreakdown = ({ inventory }) => {
  const [exportLoader, setExportLoader] = useState(false);

  const { data, isLoading, error } = useQuery({
    queryKey: ['productCBC', inventory?.productUid],
    enabled: !!inventory?.productUid,
    queryFn: () => fetchProductCBC(inventory?.productUid),
  });

  const {
    mutate: cbcExportMutate,
    isLoading: cbcExportMutateLoading,
    isMutating: cbcExportMutating,
  } = useMutation({
    mutationFn: (value) => fetchCbcListFromProductList(value),

    keepPreviousData: true,
    onError: (error) => {
      setExportLoader(false);
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      const excelExport = new ExcelExport();
      excelExport.downloadExcel(
        data.data.data.settings,
        data.data.data.sheetData,
      );
      setExportLoader(false);
    },
  });
  if (isLoading)
    return (
      <Stack
        sx={{
          minHeight: '50vh',
          margin: 10,
          minWidth: '50vw',
        }}>
        <Skeleton height="50vh" mt={10} />
      </Stack>
    );

  if (error)
    return (
      <Stack
        sx={{
          minHeight: '50vh',
          margin: 10,
          minWidth: '50vw',
        }}>
        <NoDataPlaceholder apiError={true} />
      </Stack>
    );

  const handleExportCBC = (productId, locationId) => {
    const value = {
      productId: productId,
      locationId: locationId,
    };
    setExportLoader(true);
    cbcExportMutate(value);
  };

  const { cbcs, total } = data.data.data;

  return (
    <Stack
      sx={{
        //minHeight: '50vh',
        //margin: 10,
        minWidth: '50vw',
        height: '50vh',
      }}>
      <LoadingOverlay
        visible={exportLoader}
        overlayBlur={2}
        loader={
          <Flex direction="column" align="center" gap={10}>
            <Loader />
            <Text fw={600} fz="sm">
              Expoting Data...
            </Text>
          </Flex>
        }
      />

      {inventory && (
        <Card
          withBorder
          radius="sm"
          sx={{
            backgroundColor: COLORS.primary,
            color: '#ffffff',
            position: 'relative',
          }}>
          <Grid>
            <Grid.Col span={8}>
              <Stack spacing={2}>
                <Text size="md" weight={600}>
                  {inventory?.shipmentFormatName
                    ? inventory?.shipmentFormatName
                    : inventory?.name}
                </Text>
                <Text size="md" color="yellow" fw={600}>
                  SKU : {inventory?.sku ? inventory?.sku : 'N/A'}
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={4}>
              <Stack justify="end" align="end"></Stack>
            </Grid.Col>
          </Grid>
        </Card>
      )}
      {isArrayAndHasContent(cbcs) ? (
        <ScrollArea h="40vh">
          <Table highlightOnHover>
            <thead
              style={{
                position: 'sticky',
                top: 0,
                backgroundColor: '#fff',
                zIndex: 1,
              }}>
              <tr>
                <th>Distribution Point</th>
                {/* <th>CBCs</th> */}
                <th>Intact CBC</th>
                <th>Block CBC</th>
                {/* <th>Open CBC</th> */}
                <th>Export</th>
              </tr>
            </thead>
            <tbody>
              {cbcs.map((cbc, index) => (
                <tr key={index}>
                  <td>{cbc.name}</td>
                  {/* <td>{cbc.totalCbc}</td> */}
                  <td>
                    {' '}
                    <Flex align="center" gap={5}>
                      {cbc?.intactcbc ? cbc?.intactcbc : 0}
                      <Text fz="xs" color="dimmed">
                        (
                        {cbc?.intactCBCStickCount
                          ? cbc?.intactCBCStickCount / 1000
                          : 0}{' '}
                        TH)
                      </Text>
                    </Flex>
                  </td>
                  <td>
                    <Flex align="center" gap={5}>
                      {cbc?.blockcbc ? cbc?.blockcbc : 0}
                      <Text fz="xs" color="dimmed">
                        (
                        {cbc?.blockCBCStickCount
                          ? cbc?.blockCBCStickCount / 1000
                          : 0}{' '}
                        TH)
                      </Text>
                    </Flex>
                  </td>
                  {/* <td>
                    <Flex align="center" gap={5}>
                      {cbc?.opencbc ? cbc?.opencbc : 0}
                      <Text fz="xs" color="dimmed">
                        (
                        {cbc.openCBCStickCount
                          ? cbc?.openCBCStickCount < 0
                            ? (cbc?.openCBCStickCount / 1000) * -1
                            : cbc?.openCBCStickCount / 1000
                          : 0}{' '}
                        TH)
                      </Text>
                    </Flex>
                  </td> */}
                  <td>
                    <ActionIcon>
                      <IconDatabaseExport
                        onClick={() =>
                          handleExportCBC(cbc.productid, cbc.locationid)
                        }
                      />
                    </ActionIcon>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr
                style={{
                  //backgroundColor: 'skyblue',
                  position: 'sticky',
                  bottom: -1,
                  right: 0,
                  width: '100%',
                  backgroundColor: 'white',
                  padding: '0.5em 0em',
                  zIndex: 10,
                }}>
                <th>Total</th>
                <th>
                  <Text fw={600}>
                    {inventory?.intactCbc ? inventory?.intactCbc : 0}
                    <span style={{ color: 'gray' }}>
                      (
                      {inventory?.intactCbcstickCount
                        ? inventory?.intactCbcstickCount / 1000
                        : 0}
                      TH)
                    </span>
                  </Text>
                </th>
                <th>
                  <Text fw={600}>
                    {inventory?.blockCbc ? inventory?.blockCbc : 0}
                    <span style={{ color: 'gray' }}>
                      (
                      {inventory?.blockCbcstickCount
                        ? inventory?.blockCbcstickCount / 1000
                        : 0}
                      TH)
                    </span>
                  </Text>
                </th>
                {/* <th>
                  <Text fw={600}>
                    {inventory?.openCBC ? inventory?.openCBC : 0}
                    <span style={{ color: 'gray' }}>
                      (
                      {inventory?.openCBCStickCount
                        ? inventory?.openCBCStickCount / 1000 < 0
                          ? (inventory?.openCBCStickCount / 1000) * -1
                          : inventory?.openCBCStickCount / 1000
                        : 0}
                      TH)
                    </span>
                  </Text>
                </th> */}
                <th></th>
              </tr>
            </tfoot>
          </Table>
        </ScrollArea>
      ) : (
        <Stack
          w="100%"
          h="18vh"
          align="center"
          justify="center"
          bg={COLORS.grayish}>
          <IconFaceIdError color={COLORS.primary} size={45} />
          <Text size="md" color="#272729" weight="bold">
            No assigned CBCs found
          </Text>
        </Stack>
      )}
    </Stack>
  );
};

export default CbcBreakdown;
