import { authContext } from '../constants/const';
import { handleErrorResponse } from '../utils/utils';
import axios from './axios';

export const SignInApi = async (email, password) => {
  try {
    const { data } = await axios().post('/auth/login', {
      authContext: authContext,
      identifier: email,
      password: password,
    });

    return data;
  } catch (err) {
    handleErrorResponse(err);
    throw err;
  }
};

export const verifyOtp = async (sessionId, otp) => {
  try {
    const { data } = await axios().post(`/auth/mfa/${sessionId}`, {
      otp: otp,
    });
    return data;
  } catch (err) {
    handleErrorResponse(err);
  }
};

export const forgotPassword = async (identifier) => {
  try {
    const { data } = await axios().post('/auth/password-reset', {
      identifier: identifier,
    });
    return data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const forgotPasswordValidateNumber = async (values) => {
  try {
    const { data } = await axios().post(
      '/auth/password-reset/validate-phone-number',
      {
        identifier: values.identifier,
        token: values.token,
        otp: values.otp,
      },
    );
    return data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const resetPassword = async (token, otp, password) => {
  try {
    const { data } = await axios().patch(`/auth/password-update/${token}`, {
      otp: otp,
      password: password,
    });
    return data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

export const logout = async () => {
  try {
    const { data } = await axios().post('/auth/logout');
    return data;
  } catch (error) {
    handleErrorResponse(error);
  }
};
