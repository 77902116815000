import {
  ActionIcon,
  Button,
  Flex,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Select,
  Skeleton,
  Space,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconBoxOff,
  IconCalendar,
  IconRefresh,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import ServerErrorBox from '../../../components/Global/ServerErrorBox';
import { DateInput } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { userWeight } from '../../../constants/const';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  userWiseMultiSelectDistributorDataWithId,
  userWiseMultiSelectLocationDataWithId,
} from '../../../utils/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchDistributor } from '../../../services/distributor';
import COLORS from '../../../constants/colors';
import SearchInput from '../../../components/Global/SearchInput';
import { fetchOpenCbcReport, reportGenerate } from '../../../services/reports';
import NoDataPlaceholder from '../../../components/Global/NoDataPlaceholder';
import { NotificationUtil } from '../../../utils/notifications';
import { removeExportFile } from '../../../services/dashboard';
import dayjs from 'dayjs';
import { useDisclosure } from '@mantine/hooks';
import DateSelectModal from '../../../components/Charts/DateSelectModal';
import { fetchLocations } from '../../../services/location';

const PAGE_TITLE = 'Open CBC Report';

const PAGE_SIZE = 20;

const OpenCbcreport = () => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const appUser = useSelector((state) => state.auth.user);

  const token = useSelector((state) => state?.auth?.accessToken);
  const refreshToken = useSelector((state) => state?.auth?.refreshToken);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  //   const [startDate, setStartDate] = useState(
  //     new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  //   );
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [selectedDistributor, setselectedDistributor] = useState(null);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);

  const [routeSectionData, setRouteSectionData] = useState([]);

  //date filter modal
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (appUser.userWeight === userWeight.MANAGER) {
      setselectedDistributor(appUser.distributors.map((dist) => dist.id));
      setSelectedPoint(appUser.locations.map((loc) => loc.id));
    }
  }, []);

  useEffect(() => {
    if (selectedPoint) {
      const location = locationData.data.data.locations.find(
        (location) => location.id === selectedPoint,
      );

      //console.log(location);
      setRouteSectionData({
        id: location?.id,
        routeSection: location?.routeSection,
      });
    } else {
      setRouteSectionData([]);
      setSelectedRoute(null);
    }
  }, [selectedPoint]);

  const {
    data: OpenCbcReport,
    isLoading: isLoadingOpenCbcReport,
    isFetching: isFetchingOpenCbcReport,
    error: errorOpenCbcReport,
  } = useQuery({
    queryKey: [
      'Open-cbc-report',
      page,
      startDate,
      endDate,
      searchKey,
      selectedDistributor,
      selectedPoint,
      selectedRoute,
    ],
    queryFn: () =>
      fetchOpenCbcReport({
        page,
        startDate,
        endDate,
        searchKey,
        point: selectedPoint,
        house: selectedDistributor,
        route: selectedRoute,
        pageSize: PAGE_SIZE,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const distributorData = queryClient.getQueryData([
    'distributor-pagelessData',
    null,
    null,
    null,
    null,
    true,
  ]);

  const locationData = queryClient.getQueryData([
    'location',
    1,
    null,
    null,
    selectedDistributor,
    true,
    true,
  ]);

  const callLocation = useQuery({
    queryKey: ['location', 1, null, null, selectedDistributor, true, true],
    enabled: locationData == undefined,
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const callDistributorIfCachedDataIsNotFound = useQuery({
    queryKey: ['distributor-pagelessData', null, null, null, null, true],
    enabled: distributorData == undefined,
    queryFn: fetchDistributor,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { mutate: ExportMutate, isLoading: ExportLoading } = useMutation({
    mutationFn: () =>
      reportGenerate({
        reportName: 'openCbc',
        page,
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate,
        searchKey,
        point: selectedPoint,
        house: selectedDistributor,
        route: selectedRoute,
        pageLess: true,
      }),
    keepPreviousData: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      handleDownloadImage({
        fileUrl: data.data.filePath,
        fileName: data.data.fileName,
      });
    },
  });

  const handleDownloadImage = async (file) => {
    try {
      const response = await fetch(file.fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-refresh-token': refreshToken,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifications.clean();
      NotificationUtil({
        success: true,
        title: 'File Downloaded',
        message: 'Your file has been downloaded successfully.',
      });
      removeExportFileMutate(file.fileName);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const { mutate: removeExportFileMutate, isLoading: removeExportFileLoading } =
    useMutation({
      mutationFn: (fileName) => removeExportFile(fileName),
      keepPreviousData: false,
    });

  if (isLoadingOpenCbcReport)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (errorOpenCbcReport)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const { results, total } = OpenCbcReport.data.data;

  return (
    <div>
      {/* date filter modal */}
      <DateSelectModal
        opened={opened}
        close={close}
        open={open}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={setStartDate}
        changeEndDate={setEndDate}
        exportFunc={close}
        exportBtnTitle="Filter"
        handleResetFunc={() => {
          setStartDate(new Date());
          setEndDate(null);
          close();
        }}
      />
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Space h="lg" />
      <Flex justify="space-between" gap={5}>
        <Flex align="center" gap={5}>
          <div>
            <SearchInput
              handleRefresh={() => handleRefresh()}
              handleSearch={handleSearch}
              placeholder="Search by Barcode/Packcode, SKU Name"
              invokeRefresh={invokingRefreshForSearchInput}
              refreshBtn={false}
            />
          </div>
          {/* <DateInput
            placeholder="Start Date"
            value={startDate}
            onChange={(value) => {
              setStartDate(value);
            }}
            sx={{
              maxWidth: '200px',
            }}
            maxDate={new Date()}
            weekendDays={[5, 6]}
          />
          <DateInput
            placeholder="End Date"
            // value={endDate}
            onChange={(value) => {
              setEndDate(value);
            }}
            sx={{
              maxWidth: '200px',
            }}
            clearable
            minDate={startDate ? startDate : new Date()}
            weekendDays={[5, 6]}
          /> */}

          <Select
            placeholder="Filter by House"
            dropdownPosition="bottom"
            data={
              userWiseMultiSelectDistributorDataWithId({
                appUser,
                distributorData: distributorData?.data.data.distributors || [],
              }) || []
            }
            onChange={(value) => {
              setPage(1);
              setselectedDistributor(value);
            }}
            value={selectedDistributor}
            // sx={{
            //   minWidth: '250px',
            // }}
            clearable
            searchable
            withinPortal
          />
          <Select
            placeholder="Filter by Point"
            dropdownPosition="bottom"
            data={
              userWiseMultiSelectLocationDataWithId({
                appUser,
                locationData: locationData?.data.data.locations || [],
              }) || []
            }
            onChange={(value) => {
              setPage(1);
              setSelectedPoint(value);
            }}
            value={selectedPoint}
            // sx={{
            //   minWidth: '250px',
            // }}
            clearable
            searchable
            withinPortal
          />
          <Select
            placeholder="Select Route/Section"
            searchable
            value={selectedRoute}
            onChange={(value) => {
              setPage(1);
              setSelectedRoute(value);
            }}
            nothingFound={selectedPoint ? 'No options' : 'Select Point first'}
            data={routeSectionData?.routeSection || []}
            clearable
          />

          <Tooltip label="Filter by Date">
            <ActionIcon
              size="lg"
              onClick={() => {
                open();
              }}
              sx={{
                backgroundColor: COLORS.primary,
              }}
              variant="filled">
              <IconCalendar size={18} />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Refresh">
            <ActionIcon
              size="lg"
              onClick={() => {
                setStartDate(new Date());
                setEndDate(null);
                handleRefresh();
                setselectedDistributor(null);
                setSelectedPoint(null);
                setSelectedRoute(null);
              }}
              sx={{
                backgroundColor: COLORS.orange,
              }}
              variant="filled">
              <IconRefresh size={18} />
            </ActionIcon>
          </Tooltip>
        </Flex>
        <Flex>
          <Button
            onClick={() => {
              ExportMutate();
              NotificationUtil({
                success: true,
                title: 'Processing',
                message:
                  'Your request is being processed. Feel free to browse the app while waiting for the download to finish.',
                autoClose: false,
              });
            }}
            loading={ExportLoading}
            sx={{
              backgroundColor: COLORS.primary,
            }}>
            Download XLS
          </Button>
        </Flex>
      </Flex>
      <Space h="lg" />
      <ScrollArea h="75vh">
        <LoadingOverlay
          visible={isLoadingOpenCbcReport || isFetchingOpenCbcReport}
          zIndex={1}
        />
        <Table highlightOnHover withColumnBorders={false} fontSize="xs">
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: COLORS.primary,
            }}>
            <tr>
              <th
                style={{
                  color: 'white',
                }}>
                Opened At
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Barcode
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Packcode
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                SKU
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                SKU Name
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                House
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Distribution Point
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Route/Section
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Opened By
              </th>

              <th
                style={{
                  color: 'white',
                }}>
                Production Date
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Expiry Date
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {results?.map((data, index) => (
              <tr key={index}>
                <td>{data.openedAt}</td>
                <td>{data.barcode}</td>
                <td>{data.packcode}</td>
                <td>{data.sku}</td>
                <td>{data.skuName}</td>
                <td>{data.house}</td>
                <td>{data.distributionPoint}</td>
                <td>{data.routeSection}</td>
                <td>{data.openedBy}</td>
                <td>{data.productionDate}</td>
                <td>{data.expiryDate}</td>
                <td>{data.cbcStatus}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!isArrayAndHasContent(results) && (
          <Stack
            justify="center"
            align="center"
            sx={{
              height: '51vh',
            }}>
            <NoDataPlaceholder
              title="No report data found"
              subtext={'No data available for this filter'}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Stack>
        )}
      </ScrollArea>
      <Flex justify="end">
        <Pagination
          mt="20px"
          value={page}
          onChange={setPage}
          total={Math.ceil(total / PAGE_SIZE)}
        />
      </Flex>
    </div>
  );
};

export default OpenCbcreport;
