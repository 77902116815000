import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Loader,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Table,
  Tabs,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core';
import { IconBoxOff } from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import {
  cbcTabNames,
  orderStatusWeight,
  userRoles,
} from '../../constants/const';
import { useSocket } from '../../hooks/useSocket';
import { getAssignedCbcs } from '../../services/orders';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isScanOutable,
} from '../../utils/utils';
import ExportCsvXls from '../Global/ExportCsvXlsx';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';
import SearchInput from '../Global/SearchInput';
import UnscannedCbcsModal from './UnscannedCbcsModal';
import CBCHistory from '../Inventory/Modal/CBCHistory';
import { useParams } from 'react-router-dom';
import ReceiveOrderModal from './ReceiveOrderModal';

const OrderDetailsCbcsTable = ({
  order,
  onUpdate,
  triggerScanInBtn,
  setTriggerScanInBtn,
  quantityBreakDownForStats,
}) => {
  const appUser = useSelector((state) => state.auth.user);
  const { uid } = useParams();
  const [cbcTab, setcbcTab] = useState(cbcTabNames.scanOut);

  const user = useSelector((state) => state.auth.user);

  const queryClient = useQueryClient();

  const socket = useSocket();

  const [scannedInCBC, setScannedInCBC] = useState(null);
  const [scannedOutCBC, setScannedOutCBC] = useState(null);
  const [notScannedInCBC, setNotScannedInCBC] = useState(null);

  const [showLeftOutCbcModal, setShowLeftOutCbcModal] = useState(false);
  const [showScanInModal, setShowScanInModal] = useState(false);

  const [showCbcHistoryModal, setShowCbcHistoryModal] = useState(false);
  const [selectedCbc, setSelectedCbc] = useState(null);

  const [exportType, setExportType] = useState('csv');

  const { data, isLoading, refetch, isFetching } = useQuery({
    queryKey: ['assigned-cbc-of-order', order?.orderNumber],
    queryFn: getAssignedCbcs,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      setScannedInCBC(data?.data?.data?.scannedInCBC);
      setScannedOutCBC(data?.data?.data?.scannedOutCBC);
      setNotScannedInCBC(data?.data?.data?.notScannedInCBC);
    },
  });

  useEffect(() => {
    if (triggerScanInBtn === true) {
      setShowLeftOutCbcModal(true);
    }
  }, [triggerScanInBtn]);

  useEffect(() => {
    if (data) {
      setScannedInCBC(data?.data?.data?.scannedInCBC);
      setScannedOutCBC(data?.data?.data?.scannedOutCBC);
      setNotScannedInCBC(data?.data?.data?.notScannedInCBC);
    }
  }, [data]);

  useEffect(() => {
    socket.on('update-order-details', () => {
      refetch();
      callRefetchProducts();
    });

    return () => {
      socket.off('update-order-details');
    };
  }, [socket, order?.orderNumber]);

  useEffect(() => {
    socket.emit('join-room', `update-scan-${order?.orderNumber}`);
  }, []);

  const callRefetchProducts = async () => {
    await queryClient.refetchQueries({
      queryKey: ['assigned-products-of-order'],
      type: 'active',
    });

    await queryClient.refetchQueries({
      queryKey: ['order-details', uid],
      type: 'active',
    });
  };

  const handleSearch = (searchKey) => {
    if (searchKey.length === 0) {
      setScannedInCBC(data?.data?.data?.scannedInCBC);
      setScannedOutCBC(data?.data?.data?.scannedOutCBC);
      return;
    }
    if (cbcTab === cbcTabNames.scanIn) {
      const filteredScannedInCBC = data?.data?.data?.scannedInCBC?.filter(
        (cbc) => {
          return (
            cbc?.name?.toLowerCase().includes(searchKey.toLowerCase()) ||
            cbc?.barcode?.toLowerCase().includes(searchKey.toLowerCase()) ||
            cbc?.packcode?.toLowerCase().includes(searchKey.toLowerCase()) ||
            cbc?.quantity?.toLowerCase().includes(searchKey.toLowerCase())
          );
        },
      );
      setScannedInCBC(filteredScannedInCBC);
    } else {
      const filteredScannedOutCBC = data?.data?.data?.scannedOutCBC?.filter(
        (cbc) => {
          return (
            cbc?.name?.toLowerCase().includes(searchKey.toLowerCase()) ||
            cbc?.barcode?.toLowerCase().includes(searchKey.toLowerCase()) ||
            cbc?.packcode?.toLowerCase().includes(searchKey.toLowerCase()) ||
            cbc?.quantity?.toLowerCase().includes(searchKey.toLowerCase())
          );
        },
      );
      setScannedOutCBC(filteredScannedOutCBC);
    }
  };

  const handleRefresh = () => {
    if (data) {
      setScannedInCBC(data?.data?.data?.scannedInCBC);
      setScannedOutCBC(data?.data?.data?.scannedOutCBC);
    }
  };

  const exportData = () => {
    const fileName = `CBC-ORDER-${order.orderNumber}-${dayjs(new Date()).format(
      'DD-MM-YYYY',
    )}`;
    let data = [];
    if (cbcTab === cbcTabNames.scanIn) {
      data = scannedInCBC.map((cbc) => {
        return {
          SKU: cbc?.sku,
          Name: cbc?.name,
          Barcode: cbc.barcode,
          'Pack Code': cbc.packcode,
          'Production Date': dayjs(cbc.productionDate).format('DD-MM-YYYY'),
          Quantity: parseInt(cbc.stickCount),
          Status: cbc.cbcStatus,
        };
      });
    } else {
      data = scannedOutCBC.map((cbc) => {
        return {
          SKU: cbc?.sku,
          Name: cbc?.name,
          Barcode: cbc.barcode,
          'Pack Code': cbc.packcode,
          'Production Date': dayjs(cbc.productionDate).format('DD-MM-YYYY'),
          Quantity: parseInt(cbc.stickCount),
          Status: cbc.cbcStatus,
        };
      });
    }
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <React.Fragment>
      <Modal
        opened={showCbcHistoryModal}
        onClose={() => {
          setShowCbcHistoryModal(false);
          setSelectedCbc(null);
        }}
        title="CBC Details"
        size="xxl"
        centered={true}>
        <CBCHistory
          uid={selectedCbc?.uid}
          selectedCBC={{ productName: selectedCbc?.name, ...selectedCbc }}
          openCBC={true}
        />
      </Modal>
      <Modal
        opened={showLeftOutCbcModal}
        onClose={() => {
          setShowLeftOutCbcModal(false);
          setTriggerScanInBtn(false);
        }}
        title="Receive Order"
        size="xxl"
        centered={true}>
        {/* <UnscannedCbcsModal
          order={order}
          notScannedInCBC={notScannedInCBC}
          onUpdate={() => {
            onUpdate();
            refetch();
          }}
          isFetching={isFetching}
          scanActionData={cbcTabNames.scanIn}
        /> */}
        <ReceiveOrderModal
          order={order}
          onUpdate={() => {
            onUpdate();
            refetch();
          }}
          closeModal={() => {
            setShowLeftOutCbcModal(false);
            setTriggerScanInBtn(false);
          }}
          acceptBtnDisabled={
            quantityBreakDownForStats.totalQuantity !==
            quantityBreakDownForStats.deliveredQuantity
          }
          isFetching={isFetching}
          quantityBreakDownForStats={quantityBreakDownForStats}
        />
      </Modal>
      <Modal
        opened={showScanInModal}
        onClose={() => setShowScanInModal(false)}
        title="Scan Out CBCs"
        size="xl"
        centered={true}>
        <UnscannedCbcsModal
          order={order}
          notScannedInCBC={notScannedInCBC}
          onUpdate={() => {
            onUpdate();
            refetch();
            if (close) {
              setShowScanInModal(false);
            }
          }}
          scanActionData={cbcTabNames.scanOut}
        />
      </Modal>
      <Paper shadow="xs" p="md" mt="20px" radius="md" withBorder>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h3">
            CBCs
          </Title>
          <Flex gap={10}>
            <Stack justify="end">
              <ExportCsvXls
                setExportType={setExportType}
                exportData={exportData}
                btnLoader={false}
              />
            </Stack>
            <Divider orientation="vertical" />
            <div>
              <SearchInput
                handleRefresh={handleRefresh}
                handleSearch={handleSearch}
                placeholder="Search CBCs"
                liveSearch={true}
              />
            </div>
          </Flex>
        </Flex>
        <Space h="md" />

        <Flex justify="space-between">
          <Tabs
            variant="outline"
            value={cbcTab}
            onTabChange={setcbcTab}
            sx={{ flex: 1 }}>
            <Tabs.List>
              <Tabs.Tab
                value={cbcTabNames.scanIn}
                sx={{
                  backgroundColor:
                    cbcTab === cbcTabNames.scanIn
                      ? COLORS.primary
                      : COLORS.white,
                  color:
                    cbcTab === cbcTabNames.scanIn ? COLORS.white : COLORS.black,
                }}>
                Scan in at destination ({data?.data?.data?.scannedInCBC?.length}
                )
              </Tabs.Tab>
              <Tabs.Tab
                value={cbcTabNames.scanOut}
                sx={{
                  backgroundColor:
                    cbcTab === cbcTabNames.scanOut
                      ? COLORS.primary
                      : COLORS.white,
                  color:
                    cbcTab === cbcTabNames.scanOut
                      ? COLORS.white
                      : COLORS.black,
                }}>
                Scan out at source ({data?.data?.data?.scannedOutCBC?.length})
              </Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <Flex gap={5}>
            {(isArrayAndHasContent(notScannedInCBC) ||
              order?.isScanoutComplete) &&
              userRoles[user?.role] !== userRoles['GODOWN KEEPER'] &&
              userRoles[user?.role] !== userRoles['GODOWN MANAGER'] && (
                <Button
                  color="violet"
                  disabled={
                    orderStatusWeight[order?.orderStatus] !==
                    orderStatusWeight['IN TRANSIT']
                  }
                  onClick={() => {
                    setShowLeftOutCbcModal(true);
                  }}>
                  {quantityBreakDownForStats.totalQuantity !==
                  quantityBreakDownForStats.deliveredQuantity
                    ? 'Partial Receive'
                    : 'Receive Order'}
                </Button>
              )}
            {order?.orderType !== 'SO' && (
              <Button
                color="indigo"
                disabled={
                  orderStatusWeight[order?.orderStatus] >=
                    orderStatusWeight.LOADING ||
                  isScanOutable({
                    appUser: appUser,
                    destinationLocation: order?.destinationInformation,
                  })
                }
                onClick={() => {
                  setShowScanInModal(true);
                }}>
                Scan Out
              </Button>
            )}
          </Flex>
        </Flex>
        <Space h="md" />
        {isLoading ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : isArrayAndHasContent(
            cbcTab === cbcTabNames.scanIn ? scannedInCBC : scannedOutCBC,
          ) ? (
          <ScrollArea.Autosize mah="35vh">
            <LoadingOverlay visible={false} />
            <Table highlightOnHover>
              <thead
                style={{
                  backgroundColor: 'white',
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                }}>
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Barcode</th>
                  <th>Pack Code</th>
                  <th>Production Date</th>
                  <th>Quantity</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody
                style={{
                  verticalAlign: 'baseline',
                }}>
                {(cbcTab === cbcTabNames.scanIn
                  ? scannedInCBC
                  : scannedOutCBC
                ).map((cbc, index) => (
                  <tr key={index}>
                    <td>{cbc?.sku}</td>
                    <td
                      style={{
                        maxWidth: '200px',
                      }}>
                      {cbc.shipmentFormatName
                        ? cbc.shipmentFormatName
                        : cbc?.name}
                    </td>
                    <td>
                      {' '}
                      <Box>
                        <UnstyledButton
                          onClick={() => {
                            setShowCbcHistoryModal(true);
                            setSelectedCbc(cbc);
                          }}>
                          <Text color="blue">{cbc.barcode}</Text>
                        </UnstyledButton>{' '}
                      </Box>
                    </td>
                    <td>{cbc.packcode}</td>
                    <td>{dayjs(cbc.productionDate).format('DD-MM-YYYY')}</td>
                    <td>{parseInt(cbc.stickCount)}</td>
                    {/* <td>
                      <Badge
                        color={
                          cbc?.cbcStatus === cbcTabNames.scanOut
                            ? 'red'
                            : 'blue'
                        }>
                        {cbc.cbcStatus}
                      </Badge>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea.Autosize>
        ) : (
          <div>
            <NoDataPlaceholder
              title="No CBCs found"
              subtext={' '}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </div>
        )}
      </Paper>
    </React.Fragment>
  );
};

export default OrderDetailsCbcsTable;
