import * as yup from 'yup';

export const AddDistributorSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  regionId: yup.number().required('Region is required'),
  areaId: yup.number().required('Area is required'),
});

export const UpdateDistributorSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  regionId: yup.number().required('Region is required'),
  areaId: yup.number().required('Area is required'),
});
