import axios from './axios';

export const fetchArea = async (context) => {
  const response = await axios().get('/area', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      regionId: context.queryKey[3],
      pageLess: context.queryKey[4],
    },
  });
  return response;
};

export const createArea = async (values) => {
  const response = await axios().post('/area', values);
  return response;
};

export const updateArea = async (values) => {
  const response = await axios().patch('/area', values);
  return response;
};

export const toggleArea = async (values) => {
  const response = await axios().patch(`/area/${values.id}`, {
    status: values.status,
  });
  return response;
};
