import { notifications } from '@mantine/notifications';

export const NotificationUtil = ({
  success,
  color,
  title,
  message,
  autoClose = 3000,
}) => {
  notifications.show({
    color: success ? (color ? color : 'green') : 'red',
    title: title || 'Error',
    message: message || 'Something went wrong!',
    autoClose: autoClose,
  });
};
