import React from 'react';
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  HoverCard,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Space,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  Box,
} from '@mantine/core';
import {
  IconArticle,
  IconEdit,
  IconMapPin,
  IconPlus,
  IconRefresh,
  IconUserOff,
  IconX,
  IconBoxOff,
  IconBox,
} from '@tabler/icons-react';
import COLORS from '../../../constants/colors';
import { orderStatusBadgeColors } from '../../../constants/const';
import { IconCalendarTime } from '@tabler/icons-react';
import { IconMatchstick } from '@tabler/icons-react';
import dayjs from 'dayjs';

const OrderDetailsOtpCard = ({
  orderNumber,
  orderStatus,
  sourceName,
  sourceAddress,
  destinationName,
  destinationAddress,
  inventory,
  orderDate,
  orderType,
  orderDetails,
  changeSelectedOrder,
}) => {
  return (
    <Paper
      shadow="xs"
      p="md"
      mt="20px"
      radius="md"
      withBorder
      style={{ width: '100%' }}>
      <Flex justify="space-between" align="center">
        <Title order={1} size="md">
          Order No: #{orderNumber}
        </Title>
        <Flex align="center">
          <Badge color="pink">{orderType}</Badge>
          <Badge color={orderStatusBadgeColors[orderStatus]}>
            {orderStatus}
          </Badge>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" py="sm">
        <Box>
          <Flex justify="flex-start" align="center" gap={5} py="0.5rem">
            <IconMapPin color={COLORS.green} />
            <Title order={1} size="small">
              Source
            </Title>
          </Flex>
          <Flex style={{ fontSize: 'small' }} direction="column" gap={2}>
            <Badge color="cyan" size="md" radius="md">
              {sourceName.length > 20
                ? `${sourceName.slice(0, 19)}...`
                : sourceName}
            </Badge>
            <span style={{ fontSize: 'smaller' }}>{sourceAddress}</span>
          </Flex>
        </Box>
        <Box>
          <Flex justify="flex-start" align="center" gap={5} py="0.5rem">
            <IconMapPin color={COLORS.redMatt} />
            <Title order={1} size="small">
              Destination
            </Title>
          </Flex>
          <Flex style={{ fontSize: 'small' }} direction="column" gap={2}>
            <Badge color="orange" size="md" radius="md">
              {destinationName.length > 20
                ? `${destinationName.slice(0, 19)}...`
                : destinationName}
            </Badge>
            <span style={{ fontSize: 'smaller' }}>{destinationAddress}</span>
          </Flex>
        </Box>
      </Flex>
      <Flex justify="space-between" align="center" py="sm">
        <Flex direction="column" gap={5}>
          <Flex gap={5} align="center">
            <IconCalendarTime />
            <span style={{ fontSize: 'smaller' }}>
              {dayjs(orderDate).format('MMM DD, YYYY')}
            </span>
          </Flex>
          <Flex gap={5} align="center">
            <IconMatchstick />
            <span style={{ fontSize: 'smaller' }}>
              {inventory?.reduce(
                (acc, obj) => acc + obj.quantity * obj.product.packages.size,
                0,
              ) / 1000}{' '}
              TH
            </span>
          </Flex>
        </Flex>

        <Button
          sx={{
            backgroundColor: COLORS.primary,
          }}
          size="xs"
          onClick={() => changeSelectedOrder(orderDetails)}>
          Select Order
        </Button>
      </Flex>
    </Paper>
  );
};

export default OrderDetailsOtpCard;
