import {
  ActionIcon,
  Button,
  Flex,
  Loader,
  Modal,
  Pagination,
  Paper,
  ScrollArea,
  Skeleton,
  Stack,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconArticleOff, IconPlus, IconRefresh } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import NoDataPlaceholder from '../../../components/Global/NoDataPlaceholder';
import SearchInput from '../../../components/Global/SearchInput';
import ServerErrorBox from '../../../components/Global/ServerErrorBox';
import AddSettingsModal from '../../../components/Reports/AddSettingsModal';
import EmailReportsAccordion from '../../../components/Reports/EmailReportsAccordion';
import COLORS from '../../../constants/colors';
import {
  fetchEmailReportSettings,
  toggleEmailSettings,
} from '../../../services/reports';
import { NotificationUtil } from '../../../utils/notifications';
import {
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../../utils/utils';
import { useStyles } from './Styles';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Email Report';

const EmailReport = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [selectedSetting, setSelectedSetting] = useState(null);

  const [showAddSettingsModal, setShowAddSettingsModal] = useState(false);

  const [accordionState, setAccordionState] = useState(null);

  const { data, isLoading, isFetching, error, refetch } = useQuery({
    queryKey: ['email-report', searchKey, page],
    queryFn: () =>
      fetchEmailReportSettings({
        searchKey,
        page,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const { mutate: toggleMutate, isLoading: isToggling } = useMutation({
    mutationFn: async (settingId) => await toggleEmailSettings(settingId),
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const { total, reportEmailSettings } = data.data.data;

  return (
    <div>
      <Modal
        opened={showAddSettingsModal}
        onClose={() => {
          setShowAddSettingsModal(false);
        }}
        title={`${
          isObjectAndHasProperties(selectedSetting) ? 'Edit' : 'Add'
        } Email Report`}
        size="xl"
        centered={true}
        closeOnClickOutside={false}>
        <AddSettingsModal
          onUpdate={() => {
            refetch();
            setShowAddSettingsModal(false);
          }}
          onClose={() => {
            setShowAddSettingsModal(false);
          }}
          defaultValues={selectedSetting}
          update={isObjectAndHasProperties(selectedSetting)}
        />
      </Modal>{' '}
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={() => handleRefresh()}
                handleSearch={handleSearch}
                placeholder="Search Emails"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex justify="flex-end" align="center">
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                setSelectedSetting(null);
                setShowAddSettingsModal(true);
              }}
              leftIcon={<IconPlus />}>
              Add Email Report
            </Button>
          </Flex>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(reportEmailSettings) ? (
              <ScrollArea h="70vh">
                <EmailReportsAccordion
                  reportData={reportEmailSettings}
                  toggleMutate={toggleMutate}
                  setSelectedSetting={setSelectedSetting}
                  setShowAddSettingsModal={setShowAddSettingsModal}
                  accordionState={accordionState}
                  setAccordionState={setAccordionState}
                />
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No Settings found"
                  subtext={' '}
                  icon={<IconArticleOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="end">
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default EmailReport;
