import {
  ActionIcon,
  Badge,
  Box,
  Button,
  Flex,
  HoverCard,
  Loader,
  MultiSelect,
  Pagination,
  Paper,
  Skeleton,
  Space,
  Stack,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { IconBoxOff, IconRefresh } from '@tabler/icons-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SearchInput from '../../../components/Global/SearchInput';
import COLORS from '../../../constants/colors';

import { openConfirmModal } from '@mantine/modals';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { MantineReactTable } from 'mantine-react-table';
import NoDataPlaceholder from '../../../components/Global/NoDataPlaceholder';
import { userRoles } from '../../../constants/const';
import { fetchLocations } from '../../../services/location';
import { blockCBCs, fetchBlockableCbcs } from '../../../services/orders';
import { fetchProducts } from '../../../services/product';
import { NotificationUtil } from '../../../utils/notifications';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  scanActionBadgeColorMapper,
} from '../../../utils/utils';

const BlockCbcBox = ({ title, currentTab, requiredTab }) => {
  const queryClient = useQueryClient();

  const rowVirtualizerInstanceRef = useRef(null);

  const [rowSelection, setRowSelection] = useState({});

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [location, setLocation] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [barcode, setBarCode] = useState(null);
  const [packcode, setPackCode] = useState(null);
  const [type, setType] = useState('blockable');
  const [paginated, setPaginated] = useState(true);

  //check and uncheck value states
  const [checkedCvcs, setCheckedCvcs] = useState([]);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const appUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    const tempData = Object.keys(rowSelection);
    setCheckedCvcs(tempData);
  }, [rowSelection]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'barcode',
        header: 'Barcode',
        Cell: ({ cell }) => <Text size="xs">{cell.row.original.barcode}</Text>,
      },
      {
        accessorKey: 'packcode',
        header: 'Packcode',
        Cell: ({ cell }) => <Text size="xs">{cell.row.original.packcode}</Text>,
      },

      {
        accessorKey: 'sku',
        header: 'SKU',
        Cell: ({ cell }) => <Text size="xs">{cell.row.original.sku}</Text>,
      },
      {
        accessorKey: 'productName',
        header: 'Name',
        Cell: ({ cell }) => (
          <Text size="xs">{cell.row.original.productName}</Text>
        ),
      },
      {
        accessorKey: 'locationName',
        header: 'Location',
        Cell: ({ cell }) => (
          <HoverCard width={280} shadow="md" withinPortal>
            <HoverCard.Target>
              <Text fz="xs">{cell.row.original.locationName}</Text>
            </HoverCard.Target>
            <HoverCard.Dropdown>
              <Box>
                <Text fw={600} fz="sm">
                  {cell.row.original.locationName}
                </Text>
                <Flex gap={5} py="xs">
                  <Badge color="violet">
                    {cell.row.original.district || 'N/A'},{' '}
                  </Badge>
                  <Badge color="grape">
                    {cell.row.original.division || 'N/A'}
                  </Badge>
                </Flex>
                <Text size="xs">{cell.row.original.address}</Text>
              </Box>
            </HoverCard.Dropdown>
          </HoverCard>
        ),
      },
      {
        accessorKey: 'stickCount',
        header: 'Quantity - TH (sticks)',
        Cell: ({ cell }) => (
          <Text size="xs">
            {cell.row.original.stickCount
              ? cell.row.original.stickCount / 1000
              : 0}
          </Text>
        ),
      },
      {
        accessorKey: 'productionDate',
        header: 'Production Date',
        Cell: ({ cell }) => (
          <Text fz="xs">{dayjs(cell.getValue()).format('MMM DD, YYYY')} </Text>
        ),
      },
      {
        accessorKey: 'expiryDate',
        header: 'Expiry Date',
        Cell: ({ cell }) => (
          <Text fz="xs">{dayjs(cell.getValue()).format('MMM DD, YYYY')} </Text>
        ),
      },
      {
        accessorKey: 'cbcStatus',
        header: 'Status',
        Cell: ({ cell }) => (
          <Badge color={scanActionBadgeColorMapper(cell.getValue())}>
            {cell.getValue()}
          </Badge>
        ),
      },
    ],
    [],
  );

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: ['location', 1, null, null, null, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const {
    data: productsData,
    isLoading: productsLoading,
    error: productsError,
  } = useQuery({
    queryKey: ['products', 1, null, null, null],
    queryFn: fetchProducts,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [
      'blockablecbcs',
      barcode,
      packcode,
      productIds,
      location,
      type,
      paginated,
      page,
    ],
    queryFn: fetchBlockableCbcs,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    enabled: currentTab == requiredTab,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const refetchBlockedData = async () => {
    await queryClient.invalidateQueries({ queryKey: ['allblockedcvcs'] });
  };

  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: (value) => blockCBCs(value),
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: () => {
      refetch();
      refetchBlockedData();
      setCheckedCvcs([]);

      NotificationUtil({
        success: true,
        title: 'CBC Blocked',
        message: 'CBCs Blocked Successfully',
      });
    },
  });

  //blocking the selected cvcs
  const handleBlockCBC = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you want to block CBCs?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        mutate(checkedCvcs);
      },
    });
  };

  const sourceLocationData = () => {
    if (
      userRoles[appUser?.role] === userRoles.ADMIN ||
      userRoles[appUser?.role] === userRoles['SUPER ADMIN']
    ) {
      return locationData?.data?.data?.locations.map((location) => ({
        value: location.id,
        label: location.name,
      }));
    } else {
      return appUser.locations.map((location) => ({
        value: location.id,
        label: location.name,
      }));
    }
  };

  const sourceProductData = () => {
    if (
      userRoles[appUser?.role] === userRoles.ADMIN ||
      userRoles[appUser?.role] === userRoles['SUPER ADMIN']
    ) {
      return productsData?.data?.data?.products.map((product) => ({
        value: product.id,
        label: product.name,
      }));
    } else {
      return productsData?.data?.data?.products.map((product) => ({
        value: product.id,
        label: product.name,
      }));
    }
  };

  if (isLoading)
    return (
      <div>
        <Stack
          sx={{
            height: '80vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          mt={10}>
          <Loader variant="dots" />
          <Text fw={600} fz="sm">
            {' '}
            Loading data
          </Text>
        </Stack>
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const handleSearchBarCode = (value) => {
    setPage(1);
    setBarCode(value);

    if (value == '') {
      setPaginated(true);
    } else {
      setPaginated(null);
    }
  };

  const handleSearchPackCode = (value) => {
    setPage(1);
    setPackCode(value);

    if (value == '') {
      setPaginated(true);
    } else {
      setPaginated(null);
    }
  };

  const handleRefresh = () => {
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setBarCode('');
    setPackCode('');
  };

  const handleRefreshLocal = () => {
    setPage(1);
    setProductIds([]);
    setLocation([]);
    handleRefresh();
    setRowSelection({});
    setCheckedCvcs([]);
    setPaginated(true);
  };

  let { cbcs, total } = data.data.data;

  return (
    <div>
      <Flex
        justify="space-between"
        align="center"
        py="sm"
        style={{
          position: 'relative',
        }}>
        <Flex gap={20}>
          <MultiSelect
            data={sourceProductData() || []}
            placeholder="Search Product"
            searchable
            nothingFound="Nothing found"
            dropdownPosition="bottom"
            value={productIds}
            onChange={(e) => {
              setPaginated(true);
              setProductIds(e);
            }}
            maxSelectedValues={3}
            sx={{ maxWidth: 300 }}
          />

          <MultiSelect
            data={sourceLocationData() || []}
            placeholder="Search Location"
            searchable
            nothingFound="Nothing found"
            dropdownPosition="bottom"
            value={location}
            onChange={(e) => {
              setPaginated(true);
              setLocation(e);
            }}
            maxSelectedValues={3}
            sx={{ maxWidth: 300 }}
          />

          <SearchInput
            handleRefresh={handleRefresh}
            handleSearch={handleSearchPackCode}
            placeholder="Search Packcode"
            invokeRefresh={invokingRefreshForSearchInput}
            refreshBtn={false}
          />

          <SearchInput
            handleRefresh={handleRefresh}
            handleSearch={handleSearchBarCode}
            placeholder="Search Barcode"
            invokeRefresh={invokingRefreshForSearchInput}
            refreshBtn={false}
          />

          <Flex gap={20} align="flex-start" justify="center">
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={handleRefreshLocal}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
        <div
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}>
          <Button
            disabled={isArrayAndHasContent(checkedCvcs) ? false : true}
            sx={{
              backgroundColor: COLORS.pink,
            }}
            onClick={async () => await handleBlockCBC()}
            leftIcon={<IconBoxOff />}>
            Block
          </Button>
        </div>
      </Flex>

      {isFetching ? (
        <Stack
          sx={{
            height: '74vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          mt={10}>
          <Loader variant="dots" />
          <Text fw={600} fz="sm">
            {' '}
            Fetching data
          </Text>
        </Stack>
      ) : (
        <Stack
          sx={{
            minHeight: '74vh',
          }}>
          {isArrayAndHasContent(cbcs) ? (
            <>
              <MantineReactTable
                enableStickyHeader
                columns={columns}
                data={cbcs}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                //enableColumnVirtualization={false}
                enablePagination={false}
                //enableColumnActions={false}
                //enableRowVirtualization={false}
                mantineTableContainerProps={{ sx: { height: '68vh' } }}
                state={{ isLoading, isFetching, rowSelection }}
                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
                //rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
                //columnVirtualizerProps={{ overscan: 2 }}
                enableMultiRowSelection
                enableRowSelection
                enableSelectAll
                onRowSelectionChange={setRowSelection}
                getRowId={(originalRow) => originalRow.id}
              />
              {paginated && (
                <Flex justify="flex-end">
                  <Pagination
                    mt="20px"
                    value={page}
                    onChange={setPage}
                    total={Math.ceil(total / 10)}
                  />
                </Flex>
              )}
            </>
          ) : (
            <div>
              <NoDataPlaceholder
                title="No Blockable CBCs found"
                subtext={' '}
                icon={<IconBoxOff size={70} color="#4562f7" />}
              />
            </div>
          )}
        </Stack>
      )}
    </div>
  );
};

export default BlockCbcBox;
