import AssignRouteSection from '../components/Location/AssignRouteSection';
import ActionLogs from '../pages/ActionLogs/ActionLogs';
import Dashboard from '../pages/Dashboard/Dashboard';
import DayZeroreconciliation from '../pages/DayZeroReconcilation/DayZeroReconciliation';
import DayZeroreconciliationDetails from '../pages/DayZeroReconcilation/DayZeroReconciliationDetails';
import DownloadApp from '../pages/DownloadApp/DownloadApp';
import ErrorLogs from '../pages/ErrorLogs/ErrorLogs';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import BlockCbc from '../pages/Inventory/Cvc/BlockCbc';
import InventoryManagement from '../pages/Inventory/InventoryManagement';
import ProductDetails from '../pages/Inventory/Product/ProductDetails';
import LocationManagement from '../pages/Location/LocationManagement';
import LocationsManage from '../pages/Location/Manage/LocationsManage';
import MobileApplcations from '../pages/MobileApplications/MobileApplcations';
import InboundOrders from '../pages/Orders/InboundOrders';
import OrderDetails from '../pages/Orders/OrderDetails';
import OutboundOrders from '../pages/Orders/OutboundOrders';
import Products from '../pages/Product/Products';
import Daily_Open_CBC_Scan_Performance_Report from '../pages/Reports/Details/Daily_Open_CBC_Scan_Performance_Report';
import EmailReport from '../pages/Reports/Details/EmailReport';
import GODOWN_KEEPER_APP_VERSION_LIST from '../pages/Reports/Details/GODOWN_KEEPER_APP_VERSION_LIST';
import OneView from '../pages/Reports/Details/OneView';
import OpenCbcreport from '../pages/Reports/Details/Open_Cbc_report';
import SO_IGT_Scan_Performance_Report from '../pages/Reports/Details/SO_IGT_Scan_Performance_Report';
import Reports from '../pages/Reports/Reports';
import Settings from '../pages/Settings/Settings';
import UserManagement from '../pages/Users/UserManagement';
import UsersLogout from '../pages/Users/UsersLogout';
import Otp from '../pages/auth/Otp';
import SignIn from '../pages/auth/SignIn';
import * as urls from './app-urls';

const routes = [
  //UNPROTECTED ROUTES
  {
    path: urls.SIGNIN,
    Element: SignIn,
    isIndexUrl: true,
    isProtected: false,
  },
  {
    path: urls.DOWNLOAD_APP,
    Element: DownloadApp,
    isIndexUrl: false,
    isProtected: false,
  },
  {
    path: urls.FORGOT_PASSWORD,
    Element: ForgotPassword,
    isIndexUrl: false,
    isProtected: false,
  },
  {
    path: urls.VERIFY_OTP,
    Element: Otp,
    isIndexUrl: false,
    isProtected: false,
  },
  //PROTECTED ROUTES
  {
    path: urls.DASHBOARD,
    Element: Dashboard,
    isIndexUrl: false,
    isProtected: true,
  },
  //user management routes
  {
    path: urls.USER_LOGOUT,
    Element: UsersLogout,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.USERS,
    Element: UserManagement,
    isIndexUrl: false,
    isProtected: true,
  },
  //location management routes
  {
    path: urls.LOCATIONS,
    Element: LocationManagement,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: `${urls.LOCATIONS_ROUTE_SECTION}/:uid`,
    Element: AssignRouteSection,
    isIndexUrl: false,
    isProtected: true,
  },
  ////distributor management routes
  {
    path: urls.LOCATIONS_MANAGE,
    Element: LocationsManage,
    isIndexUrl: false,
    isProtected: true,
  },
  //order management routes
  {
    path: urls.ORDERS,
    Element: OutboundOrders,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.ORDER_DETAILS,
    Element: OrderDetails,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.INBOUND_ORDERS,
    Element: InboundOrders,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.OUTBOUND_ORDERS,
    Element: OutboundOrders,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.INBOUND_ORDER_DETAILS,
    Element: OrderDetails,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.OUTBOUND_ORDER_DETAILS,
    Element: OrderDetails,
    isIndexUrl: false,
    isProtected: true,
  },
  //inventory management routes
  {
    path: urls.INVENTORY,
    Element: InventoryManagement,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.BLOCKCBC,
    Element: BlockCbc,
    isIndexUrl: false,
    isProtected: true,
  },
  ////product routes
  {
    path: urls.PRODUCTS,
    Element: ProductDetails,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.PRODUCTS_ALL,
    Element: Products,
    isIndexUrl: false,
    isProtected: true,
  },
  //logs management routes
  {
    path: urls.LOGS,
    Element: ActionLogs,
    isIndexUrl: false,
    isProtected: true,
  },
  //error logs routes
  {
    path: urls.ERROR_LOGS,
    Element: ErrorLogs,
    isIndexUrl: false,
    isProtected: true,
  },
  //mobile applications
  {
    path: urls.MOBILE_APPLICATIONS,
    Element: MobileApplcations,
    isIndexUrl: false,
    isProtected: true,
  },
  //reports
  {
    path: urls.REPORTS,
    Element: Reports,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: `${urls.SO_IGT_Scan_Performance_Report}/:orderType`,
    Element: SO_IGT_Scan_Performance_Report,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.Daily_Open_CBC_Scan_Performance_Report,
    Element: Daily_Open_CBC_Scan_Performance_Report,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.GODOWN_KEEPER_APP_VERSION_LIST,
    Element: GODOWN_KEEPER_APP_VERSION_LIST,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.OneViewReport,
    Element: OneView,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.EmailReport,
    Element: EmailReport,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.OPEN_CBC_REPORT,
    Element: OpenCbcreport,
    isIndexUrl: false,
    isProtected: true,
  },
  //DAY ZERO RECONCILIATION
  {
    path: urls.DAY_ZERO_RECONCILIATION,
    Element: DayZeroreconciliation,
    isIndexUrl: false,
    isProtected: true,
  },
  {
    path: urls.DAY_ZERO_RECONCILIATION_DETAILS,
    Element: DayZeroreconciliationDetails,
    isIndexUrl: false,
    isProtected: true,
  },
  //SETTINGS
  {
    path: urls.SETTINGS,
    Element: Settings,
    isIndexUrl: false,
    isProtected: true,
  },
];

export default routes;
