import * as yup from 'yup';

export const AddAreaSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  regionId: yup.number().required('Region is required'),
});

export const UpdateAreaSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  regionId: yup.number().required('Region is required'),
});
