import { createSlice } from '@reduxjs/toolkit';

const initState = {
  items: [],
};

const locationSlice = createSlice({
  name: 'location',
  initialState: initState,
  reducers: {
    addLocation: (state, action) => {
      state.items = action.payload;
    },
  },
});

export const locationActions = locationSlice.actions;
export default locationSlice.reducer;
