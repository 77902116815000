import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { assignRouteSection, fetchLocation } from '../../services/location';
import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Flex,
  Modal,
  Paper,
  ScrollArea,
  Skeleton,
  Space,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import ServerErrorBox from '../Global/ServerErrorBox';
import {
  IconChevronLeft,
  IconDownload,
  IconEdit,
  IconTrash,
  IconUpload,
} from '@tabler/icons-react';
import { isArrayAndHasContent } from '../../utils/utils';
import COLORS from '../../constants/colors';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import UploadRouteSectionModal from './UploadRouteSectionModal';
import { NotificationUtil } from '../../utils/notifications';
import { openConfirmModal } from '@mantine/modals';

const PAGE_TITLE = 'Route/Section';

const AssignRouteSection = () => {
  const navigate = useNavigate();
  const { uid } = useParams();

  const [checkedRouteSectionsIndex, setCheckedRouteSectionsIndex] = useState(
    [],
  );
  const [checkedRouteSectionIndexForEdit, setCheckedRouteSectionIndexForEdit] =
    useState(null);

  const [editedValue, setEditedValue] = useState(null);

  const [uploadModal, setUploadModal] = useState(false);

  useEffect(() => {
    document.title = 'Route-Section | Armada';
  }, []);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['single-location', uid],
    enabled: !!uid,
    queryFn: () => fetchLocation(uid),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const exportData = () => {
    const location = data.data.data.location;
    const fileName = `${location.name}-route-section-${dayjs(new Date()).format(
      'DD-MM-YYYY',
    )}`;
    const exportData = location.routeSection.map((route) => {
      return {
        'Route/Section': route,
      };
    });

    console.log(exportData);

    exportFromJSON({ data: exportData, fileName, exportType: 'xls' });
  };

  const deleteRouteSectionWithCheckedIndex = () => {
    const location = data.data.data.location;
    const newRouteSections = location.routeSection.filter(
      (_, index) => !checkedRouteSectionsIndex.includes(index),
    );
    ConfirmDeleteModal({
      uid: location.uid,
      routeSections: newRouteSections,
    });
  };

  const editRouteSectionFromCheckedIndexForEdit = () => {
    const location = data.data.data.location;
    const newRouteSections = location.routeSection.map((route, index) => {
      if (index === checkedRouteSectionIndexForEdit) {
        return editedValue;
      }
      return route;
    });
    ConfirmEditModal({
      uid: location.uid,
      routeSections: newRouteSections,
    });
  };

  const ConfirmDeleteModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you delete these route/sections?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        assignMutate(values);
      },
    });
  };

  const ConfirmEditModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you edit this route/sections?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        assignMutate(values);
      },
    });
  };

  const { mutate: assignMutate, isLoading: isAssigning } = useMutation({
    mutationFn: async (values) => await assignRouteSection(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Assigned',
        message: 'Route/Section updated successfully',
      });
      setCheckedRouteSectionsIndex([]);
      setCheckedRouteSectionIndexForEdit(null);
      setEditedValue(null);
      refetch();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  if (isLoading)
    return (
      <div>
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { location } = data.data.data;

  return (
    <div>
      <Modal
        opened={uploadModal}
        onClose={() => setUploadModal(false)}
        title="Upload Route/Section"
        size="auto"
        closeOnClickOutside={false}
        centered={true}>
        <UploadRouteSectionModal
          location={data.data.data.location}
          onUpdate={() => {
            refetch();
            setUploadModal(false);
          }}
        />
      </Modal>
      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          {location.name}
        </Title>
        <Button
          color="gray"
          onClick={() => {
            navigate(-1);
          }}
          leftIcon={<IconChevronLeft />}>
          Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center">
          <Title order={4}>
            Assigned Route/Section{' '}
            {location?.routeSection?.length > 0 && (
              <Badge color="teal" size="xl">
                {location?.routeSection?.length}
              </Badge>
            )}
          </Title>
          <Flex gap={10} align="center">
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                deleteRouteSectionWithCheckedIndex();
              }}
              disabled={!isArrayAndHasContent(checkedRouteSectionsIndex)}
              leftIcon={<IconTrash />}>
              Delete Selected
            </Button>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                exportData();
              }}
              disabled={!isArrayAndHasContent(location?.routeSection)}
              leftIcon={<IconDownload />}>
              Download XLS
            </Button>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                setUploadModal(true);
              }}
              leftIcon={<IconUpload />}>
              Upload XLS
            </Button>
          </Flex>
        </Flex>
        <Space h={15} />

        {isArrayAndHasContent(location?.routeSection) ? (
          <ScrollArea h="70vh">
            <Table highlightOnHover withBorder={true}>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 10,
                  backgroundColor: 'white',
                }}>
                <tr>
                  <th
                    style={{
                      width: '8%',
                    }}>
                    <Flex gap={10}>
                      <Checkbox
                        checked={
                          checkedRouteSectionsIndex.length ===
                          location?.routeSection.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckedRouteSectionsIndex(
                              location?.routeSection.map((_, index) => index),
                            );
                          } else {
                            setCheckedRouteSectionsIndex([]);
                          }
                        }}
                      />{' '}
                    </Flex>
                  </th>
                  <th>Route/Section</th>
                </tr>
              </thead>
              <tbody>
                {location?.routeSection.map((location, index) => (
                  <tr key={index}>
                    <td>
                      {' '}
                      <Checkbox
                        checked={checkedRouteSectionsIndex.includes(index)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCheckedRouteSectionsIndex([
                              ...checkedRouteSectionsIndex,
                              index,
                            ]);
                          } else {
                            setCheckedRouteSectionsIndex(
                              checkedRouteSectionsIndex.filter(
                                (i) => i !== index,
                              ),
                            );
                          }
                        }}
                      />
                    </td>
                    <td
                      style={{
                        maxWidth: '120px',
                      }}>
                      <Flex align="center" gap={20}>
                        {checkedRouteSectionIndexForEdit !== index && location}
                        {checkedRouteSectionIndexForEdit === index ? (
                          <Flex gap={10}>
                            <TextInput
                              value={editedValue}
                              onChange={(e) => {
                                setEditedValue(e.target.value);
                              }}
                            />
                            <Button
                              onClick={() => {
                                editRouteSectionFromCheckedIndexForEdit();
                              }}>
                              Save
                            </Button>
                            <Button
                              color="red"
                              onClick={() => {
                                setCheckedRouteSectionIndexForEdit(null);
                              }}>
                              Cancel
                            </Button>
                          </Flex>
                        ) : (
                          <Flex gap={5} align="center">
                            <Tooltip label={'Edit'}>
                              <ActionIcon
                                variant="light"
                                color="cyan"
                                onClick={() => {
                                  setEditedValue(location);
                                  setCheckedRouteSectionIndexForEdit(index);
                                }}>
                                <IconEdit size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        )}
                      </Flex>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        ) : (
          <div>
            <Stack sx={{ width: '100%' }}>
              <Text c="dimmed" align="center">
                No route/section assigned yet
              </Text>
            </Stack>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default AssignRouteSection;
