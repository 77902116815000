import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inventoryTab: 'inventory',
  manageCbcTab: 'blockedcbc',
};

const TabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setInventoryTab: (state, action) => {
      state.inventoryTab = action.payload.inventoryTab;
    },
    setManageCbcTab: (state, action) => {
      state.manageCbcTab = action.payload.manageCbcTab;
    },
  },
});

export const TabActions = TabSlice.actions;
export default TabSlice.reducer;
