import { ActionIcon, Flex, Select, Text, Tooltip } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import React from 'react';
import COLORS from '../../constants/colors';

const ExportCsvXls = ({ setExportType, exportData, btnLoader }) => {
  return (
    <div>
      <Flex align="center" gap={20}>
        <Text>Export as</Text>
        <Select
          onChange={(value) => setExportType(value)}
          sx={{ width: '90px' }}
          data={[
            { value: 'csv', label: 'CSV' },
            { value: 'xls', label: 'XLS' },
          ]}
        />
        <Tooltip label="Download">
          <ActionIcon
            size="md"
            onClick={() => exportData()}
            variant="filled"
            sx={{
              backgroundColor: COLORS.primary,
            }}
            loading={btnLoader}>
            <IconDownload />
          </ActionIcon>
        </Tooltip>
      </Flex>
    </div>
  );
};

export default ExportCsvXls;
