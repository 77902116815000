import {
  ActionIcon,
  Badge,
  Button,
  Card,
  CopyButton,
  Flex,
  Loader,
  LoadingOverlay,
  Modal,
  Popover,
  Space,
  Stack,
  Text,
  TextInput,
  Title,
  Tooltip,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import {
  IconAlertTriangle,
  IconArticle,
  IconChecks,
  IconCopy,
  IconFileBarcode,
  IconHammer,
  IconPencil,
  IconTruck,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  orderStatusBadgeColors,
  orderStatusConst,
  userRoles,
} from '../../../constants/const';
import {
  approveOrder,
  cancelOrder,
  reconOrder,
} from '../../../services/orders';
import { NotificationUtil } from '../../../utils/notifications';
import { useState } from 'react';
import BarcodeModal from '../BarcodeModal';
import COLORS from '../../../constants/colors';
import EditTruckNumberCard from './EditTruckNumberCard';
import { useSelector } from 'react-redux';

const OrderDetailsInfoCard = ({
  order,
  isOrderEditable,
  setSelectedOrder,
  setCreateIgtOrdersDrawer,
  setLogsDrawer,
  onUpdate,
  setResolveDrawer,
}) => {
  const appUser = useSelector((state) => state.auth.user);
  const [showBarcodeMOdal, setShowBarcodeMOdal] = useState(false);
  const [editTruckNumberCard, setEditTruckNumberCard] = useState(false);

  const { mutate: approveMutate, isLoading: isApproving } = useMutation({
    mutationFn: async (values) => await approveOrder(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Order approved successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: cancelMutate, isLoading: isCancelling } = useMutation({
    mutationFn: async (values) => await cancelOrder(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Order cancelled successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const ConfirmApproveModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you approve this order?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        approveMutate({
          uid: order.uid,
        });
      },
    });
  };

  const cancelMutateValidation = (data) => {
    if (data?.remarks === null) {
      NotificationUtil({
        success: false,
        title: 'Could not cancel order',
        message: 'Please state your reason',
      });
      return;
    }
    cancelMutate(data);
  };
  const ConfirmCancelModal = () => {
    let remark = null;
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <>
          <Text size="sm">Are you sure you cancel this order?</Text>
          <Space h="md" />
          <TextInput
            label="Please state your reason"
            placeholder="Your remark"
            data-autofocus
            onChange={(event) => {
              if (event.currentTarget.value === '') {
                remark = null;
                return;
              }

              remark = event.currentTarget.value;
            }}
          />
        </>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        cancelMutateValidation({
          uid: order.uid,
          remarks: remark,
        });
        remark = null;
      },
      onCancel: () => {
        remark = null;
      },
    });
  };

  const isReconBtnShowable = () => {
    if (
      appUser.role === userRoles['SUPER ADMIN'] &&
      (order?.orderStatus === orderStatusConst.LOADING ||
        order?.orderStatus === orderStatusConst['IN QUEUE']) &&
      order?.orderType === 'SO'
    ) {
      return true;
    }
    return false;
  };

  const { mutate: recontMutate, isLoading: isReconing } = useMutation({
    mutationFn: async (values) => await reconOrder(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Order reconciled successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Modal
        opened={showBarcodeMOdal}
        onClose={() => setShowBarcodeMOdal(false)}
        title="Print Order Sheet"
        size="xl"
        centered={true}>
        <BarcodeModal order={order} barcode={order?.orderNumber} />
      </Modal>
      {/* CUSTOM MODAL AND DRAWER END */}
      <Card
        shadow="xs"
        padding="lg"
        sx={{
          width: '100%',
          height: '100%',
        }}
        radius="md"
        withBorder>
        <LoadingOverlay
          visible={isApproving || isCancelling}
          overlayBlur={2}
          loader={
            <Stack justify="center" align="center">
              <Text>
                {isApproving && 'Approving'}
                {isCancelling && 'Cancelling'}. Please wait
              </Text>
              <Loader />
            </Stack>
          }
        />
        <Stack>
          <Flex align="center" gap={10} wrap="wrap">
            <CopyButton value={order?.orderNumber}>
              {({ copied, copy }) => (
                <Tooltip label="copy order number" withArrow>
                  <Button
                    variant="subtle"
                    color={copied ? 'teal' : 'dark'}
                    onClick={copy}
                    rightIcon={<IconCopy />}
                    sx={{
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}>
                    <Title order={1} size="h3">
                      {order?.orderNumber.toUpperCase()}
                    </Title>
                  </Button>
                </Tooltip>
              )}
            </CopyButton>
            <Badge
              color={orderStatusBadgeColors[order?.orderStatus]}
              size="md"
              variant="filled"
              radius="md">
              {order?.orderStatus}
            </Badge>
            {order?.orderType !== 'SO' && (
              <Tooltip
                label={
                  order?.isApproved
                    ? `Approved by: ${order?.approver?.name}`
                    : 'Not approved yet'
                }
                withArrow>
                <Badge color="indigo" variant="filled" size="md" radius="md">
                  {order?.isApproved ? 'Approved' : 'Not Approved'}
                </Badge>
              </Tooltip>
            )}
            <Badge color="violet" variant="filled" size="md" radius="md">
              {order?.orderType} {order?.isMobileIgt ? ' (Mobile)' : null}
            </Badge>
          </Flex>
          <Flex gap={10} align="center">
            <IconTruck />
            <Text>{order?.truckNumber || 'No truck assigned yet'}</Text>
            {isOrderEditable ? (
              <Popover
                width={300}
                trapFocus
                position="bottom"
                withArrow
                shadow="md"
                withinPortal={true}
                opened={editTruckNumberCard}
                onChange={setEditTruckNumberCard}>
                <Popover.Target>
                  <Tooltip label="Edit Truck Number" withArrow>
                    <ActionIcon
                      color="pink"
                      variant="filled"
                      onClick={() => {
                        setEditTruckNumberCard(!editTruckNumberCard);
                      }}>
                      <IconPencil size="1.125rem" />
                    </ActionIcon>
                  </Tooltip>
                </Popover.Target>
                <Popover.Dropdown
                  sx={(theme) => ({
                    background:
                      theme.colorScheme === 'dark'
                        ? theme.colors.dark[7]
                        : theme.white,
                  })}>
                  <EditTruckNumberCard
                    order={order}
                    onUpdate={() => {
                      setEditTruckNumberCard(false);
                      onUpdate();
                    }}
                  />
                </Popover.Dropdown>
              </Popover>
            ) : null}
          </Flex>
          <Flex>
            <Stack>
              <Text>{dayjs(order?.orderDate).format('MMM DD, YYYY')}</Text>
            </Stack>
          </Flex>
          <Flex align="center" gap={5}>
            {isOrderEditable && (
              <>
                <Tooltip label="Edit Order" withArrow>
                  <ActionIcon
                    color="pink"
                    variant="filled"
                    disabled={
                      order?.orderStatus === orderStatusConst.CANCELLED ||
                      order?.orderStatus === orderStatusConst['IN TRANSIT'] ||
                      order?.orderStatus === orderStatusConst.UNLOADING ||
                      order?.orderStatus === orderStatusConst.COMPLETE
                    }
                    onClick={() => {
                      if (
                        order?.orderStatus === orderStatusConst.PENDING ||
                        order?.orderStatus === orderStatusConst['IN QUEUE'] ||
                        order?.orderStatus === orderStatusConst.LOADING
                      ) {
                        const orderProducts = [];
                        order?.inventory?.map((item) => {
                          const product = {
                            category: item.category,
                            cbcQuantity: item.quantity,
                            stock: item.stock,
                            id: item.product.id,
                            name: item.product.shipmentFormatName
                              ? item.product.shipmentFormatName
                              : item.product.name,
                            sku: item.sku,
                            uid: item.productUid,
                            packages: item.product.packages,
                            value: `${
                              item.product.shipmentFormatName
                                ? item.product.shipmentFormatName
                                : item.product.name
                            } - ${item.sku}`,
                          };
                          orderProducts.push({
                            product: product,
                            cbcQuantity: item.quantity,
                            sku: item.sku,
                          });
                        });
                        setSelectedOrder({
                          orderNumber: order.orderNumber,
                          uid: order.uid,
                          destination: {
                            uid: order?.destinationInformation?.uid,
                            value: order?.destinationInformation?.name,
                          },
                          source: {
                            uid: order?.sourceInformation?.uid,
                            value: order?.sourceInformation?.name,
                          },
                          orderProducts: orderProducts,
                          orderDate: order.orderDate,
                          isApproved: order.isApproved,
                          orderStatus: order.orderStatus,
                        });

                        setCreateIgtOrdersDrawer(true);
                      } else {
                        NotificationUtil({
                          success: false,
                          title: 'Cannot Edit',
                          message: 'This order is not in editable anymore.',
                        });
                      }
                    }}>
                    <IconPencil size="1.125rem" />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Approve Order" withArrow>
                  <ActionIcon
                    color="green"
                    variant="filled"
                    onClick={ConfirmApproveModal}
                    disabled={
                      order?.isApproved ||
                      order?.orderStatus === orderStatusConst.CANCELLED
                    }>
                    <IconChecks size="1.125rem" />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Cancel Order" withArrow>
                  <ActionIcon
                    color="red"
                    variant="filled"
                    onClick={ConfirmCancelModal}
                    disabled={
                      order?.orderStatus === orderStatusConst.CANCELLED ||
                      order?.orderStatus === orderStatusConst.COMPLETE
                    }>
                    <IconX size="1.125rem" />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
            <Tooltip label="Print Order Sheet" withArrow>
              <ActionIcon
                color="violet"
                variant="filled"
                onClick={() => {
                  setShowBarcodeMOdal(true);
                }}
                disabled={order?.orderStatus === orderStatusConst.CANCELLED}>
                <IconFileBarcode size="1.125rem" />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Logs" withArrow>
              <ActionIcon
                color="blue"
                variant="filled"
                onClick={() => {
                  setLogsDrawer(true);
                }}>
                <IconArticle size="1.125rem" />
              </ActionIcon>
            </Tooltip>
            {isReconBtnShowable() && (
              <Tooltip label="Reconcile Order" withArrow>
                <ActionIcon
                  color="yellow"
                  variant="filled"
                  onClick={() => {
                    recontMutate({
                      symphonyReferenceId: order?.symphonyReferenceId,
                    });
                  }}>
                  <IconHammer size="1.125rem" />
                </ActionIcon>
              </Tooltip>
            )}
            {order?.partialReceiveHsitory?.length > 0 && (
              <Tooltip label="Order Issues" withArrow>
                <ActionIcon
                  color="yellow"
                  variant="filled"
                  onClick={() => {
                    setResolveDrawer(true);
                  }}>
                  <IconAlertTriangle size="1.125rem" />
                </ActionIcon>
              </Tooltip>
            )}
          </Flex>
        </Stack>
      </Card>
    </div>
  );
};

export default OrderDetailsInfoCard;
