import * as yup from 'yup';

export const AddTerritorySchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  regionId: yup.number().required('Region is required'),
  areaId: yup.number().required('Area is required'),
  distributorId: yup.number().required('Distributor is required'),
});

export const UpdateTerritorySchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  regionId: yup.number().required('Region is required'),
  areaId: yup.number().required('Area is required'),
  distributorId: yup.number().required('Distributor is required'),
});
