import {
  ActionIcon,
  Autocomplete,
  Box,
  Flex,
  HoverCard,
  LoadingOverlay,
  Modal,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { IconBoxOff, IconEye, IconRefresh, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import { fetchInventory } from '../../../services/product';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  userWiseAutocompleteLocationDataWithId,
} from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import SearchInput from '../../Global/SearchInput';
import exportFromJSON from 'export-from-json';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import dayjs from 'dayjs';
import CbcBreakdown from '../Modal/CbcBreakdown';
import { formatStatValue } from '../../Charts/helper';

const InventoryTab = ({ locationData, currentTab, requiredTab }) => {
  const appUser = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [searchByLocation, setSearchByLocation] = useState(null);
  const [locationSearchKey, setLocationSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [selectedInventory, setSelectedInventory] = useState(null);
  const [showCbcBreakdownModal, setShowCbcBreakdownModal] = useState(false);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['inventory', page, searchKey, null, searchByLocation],
    queryFn: fetchInventory,
    refetchOnWindowFocus: true,
    // refetchInterval: 5 * 60 * 1000,
    // refetchIntervalInBackground: true,
    enabled: currentTab == requiredTab ? true : false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { isFetching: pagelessFetching, refetch: pagelessRefetch } = useQuery({
    queryKey: ['inventory-pageless', null, null, true, null],
    queryFn: fetchInventory,
    enabled: exportToggler,
    refetchOnWindowFocus: true,
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `INVENTORY-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = response.data.data.products.map((product) => {
        return {
          SKU: product.sku,
          'Product Name': `${
            product.shipmentFormatName
              ? product.shipmentFormatName
              : product.name
          }`,
          'Distribution Point': product.locationName,
          // Address: product.address,
          'Quantity - TH (sticks)': product.stickCount / 1000,
          'Intact CBC stick count - TH (sticks)': product?.intactCbcstickCount
            ? `${product?.intactCbcstickCount / 1000}`
            : 0,
          'Block CBC stick count - TH (sticks)': product?.blockCbcstickCount
            ? `${product?.blockCbcstickCount / 1000}`
            : 0,
          // 'Open CBC stick count - TH': product?.openCBCStickCount
          //   ? `${product?.openCBCStickCount / 1000}`
          //   : 'N/A',
          'CBC Quantity': product.cbcCount,
          'Intact CBC': product?.intactCbc ? product?.intactCbc : 0,
          'Block CBC': product?.blockCbc ? product?.blockCbc : 0,
          'Open CBC': product?.openCBC ? product?.openCBC : 0,
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setLocationSearchKey(null);
    setSearchByLocation(null);
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      pagelessRefetch();
    } else {
      pagelessFetching();
    }
  };

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const { products, total } = data.data.data;

  return (
    <div>
      <Modal
        opened={showCbcBreakdownModal}
        onClose={() => {
          setShowCbcBreakdownModal(false);
          setSelectedInventory(null);
        }}
        title={<Text fw="600">CBC Breakdown</Text>}
        size="xxl"
        centered={true}>
        <CbcBreakdown inventory={selectedInventory} />
      </Modal>
      <Flex justify="space-between" align="center" py="sm">
        <Flex gap={20}>
          <div
            style={{
              minWidth: '300px',
            }}>
            <SearchInput
              handleRefresh={handleRefresh}
              handleSearch={handleSearch}
              placeholder="Search SKU, Name"
              invokeRefresh={invokingRefreshForSearchInput}
              refreshBtn={false}
            />
          </div>
          <Autocomplete
            placeholder="Search by Point"
            sx={{
              minWidth: '250px',
            }}
            onChange={(value) => {
              if (locationSearchKey && locationSearchKey.length === 0) {
                setLocationSearchKey(null);
              } else {
                setLocationSearchKey(value);
              }
            }}
            limit={locationData?.length || 0}
            maxDropdownHeight="200px"
            dropdownPosition="bottom"
            nothingFound="No options"
            value={locationSearchKey || ''}
            onItemSubmit={(e) => {
              setSearchByLocation(e.id);
            }}
            rightSection={
              searchByLocation ? (
                <UnstyledButton
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setLocationSearchKey(null);
                    setSearchByLocation(null);
                  }}>
                  <IconX size={15} />
                </UnstyledButton>
              ) : null
            }
            data={
              userWiseAutocompleteLocationDataWithId({
                appUser: appUser,
                locationData: locationData,
              }) || []
            }
          />

          <Flex gap={20} align="center" justify="center">
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={() => {
                  handleRefresh();
                }}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Stack
        sx={{
          minHeight: '50vh',
        }}>
        {isArrayAndHasContent(products) ? (
          <ScrollArea h="65vh">
            <Table highlightOnHover>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}>
                <tr>
                  <th>SKU</th>
                  <th>Product Name</th>
                  {/* <th>Current Location</th> */}
                  {/* <th>Quantity - TH (sticks)</th> */}
                  {/* <th>CBC Quantity</th> */}
                  <th>Intact CBC</th>
                  <th>Block CBC</th>
                  {/* <th>Open CBC</th> */}

                  <th></th>
                </tr>
              </thead>
              <tbody>
                <LoadingOverlay visible={isFetching} />
                {products.map((product, i) => (
                  <tr key={i}>
                    <td>
                      <Box
                        //component={Link}
                        //to={`/inventory/product-details/${product.productUid}`}
                        sx={{
                          textDecoration: 'none',
                        }}>
                        {product.sku}
                      </Box>
                    </td>
                    <td
                      style={{
                        maxWidth: '200px',
                      }}>
                      <Box
                        //component={Link}
                        //to={`/inventory/product-details/${product.productUid}`}
                        sx={{
                          textDecoration: 'none',
                        }}>
                        {product.shipmentFormatName
                          ? product.shipmentFormatName
                          : product.name}
                      </Box>
                    </td>
                    {/* <td>
                      <HoverCard width={280} shadow="md">
                        <HoverCard.Target>
                          <UnstyledButton>
                            <Text fz="sm">{product.locationName}</Text>
                          </UnstyledButton>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Text size="md">{product.locationName}</Text>
                          <Text size="sm">
                            {product.district || 'N/A'},{' '}
                            {product.division || 'N/A'}
                          </Text>
                          <Text size="sm">{product.address}</Text>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </td> */}
                    {/* <td>
                      <HoverCard width={280} shadow="md">
                        <HoverCard.Target>
                          <UnstyledButton>
                            <Text fz="sm">{product?.stickCount / 1000}</Text>
                          </UnstyledButton>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Stack>
                            <Text fz="sm">
                              Intact CBC stick count :{' '}
                              {product?.intactCbcstickCount
                                ? `${product?.intactCbcstickCount / 1000}TH`
                                : 'N/A'}
                            </Text>

                            <Text fz="sm">
                              Block CBC stick count:{' '}
                              {product?.blockCbcstickCount
                                ? `${product?.blockCbcstickCount / 1000}TH`
                                : 'N/A'}
                            </Text>
                            <Text fz="sm">
                              Open CBC stick count:{' '}
                              {product?.openCBCStickCount
                                ? `${product?.openCBCStickCount / 1000}TH`
                                : 'N/A'}
                            </Text>
                          </Stack>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </td> */}
                    {/* <td>{product.cbcCount}</td> */}
                    <td>
                      <Flex align="center" gap={5}>
                        {product?.intactCbc ? product?.intactCbc : 0}
                        <Text fz="xs" color="dimmed">
                          (
                          {product?.intactCbcstickCount
                            ? product?.intactCbcstickCount / 1000
                            : 0}{' '}
                          TH)
                        </Text>
                      </Flex>
                    </td>
                    <td>
                      <Flex align="center" gap={5}>
                        {product?.blockCbc ? product?.blockCbc : 0}
                        <Text fz="xs" color="dimmed">
                          (
                          {product?.blockCbcstickCount
                            ? product?.blockCbcstickCount / 1000
                            : 0}{' '}
                          TH)
                        </Text>
                      </Flex>
                    </td>
                    {/* <td>
                      <Flex align="center" gap={5}>
                        {product?.openCBC ? product?.openCBC : 0}
                        <Text fz="xs" color="dimmed">
                          (
                          {product?.openCBCStickCount
                            ? product?.openCBCStickCount < 0
                              ? (product?.openCBCStickCount / 1000) * -1
                              : product?.openCBCStickCount / 1000
                            : 0}{' '}
                          TH)
                        </Text>
                      </Flex>
                    </td> */}
                    <td>
                      <Tooltip label="View CBC breakdown">
                        <ActionIcon
                          onClick={() => {
                            setSelectedInventory(product);
                            setShowCbcBreakdownModal(true);
                          }}
                          size="md"
                          sx={{
                            backgroundColor: COLORS.primary,
                          }}
                          variant="filled">
                          <IconEye size={18} />
                        </ActionIcon>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        ) : (
          <Flex align="center" justify="center" style={{ height: '68vh' }}>
            <NoDataPlaceholder
              title="No Inventory found"
              subtext={' '}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Flex>
        )}
        <Flex justify="space-between">
          <Stack justify="end">
            <ExportCsvXls
              setExportType={setExportType}
              exportData={exportData}
              btnLoader={pagelessFetching}
            />
          </Stack>
          <Pagination
            mt="20px"
            value={page}
            onChange={setPage}
            total={Math.ceil(total / 10)}
          />
        </Flex>
      </Stack>
    </div>
  );
};

export default InventoryTab;
