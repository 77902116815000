import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Table,
  Title,
  Tooltip,
  TypographyStylesProvider,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconArticleOff,
  IconCalendar,
  IconRefresh,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import COLORS from '../../constants/colors';
import {
  actionLoggerActionsBadgeColor,
  actionLoggerReferenceTypes,
} from '../../constants/const';
import { fetchActionLogs } from '../../services/actionLogs';
import { handleErrorResponse, isArrayAndHasContent } from '../../utils/utils';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import DateSelectModal from '../../components/Charts/DateSelectModal';
import { useDisclosure } from '@mantine/hooks';
import { convertTimeToFormattedString } from '../../components/Charts/helper';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Logs';
const ActionLogs = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [referenceType, setRefrerenceType] = useState(null);
  const [action, setAction] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [opened, { open, close }] = useDisclosure(false);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  useEffect(() => {
    document.title = 'Action Logs | Armada';
  }, []);

  const { data, isLoading, isFetching, error } = useQuery({
    queryKey: [
      'actionLogs',
      page,
      searchKey,
      referenceType,
      action,
      startDate,
      endDate,
      null,
    ],
    queryFn: fetchActionLogs,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const changeStartDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setStartDate(time);
    } else {
      setStartDate(null);
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setEndDate(time);
    } else {
      setEndDate(null);
    }
  };

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setAction(null);
    setRefrerenceType(null);
    setEndDate(null);
    setStartDate(null);
  };

  const { total, actionLogs } = data.data.data;

  return (
    <div>
      <DateSelectModal
        opened={opened}
        close={close}
        open={open}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={changeStartDate}
        changeEndDate={changeEndDate}
        exportFunc={close}
        exportBtnTitle="Filter"
      />
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={() => handleRefresh()}
                handleSearch={handleSearch}
                placeholder="Search Logs"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>
            <Select
              value={referenceType}
              onChange={(value) => {
                setRefrerenceType(value);
              }}
              placeholder="Filter by panels"
              data={Object.keys(actionLoggerReferenceTypes).map((key) => {
                return {
                  value: key,
                  label: key.split('_').join(' '),
                };
              })}
            />
            <Select
              value={action}
              onChange={(value) => {
                setAction(value);
              }}
              placeholder="Filter by action"
              data={Object.keys(actionLoggerActionsBadgeColor).map((key) => {
                return {
                  value: key,
                  label: key.split('_').join(' '),
                };
              })}
            />

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Filter by Date">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    open();
                  }}
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  variant="filled">
                  <IconCalendar size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    setRefrerenceType(null);
                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(actionLogs) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Actor</th>
                      <th>Panel</th>
                      <th>Action</th>
                      <th>Description</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actionLogs.map((actionLog, index) => (
                      <tr key={index}>
                        <td>{actionLog?.user?.name || 'Symphony'}</td>

                        <td>{actionLog.referenceType.split('_').join(' ')}</td>
                        <td>
                          <Badge
                            color={
                              actionLoggerActionsBadgeColor[actionLog.action]
                            }>
                            {actionLog.action}
                          </Badge>
                        </td>
                        <td
                          style={{
                            maxWidth: '300px',
                          }}>
                          <Stack>
                            <TypographyStylesProvider>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: actionLog?.actionDescription,
                                }}
                              />
                            </TypographyStylesProvider>
                          </Stack>
                        </td>
                        <td>
                          {' '}
                          {dayjs(actionLog?.createdAt).format(
                            'DD MMM, YYYY, hh:mm A',
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No logs found"
                  subtext={' '}
                  icon={<IconArticleOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="end">
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default ActionLogs;
