import {
  IconEdit,
  IconKey,
  IconMoodSadFilled,
  IconPackage,
  IconPackageExport,
  IconPackageImport,
  IconPackageOff,
  IconPackages,
  IconSquareRoundedPlusFilled,
  IconTrash,
  IconTruck,
} from '@tabler/icons-react';
import COLORS from './colors';

export const authContext = 'web';

export const userRoles = {
  ADMIN: 'ADMIN',
  'SUPER ADMIN': 'SUPER ADMIN',
  MANAGER: 'MANAGER',
  'GODOWN MANAGER': 'COMPUTER OPERATOR',
  OPERATOR: 'GODOWN KEEPER',
};

export const userDesignations = {
  'SUPER ADMIN': 'Super Admin',
  'CENTRAL ADMIN': 'Central Admin',
  'REGIONAL MANAGER': 'Regional Manager',
  'AREA MANAGER': 'Area Manager',
  'TERRITORY OFFICER': 'Territory Officer',
  'BUSINESS MANAGER': 'Business Manager',
  'COMPUTER OPERATOR': 'Computer Operator',
  'GODOWN KEEPER': 'Godown Keeper',
};

export const userDesignationsAccordingToRole = [
  {
    role: userRoles['SUPER ADMIN'],
    designations: [userDesignations['SUPER ADMIN']],
  },
  {
    role: userRoles.ADMIN,
    designations: [userDesignations['CENTRAL ADMIN']],
  },
  {
    role: userRoles.MANAGER,
    designations: [
      userDesignations['REGIONAL MANAGER'],
      userDesignations['AREA MANAGER'],
      userDesignations['TERRITORY OFFICER'],
      userDesignations['BUSINESS MANAGER'],
    ],
  },
  {
    role: userRoles['GODOWN MANAGER'],
    designations: [userDesignations['COMPUTER OPERATOR']],
  },
  {
    role: userRoles.OPERATOR,
    designations: [userDesignations['GODOWN KEEPER']],
  },
];

export const userWeight = {
  'SUPER ADMIN': 10,
  ADMIN: 8,
  MANAGER: 6,
  'GODOWN MANAGER': 4,
  OPERATOR: 2,
};

export const userColors = {
  ADMIN: COLORS.ADMIN,
  'SUPER ADMIN': COLORS['SUPER ADMIN'],
  MANAGER: COLORS.MANAGER,
  'GODOWN MANAGER': COLORS['GODOWN MANAGER'],
  OPERATOR: COLORS.OPERATOR,
};

export const userBadgeBackgroundColors = {
  'SUPER ADMIN': 'rgba(255, 88, 88, 0.1)',
  ADMIN: 'rgba(134, 93, 255, 0.1)',
  MANAGER: 'rgba(127, 183, 126, 0.1)',
  'COMPUTER OPERATOR': 'rgba(253, 126, 20, 0.1)',
  'GODOWN KEEPER': 'rgba(64, 66, 88, 0.1)',
};

export const orderStatusConst = {
  PENDING: 'PENDING',
  // APPROVED: 'APPROVED',
  'IN QUEUE': 'IN QUEUE',
  LOADING: 'LOADING',
  'IN TRANSIT': 'IN TRANSIT',
  UNLOADING: 'UNLOADING',
  COMPLETE: 'COMPLETE',
  CANCELLED: 'CANCELLED',
};

export const orderStatusBadgeColors = {
  PENDING: 'yellow',
  // APPROVED: 'indigo',
  'IN QUEUE': 'grape',
  LOADING: 'orange',
  'IN TRANSIT': 'lime',
  UNLOADING: 'pink',
  COMPLETE: 'green',
  CANCELLED: 'red',
};

export const cbcTabNames = {
  scanIn: 'SCANNED IN',
  scanOut: 'SCANNED OUT',
};

export const orderDetailsType = {
  SO: 'SO',
  IGT: 'IGT',
};

export const actionLoggerReferenceTypes = {
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  LOCATION_MANAGEMENT: 'LOCATION_MANAGEMENT',
  ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
  INVENTORY_MANAGEMENT: 'INVENTORY_MANAGEMENT',
  DISTRIBUTOR_MANAGEMENT: 'DISTRIBUTOR_MANAGEMENT',
  REGION_MANAGEMENT: 'REGION_MANAGEMENT',
  AREA_MANAGEMENT: 'AREA_MANAGEMENT',
  TERRITORY_MANAGEMENT: 'TERRITORY_MANAGEMENT',
};

export const actionLoggerActionsBadgeColor = {
  CREATE: 'green',
  UPDATE: 'violet',
  DELETE: 'red',
  LOGIN: 'blue',
  LOGOUT: 'orange',
  RESET_PASSWORD: 'pink',
};

export const reportTypeConst = {
  STOCK_IN_HAND: 'STOCK_IN_HAND',
  OPEN_CBC_STOCK: 'OPEN_CBC_STOCK',
  BLOCKED_CBC_STOCK: 'BLOCKED_CBC_STOCK',
};

export const actionLoggerIcons = {
  CREATE: <IconSquareRoundedPlusFilled size="0.8rem" />,
  UPDATE: <IconEdit size="0.8rem" />,
  DELETE: <IconTrash size="0.8rem" />,
  LOGIN: <IconKey size="0.8rem" />,
  LOGOUT: <IconMoodSadFilled size="0.8rem" />,
  RESET_PASSWORD: <IconKey size="0.8rem" />,
};

export const actionLoggerIconsGradients = {
  CREATE: { from: 'lime', to: 'cyan' },
  UPDATE: { from: 'yellow', to: 'orange' },
  DELETE: { from: 'red', to: 'darkred' },
  LOGIN: { from: 'lime', to: 'cyan' },
  LOGOUT: { from: 'red', to: 'darkred' },
  RESET_PASSWORD: { from: 'yellow', to: 'orange' },
};

export const DashboardCardIconChoice = (title, color) => {
  switch (title) {
    case 'Incoming':
      return (
        <IconPackageImport color={color ? color : '#ffffff'} size="1.5em" />
      );

    case 'Outgoing':
      return (
        <IconPackageExport color={color ? color : '#ffffff'} size="1.5em" />
      );

    case 'In Transit':
      return <IconTruck color={color ? color : '#ffffff'} size="1.5em" />;

    case 'OH Stock':
      return <IconPackages color={color ? color : '#ffffff'} size="1.5em" />;

    case 'Open Stock':
      return <IconPackage color={color ? color : '#ffffff'} size="1.5em" />;

    case 'Blocked':
      return <IconPackageOff color={color ? color : '#ffffff'} size="1.5em" />;

    case 'Daily CBCs In':
      return (
        <IconPackageImport color={color ? color : '#ffffff'} size="1.5em" />
      );

    case 'Daily CBCs Out':
      return (
        <IconPackageExport color={color ? color : '#ffffff'} size="1.5em" />
      );

    default:
      return <IconPackages color={color ? color : '#ffffff'} size="1.5em" />;
  }
};

export const PdaDeviceModels = {
  'Samsung A04s': 'Samsung A04s',
  'Samsung M02s': 'Samsung M02s',
  'Galaxy A04s': 'Galaxy A04s',
};

export const orderStatusWeight = {
  PENDING: 1,
  'IN QUEUE': 2,
  LOADING: 3,
  'IN TRANSIT': 4,
  UNLOADING: 5,
  COMPLETE: 6,
  CANCELLED: 7,
};

export const brandSegmentConst = [
  'Premium',
  'Aspirational Premium',
  'Low',
  'Low Plus',
  'VFM',
];

export const orderPartialReceiveStatuses = {
  NOT_PARTIALLY_RECEIVED: 'NOT PARTIALLY RECEIVED',
  PARTIALLY_RECEIVED: 'PARTIALLY RECEIVED',
  PARTIALLY_RECEIVED_AND_RESOLVED: 'PARTIALLY RECEIVED AND RESOLVED',
};

export const orderPartialReceiveHistoryStatuses = {
  PARTIALLY_RECEIVED: 'PARTIALLY RECEIVED',
  PARTIALLY_RECEIVED_AND_RESOLVED: 'PARTIALLY RECEIVED AND RESOLVED',
};

export const dayZeroReconciliationSessionStatuses = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
  RECONCILING: 'RECONCILING',
  CANCELLED: 'CANCELLED',
};

export const emailReportSlots = [
  { value: 1, label: '10 AM' },
  { value: 2, label: '2 PM' },
  { value: 3, label: '8 PM' },
];
