import {
  Badge,
  Card,
  CopyButton,
  Flex,
  Grid,
  Stack,
  Tabs,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import COLORS from '../../../constants/colors';
import { fetchCbcHistory } from '../../../services/orders';
import dayjs from 'dayjs';
import {
  handleErrorResponse,
  scanActionBadgeColorMapper,
} from '../../../utils/utils';
import CbcHistoryTable from '../Tabs/CbcHistoryTable';
import OpenCbcHistoryTable from '../Tabs/OpenCbcHistoryTable';
import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';

const CBCHistory = ({ uid, selectedCBC, openCBC }) => {
  const appUser = useSelector((state) => state.auth.user);

  const [historyPage, setHistoryPage] = useState(1);
  const [openHistoryPage, setOpenHistoryPage] = useState(1);

  const [activeTab, setActiveTab] = useState('cbc-history');
  const [tabValues, setTabValues] = useState({});

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: ['cbc-history', uid, historyPage, openHistoryPage, null],
    enabled: !!uid,
    queryFn: fetchCbcHistory,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const tabList = [
    {
      label: 'CBC History',
      value: 'cbc-history',
      element: (
        <CbcHistoryTable
          uid={uid}
          isLoading={isLoading}
          error={error}
          cbcHistory={data?.data?.data?.histories?.history?.results}
          total={data?.data?.data?.histories?.history?.total}
          page={historyPage}
          setPage={setHistoryPage}
        />
      ),
      disabled: false,
    },
    {
      label: 'Open CBC History',
      value: 'open-cbc-history',
      element: (
        <OpenCbcHistoryTable
          uid={uid}
          isLoading={isLoading}
          error={error}
          cbcHistory={data?.data?.data?.histories?.openCbcHistory?.results}
          total={data?.data?.data?.histories?.openCbcHistory?.total}
          page={openHistoryPage}
          setPage={setOpenHistoryPage}
        />
      ),
      disabled: !openCBC,
    },
  ];

  useEffect(() => {
    let tempData = {};
    tabList.forEach((tab) => {
      tempData[tab.value] = tab.value;
    });
    setTabValues(tempData);
  }, []);

  return (
    <Stack
      sx={{
        maxHeight: '80vh',
        margin: 10,
        minWidth: '50vw',
      }}>
      {selectedCBC && (
        <Card withBorder radius="sm">
          <Grid>
            <Grid.Col span={8}>
              <Stack spacing={2}>
                <Text size="md" weight={600}>
                  {selectedCBC?.shipmentFormatName
                    ? selectedCBC?.shipmentFormatName
                    : selectedCBC?.productName}
                </Text>
                <Text size="sm" weight={500}>
                  {selectedCBC.locationName
                    ? `Current Location : ${selectedCBC.locationName}`
                    : null}
                </Text>
                <Flex gap={3} align="center">
                  <Text size="sm" weight={500}>
                    Barcode :
                  </Text>
                  <CopyButton value={selectedCBC.barcode}>
                    {({ copied, copy }) => (
                      <Tooltip label="Copy Barcode" position="right" withArrow>
                        <UnstyledButton
                          sx={{ color: copied ? 'teal' : 'blue' }}
                          onClick={copy}>
                          {copied ? 'Copied barcode' : selectedCBC.barcode}
                        </UnstyledButton>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
                <Text size="sm" weight={500}>
                  Package Code: {selectedCBC.packcode}
                </Text>
                <Text size="sm" weight={500}>
                  Production Date:{' '}
                  {dayjs(selectedCBC.productionDate).format('DD-MM-YYYY')}
                </Text>
                <Text size="sm" weight={500}>
                  Expiry Date:{' '}
                  {dayjs(selectedCBC.expiryDate).format('DD-MM-YYYY')}
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={4}>
              <Stack justify="end" align="end">
                <Badge
                  color={scanActionBadgeColorMapper(selectedCBC.cbcStatus)}>
                  {selectedCBC.cbcStatus}
                </Badge>
                {appUser.role === 'SUPER ADMIN' && (
                  <Stack justify="center" align="center">
                    <Barcode
                      value={selectedCBC.barcode}
                      width={1}
                      height={50}
                      displayValue={false}
                      margin={1}
                    />
                  </Stack>
                )}
              </Stack>
            </Grid.Col>
          </Grid>
        </Card>
      )}
      <Tabs
        variant="outline"
        defaultValue="gallery"
        value={activeTab}
        onTabChange={setActiveTab}>
        <Tabs.List>
          {tabList.map((tab) => (
            <Tabs.Tab
              value={tab.value}
              key={tab.value}
              onClick={() => setActiveTab(tab.value)}
              sx={{
                backgroundColor:
                  activeTab === tabValues[tab.value]
                    ? COLORS.primary
                    : COLORS.white,
                color:
                  activeTab === tabValues[tab.value]
                    ? COLORS.white
                    : COLORS.black,
              }}
              disabled={tab.disabled}>
              {tab.label}
            </Tabs.Tab>
          ))}
        </Tabs.List>

        {tabList.map((tab) => (
          <Tabs.Panel value={tab.value} key={tab.value} p="xs">
            {tab.element}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Stack>
  );
};

export default CBCHistory;
