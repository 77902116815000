import axios from './axios';

export const fetchOrders = async (context) => {
  const response = await axios().get('/orders', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      orderStatus: context.queryKey[3],
      orderType:
        context.queryKey[4] === 'Mobile IGT' ? null : context.queryKey[4],
      source: context.queryKey[5],
      destination: context.queryKey[6],
      pageLess: context.queryKey[7],
      startDate: context.queryKey[8],
      endDate: context.queryKey[9],
      missingCBC: context.queryKey[10] || null,
      limit: context.queryKey[11] || 10,
      isPartailReceived: context.queryKey[12] || null,
      isMobileIgt: context.queryKey[4] === 'Mobile IGT' ? true : null,
    },
  });
  return response;
};

export const fetchOrderDetails = async (context) => {
  const response = await axios().get(`/orders/details/${context.queryKey[1]}`);
  return response;
};

export const createIGTOrders = async (values) => {
  const response = await axios().post('/orders/create', values);
  return response;
};

export const updateIGTOrder = async (values) => {
  const response = await axios().patch(
    `/orders/update/${values.uid}`,
    values.resquestData,
  );
  return response;
};

export const scanAction = async (values) => {
  const response = await axios().post(
    `/orders/scan/cbc/${values.orderNumber}/${values.barcode}`,
    {
      scanAction: values.scanAction,
      scanContext: 'web',
    },
  );
  return response;
};

export const approveOrder = async (values) => {
  const response = await axios().patch(`/orders/approve/${values.uid}`);
  return response;
};

export const cancelOrder = async (values) => {
  const response = await axios().patch(`/orders/cancel/${values.uid}`, {
    remarks: values.remarks,
  });
  return response;
};

export const fetchBlockedCbcs = async (context) => {
  const response = await axios().get('/orders/cbc/blocked', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      pageLess: context.queryKey[3],
      locationId: context.queryKey[4],
    },
  });
  return response;
};

// export const fetchOpenedCbcs = async (context) => {
//   const response = await axios().get('/orders/cbc/open', {
//     params: {
//       page: context.queryKey[1],
//       limit: context.queryKey[2],
//       searchKey: context.queryKey[3],
//       pageLess: context.queryKey[4],
//       locationId: context.queryKey[5],
//       routeSection: context.queryKey[6],
//       startDate: context.queryKey[7],
//       endDate: context.queryKey[8],
//     },
//   });

//   return response;
// };

// export const fetchBlockableCbcs = async (context) => {
//   const response = await axios().get('/orders/cbc/all', {
//     params: {
//       barcode: context.queryKey[1],
//       packcode: context.queryKey[2],
//       productIds: context.queryKey[3],
//       locationIds: context.queryKey[4],
//       type: context.queryKey[5],
//       paginated: context.queryKey[6],
//       page: context.queryKey[7],
//       locationId: context.queryKey[8],
//       searchKey: context.queryKey[9],
//     },
//   });
//   return response;
// };

// export const fetchAllBlockedCbcs = async (context) => {
//   const response = await axios().get('/orders/cbc/blocked', {
//     params: {
//       page: context.queryKey[1],
//       barcode: context.queryKey[2],
//       packcode: context.queryKey[3],
//       productIds: context.queryKey[4],
//       locationIds: context.queryKey[5],
//       type: context.queryKey[6],
//       pageLess: context.queryKey[7],
//     },
//   });
//   return response;
// };

export const fetchOpenedCbcs = async (context) => {
  const response = await axios().get('/orders/cbc-list/v2', {
    params: {
      queryType: 'OPENED',
      page: context.queryKey[1],
      limit: context.queryKey[2],
      searchKey: context.queryKey[3],
      pageLess: context.queryKey[4],
      locationId: context.queryKey[5],
      routeSection: context.queryKey[6],
      startDate: context.queryKey[7],
      endDate: context.queryKey[8],
    },
  });

  return response;
};

export const fetchBlockableCbcs = async (context) => {
  const response = await axios().get('/orders/cbc-list/v2', {
    params: {
      queryType: 'INTACT',
      barcode: context.queryKey[1],
      packcode: context.queryKey[2],
      productIds: context.queryKey[3],
      locationIds: context.queryKey[4],
      type: context.queryKey[5],
      paginated: context.queryKey[6],
      page: context.queryKey[7],
      locationId: context.queryKey[8],
      searchKey: context.queryKey[9],
    },
  });
  return response;
};

export const fetchAllBlockedCbcs = async (context) => {
  const response = await axios().get('/orders/cbc-list/v2', {
    params: {
      queryType: 'BLOCKED',
      page: context.queryKey[1],
      barcode: context.queryKey[2],
      packcode: context.queryKey[3],
      productIds: context.queryKey[4],
      locationId: context.queryKey[5],
      type: context.queryKey[6],
      pageLess: context.queryKey[7],
      searchKey: context.queryKey[8],
    },
  });
  return response;
};

export const closeOpenedCBC = async (uid) => {
  const response = await axios().patch(`/orders/cbc/close/${uid}`);
  return response;
};

export const blockCBCs = async (ids) => {
  const response = await axios().patch('/orders/cbc/block', { ids: ids });
  return response;
};

export const unblockCBCs = async (ids) => {
  const response = await axios().patch('/orders/cbc/unblock', { ids: ids });
  return response;
};

export const generateOtpForOrder = async (value) => {
  const response = await axios().post(
    `/orders/generate/otp/${value.orderNumber}`,
    {
      variant: value.variant,
    },
  );
  return response;
};

export const sendOtpToPhone = async (value) => {
  const response = await axios().post(
    `/orders/send/otp/${value.orderNumber} `,
    {
      phoneNumber: value.phoneNumber,
      otp: value.otp,
    },
  );
  return response;
};

export const fetchCbcListFromProductList = async (value) => {
  const response = await axios().post(
    `/orders/export/cbc/${value.productId}/${value.locationId}`,
  );
  return response;
};

export const getAssignedProducts = async (context) => {
  const response = await axios().get(
    `/orders/assigned/products/${context.queryKey[1]}`,
  );
  return response;
};

export const getAssignedCbcs = async (context) => {
  const response = await axios().get(
    `/orders/assigned/cbc/${context.queryKey[1]}`,
  );
  return response;
};

export const updateTruckNumber = async (values) => {
  const response = await axios().patch(
    `/orders/update/truck/${values.orderNumber}`,
    {
      truckNumber: values.truckNumber,
    },
  );
  return response;
};

export const fetchCbcHistory = async (context) => {
  const response = await axios().get(
    `orders/cbc/history/${context.queryKey[1]}`,
    {
      params: {
        page: context.queryKey[2],
        openHistoryPage: context.queryKey[3],
        pageLess: context.queryKey[4],
      },
    },
  );
  return response;
};

export const reconOrder = async (values) => {
  const response = await axios().post(
    `/orders/resend/scan/end/${values.symphonyReferenceId}`,
    values.reconData,
  );
  return response;
};

export const fetchScannedCbcsForApp = async (context) => {
  const response = await axios().get(
    `/orders/app/history/scanned-out/${context.queryKey[1]}`,
    {
      params: {
        scanAction: 'SCANNED OUT',
      },
    },
  );
  return response;
};

export const acceptOrder = async (values) => {
  const response = await axios().post(`/orders/accept/${values.orderNumber}`, {
    scanContext: 'web',
    isPartiallyReceived: values.isPartiallyReceived || false,
    receivedAmount: values.receivedAmount || 0,
    historyNote: values.historyNote || null,
    partialReceiveReason: values.partialReceiveReason || null,
  });
  return response;
};

export const resolvePartialReceiveOrder = async (values) => {
  const response = await axios().post(
    `/orders/partial-receive/resolve/${values.orderNumber}`,
    {
      partialReceiveReason: values.partialReceiveReason,
      historyNote: values.historyNote,
    },
  );
  return response;
};
