import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  HoverCard,
  Loader,
  Modal,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import SearchInput from '../../components/Global/SearchInput';
import {
  IconArrowLeft,
  IconCheck,
  IconDeviceMobileOff,
  IconDownload,
  IconPlus,
  IconRefresh,
  IconTextPlus,
  IconX,
} from '@tabler/icons-react';
import { isArrayAndHasContent } from '../../utils/utils';
import COLORS from '../../constants/colors';
import UploadApplication from '../../components/MobileApplication/UploadApplication';
import { useSelector } from 'react-redux';
import { userRoles } from '../../constants/const';
import {
  fetchAllBuilds,
  getLatestDistribution,
  markAsLatest,
} from '../../services/app-dist';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import { openConfirmModal } from '@mantine/modals';
import { NotificationUtil } from '../../utils/notifications';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'App Distributions';

const MobileApplcations = () => {
  const navigate = useNavigate();

  const appUser = useSelector((state) => state.auth.user);

  const [searchByVariant, setSearchByVariant] = useState(null);

  const [downloadLatest, setDownloadLatest] = useState(false);

  const [showUploadModal, setShowUploadModal] = useState(false);

  useEffect(() => {
    document.title = 'App Distribution | Armada';
  }, []);

  useEffect(() => {
    if (appUser.role === userRoles.ADMIN) {
      setSearchByVariant(process.env.REACT_APP_ENVIRONMENT);
    }
  }, []);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['mobile-applications', searchByVariant],
    queryFn: fetchAllBuilds,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
  });

  const { refetch: latestDownloadRefetch } = useQuery({
    queryKey: ['mobile-applications-latest-download'],
    queryFn: getLatestDistribution,
    enabled: downloadLatest,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchIntervalInBackground: true,
    retry: false,
    onSuccess: (data) => {
      const fileName = data?.data?.data?.appUrl;
      handleDownloadImage(fileName);
      setDownloadLatest(false);
    },
  });

  const ConfirmDeleteModal = (uid) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you make this version latest??</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        markAsLatestMutate(uid);
      },
    });
  };

  const { mutate: markAsLatestMutate, isLoading: isMarking } = useMutation({
    mutationFn: async (values) => await markAsLatest(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Successfully marked as latest',
      });
      refetch();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const handleDownloadImage = (fileName) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading image:', error);
      });
  };

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { total, distributions } = data.data.data;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Modal
        opened={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        title="Upload Mobile Application"
        size="md"
        centered={true}>
        <UploadApplication
          onUpdate={() => {
            refetch();
            setShowUploadModal(false);
          }}
        />
      </Modal>
      {/* CUSTOM MODAL AND DRAWER END */}
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            {appUser.role === userRoles['SUPER ADMIN'] && (
              <>
                <div>
                  <Select
                    value={searchByVariant}
                    onChange={(value) => {
                      setSearchByVariant(value);
                    }}
                    placeholder="Filter by variant"
                    data={[
                      { value: 'stage', label: 'Stage' },
                      { value: 'prod', label: 'Production' },
                      { value: 'dev', label: 'Development' },
                    ]}
                  />
                </div>

                <Flex gap={20} align="center" justify="center">
                  <Tooltip label="Refresh">
                    <ActionIcon
                      size="lg"
                      onClick={() => {
                        setSearchByVariant(null);
                      }}
                      disabled={appUser.role !== userRoles['SUPER ADMIN']}
                      sx={{
                        backgroundColor: COLORS.orange,
                      }}
                      variant="filled">
                      <IconRefresh size={18} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </>
            )}
          </Flex>
          <Flex justify="flex-end" align="center" gap={8}>
            <Button
              sx={{
                backgroundColor: COLORS.secondary,
              }}
              onClick={() => {
                if (downloadLatest) {
                  latestDownloadRefetch();
                } else {
                  setDownloadLatest(true);
                }
              }}
              leftIcon={<IconDownload />}>
              Download Latest
            </Button>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                setShowUploadModal(true);
              }}
              leftIcon={<IconPlus />}>
              Add Version
            </Button>
          </Flex>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(distributions) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Version</th>
                      <th>Build Name</th>
                      <th>Build Number</th>
                      <th>Change Logs</th>
                      <th>Variant</th>
                      <th>Latest</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {distributions.map((distribution, index) => (
                      <tr key={index}>
                        <td>{distribution.version}</td>
                        <td>{distribution.buildName}</td>
                        <td>{distribution.buildNumber}</td>
                        <td>
                          <Text
                            sx={{
                              whiteSpace: 'pre-line',
                            }}>
                            {distribution.changelogs.length > 100 ? (
                              <HoverCard width={'80%'} shadow="md" withinPortal>
                                <HoverCard.Target>
                                  <Text>
                                    {distribution.changelogs.substring(0, 100)}
                                    ...
                                  </Text>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                  <Text
                                    size="sm"
                                    sx={{
                                      whiteSpace: 'pre-line',
                                    }}>
                                    {distribution.changelogs}
                                  </Text>
                                </HoverCard.Dropdown>
                              </HoverCard>
                            ) : (
                              distribution.changelogs
                            )}
                          </Text>
                        </td>
                        <td>
                          {distribution.variant === 'dev' && (
                            <Badge color="orange">{distribution.variant}</Badge>
                          )}
                          {distribution.variant === 'stage' && (
                            <Badge color="yellow">{distribution.variant}</Badge>
                          )}
                          {distribution.variant === 'prod' && (
                            <Badge color="green">{distribution.variant}</Badge>
                          )}
                        </td>
                        <td>
                          {distribution.isLatest ? (
                            <IconCheck size={20} color="green" />
                          ) : (
                            <IconX size={20} color="red" />
                          )}
                        </td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Download'}>
                              <ActionIcon
                                variant="light"
                                color="teal"
                                onClick={() => {
                                  handleDownloadImage(distribution.appUrl);
                                }}>
                                <IconDownload size={16} />
                              </ActionIcon>
                            </Tooltip>

                            <Tooltip label={'Make Latest'}>
                              <ActionIcon
                                variant="light"
                                color="orange"
                                onClick={() => {
                                  ConfirmDeleteModal(distribution.uid);
                                }}>
                                <IconTextPlus size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No Mobile Application found"
                  subtext={' '}
                  icon={<IconDeviceMobileOff size={70} color="#4562f7" />}
                />
              </div>
            )}
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default MobileApplcations;
