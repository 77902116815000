import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Flex, Select, Text, TextInput } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import COLORS from '../../constants/colors';
import {
  createDistributor,
  toggleDistributor,
  updateDistributor,
} from '../../services/distributor';
import { NotificationUtil } from '../../utils/notifications';
import {
  AddDistributorSchema,
  UpdateDistributorSchema,
} from '../../validators/distributor';

const DistributorForm = ({
  defaultValues,
  update,
  onUpdate,
  regionPagelessdata,
  areaPagelessData,
}) => {
  const queryClient = useQueryClient();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: defaultValues ? defaultValues : {},
    resolver: yupResolver(
      update ? UpdateDistributorSchema : AddDistributorSchema,
    ),
    mode: 'onChange',
  });

  const regionId = useWatch({
    control,
    name: 'regionId',
  });

  useEffect(() => {
    reset();
  }, []);

  const onSubmit = (data) => {
    ConfirmModal(data);
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you {update ? 'update' : 'create'} this distributor?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        if (update) {
          updateMutate({
            uid: defaultValues.uid,
            id: defaultValues.id,
            ...values,
          });
        } else {
          createMutate(values);
        }
      },
    });
  };

  const ConfirmDeleteModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you inactivate this distributor?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        deleteMutate(values);
      },
    });
  };

  const { mutate: createMutate, isLoading: isCreating } = useMutation({
    mutationFn: async (values) => await createDistributor(values),
    onSuccess: () => {
      onUpdate();
      callRefetch();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (values) => await updateDistributor(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation({
    mutationFn: async (values) => await toggleDistributor(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const callRefetch = async () => {
    await queryClient.refetchQueries({
      queryKey: ['region-pagelessData'],
      type: 'active',
    });
    await queryClient.refetchQueries({
      queryKey: ['area-pagelessData'],
      type: 'active',
    });
    await queryClient.refetchQueries({
      queryKey: ['distributor-pagelessData'],
      type: 'active',
    });
  };

  return (
    <div
      style={{
        height: '90vh',
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="accounts-input">
          <Controller
            name="regionId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  regionPagelessdata?.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  }) || []
                }
                searchable
                label="Region"
                placeholder="Select region"
                dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.regionId && <p>{errors?.regionId?.message}</p>}
        </div>
        <div className="accounts-input">
          <Controller
            name="areaId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  areaPagelessData
                    ?.filter((item) => item?.regionId === regionId)
                    ?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }) || []
                }
                searchable
                label="Area"
                placeholder="Select area"
                dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.areaId && <p>{errors?.areaId?.message}</p>}
        </div>
        <div className="accounts-input">
          <TextInput
            {...register('name')}
            label="Name"
            placeholder="Enter name"
            error={errors?.name?.message}
          />
        </div>

        <Flex gap={15} py={8} mt={10}>
          {update && (
            <Button
              disabled={isSubmitting}
              sx={{
                flex: 1,
              }}
              onClick={() => {
                ConfirmDeleteModal({
                  status: !defaultValues.isActive,
                  uid: defaultValues.uid,
                });
              }}
              color={defaultValues.isActive ? 'red' : 'green'}>
              {defaultValues.isActive ? 'Deactivate' : 'Activate'}
            </Button>
          )}
          <Button
            type="submit"
            loading={isSubmitting || isCreating || isUpdating || isDeleting}
            disabled={isSubmitting || !isValid}
            sx={{
              flex: 1,
              backgroundColor: COLORS.primary,
            }}>
            {update ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default DistributorForm;
