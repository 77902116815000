import {
  Badge,
  Button,
  Card,
  Flex,
  MultiSelect,
  Stack,
  Text,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import COLORS from '../../constants/colors';
import { assignRouteSection } from '../../services/location';
import { NotificationUtil } from '../../utils/notifications';
import { isArrayAndHasContent } from '../../utils/utils';

const AssignRouteSectionModal = ({ location, onUpdate }) => {
  const [routeSectionData, setRouteSectionData] = useState([]);
  const [routeSectionSelection, setrouteSectionSelection] = useState(null);

  useEffect(() => {
    if (location?.routeSection) {
      const data = location?.routeSection.map((item) => {
        return { value: item, label: item };
      });

      setRouteSectionData(data);
      setrouteSectionSelection(location?.routeSection);
    }
  }, [location]);

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to make these changes?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        assignMutate({
          uid: location.uid,
          routeSections: routeSectionSelection,
        });
      },
    });
  };

  const { mutate: assignMutate, isLoading: isAssigning } = useMutation({
    mutationFn: async (values) => await assignRouteSection(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Assigned',
        message: 'Route/Section assigned successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  //   const handleOmitLocation = (location) => {
  //     const tempData = routeSectionSelection.filter((item) => item !== location);
  //     setrouteSectionSelection(tempData);
  //   };

  return (
    <Stack
      sx={{
        height: '500px',
        justifyContent: 'space-between',
      }}>
      <Stack>
        <MultiSelect
          label="Select Route/Section or create new"
          data={routeSectionData || []}
          value={routeSectionSelection}
          placeholder="Select items"
          searchable
          creatable
          getCreateLabel={(query) => `+ Create ${query}`}
          onCreate={(query) => {
            const item = { value: query, label: query };
            setRouteSectionData((current) => [...current, item]);
            return item;
          }}
          onChange={(value) => {
            console.log(value);
            setrouteSectionSelection(value);
          }}
        />
        {/* {isObjectAndHasProperties(routeSectionSelection) ? (
          <Card my={10} shadow="xs" padding="md">
            <Flex gap={5} align="center" wrap="wrap">
              {isObjectAndHasProperties(routeSectionSelection)
                ? routeSectionSelection.map((location, index) => (
                    <Badge key={index}>
                      <Flex gap={5}>
                        <Text>{location}</Text>
                        <ActionIcon
                          color="orange"
                          size="xs"
                          radius="xs"
                          variant="transparent"
                          onClick={() => {
                            handleOmitLocation(location);
                          }}>
                          <IconX size={12} />
                        </ActionIcon>
                      </Flex>
                    </Badge>
                  ))
                : null}
            </Flex>
          </Card>
        ) : null} */}
        <Stack>
          <Card my={10} shadow="xs" padding="xs">
            <Text size="sm">Assigned route/section</Text>
            <Stack p="xs">
              <Flex gap={5} align="center" my={5} wrap="wrap">
                {isArrayAndHasContent(location?.routeSection) ? (
                  location?.routeSection.map((location, index) => (
                    <Badge key={index} color="grape">
                      <Flex gap={5}>
                        <Text>{location}</Text>
                        {/* <ActionIcon
                          color="orange"
                          size="xs"
                          radius="xs"
                          variant="transparent"
                            onClick={() => {
                              ConfirmDeleteModal(location);
                            }}
                        >
                          <IconX size={12} />
                        </ActionIcon> */}
                      </Flex>
                    </Badge>
                  ))
                ) : (
                  <Stack sx={{ width: '100%' }}>
                    <Text c="dimmed" align="center">
                      No route/section assigned yet
                    </Text>
                  </Stack>
                )}
              </Flex>
            </Stack>
          </Card>
        </Stack>
      </Stack>
      <Stack justify="end">
        <Button
          sx={{
            backgroundColor: COLORS.primary,
          }}
          onClick={() => {
            ConfirmModal();
          }}
          loading={isAssigning}>
          Assign
        </Button>
      </Stack>
    </Stack>
  );
};

export default AssignRouteSectionModal;
