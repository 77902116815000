import { useEffect } from 'react';
import { useSocketContext } from '../context/SocketContext';

export const useSocket = () => {
  const socket = useSocketContext();

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  return socket;
};
