const GRADIENTS = {
  darkblue: '#1e3c72',
  blue: '#005c97',
  pink: '#b24592',
  green: '#0cebeb',
  orange: '#f7971e',
  lightgreen: '#76b852',
  skyblue: '#2193b0',
  purple: '#61045f',
};

export default GRADIENTS;
