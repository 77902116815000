import {
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Flex,
  HoverCard,
  LoadingOverlay,
  Modal,
  Pagination,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import {
  IconBoxOff,
  IconCalendar,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import { closeOpenedCBC, fetchOpenedCbcs } from '../../../services/orders';
import { NotificationUtil } from '../../../utils/notifications';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isReopenable,
  scanActionBadgeColorMapper,
  userWiseAutocompleteLocationDataWithId,
} from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import SearchInput from '../../Global/SearchInput';
import CBCHistory from '../Modal/CBCHistory';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import exportFromJSON from 'export-from-json';
import { useDisclosure } from '@mantine/hooks';
import { convertTimeToFormattedString } from '../../Charts/helper';
import DateSelectModal from '../../Charts/DateSelectModal';

const LIMIT = 50;
const OpenedCBC = ({ locationData, currentTab, requiredTab }) => {
  const appUser = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [searchByLocation, setSearchByLocation] = useState(null);
  const [locationSearchKey, setLocationSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const [searchByRouteSection, setSearchByRouteSection] = useState(null);

  const [showCbcHistoryModal, setShowCbcHistoryModal] = useState(false);
  const [selectedCbc, setSelectedCbc] = useState(null);

  const [routeSectionData, setRouteSectionData] = useState([]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  //date filter modal
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (searchByLocation) {
      const location = locationData.find(
        (location) => location.id === searchByLocation,
      );

      //console.log(location);
      setRouteSectionData({
        id: location?.id,
        routeSection: location?.routeSection,
      });
    } else {
      setRouteSectionData([]);
      setSearchByRouteSection(null);
    }
  }, [searchByLocation]);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'openedCBC',
      page,
      LIMIT,
      searchKey,
      null,
      searchByLocation,
      searchByRouteSection,
      startDate,
      endDate,
    ],
    queryFn: fetchOpenedCbcs,
    refetchOnWindowFocus: true,
    // refetchInterval: 5 * 60 * 1000,
    // refetchIntervalInBackground: true,
    enabled: currentTab == requiredTab ? true : false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { isFetching: pagelessFetching, refetch: pagelessRefetch } = useQuery({
    queryKey: [
      'openedCBC',
      page,
      LIMIT,
      searchKey,
      true,
      searchByLocation,
      searchByRouteSection,
      startDate,
      endDate,
    ],
    queryFn: fetchOpenedCbcs,
    enabled: exportToggler,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `OPENED-CBC-${dayjs(new Date()).format('DD-MM-YYYY')}`;

      const data = response.data.data.cbcs.map((product) => {
        return {
          Barcode: product?.barcode || 'N/A',
          Packcode: product?.packcode || 'N/A',
          SKU: product.sku || 'N/A',
          Name: product?.shipmentFormatName
            ? product?.shipmentFormatName
            : product?.productName,
          'Distribution Point': product?.locationName || 'N/A',
          'Route/Section': product?.routeSection || 'N/A',
          'Opened At': product?.openedAt
            ? dayjs(product?.openedAt).format('DD/MM/YYYY')
            : 'N/A',
          'Production Date': product?.productionDate
            ? dayjs(product?.productionDate).format('DD/MM/YYYY')
            : 'N/A',
          'Expiry Date': product?.expiryDate
            ? dayjs(product?.expiryDate).format('DD/MM/YYYY')
            : 'N/A',
          Status: product?.cbcStatus ? product?.cbcStatus : 'N/A',
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const { mutate, isLoading: isMutating } = useMutation({
    mutationFn: (value) => closeOpenedCBC(value),
  });

  const handleRecloseCBC = (uid) => {
    mutate(uid, {
      onSuccess: () => {
        refetch();
        NotificationUtil({
          success: true,
          title: 'Success',
          message: 'CBC successfully reclosed',
        });
      },
    });
  };

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setLocationSearchKey(null);
    setSearchByLocation(null);
    setSearchByRouteSection(null);
    setStartDate(null);
    setEndDate(null);
    //setting the date null after refresh
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you re-close this CBC?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        handleRecloseCBC(values);
      },
    });
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      pagelessRefetch();
    } else {
      pagelessFetching();
    }
  };

  const changeStartDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setStartDate(time);
    } else {
      setStartDate(null);
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setEndDate(time);
    } else {
      setEndDate(null);
    }
  };

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const { cbcs, total } = data.data.data;

  return (
    <div>
      {/* date filter modal */}
      <DateSelectModal
        opened={opened}
        close={close}
        open={open}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={changeStartDate}
        changeEndDate={changeEndDate}
        exportFunc={close}
        exportBtnTitle="Filter"
      />

      <Modal
        opened={showCbcHistoryModal}
        onClose={() => {
          setShowCbcHistoryModal(false);
          setSelectedCbc(null);
        }}
        title={<Text fw="600">CBC Details</Text>}
        size="xxl"
        centered={true}>
        <CBCHistory
          uid={selectedCbc?.uid}
          selectedCBC={selectedCbc}
          openCBC={true}
        />
      </Modal>
      <Flex justify="space-between" align="center" py="sm">
        <Flex gap={20}>
          <div
            style={{
              minWidth: '300px',
            }}>
            <SearchInput
              handleRefresh={handleRefresh}
              handleSearch={handleSearch}
              placeholder="Search Barcode, Packcode, Name"
              invokeRefresh={invokingRefreshForSearchInput}
              refreshBtn={false}
            />
          </div>
          <Autocomplete
            placeholder="Search by Point"
            sx={{
              minWidth: '250px',
            }}
            onChange={(value) => {
              if (locationSearchKey && locationSearchKey.length === 0) {
                setLocationSearchKey(null);
              } else {
                setLocationSearchKey(value);
              }
            }}
            limit={locationData?.length || 0}
            maxDropdownHeight="200px"
            dropdownPosition="bottom"
            nothingFound="No options"
            value={locationSearchKey || ''}
            onItemSubmit={(e) => {
              setSearchByLocation(e.id);
            }}
            rightSection={
              searchByLocation ? (
                <UnstyledButton
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setLocationSearchKey(null);
                    setSearchByLocation(null);
                  }}>
                  <IconX size={15} />
                </UnstyledButton>
              ) : null
            }
            data={
              userWiseAutocompleteLocationDataWithId({
                appUser: appUser,
                locationData: locationData,
              }) || []
            }
          />

          <Select
            placeholder="Select Route/Section"
            searchable
            value={searchByRouteSection}
            onChange={(value) => {
              setPage(1);
              setSearchByRouteSection(value);
            }}
            nothingFound={
              searchByLocation ? 'No options' : 'Select Location first'
            }
            data={routeSectionData?.routeSection || []}
          />

          <Flex gap={10} align="center" justify="center">
            <Tooltip label="Filter by Date">
              <ActionIcon
                size="lg"
                onClick={() => {
                  open();
                }}
                sx={{
                  backgroundColor: COLORS.primary,
                }}
                variant="filled">
                <IconCalendar size={18} />
              </ActionIcon>
            </Tooltip>
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={() => {
                  handleRefresh();
                }}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Stack
        sx={{
          minHeight: '50vh',
        }}>
        {isArrayAndHasContent(cbcs) ? (
          <ScrollArea h="65vh">
            <Table highlightOnHover>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}>
                <tr>
                  <th>Barcode</th>
                  <th>Packcode</th>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Distribution Point</th>
                  <th>Route/Section</th>
                  <th>Opened At</th>
                  <th>Production Date</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <LoadingOverlay visible={isFetching} />
                {cbcs.map((cbc, i) => (
                  <tr key={i}>
                    <td>
                      <Box>
                        <UnstyledButton
                          onClick={() => {
                            setShowCbcHistoryModal(true);
                            setSelectedCbc({
                              ...cbc,
                              productName: cbc?.productName,
                              locationName: cbc?.locationName,
                            });
                          }}>
                          <Text color="blue">{cbc.barcode}</Text>
                        </UnstyledButton>
                      </Box>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.packcode}</Text>
                      </Box>
                    </td>

                    <td>
                      <Box>
                        <Text>{cbc?.sku}</Text>
                      </Box>
                    </td>
                    <td
                      style={{
                        maxWidth: '200px',
                      }}>
                      <Box>
                        <Text>
                          {cbc?.shipmentFormatName
                            ? cbc?.shipmentFormatName
                            : cbc?.productName}
                        </Text>
                      </Box>
                    </td>

                    <td>
                      <HoverCard width={280} shadow="md">
                        <HoverCard.Target>
                          <Text fz="sm">{cbc?.locationName}</Text>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Text size="md">{cbc?.locationName}</Text>
                          <Text size="sm">
                            {cbc?.district || 'N/A'}, {cbc?.division || 'N/A'}
                          </Text>
                          <Text size="sm">{cbc?.address}</Text>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </td>
                    <td>
                      <Box>
                        {cbc?.routeSection ? (
                          <Text>{cbc?.routeSection}</Text>
                        ) : (
                          <Text c="dimmed">N/A</Text>
                        )}{' '}
                      </Box>
                    </td>
                    <td>
                      <Box>{cbc.openedAt} </Box>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.productionDate}</Text>
                      </Box>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.expiryDate}</Text>
                      </Box>
                    </td>

                    <td>
                      <Badge color={scanActionBadgeColorMapper(cbc?.cbcStatus)}>
                        {cbc?.cbcStatus}
                      </Badge>
                    </td>
                    <td>
                      {isReopenable(cbc?.openedAt) ? (
                        <Tooltip label="Re-Close This CBC">
                          <ActionIcon
                            size="md"
                            onClick={async () => ConfirmModal(cbc.uid)}
                            sx={{
                              backgroundColor: COLORS.primary,
                            }}
                            disabled={!isReopenable(cbc?.openedAt)}
                            loading={isMutating}
                            variant="filled">
                            <IconRefresh size={18} />
                          </ActionIcon>
                        </Tooltip>
                      ) : (
                        <Tooltip label="More than 24 hours have passed since this CBC was opened">
                          <ActionIcon
                            size="md"
                            onClick={() => {
                              NotificationUtil({
                                success: false,
                                title: 'Sorry cannot re-close this CBC',
                                message:
                                  'More than 24 hours have passed since this CBC was opened',
                              });
                            }}
                            sx={{
                              backgroundColor: COLORS.primary,
                            }}
                            // disabled={!isReopenable(cbc?.openedAt)}
                            loading={isMutating}
                            variant="filled">
                            <IconRefresh size={18} />
                          </ActionIcon>
                        </Tooltip>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        ) : (
          <Flex align="center" justify="center" style={{ height: '68vh' }}>
            <NoDataPlaceholder
              title="No Opened CBCs found"
              subtext={' '}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Flex>
        )}
        <Flex
          justify={
            searchKey ||
            searchByLocation ||
            searchByRouteSection ||
            startDate ||
            endDate
              ? 'space-between'
              : 'flex-end'
          }>
          {searchKey ||
          searchByLocation ||
          searchByRouteSection ||
          startDate ||
          endDate ? (
            <Stack justify="end">
              <ExportCsvXls
                setExportType={setExportType}
                exportData={exportData}
                btnLoader={pagelessFetching}
              />
            </Stack>
          ) : (
            <></>
          )}
          <Pagination
            mt="20px"
            value={page}
            onChange={setPage}
            total={Math.ceil(total / LIMIT)}
          />
        </Flex>
      </Stack>
    </div>
  );
};

export default OpenedCBC;
