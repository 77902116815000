import React, { createContext, useContext } from 'react';
import io from 'socket.io-client';
import store from '../store';

const SocketContext = createContext();

export const useSocketContext = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('useSocketContext must be used within a SocketProvider');
  }
  return context;
};

export const SocketProvider = ({ children }) => {
  // Create a single socket instance using io.connect only once
  const socket = io(process.env.REACT_APP_SERVER_URL || '', {
    auth: {
      token: store.getState().auth.accessToken,
      refreshToken: store.getState().auth.refreshToken,
    },
    transports: ['websocket'],
    upgrade: false,
    forceNew: true,
    reconnection: true,
    secure: true,
    reconnectionDelay: 3000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 5,
    path: '/socket',
    randomizationFactor: 0.5,
  });

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
