import {
  ActionIcon,
  Badge,
  Button,
  Drawer,
  Flex,
  Loader,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Tooltip,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useState } from 'react';
import { fetchRegion } from '../../../services/region';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import RegionForm from '../../Region/RegionForm';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import COLORS from '../../../constants/colors';
import { IconEdit, IconPlus, IconRefresh } from '@tabler/icons-react';
import SearchInput from '../../Global/SearchInput';

const RegionsTab = () => {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [addRegionDrawer, setAddRegionDrawer] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['region', page, searchKey, null],
    queryFn: fetchRegion,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { isFetching: regionPagelessFetching, refetch: regionPagelessRefetch } =
    useQuery({
      queryKey: ['region-pageless', null, null, true],
      queryFn: fetchRegion,
      refetchOnWindowFocus: false,
      enabled: exportToggler,
      keepPreviousData: true,
      retry: false,
      onError: (error) => {
        handleErrorResponse(error);
      },
      onSuccess: (response) => {
        const fileName = `REGIONS-${dayjs(new Date()).format('DD-MM-YYYY')}`;
        const data = response.data.data.regions.map((region) => {
          return {
            Name: region.name,
            Status: region.isActive ? 'Active' : 'Inactive',
            CreatedAt: dayjs(region.createdAt).format('MMM DD, YYYY'),
          };
        });
        exportFromJSON({ data, fileName, exportType });
        setExportToggler(false);
      },
    });

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      regionPagelessRefetch();
    } else {
      regionPagelessRefetch();
    }
  };

  const { regions, total } = data.data.data;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={addRegionDrawer}
        onClose={() => {
          setSelectedRegion(null);
          setAddRegionDrawer(false);
        }}
        title={
          isObjectAndHasProperties(selectedRegion)
            ? 'Edit Region'
            : 'Add Region'
        }
        position="right">
        <RegionForm
          update={isObjectAndHasProperties(selectedRegion)}
          defaultValues={selectedRegion}
          onUpdate={() => {
            refetch();
            setAddRegionDrawer(false);
          }}
        />
      </Drawer>

      {/* CUSTOM MODAL AND DRAWER END */}
      <Stack>
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={handleRefresh}
                handleSearch={handleSearch}
                placeholder="Search Region"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <div>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => setAddRegionDrawer(true)}
              leftIcon={<IconPlus />}>
              Add Region
            </Button>
          </div>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(regions) ? (
              <ScrollArea h="60vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {regions.map((region, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            maxWidth: '120px',
                          }}>
                          {region?.name}
                        </td>

                        <td>
                          <Badge color={region?.isActive ? 'green' : 'red'}>
                            {region?.isActive ? 'Active' : 'Inactive'}
                          </Badge>
                        </td>
                        <td>
                          {dayjs(region?.createdAt).format('MMM DD, YYYY')}
                        </td>
                        <td>{region?.createdByUser?.name}</td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Edit location'}>
                              <ActionIcon
                                variant="light"
                                color="teal"
                                onClick={() => {
                                  setSelectedRegion(region);
                                  setAddRegionDrawer(true);
                                }}>
                                <IconEdit size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No region found"
                  subtext={' '}
                  //   icon={<IconMapPinOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={regionPagelessFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default RegionsTab;
