import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filters: {
    page: 1,
    searchKey: null,
    orderStatus: null,
    searchByorderType: null,
    source: null,
    sourceSearchKey: null,
    destination: null,
    destinationSearchKey: null,
    startDate: null,
    endDate: null,
    missingCbcToggler: false,
    filterSource: null,
  },
};

const OrderFiltersSlice = createSlice({
  name: 'orderFilters',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload.filters;
    },
    clearFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

export const OrderFilterActions = OrderFiltersSlice.actions;
export default OrderFiltersSlice.reducer;
