import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Drawer,
  Flex,
  Loader,
  Stack,
  Text,
  Transition,
} from '@mantine/core';
import { useViewportSize, useWindowScroll } from '@mantine/hooks';
import {
  IconAlertCircle,
  IconArrowBigUpLinesFilled,
  IconChevronLeft,
  IconInfoCircle,
} from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import LogsDrawer from '../../components/Global/LogsDrawer';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import IGTOrderForm from '../../components/Orders/IGTOrderForm';
import OrderDetailsCbcsTable from '../../components/Orders/OrderDetailsCbcsTable';
import OrderDetailsProductsTable from '../../components/Orders/OrderDetailsProductsTable';
import OrderInfoGridLayout from '../../components/Orders/OrderInfoGridLayout';
import OrderTimeline from '../../components/Orders/OrderTimeline';
import ResolveOrderDrawer from '../../components/Orders/ResolveOrderDrawer';
import {
  actionLoggerReferenceTypes,
  orderDetailsType,
  orderPartialReceiveStatuses,
  orderStatusConst,
  userWeight,
} from '../../constants/const';
import { fetchOrderDetails } from '../../services/orders';
import { handleErrorResponse } from '../../utils/utils';

const OrderDetails = () => {
  const appUser = useSelector((state) => state.auth.user);

  const { width } = useViewportSize();
  const navigate = useNavigate();
  const { uid } = useParams();
  const [scroll, scrollTo] = useWindowScroll();

  const queryClient = useQueryClient();

  const [isOrderEditable, setIsOrderEditable] = useState(true);

  const [DnRmismatch, setDnRmismatch] = useState(false);

  const [logsDrawer, setLogsDrawer] = useState(false);

  const [resolveDrawer, setResolveDrawer] = useState(false);

  const [createIgtOrdersDrawer, setCreateIgtOrdersDrawer] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [triggerScanInBtn, setTriggerScanInBtn] = useState(false);

  const [quantityBreakDownForStats, setQuantityBreakDownForStats] = useState({
    totalQuantity: 0,
    deliveredQuantity: 0,
    receivedQuantity: 0,
  });

  useEffect(() => {
    document.title = 'Order Details | Armada';
  }, []);

  const { data, isLoading, isFetching, refetch, error, isFetched } = useQuery({
    queryKey: ['order-details', uid],
    queryFn: fetchOrderDetails,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  useEffect(() => {
    if (location.pathname.split('/').includes('inbound-orders')) {
      setIsOrderEditable(false);
    } else {
      if (data?.data?.data?.order?.orderType === orderDetailsType.SO) {
        setIsOrderEditable(false);
      }
      if (appUser.userWeight < userWeight.MANAGER) {
        setIsOrderEditable(false);
      }
    }
  }, [data]);

  const callRefetchProducts = async () => {
    await queryClient.refetchQueries({
      queryKey: ['assigned-products-of-order'],
      type: 'active',
    });
  };

  if (isLoading)
    return (
      <Stack
        justify="center"
        align="center"
        sx={{
          width: '100%',
          height: '80vh',
        }}>
        <Text>Fetching Order details. Please wait.</Text>
        <Loader size="xl" variant="dots" />
      </Stack>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const { order } = data.data.data;

  return (
    <div
      style={{
        paddingBottom: '5rem',
      }}>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={createIgtOrdersDrawer}
        onClose={() => {
          setSelectedOrder(null);
          setCreateIgtOrdersDrawer(false);
        }}
        title={'Edit IGT Order'}
        styles={{
          title: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
          },
        }}
        position="right"
        size={width < 1420 ? '80%' : '75%'}>
        <IGTOrderForm
          update={true}
          defaultValues={selectedOrder}
          onUpdate={() => {
            refetch();
            setCreateIgtOrdersDrawer(false);
          }}
        />
      </Drawer>
      <Drawer
        opened={logsDrawer}
        onClose={() => {
          setLogsDrawer(false);
        }}
        title="Order Logs"
        position="right">
        <LogsDrawer
          referenceType={actionLoggerReferenceTypes.ORDER_MANAGEMENT}
          singleOrderFetch={true}
          uid={uid}
        />
      </Drawer>
      <Drawer
        opened={resolveDrawer}
        onClose={() => {
          setResolveDrawer(false);
        }}
        title="Order issues"
        position="right">
        <ResolveOrderDrawer
          onUpdate={() => {
            refetch();
            setResolveDrawer(false);
          }}
          orderNumber={order?.orderNumber}
          partialReceiveHistory={order?.partialReceiveHsitory}
          onlyHistory={
            order?.partialReceiveStatus ===
            orderPartialReceiveStatuses.PARTIALLY_RECEIVED_AND_RESOLVED
          }
        />
      </Drawer>
      {/* CUSTOM MODAL AND DRAWER END */}

      <Transition
        mounted={scroll.y > 20 ? true : false}
        transition="fade"
        duration={400}
        timingFunction="ease">
        {(styles) => (
          <div style={styles}>
            <ActionIcon
              color="indigo"
              size="xl"
              radius="xl"
              variant="filled"
              sx={{
                position: 'fixed',
                bottom: '2rem',
                right: '2rem',
                zIndex: 1,
              }}
              onClick={() => scrollTo({ y: 0 })}>
              <IconArrowBigUpLinesFilled size="2.125rem" />
            </ActionIcon>
          </div>
        )}
      </Transition>
      <Flex justify="space-between" align="start">
        <Button
          color="gray"
          onClick={() => {
            navigate(-1);
          }}
          leftIcon={<IconChevronLeft />}>
          Back
        </Button>
        {DnRmismatch && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              backgroundColor: '#fa5252',
              borderRadius: '0.5rem',
              padding: '0.5rem 1rem',
              maxHeight: '2.25rem',
            }}>
            <Flex gap={8} align="center">
              <IconAlertCircle size="1.5rem" color="white" />
              <Text
                sx={{
                  color: 'white',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}>
                Missing items for this order!
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
      {appUser.userWeight >= userWeight.MANAGER && (
        <>
          {order?.partialReceiveStatus ===
            orderPartialReceiveStatuses.PARTIALLY_RECEIVED && (
            <Stack my={10}>
              <Alert
                icon={<IconAlertCircle size="2rem" />}
                title="Attention! Order Flagged"
                color="red">
                <Flex justify="space-between">
                  <Text>
                    This order has been flagged by the system. Please check the
                    order and resolve the issue.
                  </Text>
                  <Button color="red" onClick={() => setResolveDrawer(true)}>
                    Resolve
                  </Button>
                </Flex>
              </Alert>
            </Stack>
          )}
        </>
      )}

      {appUser.userWeight >= userWeight.MANAGER && (
        <>
          {order?.orderStatus === orderStatusConst['IN TRANSIT'] && (
            <Stack my={10}>
              <Alert
                icon={<IconInfoCircle size="2rem" />}
                title={`Your order is on its way from ${order?.sourceInformation?.name}.`}
                color="blue">
                <Flex justify="space-between">
                  <Text>
                    Please double check your consignment and receive your order
                    as soon as the truck arrives.
                  </Text>
                  <Button
                    color="blue"
                    onClick={() => {
                      setTriggerScanInBtn(true);
                    }}>
                    {quantityBreakDownForStats.totalQuantity !==
                    quantityBreakDownForStats.deliveredQuantity
                      ? 'Partial Receive'
                      : 'Receive Order'}
                  </Button>
                </Flex>
              </Alert>
            </Stack>
          )}{' '}
        </>
      )}

      <Stack my={5}>
        <OrderTimeline order={order} />
      </Stack>
      <OrderInfoGridLayout
        order={order}
        isOrderEditable={isOrderEditable}
        setSelectedOrder={(data) => setSelectedOrder(data)}
        setCreateIgtOrdersDrawer={(data) => setCreateIgtOrdersDrawer(data)}
        setLogsDrawer={(data) => setLogsDrawer(data)}
        onUpdate={() => {
          refetch();
          callRefetchProducts();
        }}
        quantityBreakDownForStats={quantityBreakDownForStats}
        setResolveDrawer={(data) => setResolveDrawer(data)}
      />
      <Stack>
        <OrderDetailsProductsTable
          order={order}
          setDnRmismatch={setDnRmismatch}
          setQuantityBreakDownForStats={setQuantityBreakDownForStats}
        />
      </Stack>
      <Stack>
        <OrderDetailsCbcsTable
          order={order}
          onUpdate={() => {
            refetch();
            callRefetchProducts();
          }}
          quantityBreakDownForStats={quantityBreakDownForStats}
          triggerScanInBtn={triggerScanInBtn}
          setTriggerScanInBtn={(data) => setTriggerScanInBtn(data)}
        />
      </Stack>
    </div>
  );
};

export default OrderDetails;
