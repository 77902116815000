import {
  ActionIcon,
  Badge,
  Button,
  Drawer,
  Flex,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconArticleOff,
  IconListDetails,
  IconMessageForward,
  IconPlus,
  IconRefresh,
} from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SessionForm from '../../components/DayZeroRecon/SessionForm';
import FilterBadgeClearable from '../../components/Global/FilterBadgeClearable';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import COLORS from '../../constants/colors';
import { dayZeroReconciliationSessionStatuses } from '../../constants/const';
import { fetchAllSessions } from '../../services/dayZeroRecon';
import { fetchLocations } from '../../services/location';
import {
  dayZeroReconciliationSessionStatusesColorMapper,
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
  userWiseMultiSelectLocationDataWithId,
} from '../../utils/utils';
import { openConfirmModal } from '@mantine/modals';

const PAGE_TITLE = 'Inventory Reconciliation';

const DayZeroreconciliation = () => {
  const appUser = useSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [addSessionDrawer, setAddSessionDrawer] = useState(false);

  const [selectedLocation, setselectedLocation] = useState(null);
  const [selectedSession, setselectedSession] = useState(null);
  const [searchByStatus, setSearchByStatus] = useState(null);

  const [isAskingForOtp, setIsAskingForOtp] = useState(false);

  useEffect(() => {
    document.title = 'Inventory Reconciliation | Armada';
  }, []);

  const { data, refetch, isLoading, isFetching, error } = useQuery({
    queryKey: [
      'day-zero-recon',
      page,
      searchKey,
      selectedLocation,
      searchByStatus,
    ],
    queryFn: () =>
      fetchAllSessions({
        page: page,
        searchKey: searchKey,
        locationId: selectedLocation,
        status: searchByStatus,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
  });

  const locationData = queryClient.getQueryData([
    'location',
    1,
    null,
    null,
    null,
    true,
    true,
  ]);

  const callLocation = useQuery({
    queryKey: ['location', 1, null, null, null, true, true],
    enabled: locationData == undefined,
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to exit? Your OTP will be invalid
        </Text>
      ),
      confirmProps: { color: 'blue' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        setselectedSession(null);
        setAddSessionDrawer(false);
        setIsAskingForOtp(false);
      },
    });
  };

  if (isLoading)
    return (
      <div>
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const { total, results } = data.data.data.sessions;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={addSessionDrawer}
        onClose={() => {
          if (isAskingForOtp) {
            ConfirmModal();
          } else {
            setselectedSession(null);
            setAddSessionDrawer(false);
          }
        }}
        title={
          isObjectAndHasProperties(selectedSession)
            ? 'Verify Session'
            : 'Create Session'
        }
        closeOnClickOutside={false}
        position="right">
        <SessionForm
          update={isObjectAndHasProperties(selectedSession)}
          defaultValues={selectedSession}
          onUpdate={() => {
            refetch();
            setAddSessionDrawer(false);
          }}
          setIsAskingForOtp={setIsAskingForOtp}
        />
      </Drawer>

      {/* CUSTOM MODAL AND DRAWER END */}
      <Flex align="center" justify="space-between">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={() => handleRefresh()}
                handleSearch={handleSearch}
                placeholder="Search sessions"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>
            <Select
              placeholder="Search by point"
              dropdownPosition="bottom"
              data={
                userWiseMultiSelectLocationDataWithId({
                  appUser: appUser,
                  locationData: locationData?.data?.data?.locations || [],
                }) || []
              }
              onChange={(value) => {
                setselectedLocation(value);
              }}
              value={selectedLocation}
              clearable
              searchable
              withinPortal
            />
            <Select
              placeholder="Search by status"
              dropdownPosition="bottom"
              data={
                Object.keys(dayZeroReconciliationSessionStatuses).map(
                  (item) => ({
                    label: dayZeroReconciliationSessionStatuses[item],
                    value: dayZeroReconciliationSessionStatuses[item],
                  }),
                ) || []
              }
              onChange={(value) => {
                setSearchByStatus(value);
              }}
              value={searchByStatus}
              clearable
              searchable
              withinPortal
            />

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                    setselectedLocation(null);
                    setSearchByStatus(null);
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <div>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                setAddSessionDrawer(true);
              }}
              leftIcon={<IconPlus />}>
              Create Session
            </Button>
          </div>
        </Flex>
        {/* badge filters */}
        <Flex my={5} gap={5} align="center">
          <Text fz="xs">Showing {total || 0} sessions</Text>
          {searchKey || selectedLocation ? (
            <Text fz="xs">with filters</Text>
          ) : null}
          {searchKey && (
            <FilterBadgeClearable
              text={`search: ${searchKey}`}
              func={() => {
                setInvokingRefreshForSearchInput(
                  !invokingRefreshForSearchInput,
                );
                setSearchKey('');
              }}
              color="blue"
            />
          )}
          {selectedLocation && (
            <FilterBadgeClearable
              text={`location: ${
                locationData?.data?.data?.locations?.find(
                  (item) => item.id === selectedLocation,
                )?.name
              }`}
              func={() => {
                setselectedLocation(null);
              }}
              color="blue"
            />
          )}
          {searchByStatus && (
            <FilterBadgeClearable
              text={`status: ${searchByStatus}`}
              func={() => {
                setSearchByStatus(null);
              }}
              color="blue"
            />
          )}
        </Flex>

        {/* badge filters */}
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(results) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Session ID</th>
                      <th>Created At</th>
                      <th>Point</th>
                      <th>Status</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {results.map((result, index) => (
                      <tr key={index}>
                        <td>{result.name}</td>
                        <td>
                          {dayjs(result?.createdAt).format(
                            'DD MMM, YYYY, hh:mm A',
                          )}
                        </td>
                        <td>{result?.location?.name}</td>
                        <td>
                          <Badge
                            color={dayZeroReconciliationSessionStatusesColorMapper(
                              result.sessionStatus,
                            )}
                            size="lg">
                            {result.sessionStatus}
                          </Badge>
                        </td>
                        <td>{result?.creator?.name}</td>

                        <td>
                          {' '}
                          <Flex gap={5} align="center">
                            {result?.sessionStatus === 'PENDING' ? (
                              <Tooltip label={'Verify'}>
                                <ActionIcon
                                  variant="light"
                                  color="teal"
                                  disabled={
                                    result.sessionStatus !== 'PENDING'
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    setselectedSession(result);
                                    setAddSessionDrawer(true);
                                  }}>
                                  <IconMessageForward size={16} />
                                </ActionIcon>
                              </Tooltip>
                            ) : (
                              <Tooltip label={'View'}>
                                <ActionIcon
                                  variant="light"
                                  color="blue"
                                  disabled={
                                    result.sessionStatus === 'CANCELLED'
                                      ? true
                                      : false
                                  }
                                  onClick={() => {
                                    navigate(
                                      `/inventory-reconciliation/details/${result.uid}`,
                                    );
                                  }}>
                                  <IconListDetails size={16} />
                                </ActionIcon>
                              </Tooltip>
                            )}
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No sessions found"
                  subtext={' '}
                  icon={<IconArticleOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="end">
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default DayZeroreconciliation;
