import { Box, SimpleGrid, Space, Stack, Text } from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import ExcelExport from 'export-xlsx';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import AdminDashboardUpdatedChartBox from '../../components/Charts/AdminDashboardUpdatedChartBox';
import DashboardCbcSection from '../../components/Dashboard/DashboardCbcSection';
import DashboardMainFilter from '../../components/Dashboard/DashboardMainFilter';
import DashboardOrderSection from '../../components/Dashboard/DashboardOrderSection';
import CommonLoaderBox from '../../components/Dashboard/global/CommonLoaderBox';
import { SIGNIN } from '../../constants/app-urls';
import {
  exportCbcStats,
  exportDashboardCbcStats,
  exportDashboardOrderStats,
  exportInventoryStats,
  fetchDashboardCbcStats,
  fetchDashboardInventoryStats,
  fetchDashboardOrderStats,
  fetchDashboardTrend,
  removeExportFile,
} from '../../services/dashboard';
import { handleErrorResponse } from '../../utils/utils';
import { NotificationUtil } from '../../utils/notifications';
import { notifications } from '@mantine/notifications';

import AdminDashboardView from '../../components/Dashboard/AdminDashboardView';
import GDDashboardView from '../../components/Dashboard/GDDashboardView';
import ManagerDashboardView from '../../components/Dashboard/ManagerDashboardView';
import { userWeight } from '../../constants/const';

const GetDashBoardContent = ({ role, locations }) => {
  if (userWeight[role] >= userWeight.ADMIN) {
    return <AdminDashboardView role={role} />;
  } else if (userWeight[role] >= userWeight['GODOWN MANAGER']) {
    return <ManagerDashboardView role={role} location={locations} />;
  } else {
    return <GDDashboardView role={role} location={locations} />;
  }
};
const Dashboard = () => {
  const user = useSelector((state) => state?.auth?.user);
  const token = useSelector((state) => state?.auth?.accessToken);
  const refreshToken = useSelector((state) => state?.auth?.refreshToken);
  const isAuth = useSelector((state) => state?.auth?.isAuth);

  const [mainFilters, setMainFilters] = useState(null);
  const [orderFilter, setOrderFilter] = useState(null);
  const [cbcFilter, setCbcFilter] = useState(null);
  const [trendFilter, setTrendFilter] = useState(null);

  useEffect(() => {
    document.title = 'Dashboard | Armada';
  }, []);

  // const {
  //   data: dashboardOrderStats,
  //   isLoading: dashboardOrderStatsLoading,
  //   isError: dashboardOrderStatsError,
  //   isFetching: dashboardOrderStatsFetching,
  // } = useQuery({
  //   queryKey: ['fetch-dashbaord-order-stats', mainFilters, orderFilter],
  //   queryFn: () =>
  //     fetchDashboardOrderStats({
  //       startDate: mainFilters?.startDate,
  //       endDate: mainFilters?.endDate,
  //       orderType: orderFilter === 'ALL' ? null : orderFilter,
  //       region: mainFilters?.searchByRegion,
  //       area: mainFilters?.searchByArea,
  //       territory: mainFilters?.searchByTerritory,
  //       dh: mainFilters?.searchByDistributor,
  //       location: mainFilters?.searchByLocation,
  //     }),
  //   refetchOnWindowFocus: false,
  //   keepPreviousData: true,
  //   retry: false,
  //   onError: (error) => {
  //     handleErrorResponse(error);
  //   },
  // });

  // const {
  //   data: dashboardCbcStats,
  //   isLoading: dashboardCbcStatsLoading,
  //   isError: dashboardCbcStatsError,
  //   isFetching: dashboardCbcStatsFetching,
  // } = useQuery({
  //   queryKey: ['fetch-dashbaord-cbc-stats', mainFilters, cbcFilter],
  //   queryFn: () =>
  //     fetchDashboardCbcStats({
  //       startDate: mainFilters?.startDate,
  //       endDate: mainFilters?.endDate,
  //       region: mainFilters?.searchByRegion,
  //       area: mainFilters?.searchByArea,
  //       territory: mainFilters?.searchByTerritory,
  //       dh: mainFilters?.searchByDistributor,
  //       location: mainFilters?.searchByLocation,
  //       segment: cbcFilter?.segment,
  //       family: cbcFilter?.family,
  //       sku: cbcFilter?.sku,
  //       packSize: cbcFilter?.packSize,
  //     }),
  //   refetchOnWindowFocus: false,
  //   keepPreviousData: true,
  //   retry: false,
  //   onError: (error) => {
  //     handleErrorResponse(error);
  //   },
  // });

  // const {
  //   data: dashboardInventoryStats,
  //   isLoading: dashboardInventoryStatsLoading,
  //   isError: dashboardInventoryStatsError,
  //   isFetching: dashboardInventoryStatsFetching,
  // } = useQuery({
  //   queryKey: ['fetch-dashbaord-inventory-stats', mainFilters, cbcFilter],
  //   queryFn: () =>
  //     fetchDashboardInventoryStats({
  //       region: mainFilters?.searchByRegion,
  //       area: mainFilters?.searchByArea,
  //       territory: mainFilters?.searchByTerritory,
  //       dh: mainFilters?.searchByDistributor,
  //       location: mainFilters?.searchByLocation,
  //       segment: cbcFilter?.segment,
  //       family: cbcFilter?.family,
  //       sku: cbcFilter?.sku,
  //       packSize: cbcFilter?.packSize,
  //     }),
  //   refetchOnWindowFocus: false,
  //   keepPreviousData: true,
  //   retry: false,
  //   onError: (error) => {
  //     handleErrorResponse(error);
  //   },
  // });

  // const {
  //   data: dashboardTrend,
  //   isLoading: dashboardTrendLoading,
  //   isError: dashboardTrendError,
  //   isFetching: dashboardTrendFetching,
  // } = useQuery({
  //   queryKey: ['fetch-dashbaord-trend', trendFilter, mainFilters],
  //   queryFn: () =>
  //     fetchDashboardTrend({
  //       startDate: mainFilters?.startDate,
  //       endDate: mainFilters?.endDate,
  //       region: mainFilters?.searchByRegion,
  //       area: mainFilters?.searchByArea,
  //       territory: mainFilters?.searchByTerritory,
  //       dh: mainFilters?.searchByDistributor,
  //       location: mainFilters?.searchByLocation,
  //       mode: mainFilters?.startDate ? 'custom' : trendFilter,
  //     }),
  //   refetchOnWindowFocus: false,
  //   keepPreviousData: true,
  //   retry: false,
  //   onError: (error) => {
  //     handleErrorResponse(error);
  //   },
  // });

  const { mutate: orderExportMutate, isLoading: orderExportLoading } =
    useMutation({
      mutationFn: () =>
        exportDashboardOrderStats({
          startDate: mainFilters?.startDate,
          endDate: mainFilters?.endDate,
          region: mainFilters?.searchByRegion,
          area: mainFilters?.searchByArea,
          territory: mainFilters?.searchByTerritory,
          dh: mainFilters?.searchByDistributor,
          location: mainFilters?.searchByLocation,
        }),
      keepPreviousData: false,
      onError: (error) => {
        handleErrorResponse(error);
      },
      onSuccess: (data) => {
        handleDownloadImage(data.data.data);
      },
    });

  const { mutate: inOutScansExportMutate, isLoading: inOutScansExportLoading } =
    useMutation({
      mutationFn: () =>
        exportDashboardCbcStats({
          startDate: mainFilters?.startDate,
          endDate: mainFilters?.endDate,
          region: mainFilters?.searchByRegion,
          area: mainFilters?.searchByArea,
          territory: mainFilters?.searchByTerritory,
          dh: mainFilters?.searchByDistributor,
          location: mainFilters?.searchByLocation,
        }),
      keepPreviousData: false,
      onError: (error) => {
        handleErrorResponse(error);
      },
      onSuccess: (data) => {
        handleDownloadImage(data.data.data);
      },
    });

  const { mutate: CbcExportMutate, isLoading: CbcExportLoading } = useMutation({
    mutationFn: () =>
      exportCbcStats({
        startDate: mainFilters?.startDate,
        endDate: mainFilters?.endDate,
        region: mainFilters?.searchByRegion,
        area: mainFilters?.searchByArea,
        territory: mainFilters?.searchByTerritory,
        dh: mainFilters?.searchByDistributor,
        location: mainFilters?.searchByLocation,
      }),
    keepPreviousData: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      handleDownloadImage(data.data.data);
    },
  });

  const handleDownloadImage = async (file) => {
    try {
      const response = await fetch(file.fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-refresh-token': refreshToken,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifications.clean();
      NotificationUtil({
        success: true,
        title: 'File Downloaded',
        message: 'Your file has been downloaded successfully.',
      });
      removeExportFileMutate(file.fileName);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const { mutate: removeExportFileMutate, isLoading: removeExportFileLoading } =
    useMutation({
      mutationFn: (fileName) => removeExportFile(fileName),
      keepPreviousData: false,
    });

  const { mutate: inventoryExportMutate, isLoading: inventoryExportLoading } =
    useMutation({
      mutationFn: () =>
        exportInventoryStats({
          startDate: mainFilters?.startDate,
          endDate: mainFilters?.endDate,
          region: mainFilters?.searchByRegion,
          area: mainFilters?.searchByArea,
          territory: mainFilters?.searchByTerritory,
          dh: mainFilters?.searchByDistributor,
          location: mainFilters?.searchByLocation,
        }),
      keepPreviousData: false,
      onError: (error) => {
        handleErrorResponse(error);
      },
      onSuccess: (data) => {
        handleDownloadImage(data.data.data);
      },
    });
  if (!isAuth) {
    return <Navigate to={SIGNIN} />;
  }

  return (
    <Stack
      justify="center"
      align="center"
      spacing={5}
      style={{
        width: '100%',
        height: '100%',
      }}>
      <img
        src="https://i0.wp.com/devmagic.com.br/wp-content/uploads/2022/04/6ELx.gif?w=800&ssl=1"
        width={250}
      />
      <Text fw={700} size="xl">
        Dashboard is under maintenance.
      </Text>
      <Text>Sorry for the inconvenience!</Text>
      <Text>Feel free to use the rest of the features.</Text>
    </Stack>
  );
  // return (
  //   <div>
  //     <DashboardMainFilter setMainFilters={setMainFilters} />
  //     <Space h="md" />
  //     <SimpleGrid
  //       cols={2}
  //       py="md"
  //       spacing="lg"
  //       breakpoints={[
  //         { maxWidth: 'xl', cols: 1, spacing: 'xl' },
  //         { maxWidth: 'lg', cols: 1, spacing: 'lg' },
  //         { maxWidth: 'sm', cols: 1, spacing: 'sm' },
  //         { maxWidth: 'xs', cols: 1, spacing: 'sm' },
  //       ]}>
  //       <DashboardOrderSection
  //         setOrderFilter={setOrderFilter}
  //         dashboardOrderStats={dashboardOrderStats?.data?.data}
  //         loading={dashboardOrderStatsLoading}
  //         isFetching={dashboardOrderStatsFetching}
  //         exportFunc={orderExportMutate}
  //         isExporting={orderExportLoading}
  //       />
  //       <DashboardCbcSection
  //         setCbcFilter={setCbcFilter}
  //         dashboardCbcStats={dashboardCbcStats?.data?.data}
  //         dashboardInventoryStats={dashboardInventoryStats?.data?.data}
  //         loading={dashboardCbcStatsLoading}
  //         isFetching={dashboardCbcStatsFetching}
  //         inOutScansExportFunc={inOutScansExportMutate}
  //         inOutScansIsExporting={inOutScansExportLoading}
  //         CbcExportFunc={CbcExportMutate}
  //         CbcIsExporting={CbcExportLoading}
  //         inventoryExportFunc={inventoryExportMutate}
  //         inventoryIsExporting={inventoryExportLoading}
  //       />
  //     </SimpleGrid>
  //     {dashboardTrendLoading ? (
  //       <CommonLoaderBox />
  //     ) : (
  //       <Box style={{ width: '100%' }}>
  //         <AdminDashboardUpdatedChartBox
  //           setTrendFilter={setTrendFilter}
  //           data={dashboardTrend?.data?.data}
  //           open={open}
  //           isFetching={dashboardTrendFetching}
  //         />
  //       </Box>
  //     )}
  //   </div>
  // );
};

export default Dashboard;
