import { Autocomplete, Button, MultiSelect, Stack, Text } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { reportTypeConst, userRoles } from '../../../constants/const';
import { fetchLocations } from '../../../services/location';
import { fetchProductsbyLocations } from '../../../services/product';
import { fetchInventoryReports } from '../../../services/reports';
import {
  handleErrorResponse,
  isArrayAndHasContent,
} from '../../../utils/utils';
import { alignment, defaultDataType } from 'export-xlsx';
import ExcelExport from 'export-xlsx';
import COLORS from '../../../constants/colors';

const SETTINGS_FOR_EXPORT = {
  // Table settings
  fileName: 'example',
  workSheets: [
    {
      sheetName: 'example',
      startingRowNumber: 2,
      tableSettings: {
        table1: {
          tableTitle: 'Score',
          headerGroups: [
            {
              name: '',
              key: 'void',
              groupKey: 'directions',
            },
            {
              name: 'Science',
              key: 'science',
              groupKey: 'directions',
            },
            {
              name: 'Directions',
              key: 'directions',
            },
          ],
          headerDefinition: [
            {
              name: '#',
              key: 'number',
            },
            {
              name: 'Name',
              key: 'name',
            },
            {
              name: 'SUM',
              key: 'sum',
              groupKey: 'void',
              rowFormula:
                '{math}+{physics}+{chemistry}+{informatics}+{literature}+{foreignLang}',
            },
            {
              name: 'Mathematics',
              key: 'math',
              groupKey: 'science',
            },
            {
              name: 'Physics',
              key: 'physics',
              groupKey: 'science',
            },
            {
              name: 'Chemistry',
              key: 'chemistry',
              groupKey: 'science',
            },
            {
              name: 'Informatics',
              key: 'informatics',
              groupKey: 'science',
            },
            {
              name: 'Literature',
              key: 'literature',
              groupKey: 'science',
            },
            {
              name: 'Foreign lang.',
              key: 'foreignLang',
              groupKey: 'science',
            },
            {
              name: 'AVG',
              key: 'avg',
              groupKey: 'void',
              rowFormula: '{sum}/6',
            },
          ],
        },
      },
    },
  ],
};

const dummydata = [
  {
    table1: [
      {
        number: 1,
        name: 'Jack',
        sum: '',
        math: 1,
        physics: 2,
        chemistry: 2,
        informatics: 1,
        literature: 2,
        foreignLang: 1,
        avg: '',
      },
      {
        number: 2,
        name: 'Peter',
        sum: '',
        math: 2,
        physics: 2,
        chemistry: 1,
        informatics: 2,
        literature: 2,
        foreignLang: 1,
        avg: '',
      },
    ],
  },
];

const ReportsDrawer = () => {
  const appUser = useSelector((state) => state.auth.user);

  const [location, setLocation] = useState([]);
  const [reportType, setReportType] = useState([]);
  const [product, setProduct] = useState({});
  const [generateToggler, setGenerateToggler] = useState(false);

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: ['location', 1, null, null, null, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const {
    data: productsData,
    isLoading: productsLoading,
    error: productsError,
  } = useQuery({
    queryKey: ['productsByLocations', location],
    queryFn: fetchProductsbyLocations,
    enabled: isArrayAndHasContent(location),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const {
    data: reportsData,
    isLoading: reportsLoading,
    isFetching: reportsFetching,
    refetch,
    error: reportsError,
  } = useQuery({
    queryKey: ['generateReport', null, location, reportType, product],
    queryFn: fetchInventoryReports,
    enabled: generateToggler === true,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
      setGenerateToggler(false);
    },
    onSuccess: (data) => {
      const excelExport = new ExcelExport();
      excelExport.downloadExcel(
        data.data.data.settings,
        data.data.data.sheetData,
      );
      setGenerateToggler(false);
    },
  });

  const sourceLocationData = () => {
    if (
      userRoles[appUser?.role] === userRoles.ADMIN ||
      userRoles[appUser?.role] === userRoles['SUPER ADMIN']
    ) {
      return locationData?.data?.data?.locations.map((location) => ({
        value: location.uid,
        label: location.name,
      }));
    } else {
      return appUser.locations.map((location) => ({
        value: location.uid,
        label: location.name,
      }));
    }
  };

  return (
    <div
      style={{
        minHeight: '75vh',
      }}>
      <Text>Select parameters to generate report</Text>

      <Stack
        sx={{
          height: '70px',
        }}>
        <Stack
          sx={{
            flex: 1,
          }}>
          <MultiSelect
            data={sourceLocationData() || []}
            label="Location"
            placeholder="Search Location"
            searchable
            nothingFound="Nothing found"
            dropdownPosition="bottom"
            value={location}
            onChange={(e) => {
              setLocation(e);
            }}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
          }}>
          <MultiSelect
            data={Object.keys(reportTypeConst).map((key) => ({
              label: key.split('_').join(' '),
              value: key,
            }))}
            label="Report Type"
            searchable
            placeholder="Select Report Type"
            dropdownPosition="bottom"
            onChange={(e) => {
              setReportType(e);
            }}
          />
        </Stack>
        <Stack
          sx={{
            flex: 1,
          }}>
          <Autocomplete
            label="Product Name"
            placeholder="Search Product"
            limit={productsData?.data?.data?.inventory.length || 0}
            maxDropdownHeight="200px"
            data={
              productsData?.data?.data?.inventory.map((product) => ({
                value: `${product.name} - ${product.sku}`,
                id: product.id,
                name: product.name,
                packages: product.packages,
                sku: product.sku,
                uid: product.uid,
                cbcquantity: product.cbcQuantity,
                category: product.category,
              })) || []
            }
            value={product?.value}
            onChange={(e) => {
              if (e.length === 0) {
                setProduct(null);
              }
            }}
            onItemSubmit={(e) => {
              setProduct(e);
            }}
          />
        </Stack>
        <Stack justify="end" align="end" my={10}>
          <Button
            sx={{
              backgroundColor: COLORS.primary,
            }}
            onClick={() => {
              if (!generateToggler) {
                setGenerateToggler(true);
                refetch();
              } else {
                refetch();
              }
            }}
            loading={reportsFetching}
            disabled={
              !isArrayAndHasContent(location) ||
              !isArrayAndHasContent(reportType)
            }>
            Generate Report
          </Button>
        </Stack>
      </Stack>
    </div>
  );
};

export default ReportsDrawer;
