import {
  Button,
  Flex,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Stepper,
  Table,
  Text,
  createStyles,
} from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import SuccessGif from '../../assets/success.gif';
import UploadingFileGif from '../../assets/uploading_web.gif';
import COLORS from '../../constants/colors';
import { assignRouteSection } from '../../services/location';
import { NotificationUtil } from '../../utils/notifications';
import Uploader from './Uploader';

const useStyles = createStyles(() => ({
  stepperBox: {
    backgroundColor: COLORS.white,
    borderColor: 'darkgray',
    borderStyle: 'dotted',
    borderRadius: 'none',
    minHeight: '65vh',
    width: '75vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}));

const UploadRouteSectionModal = ({ location, onUpdate }) => {
  const { classes } = useStyles();

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [previewData, setPreviewData] = useState(null);

  const [active, setActive] = useState(0);

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const uploadFile = async (selectedFile) => {
    if (selectedFile) {
      const file = selectedFile;
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        jsonData.shift();
        setPreviewData(jsonData.flat());
      };

      reader.readAsArrayBuffer(file);
      nextStep();
    }
  };

  const { mutate: assignMutate, isLoading: isAssigning } = useMutation({
    mutationFn: async (values) => await assignRouteSection(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Assigned',
        message: 'Route/Section assigned successfully',
      });
      nextStep();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <Stack
      justify="center"
      align="center"
      sx={{
        height: '80vh',
        width: '80vw',
      }}>
      <Stepper active={active} onStepClick={null} breakpoint="sm" py="xl">
        {/* uploader */}
        <Stepper.Step label="Upload File" description="Upload File (.xlsx)">
          <ScrollArea h="65vh">
            <div className={classes.stepperBox}>
              {/* {!isUploading ? ( */}
              <Uploader
                uploadFile={uploadFile}
                isUploading={false}
                title=" Upload Updated Route/Section"
                desc="Please Upload Valid File Format (xlsx/excel)"
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                fileName={fileName}
                setFileName={setFileName}
              />
              {/* ) : (
            <Flex
              direction="column"
              gap={10}
              justify="center"
              align="center">
              <Text fw={700} fz="xl">
                Uploading Calendar For Preview
              </Text>
              <Box py="md">
                <img src={UploadingForPreviewGif} alt="uploading.." />
              </Box>
              <Text fz="sm" c="dimmed">
                Please wait a moment ...
              </Text>
            </Flex>
          )} */}
            </div>
          </ScrollArea>
        </Stepper.Step>

        {/* preview */}
        <Stepper.Step
          label="Preview Uploaded Route/Section"
          description="Check Route/Section Preview">
          <ScrollArea
            h="63vh"
            style={{
              height: 'auto !important',
              width: '75vw',
              padding: '1em',
              borderStyle: 'dotted',
            }}>
            {previewData ? (
              <div>
                <ScrollArea h="55vh">
                  <Table highlightOnHover>
                    <thead
                      style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}>
                      <tr>
                        <th>Route/Section</th>
                      </tr>
                    </thead>
                    <tbody>
                      {previewData.map((location, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              maxWidth: '120px',
                            }}>
                            {location}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </ScrollArea>
              </div>
            ) : (
              <div>
                <LoadingOverlay visible={true} />
              </div>
            )}
          </ScrollArea>
        </Stepper.Step>

        {/* final upload */}
        <Stepper.Step
          label="Confirm Route/Section Upload"
          description="Confirm and finish the upload">
          <ScrollArea h="65vh">
            <div className={classes.stepperBox}>
              {isAssigning ? (
                <div>
                  <Flex
                    direction="column"
                    gap={10}
                    justify="center"
                    align="center">
                    <Text fz="sm" c="dimmed">
                      Please don&apos;t close or reload while uploading
                    </Text>
                    <div>
                      <img
                        src={UploadingFileGif}
                        alt="uploading.."
                        height="200px"
                      />
                    </div>
                    <Text fz="sm" c="dimmed">
                      Please wait a moment ...
                    </Text>
                    <Text fw={700} fz="1.6rem">
                      Updating Route/Section
                    </Text>
                  </Flex>
                </div>
              ) : // <DisclaimerPanel
              //   uploadFile={uploadFileFinal}
              //   isUploading={isFileUploading}
              //   selectedFile={selectedFile}
              //   fileName={fileName}
              //   onUpdate={onUpdate}
              // />
              null}
            </div>
          </ScrollArea>
        </Stepper.Step>

        <Stepper.Step
          label="Successfully Updated"
          description="Route/Section update completed">
          <ScrollArea h="65vh">
            <div className={classes.stepperBox}>
              <div>
                <Flex
                  direction="column"
                  gap={5}
                  justify="center"
                  align="center">
                  <div>
                    <img src={SuccessGif} alt="Uploaded" height="200px" />
                  </div>

                  <Text fw={700} fz="md">
                    Route/Section Updated Successfully
                  </Text>
                  <Button
                    mt="md"
                    size="lg"
                    onClick={() => {
                      setPreviewData(null);
                      onUpdate();
                    }}
                    sx={{ backgroundColor: COLORS.primary }}>
                    Continue
                  </Button>
                </Flex>
              </div>
            </div>
          </ScrollArea>
        </Stepper.Step>
      </Stepper>

      <Flex
        justify="space-between"
        align="center"
        style={{ width: '100%' }}
        py="sm">
        {active == 1 ? (
          <div>
            <Text fz="md" color="red" fw={600}>
              *** Please review the whole data and continue
            </Text>
          </div>
        ) : (
          <div></div>
        )}
        <Flex justify="center" align="center" gap={5}>
          {active >= 1 && active < 3 ? (
            <Button variant="default" onClick={prevStep} size="md">
              Back
            </Button>
          ) : (
            <></>
          )}
          {active == 1 ? (
            <Button
              onClick={() => {
                console.log(active);
                if (active == 1) {
                  assignMutate({
                    uid: location.uid,
                    routeSections: previewData,
                  });
                }
                nextStep();
              }}
              size="md">
              Continue Upload
            </Button>
          ) : (
            <></>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
};

export default UploadRouteSectionModal;
