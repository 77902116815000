import {
  Badge,
  Button,
  Card,
  Flex,
  Grid,
  Loader,
  SimpleGrid,
  Stack,
  Text,
} from '@mantine/core';
import {
  IconCubeSend,
  IconFaceIdError,
  IconPackage,
  IconPackageImport,
  IconPackages,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AssignedCBCList from '../../../components/Inventory/Product/AssignedCBCList';
import AssignedOrdersList from '../../../components/Inventory/Product/AssignedOrdersList';
import COLORS from '../../../constants/colors';
import { fetchProductExtraDetails } from '../../../services/product';
import GRADIENTS from '../../../components/Dashboard/global/colorSchemes';

const ProductDetails = () => {
  const { uid } = useParams();
  const navigate = useNavigate();

  const { isLoading, isError, data } = useQuery({
    queryKey: ['productDetails', uid],
    queryFn: () => fetchProductExtraDetails(uid),
  });

  if (isLoading) {
    return (
      <Stack w="100%" h="calc(100vh - 35px)" align="center" justify="center">
        <Loader size="xl" variant="oval" />
        {/* <Text size="md" color="#272729" weight="bold">
          Getting product details please wait
        </Text> */}
      </Stack>
    );
  }

  if (isError) {
    return (
      <Stack w="100%" h="calc(100vh - 35px)" align="center" justify="center">
        <IconFaceIdError size={72} />
        <Text size="md" color="red" weight="bold">
          Sorry could not find product details
        </Text>
        <Button variant="filled" color="blue" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Stack>
    );
  }

  const productDetails = data.data.data;
  //console.log('product details', productDetails);

  return (
    <div>
      <Grid>
        <Grid.Col md={12} lg={4}>
          <Card
            shadow="xs"
            padding="md"
            sx={{
              width: '100%',
              height: '100%',
              minHeight: '8em',
              backgroundColor: '#e5e5e5',
            }}
            radius="md"
            withBorder>
            <Flex justify="space-between" gap={5}>
              <Text size="md" weight="bold">
                {productDetails?.product?.name}
              </Text>
              <Badge
                color={productDetails?.product.isActive ? 'green' : 'red'}
                variant="light">
                {productDetails?.product.isActive ? 'Active' : 'Inactive'}
              </Badge>
            </Flex>
            <Flex align="center" gap={10}>
              <Text size="xs" weight="bold" color={COLORS.primary} py="sm">
                Package Name :
                <Badge size="0.7rem" color="violet">
                  {productDetails?.product?.packages?.name
                    ? productDetails?.product?.packages?.name
                    : 'N/A'}
                </Badge>
              </Text>

              <Text size="xs" weight="bold" color={COLORS.primary}>
                Symphony Ref:{' '}
                <Badge size="0.7rem" color="orange">
                  {productDetails?.product?.symphonyReferenceId
                    ? productDetails?.product?.symphonyReferenceId
                    : 'N/A'}
                </Badge>
              </Text>
            </Flex>
            <Flex justify="space-between" align="center">
              <Text size="xs" weight="bold" color={COLORS.primary} py="sm">
                SKU :{' '}
                <Badge size="0.7rem">
                  {productDetails.product.sku
                    ? productDetails.product.sku
                    : 'N/A'}
                </Badge>
              </Text>
              <Text size="xs" weight="bold" color={COLORS.primary}>
                Category:{' '}
                <Badge color="blue" variant="light">
                  {productDetails?.product?.category
                    ? productDetails?.product?.category
                    : 'N/A'}{' '}
                  /{' '}
                  {productDetails?.product?.subCategory
                    .map((sub) => sub)
                    .join('/')
                    ? productDetails?.product?.subCategory
                        .map((sub) => sub)
                        .join('/')
                    : 'N/A'}
                </Badge>
              </Text>
              <Text size="xs" weight="bold" color={COLORS.primary}>
                Size:{' '}
                <Badge color="blue" variant="light">
                  {productDetails?.product?.packages?.size / 1000
                    ? productDetails?.product?.packages?.size / 1000
                    : 'N/A'}{' '}
                  TH{' '}
                  {productDetails?.product?.packages?.sizeUnit
                    ? productDetails?.product?.packages?.sizeUnit
                    : 'N/A'}
                </Badge>
              </Text>
            </Flex>
          </Card>
        </Grid.Col>

        <Grid.Col md={6} lg={2}>
          <Card
            shadow="xs"
            padding="md"
            sx={{
              width: '100%',
              height: '100%',
              minHeight: '8em',
              background: GRADIENTS.darkblue,
            }}
            radius="md"
            withBorder>
            <Flex justify="flex-start" align="center" gap={5}>
              <IconPackageImport color="#ffffff" size="1.5em" />
              <Text fw={700} fz="md" color="#ffffff">
                Assigned Orders
              </Text>
            </Flex>
            <Flex justify="flex-end" align="center">
              <Text
                fw={700}
                fz="xl"
                color="#ffffff"
                px="sm"
                style={{ fontSize: '3.5rem' }}>
                {productDetails?.assignedOrder
                  ? productDetails?.assignedOrder
                  : 0}
              </Text>
            </Flex>
          </Card>
        </Grid.Col>

        <Grid.Col md={6} lg={2}>
          <Card
            shadow="xs"
            padding="md"
            sx={{
              width: '100%',
              height: '100%',
              minHeight: '8em',
              backgroundColor: GRADIENTS.pink,
            }}
            radius="md"
            withBorder>
            <Flex justify="flex-start" align="center" gap={5}>
              <IconPackages color="#ffffff" size="1.5em" />
              <Text fw={700} fz="md" color="#ffffff">
                Total CBCs
              </Text>
            </Flex>
            <Flex justify="flex-end" align="center">
              <Text
                fw={700}
                fz="xl"
                color="#ffffff"
                px="sm"
                style={{ fontSize: '3.5rem' }}>
                {productDetails?.cbcCount ? productDetails?.cbcCount : 0}
              </Text>
            </Flex>
          </Card>
        </Grid.Col>

        <Grid.Col md={6} lg={2}>
          <Card
            shadow="xs"
            padding="md"
            sx={{
              width: '100%',
              height: '100%',
              minHeight: '8em',
              backgroundColor: GRADIENTS.skyblue,
            }}
            radius="md"
            withBorder>
            <Flex justify="flex-start" align="center" gap={5}>
              <IconPackage color="#ffffff" size="1.5em" />
              <Text fw={700} fz="md" color="#ffffff">
                Intact CBCs
              </Text>
            </Flex>
            <Flex justify="flex-end" align="center">
              <Text
                fw={700}
                fz="xl"
                color="#ffffff"
                px="sm"
                style={{ fontSize: '3.5rem' }}>
                {productDetails?.cbcScannedIn
                  ? productDetails?.cbcScannedIn
                  : 0}
              </Text>
            </Flex>
          </Card>
        </Grid.Col>

        <Grid.Col md={6} lg={2}>
          <Card
            shadow="xs"
            padding="md"
            sx={{
              width: '100%',
              height: '100%',
              minHeight: '8em',
              backgroundColor: GRADIENTS.lightgreen,
            }}
            radius="md"
            withBorder>
            <Flex justify="flex-start" align="center" gap={5}>
              <IconPackage color="#ffffff" size="1.5em" />
              <Text fw={700} fz="md" color="#ffffff">
                Opened CBCs
              </Text>
            </Flex>
            <Flex justify="flex-end" align="center">
              <Text
                fw={700}
                fz="xl"
                color="#ffffff"
                px="sm"
                style={{ fontSize: '3.5rem' }}>
                {productDetails?.cbcOpened ? productDetails?.cbcOpened : 0}
              </Text>
            </Flex>
          </Card>
        </Grid.Col>
      </Grid>
      <AssignedOrdersList
        uid={uid}
        productSize={productDetails?.product?.packages?.size || 10500}
      />

      <AssignedCBCList
        uid={uid}
        productSize={productDetails?.product?.packages?.size || 10500}
      />
    </div>
  );
};

export default ProductDetails;
