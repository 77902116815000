import {
  Button,
  Center,
  Flex,
  Stack,
  Text,
  Tooltip,
  createStyles,
} from '@mantine/core';
import {
  IconFile3d,
  IconFileUpload,
  IconTrashFilled,
} from '@tabler/icons-react';
import React from 'react';

import FileGif from '../../assets/file.gif';
import FileSelectGif from '../../assets/fileselect.gif';
import COLORS from '../../constants/colors';
import { NotificationUtil } from '../../utils/notifications';

const useStyles = createStyles(() => ({
  uploaderBox: {
    height: '300px',
    width: '500px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: ' 2px dashed #1475cf',
    borderRadius: '8px',
  },
}));

const Uploader = ({
  uploadFile,
  isUploading,
  title,
  desc,
  selectedFile,
  setSelectedFile,
  fileName,
  setFileName,
}) => {
  const { classes } = useStyles();

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (
      file &&
      (file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'application/vnd.ms-excel')
    ) {
      setFileName(file.name);
      setSelectedFile(file);
    } else {
      NotificationUtil({
        success: false,
        title: 'Invalid File',
        message: 'Please upload valid file',
      });
    }
  };

  return (
    <main>
      <Center>
        <Flex direction="column" justify="center" align="center" gap={5}>
          <Text fz="xl" fw={600} color={COLORS.fontPrimary}>
            {title}
          </Text>
          <Text fz="xs" c="dimmed" pb="xs">
            {desc}
          </Text>
        </Flex>
      </Center>
      <form
        className={classes.uploaderBox}
        onClick={() => document.querySelector('.input-field').click()}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}>
        <input
          type="file"
          className="input-field"
          hidden
          onChange={({ target: { files } }) => {
            const file = files[0];

            if (
              file &&
              (file.type ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                file.type === 'application/vnd.ms-excel')
            ) {
              setFileName(file.name);
              setSelectedFile(file);
            } else {
              NotificationUtil({
                success: false,
                title: 'Invalid File',
                message: 'Please upload valid file',
              });
            }
          }}
        />
        {selectedFile ? (
          <Flex direction="column" align="center" justify="center" gap={10}>
            <div>
              <img src={FileGif} alt="select-file" height="120px" />
            </div>
            <Text align="center" p={10}>
              {fileName}
            </Text>
          </Flex>
        ) : (
          <Flex direction="column" align="center" justify="center" gap={10}>
            <div>
              <img src={FileSelectGif} alt="select-file" height="120px" />
            </div>
            <Flex direction="column" align="center" justify="center" gap={2}>
              <Text fz="sm" fw={600}>
                Drag & Drop
              </Text>
              <Text fz="xs">or</Text>
              <Text fz="sm" fw={600}>
                Select File
              </Text>
            </Flex>
          </Flex>
        )}
      </form>
      {selectedFile ? (
        <Flex
          mt="sm"
          p="sm"
          justify="space-between"
          align="center"
          style={{
            backgroundColor: '#1475cf',
            borderRadius: '8px',
            color: '#ffffff',
          }}>
          <Flex gap={20}>
            <IconFile3d />

            <Text fz="sm" fw={600}>
              {fileName && fileName.length > 25
                ? `${fileName.slice(0, 40)}...`
                : fileName}
            </Text>
          </Flex>
          <IconTrashFilled
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSelectedFile(null); // Clear the selectedFile state
              setFileName(''); // Clear the file name
              // Clear the input field value by accessing it and setting it to an empty string
              document.querySelector('.input-field').value = '';
            }}
          />
        </Flex>
      ) : (
        <></>
      )}

      {selectedFile ? (
        <Flex py="sm" justify="flex-end">
          <Button
            disabled={isUploading}
            onClick={() => uploadFile(selectedFile)}
            size="md"
            w={'100%'}
            leftIcon={<IconFileUpload />}>
            Preview Data
          </Button>
        </Flex>
      ) : (
        <></>
      )}
    </main>
  );
};

export default Uploader;
