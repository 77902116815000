import { LoadingOverlay, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { IconBoxOff } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import NoDataPlaceholder from '../../../../components/Global/NoDataPlaceholder';
import COLORS from '../../../../constants/colors';
import { isArrayAndHasContent } from '../../../../utils/utils';
import ServerErrorBox from '../../../../components/Global/ServerErrorBox';

const StockOutlook = ({ reportData, isLoading, isFetching, error }) => {
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    if (reportData) {
      const houseTotals = {};

      let newArray = [];

      reportData.forEach((item, index) => {
        const {
          house,
          base_value,
          opening,
          closing,
          igt_in,
          igt_out,
          so,
          total_opened,
        } = item;

        if (!houseTotals[house]) {
          if (index > 0) {
            newArray.push({
              ...houseTotals[reportData[index - 1]?.house],
              region: `${reportData[index - 1]?.house} Total`,
              area: null,
              territory: null,
              totals: true,
            });
          }

          houseTotals[house] = {
            base_value: 0,
            opening: 0,
            closing: 0,
            igt_in: 0,
            igt_out: 0,
            so: 0,
            total_opened: 0,
          };
        }

        houseTotals[house].base_value += parseFloat(base_value) || 0;
        houseTotals[house].opening += parseInt(opening) || 0;
        houseTotals[house].closing += parseInt(closing) || 0;
        houseTotals[house].igt_in += parseInt(igt_in) || 0;
        houseTotals[house].igt_out += parseInt(igt_out) || 0;
        houseTotals[house].so += parseInt(so) || 0;
        houseTotals[house].total_opened += parseInt(total_opened) || 0;

        newArray.push({ ...item, totals: false });

        if (index === reportData.length - 1) {
          newArray.push({
            ...houseTotals[reportData[index]?.house],
            region: `${reportData[index]?.house} Total`,
            area: null,
            territory: null,
            totals: true,
          });
        }
      });

      setTotals(newArray);
    }
  }, [reportData]);

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  return (
    <div>
      <ScrollArea h="75vh">
        <LoadingOverlay visible={isLoading || isFetching} zIndex={1} />
        <Table highlightOnHover withColumnBorders={false} fontSize="xs">
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: COLORS.primary,
            }}>
            <tr>
              <th
                style={{
                  color: 'white',
                }}>
                Region
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Area
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                House
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Territory
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Distribution Point
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Base (CBC)
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Opening
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Opened CBC
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                IGT In
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                IGT Out
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Shipment
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Closing
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                SCR vs Base
              </th>
            </tr>
          </thead>
          <tbody>
            {totals?.map((data, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: data.totals ? '#bcd4e6' : 'white',
                }}>
                <td
                  colSpan={data.totals ? 5 : 1}
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.region}
                </td>
                {data.totals ? null : <td>{data.area}</td>}
                {data.totals ? null : <td>{data.house}</td>}
                {data.totals ? null : <td>{data.territory}</td>}
                {data.totals ? null : <td>{data.location_name}</td>}
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.base_value}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.opening}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  ({data.total_opened})
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.igt_in}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  ({data.igt_out})
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.so}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.closing}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.totals
                    ? data.base_value === 0
                      ? 0.0
                      : (
                          parseInt(data.closing) / parseInt(data.base_value)
                        ).toFixed(2)
                    : data.scr_vs_base}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!isArrayAndHasContent(totals) && (
          <Stack
            justify="center"
            align="center"
            sx={{
              height: '51vh',
            }}>
            <NoDataPlaceholder
              title="No report data found"
              subtext={''}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Stack>
        )}
      </ScrollArea>
    </div>
  );
};

export default StockOutlook;
