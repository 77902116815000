import dayjs from 'dayjs';
import axios from './axios';

export const fetchInventoryReports = async (context) => {
  const response = await axios().get('/reports/inventory', {
    params: {
      searchKey: context.queryKey[1],
      locations: context.queryKey[2],
      reportTypes: context.queryKey[3],
      product: context.queryKey[4],
    },
  });
  return response;
};

export const fetchSoIgtScanPerformanceReports = async ({
  orderType,
  startDate,
  endDate,
  selectedDistributor,
}) => {
  const response = await axios().get(
    '/reports/so-igt-scan-performance-report',
    {
      params: {
        orderType,
        startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
        endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
        distributors: selectedDistributor,
      },
    },
  );
  return response;
};

export const fetchDailyOpenCbcScanPerformanceReports = async ({
  startDate,
  endDate,
  selectedDistributor,
}) => {
  const response = await axios().get(
    '/reports/daily-open-cbc-scan-performance-report',
    {
      params: {
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate,
        distributors: selectedDistributor,
      },
    },
  );
  return response;
};

export const fetchGodownKeeperAppVersionList = async (context) => {
  const response = await axios().get('/reports/godown-keeper-app-version-list');
  return response;
};

export const fetchOneViewReport = async ({
  startDate,
  endDate,
  selectedDistributor,
}) => {
  const response = await axios().get('/reports/oneview', {
    params: {
      startDate: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
      endDate: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
      distributors: selectedDistributor,
    },
  });
  return response;
};

export const fetchOneViewLastDayReport = async ({ selectedDistributor }) => {
  const response = await axios().get('/reports/oneview-last-day', {
    params: {
      startDate: dayjs(dayjs().subtract(1, 'day')).format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      distributors: selectedDistributor,
    },
  });
  return response;
};

export const fetchOneViewStockOutlook = async ({ selectedDistributor }) => {
  const response = await axios().get('/reports/oneview-stock-outlook', {
    params: {
      startDate: dayjs().format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      distributors: selectedDistributor,
    },
  });
  return response;
};

export const createEmailReportSettings = async (body) => {
  const response = await axios().post(
    '/reports/create-report-email-settings',
    body,
  );
  return response;
};

export const fetchEmailReportSettings = async ({ searchKey, page }) => {
  const response = await axios().get('/reports/get-report-email-settings', {
    params: {
      page,
      searchKey,
    },
  });
  return response;
};

export const fetchOpenCbcReport = async ({
  page,
  startDate,
  endDate,
  searchKey,
  point,
  house,
  route,
  pageLess,
  pageSize,
}) => {
  const response = await axios().get('/reports/open-cbc', {
    params: {
      page,
      startDate: dayjs(startDate).format('YYYY-MM-DD'),
      endDate,
      searchKey,
      point,
      house,
      route,
      pageLess,
      pageSize,
    },
  });
  return response;
};

export const liveCheckIfReportGroupExists = async (reportName) => {
  const response = await axios().get(
    '/reports/live-check-if-report-group-exists',
    {
      params: {
        reportName,
      },
    },
  );
  return response;
};

export const reportGenerate = async (params) => {
  const response = await axios().get(
    `/reports/report-generate/${params.reportName}`,
    {
      params: params,
    },
  );
  return response;
};

export const updateEmailReportSettings = async (uid, body) => {
  const response = await axios().patch(
    `/reports/update-report-email-settings/${uid}`,
    body,
  );
  return response;
};

export const toggleEmailSettings = async (settingId) => {
  const response = await axios().patch(
    `/reports/toggle-report-email-settings/${settingId}`,
  );
  return response;
};
