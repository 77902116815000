import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  HoverCard,
  Loader,
  Modal,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  IconAd2,
  IconArrowLeft,
  IconArticle,
  IconEdit,
  IconLink,
  IconMapPinOff,
  IconPlus,
  IconRefresh,
  IconRoad,
  IconX,
} from '@tabler/icons-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ExportCsvXls from '../../components/Global/ExportCsvXlsx';
import LogsDrawer from '../../components/Global/LogsDrawer';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import LocationForm from '../../components/Location/LocationForm';
import { LOCATIONS_MANAGE } from '../../constants/app-urls';
import COLORS from '../../constants/colors';
import { actionLoggerReferenceTypes } from '../../constants/const';
import { fetchDistributor } from '../../services/distributor';
import { fetchLocations } from '../../services/location';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../utils/utils';
import { fetchRegion } from '../../services/region';
import { fetchArea } from '../../services/area';
import { fetchTerritory } from '../../services/territories';
import AssignRouteSectionModal from '../../components/Location/AssignRouteSectionModal';
import * as urls from '../../constants/app-urls';

const PAGE_TITLE = 'Points';

const LocationManagement = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [activeLocations, setActiveLocations] = useState(null);

  const [searchByRegion, setSearchByRegion] = useState(null);
  const [regionSearchKey, setRegionSearchKey] = useState(null);
  const [searchByArea, setSearchByArea] = useState(null);
  const [areaSearchKey, setAreaSearchKey] = useState(null);
  const [searchByDistributor, setSearchByDistributor] = useState(null);
  const [distributorSearchKey, setDistributorSearchKey] = useState(null);
  const [searchByTerritory, setSearchByTerritory] = useState(null);
  const [territorySearchKey, setTerritorySearchKey] = useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [addLocationsDrawer, setAddLocationsDrawer] = useState(false);

  const [logsDrawer, setLogsDrawer] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [routeSectionModal, setRouteSectionModal] = useState(false);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  useEffect(() => {
    document.title = 'Points | Armada';
  }, []);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'location',
      page,
      searchKey,
      activeLocations,
      searchByDistributor,
      null,
      true,
      searchByRegion,
      searchByArea,
      searchByTerritory,
    ],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    isFetching: locationPagelessFetching,
    refetch: locationPagelessRefetch,
  } = useQuery({
    queryKey: [
      'location-pageless',
      page,
      searchKey,
      activeLocations,
      searchByDistributor,
      true,
      true,
      searchByRegion,
      searchByArea,
      searchByTerritory,
    ],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    enabled: exportToggler,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `LOCATIONS-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = response.data.data.locations.map((location) => {
        return {
          Name: location?.name,
          Division: location?.division || 'N/A',
          District: location?.district || 'N/A',
          Address: location?.address,
          Region: location?.regionInfo?.name,
          Area: location?.areaInfo?.name,
          Distributor: location?.distributorInfo?.name,
          Territory: location?.territoryInfo?.name,
          ReferenceLabel: location?.referenceLabel,
          Status: location?.isActive ? 'Active' : 'Inactive',
          CreatedAt: dayjs(location?.createdAt).format('MMM DD, YYYY'),
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const [
    { data: regionPagelessdata },
    { data: areaPagelessData },
    { data: distributorPagelessdata },
    { data: territoryPagelessData },
  ] = useQueries({
    queries: [
      {
        queryKey: ['region-pagelessData', null, null, true],
        queryFn: fetchRegion,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['area-pagelessData', null, null, null, true],
        queryFn: fetchArea,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['distributor-pagelessData', null, null, null, null, true],
        queryFn: fetchDistributor,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: [
          'terriytory-pagelessData',
          null,
          null,
          null,
          null,
          null,
          true,
        ],
        queryFn: fetchTerritory,
        refetchOnWindowFocus: false,
        retry: false,
      },
    ],
  });

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      locationPagelessRefetch();
    } else {
      locationPagelessRefetch();
    }
  };

  const { locations, total } = data.data.data;
  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={addLocationsDrawer}
        onClose={() => {
          setSelectedLocation(null);
          setAddLocationsDrawer(false);
        }}
        title={
          isObjectAndHasProperties(selectedLocation)
            ? 'Edit Location'
            : 'Add Location'
        }
        position="right">
        <LocationForm
          update={isObjectAndHasProperties(selectedLocation)}
          defaultValues={selectedLocation}
          onUpdate={() => {
            refetch();
            setAddLocationsDrawer(false);
          }}
        />
      </Drawer>
      <Drawer
        opened={logsDrawer}
        onClose={() => {
          setLogsDrawer(false);
        }}
        title="User Logs"
        position="right">
        <LogsDrawer
          referenceType={actionLoggerReferenceTypes.LOCATION_MANAGEMENT}
          singleOrderFetch={false}
        />
      </Drawer>
      <Modal
        opened={routeSectionModal}
        onClose={() => setRouteSectionModal(false)}
        title="Assign Route/Section"
        size="xl"
        centered={true}>
        <AssignRouteSectionModal
          location={selectedLocation}
          onUpdate={() => {
            refetch();
            setRouteSectionModal(false);
          }}
        />
      </Modal>

      {/* CUSTOM MODAL AND DRAWER END */}
      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Flex gap={10}>
          <Link to={LOCATIONS_MANAGE}>
            <Button
              // onClick={() => navigate(DISTRIBUTOR)}
              color="cyan"
              leftIcon={<IconAd2 />}>
              Manage
            </Button>
          </Link>
          <Button
            onClick={() => setLogsDrawer(true)}
            color="violet"
            leftIcon={<IconArticle />}>
            Logs
          </Button>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex justify="space-between" align="center" py="sm" gap={8}>
          <Flex gap={8}>
            <div
              style={{
                maxWidth: '250px',
              }}>
              <SearchInput
                handleRefresh={handleRefresh}
                handleSearch={handleSearch}
                placeholder="Search Points"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>
            <Select
              value={activeLocations}
              onChange={(value) => {
                setActiveLocations(value);
              }}
              placeholder="Filter by status"
              data={[
                {
                  value: true,
                  label: 'Active',
                },
                {
                  value: false,
                  label: 'Inactive',
                },
              ]}
              sx={{
                maxWidth: '180px',
              }}
            />
            <Autocomplete
              placeholder="Search by region"
              sx={{
                maxWidth: '200px',
              }}
              onChange={(value) => {
                if (regionSearchKey && regionSearchKey.length === 0) {
                  setRegionSearchKey(null);
                } else {
                  setRegionSearchKey(value);
                }
              }}
              limit={regionPagelessdata?.data?.data?.regions?.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={regionSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByRegion(e.id);
              }}
              rightSection={
                searchByRegion ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setRegionSearchKey(null);
                      setSearchByRegion(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                regionPagelessdata?.data?.data?.regions?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
            />
            <Autocomplete
              placeholder="Search by area"
              sx={{
                maxWidth: '200px',
              }}
              onChange={(value) => {
                if (areaSearchKey && areaSearchKey.length === 0) {
                  setAreaSearchKey(null);
                } else {
                  setAreaSearchKey(value);
                }
              }}
              limit={areaPagelessData?.data?.data?.areas?.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={areaSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByArea(e.id);
              }}
              rightSection={
                searchByArea ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setAreaSearchKey(null);
                      setSearchByArea(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                areaPagelessData?.data?.data?.areas?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
            />
            <Autocomplete
              placeholder="Search by distributor"
              sx={{
                maxWidth: '200px',
              }}
              onChange={(value) => {
                if (distributorSearchKey && distributorSearchKey.length === 0) {
                  setDistributorSearchKey(null);
                } else {
                  setDistributorSearchKey(value);
                }
              }}
              limit={
                distributorPagelessdata?.data?.data?.distributors.length || 0
              }
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={distributorSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByDistributor(e.id);
              }}
              rightSection={
                searchByDistributor ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setDistributorSearchKey(null);
                      setSearchByDistributor(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                distributorPagelessdata?.data?.data?.distributors.map(
                  (location) => ({
                    id: location.id,
                    value: location.name,
                  }),
                ) || []
              }
            />
            <Autocomplete
              placeholder="Search by territory"
              sx={{
                maxWidth: '200px',
              }}
              onChange={(value) => {
                if (territorySearchKey && territorySearchKey.length === 0) {
                  setTerritorySearchKey(null);
                } else {
                  setTerritorySearchKey(value);
                }
              }}
              limit={territoryPagelessData?.data?.data?.territories.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={territorySearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByTerritory(e.id);
              }}
              rightSection={
                searchByTerritory ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setTerritorySearchKey(null);
                      setSearchByTerritory(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                territoryPagelessData?.data?.data?.territories.map(
                  (location) => ({
                    id: location.id,
                    value: location.name,
                  }),
                ) || []
              }
            />
            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    setActiveLocations(null);
                    setDistributorSearchKey(null);
                    setSearchByDistributor(null);
                    setSearchByArea(null);
                    setAreaSearchKey(null);
                    setSearchByRegion(null);
                    setRegionSearchKey(null);
                    setTerritorySearchKey(null);
                    setSearchByTerritory(null);
                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <div>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                setSelectedLocation(null);
                setAddLocationsDrawer(true);
              }}
              leftIcon={<IconPlus />}>
              Add Point
            </Button>
          </div>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(locations) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover withColumnBorders={true}>
                  {' '}
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Name</th>
                      <th>Region</th>
                      <th>Area</th>
                      <th>Distributor</th>
                      <th>Territory</th>
                      <th>Address</th>
                      <th>Reference Label</th>
                      <th>Status</th>
                      <th>Base Value</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((location, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            maxWidth: '120px',
                          }}>
                          {location?.name}
                        </td>
                        <td>
                          {location?.regionInfo?.name || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>
                        <td>
                          {location?.areaInfo?.name || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>
                        <td>
                          {location?.distributorInfo?.name || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>
                        <td>
                          {location?.territoryInfo?.name || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>
                        <td
                          style={{
                            maxWidth: '120px',
                          }}>
                          <HoverCard width={280} shadow="md">
                            <HoverCard.Target>
                              <Text
                                sx={{
                                  whiteSpace: 'pre-line',
                                }}>
                                {location?.address}
                              </Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Flex justify="space-between">
                                <Text size="md">{location.name}</Text>
                                <ActionIcon
                                  color="red"
                                  variant="filled"
                                  onClick={() => {
                                    window.open(
                                      `https://www.google.com/maps/search/?api=1&query=${location?.longitude},${location?.latitude}`,
                                      '_blank',
                                    );
                                  }}>
                                  <IconLink />
                                </ActionIcon>
                              </Flex>
                              <Text size="sm">
                                {location.district || 'N/A'},{' '}
                                {location.division || 'N/A'}
                              </Text>
                              <Text size="sm">{location.address}</Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td>
                        <td>
                          {location?.referenceLabel || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>

                        <td>
                          <Badge color={location?.isActive ? 'green' : 'red'}>
                            {location?.isActive ? 'Active' : 'Inactive'}
                          </Badge>
                        </td>
                        <td>{location?.baseValue}</td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Edit location'}>
                              <ActionIcon
                                variant="light"
                                color="teal"
                                onClick={() => {
                                  setSelectedLocation(location);
                                  setAddLocationsDrawer(true);
                                }}>
                                <IconEdit size={16} />
                              </ActionIcon>
                            </Tooltip>
                            <Tooltip label={'Edit Route/Section'}>
                              <ActionIcon
                                variant="light"
                                color="orange"
                                onClick={() => {
                                  // setSelectedLocation(location);
                                  // setRouteSectionModal(true);
                                  navigate(
                                    `${urls.LOCATIONS_ROUTE_SECTION}/${location.uid}`,
                                  );
                                }}>
                                <IconRoad size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No location found"
                  subtext={' '}
                  icon={<IconMapPinOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={locationPagelessFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default LocationManagement;
