import axios from './axios';

export const fetchAllBuilds = async (context) => {
  const response = await axios().get('/distribution', {
    params: {
      variant: context.queryKey[1],
      version: context.queryKey[2],
    },
  });
  return response;
};

export const versionCheck = async (context) => {
  const response = await axios().get('/distribution/check', {
    params: {
      version: context.queryKey[1],
      variant: process.env.REACT_APP_ENVIRONMENT,
    },
  });
  return response;
};

export const getLatestDistribution = async (context) => {
  const response = await axios().get('/distribution/latest', {
    params: {
      variant: process.env.REACT_APP_ENVIRONMENT,
    },
  });
  return response;
};

export const uploadApk = async (values) => {
  const response = await axios().post('/distribution', values, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

export const markAsLatest = async (uid) => {
  const response = await axios().patch(`/distribution/latest/${uid}`);
  return response;
};
