import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Dialog,
  Drawer,
  Flex,
  Grid,
  HoverCard,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Space,
  Stack,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconAlertTriangle,
  IconBoxOff,
  IconCalendar,
  IconListDetails,
  IconPassword,
  IconPlus,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DateSelectModal from '../../components/Charts/DateSelectModal';
import { convertTimeToFormattedString } from '../../components/Charts/helper';
import ExportCsvXls from '../../components/Global/ExportCsvXlsx';
import FilterBadgeClearable from '../../components/Global/FilterBadgeClearable';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import GenerateOtp from '../../components/Orders/GenerateOtp';
import IGTOrderForm from '../../components/Orders/IGTOrderForm';
import COLORS from '../../constants/colors';
import {
  orderPartialReceiveStatuses,
  orderStatusBadgeColors,
  orderStatusConst,
  userDesignations,
  userRoles,
  userWeight,
} from '../../constants/const';
import { fetchLocations } from '../../services/location';
import { fetchOrders } from '../../services/orders';
import { OrderFilterActions } from '../../store/reducers/orderFiltersReducer';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
  userWiseAutocompleteLocationDataWithId,
} from '../../utils/utils';
import { fetchTerritory } from '../../services/territories';
import { fetchDistributor } from '../../services/distributor';
import { fetchArea } from '../../services/area';
import { fetchRegion } from '../../services/region';

const PAGE_TITLE = 'Outbound Orders';

const PAGE_LIMIT = 15;

const OutboundOrders = () => {
  const [opened, { toggle, close }] = useDisclosure(false);

  const navigate = useNavigate();

  const location = useLocation();

  const dispatch = useDispatch();

  const appUser = useSelector((state) => state.auth.user);

  const orderFilters = useSelector((state) => state.orderFilters.filters);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);
  const [searchByorderType, setSearchByorderType] = useState(null);
  const [missingCbcToggler, setMissingCbcToggler] = useState(false);
  const [twelveLocationsSource, setTwelveLocationsSource] = useState(false);
  const [twelveLocationsDestination, setTwelveLocationsDestination] =
    useState(false);
  const [flaggedOrders, setFlaggedOrders] = useState(false);

  const [sourceSearchKey, setSourceSearchKey] = useState(null);
  const [destinationSearchKey, setDestinationSearchKey] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [openedDateModal, { open: openDateModal, close: closeDateModal }] =
    useDisclosure(false);

  useEffect(() => {
    document.title = `${
      location.pathname.split('/').includes('outbound-orders')
        ? 'Outbound Orders'
        : 'Orders'
    } | Armada`;
  }, []);

  useEffect(() => {
    if (orderFilters && orderFilters.filterSource === 'outbound-orders') {
      setPage(orderFilters.page);
      setSearchKey(orderFilters.searchKey);
      setOrderStatus(orderFilters.orderStatus);
      if (
        userRoles[appUser?.role] === userRoles.ADMIN ||
        userRoles[appUser?.role] === userRoles['SUPER ADMIN']
      ) {
        setSource(orderFilters.source);
      }
      setDestination(orderFilters.destination);
      setSearchByorderType(orderFilters.searchByorderType);
      setSourceSearchKey(orderFilters.sourceSearchKey);
      setMissingCbcToggler(orderFilters.missingCbcToggler);
      setDestinationSearchKey(orderFilters.destinationSearchKey);
      setStartDate(orderFilters.startDate);
      setEndDate(orderFilters.endDate);
      setFlaggedOrders(orderFilters.flaggedOrders);
    }
  }, []);

  useEffect(() => {
    const filters = {
      page,
      searchKey,
      orderStatus,
      source,
      destination,
      searchByorderType,
      missingCbcToggler,
      sourceSearchKey,
      destinationSearchKey,
      startDate,
      endDate,
      flaggedOrders,
      filterSource: 'outbound-orders',
    };

    dispatch(OrderFilterActions.setFilters({ filters }));
  }, [
    page,
    searchKey,
    orderStatus,
    source,
    destination,
    searchByorderType,
    missingCbcToggler,
    sourceSearchKey,
    destinationSearchKey,
    startDate,
    endDate,
    flaggedOrders,
  ]);

  // useEffect(() => {
  //   setPage(1);
  // }, [
  //   searchKey,
  //   orderStatus,
  //   source,
  //   destination,
  //   searchByorderType,
  //   missingCbcToggler,
  //   sourceSearchKey,
  //   destinationSearchKey,
  //   startDate,
  //   endDate,
  // ]);

  useEffect(() => {
    if (
      userRoles[appUser?.role] === userRoles['GODOWN MANAGER'] ||
      userRoles[appUser?.role] === userRoles.MANAGER ||
      userRoles[appUser?.role] === userRoles.OPERATOR
    ) {
      let toLocation = [];
      appUser.locations.map((location) => {
        toLocation.push(parseInt(location.id));
      });
      setSource(toLocation);
    }
  }, []);

  const [createIgtOrdersDrawer, setCreateIgtOrdersDrawer] = useState(false);
  const [generateOtpDrawer, setGenerateOtpDrawer] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const [latestOrderCreatedOrderNumber, setlatestOrderCreatedOrderNumber] =
    useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const changeStartDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setStartDate(time);
    } else {
      setStartDate(null);
    }
  };

  const changeEndDate = (value) => {
    if (value) {
      let time = convertTimeToFormattedString(value);
      setEndDate(time);
    } else {
      setEndDate(null);
    }
  };

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'outbound-orders',
      page,
      searchKey,
      orderStatus,
      searchByorderType,
      source,
      destination,
      null,
      startDate,
      endDate,
      missingCbcToggler,
      PAGE_LIMIT,
      flaggedOrders,
    ],
    queryFn: fetchOrders,
    enabled:
      isArrayAndHasContent(source) ||
      userRoles[appUser?.role] === userRoles.ADMIN ||
      userRoles[appUser?.role] === userRoles['SUPER ADMIN'],
    refetchOnWindowFocus: false,
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: ordersPagelessData,
    isLoading: ordersPagelessLoading,
    isFetching: ordersPagelessFetching,
    refetch: ordersPagelessRefetch,
    error: ordersPagelessError,
  } = useQuery({
    queryKey: [
      'outbound-orders-pageless',
      null,
      searchKey,
      orderStatus,
      null,
      source,
      destination,
      true,
      startDate,
      endDate,
      missingCbcToggler,
    ],
    queryFn: fetchOrders,
    enabled: exportToggler,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const { orders } = response.data.data;

      const fileName = `${
        location.pathname.split('/').includes('outbound-orders')
          ? 'ORDERS'
          : 'OUTBOUND_ORDERS'
      }-${appUser.name}-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = orders.map((order) => {
        return {
          'Order Date': dayjs(order.created_at).format('DD-MM-YYYY'),
          'Order Type': order.orderType,
          'Order Number': order.orderNumber,
          'Total Quantiy (TH)':
            order?.inventory?.reduce(
              (acc, obj) =>
                acc + obj.quantity * obj?.product?.packages?.size || 10500,
              0,
            ) / 1000,
          Status: order?.orderStatus,
          Source: order?.sourceInformation?.name,
          Destination: order?.destinationInformation?.name,
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const [
    { data: regionPagelessdata },
    { data: areaPagelessData },
    { data: distributorPagelessdata },
    { data: territoryPagelessData },
  ] = useQueries({
    queries: [
      {
        queryKey: ['region-pagelessData', null, null, true],
        queryFn: fetchRegion,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['area-pagelessData', null, null, null, true],
        queryFn: fetchArea,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['distributor-pagelessData', null, null, null, null, true],
        queryFn: fetchDistributor,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: [
          'terriytory-pagelessData',
          null,
          null,
          null,
          null,
          null,
          true,
        ],
        queryFn: fetchTerritory,
        refetchOnWindowFocus: false,
        retry: false,
      },
    ],
  });

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: ['location', 1, null, null, null, true, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  if (isLoading)
    return (
      <div>
        <Title order={1} size="h2">
          {location.pathname.split('/').includes('outbound-orders')
            ? PAGE_TITLE
            : 'Orders'}
        </Title>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setOrderStatus(null);
    setSearchByorderType(null);
    setStartDate(null);
    setEndDate(null);
    setMissingCbcToggler(false);
    setTwelveLocationsDestination(false);
    setTwelveLocationsSource(false);
    if (appUser.role === 'ADMIN' || appUser.role === 'SUPER ADMIN') {
      setSource(null);
      setSourceSearchKey(null);
      setDestination(null);
      setDestinationSearchKey(null);
    }
    if (location.pathname.split('/').includes('outbound-orders')) {
      setDestination(null);
      setDestinationSearchKey(null);
    }
    if (userRoles[appUser?.role] === userRoles.MANAGER) {
      setSourceSearchKey(null);
      let toLocation = [];
      appUser.locations.map((location) => {
        toLocation.push(parseInt(location.id));
      });
      setSource(toLocation);
    }
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      ordersPagelessRefetch();
    } else {
      ordersPagelessRefetch();
    }
  };

  const { orders, total } = data.data.data;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <DateSelectModal
        opened={openedDateModal}
        close={closeDateModal}
        open={openDateModal}
        startDate={startDate}
        endDate={endDate}
        changeStartDate={changeStartDate}
        changeEndDate={changeEndDate}
        exportFunc={closeDateModal}
        exportBtnTitle="Filter"
      />
      <Drawer
        opened={createIgtOrdersDrawer}
        onClose={() => {
          setSelectedOrder(null);
          setCreateIgtOrdersDrawer(false);
        }}
        title={
          isObjectAndHasProperties(selectedOrder)
            ? 'Edit IGT Order'
            : 'Create IGT Order'
        }
        styles={{
          title: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
          },
        }}
        position="right"
        size="75%">
        <IGTOrderForm
          update={isObjectAndHasProperties(selectedOrder)}
          defaultValues={selectedOrder}
          onUpdate={(orderNumber) => {
            setlatestOrderCreatedOrderNumber(orderNumber);
            refetch();
            setCreateIgtOrdersDrawer(false);
            toggle();
          }}
          regionPagelessdata={regionPagelessdata?.data?.data?.regions}
          areaPagelessData={areaPagelessData?.data?.data?.areas}
          distributorPagelessdata={
            distributorPagelessdata?.data?.data?.distributors
          }
          territoryPagelessData={territoryPagelessData?.data?.data?.territories}
        />
      </Drawer>

      <Drawer
        closeOnClickOutside={false}
        opened={generateOtpDrawer}
        title="Generate OTP"
        onClose={() => {
          setGenerateOtpDrawer(false);
        }}
        styles={{
          title: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
          },
        }}
        position="right"
        size="30%">
        <GenerateOtp />
      </Drawer>

      <Dialog
        opened={opened}
        withCloseButton
        onClose={close}
        size="lg"
        radius="md"
        sx={{
          backgroundColor: COLORS.primary,
        }}>
        <Text color={COLORS.white}>
          {latestOrderCreatedOrderNumber} has been created successfully.
        </Text>
        <Space h="md" />
        <Button
          compact
          color="gray"
          onClick={() => {
            navigate(`order-details/${latestOrderCreatedOrderNumber}`);
            close();
          }}>
          View Details
        </Button>
      </Dialog>
      {/* <Drawer
        opened={logsDrawer}
        onClose={() => {
          setLogsDrawer(false);
        }}
        title="User Logs"
        position="right">
        <LogsDrawer
          referenceType={actionLoggerReferenceTypes.ORDER_MANAGEMENT}
          singleOrderFetch={false}
        />
      </Drawer> */}
      {/* CUSTOM MODAL AND DRAWER END */}

      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          {location.pathname.split('/').includes('outbound-orders')
            ? PAGE_TITLE
            : 'Orders'}
        </Title>
        <Flex gap={10}>
          <Button
            sx={{
              backgroundColor: COLORS.primary,
            }}
            onClick={() => setCreateIgtOrdersDrawer(true)}
            disabled={
              !userDesignations[appUser.destination] ===
                userDesignations['TERRITORY OFFICER'] ||
              !userDesignations[appUser.destination] ===
                userDesignations['BUSINESS MANAGER'] ||
              !userRoles[appUser.role] === userRoles['GODOWN MANAGER']
            }
            leftIcon={<IconPlus />}>
            Create IGT Order
          </Button>
          {/* {userRoles[appUser?.role] === userRoles['GODOWN MANAGER'] ? null : (
            <Button
              color="pink"
              onClick={() => setGenerateOtpDrawer(true)}
              leftIcon={<IconPassword />}>
              Generate OTP
            </Button>
          )} */}
          {/* {appUser.userWeight > userWeight['GODOWN MANAGER'] ? (
            <Button
              onClick={() => setLogsDrawer(true)}
              color="violet"
              leftIcon={<IconArticle />}>
              Logs
            </Button>
          ) : null} */}
        </Flex>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Grid gap={20}>
          <Grid.Col md={9} lg={9} sm={12} xs={12} py="md">
            <Flex gap={5} align="center">
              <div
                style={{
                  width: '200px',
                }}>
                <SearchInput
                  handleRefresh={() => {
                    setInvokingRefreshForSearchInput(
                      !invokingRefreshForSearchInput,
                    );
                    setSearchKey('');
                  }}
                  handleSearch={handleSearch}
                  placeholder="Search orders"
                  invokeRefresh={invokingRefreshForSearchInput}
                  refreshBtn={false}
                />
              </div>
              <Select
                sx={{
                  maxWidth: '200px',
                }}
                value={orderStatus}
                onChange={(value) => {
                  setPage(1);
                  setOrderStatus(value);
                }}
                placeholder="Filter by status"
                data={Object.keys(orderStatusConst).map((key) => ({
                  label: orderStatusConst[key],
                  value: key,
                }))}
              />
              {userRoles[appUser?.role] === userRoles.ADMIN ||
              userRoles[appUser?.role] === userRoles['SUPER ADMIN'] ? (
                <Select
                  sx={{
                    maxWidth: '200px',
                  }}
                  value={searchByorderType}
                  onChange={(value) => {
                    setPage(1);
                    setSearchByorderType(value);
                  }}
                  placeholder="Filter by order type"
                  data={[
                    { label: 'IGT', value: 'IGT' },
                    { label: 'SO', value: 'SO' },
                    { label: 'Mobile IGT', value: 'Mobile IGT' },
                  ]}
                />
              ) : null}
              {(userRoles[appUser?.role] === userRoles.ADMIN ||
                userRoles[appUser?.role] === userRoles['SUPER ADMIN'] ||
                userRoles[appUser?.role] === userRoles.MANAGER ||
                userRoles[appUser?.role] === userRoles['GODOWN MANAGER']) && (
                <Autocomplete
                  placeholder="Search by source"
                  onChange={(value) => {
                    if (sourceSearchKey && sourceSearchKey.length === 0) {
                      setSourceSearchKey(null);
                    } else {
                      setSourceSearchKey(value);
                    }
                  }}
                  limit={locationData?.data?.data?.locations.length || 0}
                  maxDropdownHeight="200px"
                  dropdownPosition="bottom"
                  nothingFound="No options"
                  value={sourceSearchKey || ''}
                  onItemSubmit={(e) => {
                    setPage(1);
                    setSource([e.id]);
                  }}
                  rightSection={
                    source?.length === 1 ? (
                      <UnstyledButton
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() => {
                          if (userRoles[appUser?.role] === userRoles.MANAGER) {
                            setSourceSearchKey(null);
                            let toLocation = [];
                            appUser.locations.map((location) => {
                              toLocation.push(parseInt(location.id));
                            });
                            setSource(toLocation);
                          } else {
                            setSourceSearchKey(null);
                            setSource(null);
                          }
                        }}>
                        <IconX size={15} />
                      </UnstyledButton>
                    ) : null
                  }
                  data={
                    userWiseAutocompleteLocationDataWithId({
                      appUser: appUser,
                      locationData: locationData?.data?.data?.locations || [],
                    }) || []
                  }
                  sx={{
                    maxWidth: '250px',
                  }}
                />
              )}
              <Autocomplete
                placeholder="Search by destination"
                onChange={(value) => {
                  if (
                    destinationSearchKey &&
                    destinationSearchKey.length === 0
                  ) {
                    setDestinationSearchKey(null);
                  } else {
                    setDestinationSearchKey(value);
                  }
                }}
                limit={locationData?.data?.data?.locations.length || 0}
                maxDropdownHeight="200px"
                dropdownPosition="bottom"
                nothingFound="No options"
                value={destinationSearchKey || ''}
                onItemSubmit={(e) => {
                  setPage(1);
                  setDestination([e.id]);
                }}
                rightSection={
                  destination ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setDestinationSearchKey(null);
                        setDestination(null);
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                data={
                  locationData?.data?.data.locations?.map((location) => ({
                    id: location.id,
                    value: location.name,
                  })) || []
                }
                sx={{
                  maxWidth: '250px',
                }}
              />
              <Tooltip label="Filter by Date">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    openDateModal();
                  }}
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  variant="filled">
                  <IconCalendar size={18} />
                </ActionIcon>
              </Tooltip>
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Grid.Col>
          <Grid.Col md={3} lg={3} sm={12} xs={12} py="md">
            <Flex gap={20} justify="flex-end">
              {appUser.userWeight >= userWeight.MANAGER && (
                <Stack spacing={0} align="center">
                  <Switch
                    size="lg"
                    onLabel="On"
                    offLabel="Off"
                    checked={flaggedOrders}
                    onChange={(event) => {
                      setFlaggedOrders(event.currentTarget.checked);
                    }}
                  />
                  <Text size="xs">Flagged</Text>
                </Stack>
              )}
              {(appUser.role === userRoles['SUPER ADMIN'] ||
                appUser.role === userRoles.ADMIN) && (
                <Stack spacing={0} align="center">
                  <Switch
                    size="lg"
                    onLabel="12 on"
                    offLabel="12 Off"
                    checked={twelveLocationsSource}
                    onChange={(event) => {
                      setTwelveLocationsSource(event.currentTarget.checked);
                      if (event.currentTarget.checked === true) {
                        setSource([
                          92, 99, 101, 98, 96, 95, 97, 64, 100, 94, 93, 55,
                        ]);
                      } else {
                        setSource(null);
                      }
                    }}
                  />
                  <Text size="xs">Source</Text>
                </Stack>
              )}
              {(appUser.role === userRoles['SUPER ADMIN'] ||
                appUser.role === userRoles.ADMIN) && (
                <Stack spacing={0} align="center">
                  <Switch
                    size="lg"
                    onLabel="12 on"
                    offLabel="12 Off"
                    checked={twelveLocationsDestination}
                    onChange={(event) => {
                      setTwelveLocationsDestination(
                        event.currentTarget.checked,
                      );
                      if (event.currentTarget.checked === true) {
                        setDestination([
                          92, 99, 101, 98, 96, 95, 97, 64, 100, 94, 93, 55,
                        ]);
                      } else {
                        setDestination(null);
                      }
                    }}
                  />
                  <Text size="xs">Destination</Text>
                </Stack>
              )}
              <Switch
                size="lg"
                onLabel="Missing CBC On"
                offLabel="Missing CBC Off"
                checked={missingCbcToggler}
                onChange={(event) =>
                  setMissingCbcToggler(event.currentTarget.checked)
                }
              />
            </Flex>
          </Grid.Col>
        </Grid>
        <Flex my={5} gap={5} align="center">
          <Text fz="xs">Showing {total || 0} orders</Text>
          {searchKey ||
          orderStatus ||
          searchByorderType ||
          source ||
          destination ||
          startDate ||
          endDate ||
          missingCbcToggler ? (
            <Text fz="xs">with filters</Text>
          ) : null}
          {searchKey && (
            <FilterBadgeClearable
              text={`search: ${searchKey}`}
              func={() => {
                setInvokingRefreshForSearchInput(
                  !invokingRefreshForSearchInput,
                );
                setSearchKey('');
              }}
              color="blue"
            />
          )}
          {orderStatus && (
            <FilterBadgeClearable
              text={`status: ${orderStatus}`}
              func={() => {
                setOrderStatus(null);
              }}
              color="orange"
            />
          )}
          {searchByorderType && (
            <FilterBadgeClearable
              text={`order type: ${searchByorderType}`}
              func={() => {
                setSearchByorderType(null);
              }}
              color="grape"
            />
          )}
          {(userRoles[appUser?.role] === userRoles.ADMIN ||
            userRoles[appUser?.role] === userRoles['SUPER ADMIN'] ||
            userRoles[appUser?.role] === userRoles.MANAGER ||
            userRoles[appUser?.role] === userRoles['GODOWN MANAGER']) &&
            sourceSearchKey && (
              <FilterBadgeClearable
                text={`source: ${sourceSearchKey}`}
                func={() => {
                  if (userRoles[appUser?.role] === userRoles.MANAGER) {
                    setSourceSearchKey(null);
                    let toLocation = [];
                    appUser.locations.map((location) => {
                      toLocation.push(parseInt(location.id));
                    });
                    setSource(toLocation);
                  } else {
                    setSourceSearchKey(null);
                    setSource(null);
                  }
                }}
                color="pink"
              />
            )}
          {destination?.length === 1 && (
            <FilterBadgeClearable
              text={`destination: ${destinationSearchKey}`}
              func={() => {
                setDestinationSearchKey(null);
                setDestination(null);
              }}
              color="yellow"
            />
          )}
          {startDate && (
            <FilterBadgeClearable
              text={`start date: ${dayjs(startDate).format('DD-MM-YYYY')}`}
              func={() => {
                setStartDate(null);
              }}
              color="green"
            />
          )}
          {endDate && (
            <FilterBadgeClearable
              text={`end date: ${dayjs(endDate).format('DD-MM-YYYY')}`}
              func={() => {
                setEndDate(null);
              }}
              color="cyan"
            />
          )}
          {missingCbcToggler && (
            <FilterBadgeClearable
              text={`missing CBC: ${missingCbcToggler ? 'On' : 'Off'}`}
              func={() => {
                setMissingCbcToggler(false);
              }}
              color="lime"
            />
          )}
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(orders) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Order Date</th>
                      <th>Created At</th>
                      <th>Order Type</th>
                      <th>Order #</th>
                      <th
                        style={{
                          maxWidth: '150px',
                        }}>
                        Total Quantity - TH (sticks)
                      </th>
                      <th>Status</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      verticalAlign: 'baseline',
                    }}>
                    {orders.map((order, index) => (
                      <tr key={index}>
                        <td>
                          {dayjs(order?.orderDate).format('MMM DD, YYYY')}
                        </td>
                        <td>
                          {dayjs(order?.createdAt).format(
                            'MMM DD, YYYY HH:mmA',
                          )}
                        </td>
                        <td>
                          {order?.orderType}{' '}
                          {order?.isMobileIgt ? ' (Mobile)' : null}
                        </td>
                        <td>
                          <Flex gap={2} align="center">
                            {order?.partialReceiveStatus ===
                              orderPartialReceiveStatuses.PARTIALLY_RECEIVED && (
                              <Tooltip label="Flagged" withArrow>
                                <IconAlertTriangle size="1rem" color="red" />
                              </Tooltip>
                            )}
                            <Text>{order?.orderNumber}</Text>
                          </Flex>
                          {order?.orderType !== 'SO' && (
                            <Badge color={order?.isApproved ? 'green' : 'red'}>
                              {order?.isApproved ? 'Approved' : 'Not Approved'}
                            </Badge>
                          )}
                        </td>
                        <td>
                          {order?.inventory?.reduce(
                            (acc, obj) =>
                              acc +
                              (obj.quantity * obj?.product?.packages?.size ||
                                10000),
                            0,
                          ) / 1000}
                        </td>
                        <td>
                          <Stack spacing={2} align="start">
                            <Badge
                              color={orderStatusBadgeColors[order?.orderStatus]}
                              variant="filled"
                              radius="sm">
                              {order?.orderStatus}
                            </Badge>
                            {order?.isPartialUnloaded && (
                              <Badge color="red" variant="filled" radius="sm">
                                CBC missing
                              </Badge>
                            )}
                          </Stack>
                        </td>
                        <td>
                          <HoverCard key={index} width={280} shadow="md">
                            <HoverCard.Target>
                              <Stack
                                spacing="xs"
                                sx={{
                                  maxWidth: '150px',
                                }}>
                                <Badge
                                  color="dark"
                                  variant="filled"
                                  radius="sm">
                                  {order?.sourceInformation?.name}
                                </Badge>
                              </Stack>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Text size="md">
                                {' '}
                                {order?.sourceInformation?.name}
                              </Text>
                              <Text size="sm">
                                {order?.sourceInformation?.district || 'N/A'},{' '}
                                {order?.sourceInformation?.division || 'N/A'}
                              </Text>
                              <Text size="sm">
                                {order?.sourceInformation?.address}
                              </Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td>
                        <td>
                          <HoverCard key={index} width={280} shadow="md">
                            <HoverCard.Target>
                              <Stack
                                spacing="xs"
                                sx={{
                                  maxWidth: '150px',
                                }}>
                                <Badge
                                  color="dark"
                                  variant="outline"
                                  radius="sm">
                                  {order?.destinationInformation?.name}
                                </Badge>
                              </Stack>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>
                              <Text size="md">
                                {' '}
                                {order?.destinationInformation?.name}
                              </Text>
                              <Text size="sm">
                                {order?.destinationInformation?.district ||
                                  'N/A'}
                                ,{' '}
                                {order?.destinationInformation?.division ||
                                  'N/A'}
                              </Text>
                              <Text size="sm">
                                {order?.destinationInformation?.address}
                              </Text>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Details'}>
                              <Link to={`order-details/${order.orderNumber}`}>
                                <ActionIcon variant="light" color="cyan">
                                  <IconListDetails size={16} />
                                </ActionIcon>
                              </Link>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No order found"
                  subtext={' '}
                  icon={<IconBoxOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={ordersPagelessFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / PAGE_LIMIT)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default OutboundOrders;
