import {
  ActionIcon,
  Badge,
  Card,
  Flex,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { fetchProductCBC } from '../../../services/product';
import dayjs from 'dayjs';
import COLORS from '../../../constants/colors';
import { IconDatabaseExport, IconFaceIdError } from '@tabler/icons-react';
import { fetchCbcListFromProductList } from '../../../services/orders';
import ExcelExport from 'export-xlsx';
import { NotificationUtil } from '../../../utils/notifications';
import { handleErrorResponse } from '../../../utils/utils';

const RenderTableBody = ({ isLoading, isError, data, productSize }) => {
  //console.log('table data', data);

  const {
    mutate: cbcExportMutate,
    isLoading: cbcExportMutateLoading,
    isMutating: cbcExportMutating,
  } = useMutation({
    mutationFn: (value) => fetchCbcListFromProductList(value),

    keepPreviousData: true,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      const excelExport = new ExcelExport();
      excelExport.downloadExcel(
        data.data.data.settings,
        data.data.data.sheetData,
      );
    },
  });

  const handleExportCBC = (productId, locationId) => {
    const value = {
      productId: productId,
      locationId: locationId,
    };
    cbcExportMutate(value);
  };

  if (isError) {
    return (
      <tr>
        <td colSpan={5}>
          <Stack
            w="100%"
            h="18vh"
            align="center"
            justify="center"
            bg={COLORS.grayish}>
            <IconFaceIdError color={COLORS.error} size={45} />
            <Text size="md" color="#272729" weight="bold">
              Sorry, Assigned CBCs could not be fetched at this time
            </Text>
          </Stack>
        </td>
      </tr>
    );
  }

  if (isLoading) {
    return (
      <tr>
        <td colSpan={5}>
          <Stack
            w="100%"
            h="18vh"
            align="center"
            justify="center"
            bg={COLORS.grayish}>
            <Loader size="xl" variant="oval" />
            <Text size="md" color="#272729" weight="bold">
              Getting assigned CBCs please wait
            </Text>
          </Stack>
        </td>
      </tr>
    );
  }

  if (!Array.isArray(data) || data?.length === 0) {
    return (
      <tr>
        <td colSpan={5}>
          <Stack
            w="100%"
            h="18vh"
            align="center"
            justify="center"
            bg={COLORS.grayish}>
            <IconFaceIdError color={COLORS.primary} size={45} />
            <Text size="md" color="#272729" weight="bold">
              No assigned CBCs found
            </Text>
          </Stack>
        </td>
      </tr>
    );
  }
  return data.map((cbc) => (
    <tr key={cbc.uid}>
      <td>{cbc.name}</td>
      <td>{cbc.address}</td>
      <td>{cbc.totalcbc}</td>
      <td>{((cbc.totalcbc * productSize) / 1000).toFixed(2)}</td>
      <td>
        <ActionIcon>
          <IconDatabaseExport
            onClick={() => handleExportCBC(cbc.productid, cbc.locationid)}
          />
        </ActionIcon>
      </td>
    </tr>
  ));
};

const AssignedCBCList = ({ uid, productSize }) => {
  const [exportType, setExportType] = useState('xls');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, isError } = useQuery({
    queryKey: ['productCBC', uid],
    queryFn: () => fetchProductCBC(uid),
  });

  return (
    <Card
      shadow="xs"
      padding="md"
      sx={{
        width: '100%',
        height: '100%',
        marginTop: '1rem',
      }}
      radius="sm"
      withBorder>
      <Flex justify="space-between" mb="sm">
        <Text size="lg" weight="bold" style={{ minWidth: '10em' }} mb="sm">
          CBCs
        </Text>
      </Flex>
      <ScrollArea h="35vh">
        <Table highlightOnHover>
          <thead
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#fff',
              zIndex: 1,
            }}>
            <tr>
              <th>Location</th>
              <th>Address</th>
              <th>CBCs</th>
              <th>Sticks - (TH)</th>
              <th>Export</th>
            </tr>
          </thead>
          <tbody>
            <RenderTableBody
              isError={isError}
              isLoading={isLoading}
              productSize={productSize}
              data={data?.data?.data?.cbcs || []}
            />
          </tbody>
        </Table>
      </ScrollArea>
      <Flex justify="flex-start">
        <Badge size="lg">Total: {data?.data?.data?.total || 0}</Badge>
      </Flex>
    </Card>
  );
};

export default AssignedCBCList;
