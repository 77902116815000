import { ActionIcon, Badge, Box, Flex, HoverCard, Text } from '@mantine/core';
import dayjs from 'dayjs';
import React from 'react';
import COLORS from '../../constants/colors';
import { orderStatusConst } from '../../constants/const';
import { getOrderStatusById } from '../../utils/utils';

const OrderTimeline = ({ order, ...rest }) => {
  const timelineStatuses = (order?.orderTimeline || []).map(
    (item) => item?.status,
  );

  const allStatuses = Object.keys(orderStatusConst).map((key) => key);

  const hasStatus = (status) => !!timelineStatuses.includes(status);

  const timelineItems = (status) =>
    (order?.orderTimeline || []).find((item) => item.status === status);

  return (
    <Flex my={15} gap={10} {...rest} w="100%" justify="space-between">
      {(allStatuses || []).map((item, index) => (
        <React.Fragment key={index}>
          <Flex
            gap={10}
            align="center"
            sx={{ flex: index === (allStatuses || []).length - 1 ? 0 : 1 }}>
            <HoverCard>
              <HoverCard.Target>
                <ActionIcon
                  variant="filled"
                  sx={{
                    backgroundColor: hasStatus(item)
                      ? item === orderStatusConst.CANCELLED
                        ? 'red'
                        : COLORS.primary
                      : 'gray',
                  }}
                  w={40}
                  h={40}
                  radius={500}>
                  {getOrderStatusById(index).icon}
                </ActionIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Badge color={getOrderStatusById(index).color} radius="sm">
                  {getOrderStatusById(index).label}
                </Badge>
                {(timelineItems(item) || {})?.remarks && (
                  <>
                    <Text size="sm" w={250}>
                      <span>{(timelineItems(item) || {})?.remarks}</span>
                    </Text>
                    <Text size="xs" weight={700} mt={10}>
                      {dayjs(
                        timelineItems(item)?.createdAt || new Date(),
                      ).format('hh:mm A, DD, MMM YYYY')}
                    </Text>
                  </>
                )}
              </HoverCard.Dropdown>
            </HoverCard>
            {index === (allStatuses || []).length - 1 ? null : (
              <Box
                // w={`calc(${window.innerWidth}px / ${allStatuses.length} - (${allStatuses.length} * 15px))`}
                h={4}
                bg={hasStatus(item) ? COLORS.primary : 'gray'}
                sx={{ borderRadius: 10000, flex: 1 }}
              />
            )}
          </Flex>
        </React.Fragment>
      ))}
    </Flex>
  );
};

export default OrderTimeline;
