import {
  Accordion,
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Space,
  Stack,
  Switch,
  Text,
  TypographyStylesProvider,
} from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import dayjs from 'dayjs';
import COLORS from '../../constants/colors';
import { emailReportSlots } from '../../constants/const';
import { useStyles } from '../../pages/Reports/Details/Styles';

const days = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
];

const EmailReportsAccordion = ({
  reportData,
  toggleMutate,
  setSelectedSetting,
  setShowAddSettingsModal,
  accordionState,
  setAccordionState,
}) => {
  const { classes } = useStyles();

  return (
    <div>
      <Flex align="center" justify="end" gap={5}>
        <Stack
          sx={{
            backgroundColor: COLORS.success,
            width: '40px',
            height: '10px',
          }}></Stack>
        <Text fz="xs">Active</Text>
        <Space w="sm" />
        <Stack
          sx={{
            backgroundColor: COLORS.error,
            width: '40px',
            height: '10px',
          }}></Stack>
        <Text fz="xs">Disabled</Text>
      </Flex>
      <Space h="sm" />
      <Accordion
        variant="separated"
        classNames={classes}
        className={classes.root}
        value={accordionState}
        onChange={(value) => setAccordionState(value)}>
        {reportData.map((d, i) => (
          <Accordion.Item key={i} value={d.uid}>
            <Accordion.Control
              sx={{
                borderLeft: `8px solid ${
                  d.isActive ? COLORS.success : COLORS.error
                }`,
                height: '50px',
              }}>
              <Flex justify="space-between" align="center">
                <Flex align="center" gap={10}>
                  <Text fw={600} size="lg">
                    {d.reportName}
                  </Text>
                  <Divider orientation="vertical" />
                  <Text size="0.8rem" fw={500}>
                    by {d?.createdByUser?.name}
                  </Text>
                </Flex>

                <Flex gap={8} wrap="wrap">
                  <Flex gap={10} align="center">
                    {days.map((day, index) => (
                      <Stack
                        key={index}
                        sx={{
                          borderRadius: '50%',
                          width: '30px',
                          height: '30px',
                          border: '1px solid #ccc',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: d.schedule.find(
                            (item) => item.day === day.value,
                          )
                            ? COLORS.yellow
                            : COLORS.grayish,
                          color: COLORS.black,
                          padding: '0px',
                          margin: '0px',
                        }}>
                        <Text align="center">{day.label[0]}</Text>
                      </Stack>
                    ))}
                    <Text>-</Text>
                    <Text>
                      {
                        emailReportSlots.find(
                          (item) => item.value === d.schedule[0]?.slot,
                        )?.label
                      }
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Accordion.Control>

            <Accordion.Panel>
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                }}>
                <Stack align="end">
                  <Flex align="center" gap={10}>
                    <Switch
                      onLabel="ACTIVE"
                      offLabel="DISABLED"
                      size="lg"
                      checked={d.isActive}
                      onChange={() => {
                        toggleMutate(d.id);
                      }}
                    />
                    <Button
                      sx={{
                        height: '30px',
                        backgroundColor: COLORS.primary,
                      }}
                      leftIcon={<IconEdit />}
                      disabled={!d.isActive}
                      onClick={() => {
                        setSelectedSetting(d);
                        setShowAddSettingsModal(true);
                      }}>
                      Edit
                    </Button>
                  </Flex>
                </Stack>
                <Stack
                  sx={{
                    border: '1px solid #e3e3e3',
                    position: 'relative',
                  }}>
                  <Flex>
                    <Stack
                      sx={{
                        width: '70%',
                        padding: '1rem',
                      }}>
                      <Text fw={500}>{d.emailSubject}</Text>
                      <Flex>
                        <Stack
                          sx={{
                            width: '5%',
                          }}>
                          <Text c="dimmed">To</Text>
                        </Stack>
                        <Stack
                          sx={{
                            width: '95%',
                          }}>
                          <Text>{d?.emails?.to.join(', ')}</Text>
                        </Stack>
                      </Flex>
                      <Flex>
                        <Stack
                          sx={{
                            width: '5%',
                          }}>
                          <Text c="dimmed">Cc</Text>
                        </Stack>
                        <Stack
                          sx={{
                            width: '95%',
                          }}>
                          <Text>{d?.emails?.cc.join(', ')}</Text>
                        </Stack>
                      </Flex>
                      <Flex align="center">
                        <Stack
                          sx={{
                            width: '8%',
                          }}>
                          <Text c="dimmed">Schedule</Text>
                        </Stack>
                        <Stack
                          sx={{
                            width: '92%',
                          }}>
                          <Flex gap={8} wrap="wrap">
                            <Flex gap={10} align="center">
                              {days.map((day, index) => (
                                <Stack
                                  key={index}
                                  sx={{
                                    borderRadius: '50%',
                                    width: '30px',
                                    height: '30px',
                                    border: '1px solid #ccc',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: d.schedule.find(
                                      (item) => item.day === day.value,
                                    )
                                      ? COLORS.yellow
                                      : COLORS.grayish,
                                    color: COLORS.black,
                                    padding: '0px',
                                    margin: '0px',
                                  }}>
                                  <Text align="center">{day.label[0]}</Text>
                                </Stack>
                              ))}
                              <Text>-</Text>
                              <Text>
                                {
                                  emailReportSlots.find(
                                    (item) =>
                                      item.value === d.schedule[0]?.slot,
                                  )?.label
                                }
                              </Text>
                            </Flex>
                          </Flex>
                        </Stack>
                      </Flex>
                    </Stack>
                    <Divider orientation="vertical" />
                    <Stack
                      sx={{
                        width: '30%',
                        padding: '1rem',
                      }}>
                      <Flex align="center" gap={5} wrap="wrap">
                        {d.reportFilters?.reports?.map((report, index) => (
                          <Badge key={index} color="cyan" size="lg" radius="sm">
                            {report}
                          </Badge>
                        ))}
                      </Flex>
                      <Divider my="sm" variant="dotted" />
                      <Flex align="center" gap={5} wrap="wrap">
                        {d.reportFilters?.locations?.map((location, index) => (
                          <Badge key={index} color="orange" radius="sm">
                            {location.name}
                          </Badge>
                        ))}
                      </Flex>
                    </Stack>
                  </Flex>
                </Stack>
                <Stack
                  sx={{
                    border: '1px solid #e3e3e3',
                    position: 'relative',
                    padding: '1rem',
                  }}>
                  <TypographyStylesProvider>
                    <div
                      style={{
                        fontSize: 'small',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: d?.emailBody,
                      }}
                    />
                  </TypographyStylesProvider>
                </Stack>
              </Stack>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

export default EmailReportsAccordion;
