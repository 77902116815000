import {
  Badge,
  Button,
  Card,
  Group,
  Space,
  Stack,
  Text,
  Textarea,
  ThemeIcon,
  Timeline,
} from '@mantine/core';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import React, { useState } from 'react';
import COLORS from '../../constants/colors';
import { openConfirmModal } from '@mantine/modals';
import { handleErrorResponse } from '../../utils/utils';
import { useMutation } from '@tanstack/react-query';
import { resolvePartialReceiveOrder } from '../../services/orders';
import dayjs from 'dayjs';
import { orderPartialReceiveHistoryStatuses } from '../../constants/const';
import { NotificationUtil } from '../../utils/notifications';

const ResolveOrderDrawer = ({
  onUpdate,
  orderNumber,
  partialReceiveHistory,
  onlyHistory = false,
}) => {
  const [resolveComment, setResolveComment] = useState(null);

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to resolve this order?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        resolveMutate();
      },
    });
  };

  const { mutate: resolveMutate, isLoading: resolveMutateLoading } =
    useMutation({
      mutationFn: () =>
        resolvePartialReceiveOrder({
          orderNumber: orderNumber,
          partialReceiveReason: 'OTHER',
          historyNote: resolveComment,
        }),

      keepPreviousData: true,
      onError: (error) => {
        handleErrorResponse(error);
      },
      onSuccess: (data) => {
        NotificationUtil({
          success: true,
          message: 'Order resolved successfully',
        });
        onUpdate();
      },
    });

  return (
    <Stack>
      <Card shadow="xs" padding="lg" radius="md" withBorder>
        <Timeline lineWidth={2}>
          {partialReceiveHistory?.map((item, index) => (
            <Timeline.Item
              key={index}
              bulletSize={24}
              bullet={
                item?.historyStatus ===
                orderPartialReceiveHistoryStatuses.PARTIALLY_RECEIVED ? (
                  <ThemeIcon size={22} radius="xl" color="red">
                    <IconAlertTriangle size="1rem" />
                  </ThemeIcon>
                ) : (
                  <ThemeIcon size={22} radius="xl" color="green">
                    <IconCheck size="1rem" />
                  </ThemeIcon>
                )
              }
              title={
                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>
                    {item?.historyStatus ===
                    orderPartialReceiveHistoryStatuses.PARTIALLY_RECEIVED
                      ? 'Order Flagged'
                      : 'Resolved'}
                  </Text>
                  <Badge color="gray" variant="light">
                    {item.receiver?.name}
                  </Badge>
                </Group>
              }>
              <Text size="xs" mt={4}>
                {dayjs(item.createdAt).format('MMMM D, YYYY hh:mma')}
              </Text>
              <Space h="sm" />
              {item?.historyStatus ===
                orderPartialReceiveHistoryStatuses.PARTIALLY_RECEIVED && (
                <Text size="sm">
                  Received Quantity: {item?.amountReceived} CBCs
                </Text>
              )}
              <Text
                size="sm"
                sx={{
                  whiteSpace: 'pre-wrap',
                }}>
                Remarks:{' '}
                {item?.reason.toLowerCase() === 'other'
                  ? item?.historyNote
                  : item?.reason}
              </Text>
            </Timeline.Item>
          ))}
        </Timeline>
      </Card>
      <Space h="sm" />
      {!onlyHistory && (
        <>
          <Textarea
            value={resolveComment || ''}
            onChange={(event) => setResolveComment(event.currentTarget.value)}
            placeholder="Your comment"
            label="Resolve this order?"
            autosize
            minRows={3}
            maxRows={10}
          />
          <Space h="sm" />
          <Stack>
            <Button
              disabled={!resolveComment}
              loading={resolveMutateLoading}
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => ConfirmModal()}>
              Resolve
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default ResolveOrderDrawer;
