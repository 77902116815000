import {
  ActionIcon,
  Badge,
  Card,
  Flex,
  HoverCard,
  Loader,
  Pagination,
  ScrollArea,
  Select,
  Stack,
  Table,
  Text,
  TextInput,
} from '@mantine/core';
import { IconFaceIdError, IconRefresh } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import COLORS from '../../../constants/colors';
import {
  orderStatusBadgeColors,
  orderStatusConst,
} from '../../../constants/const';
import { fetchProductAssignedOrders } from '../../../services/product';
import dayjs from 'dayjs';
import { DateInput } from '@mantine/dates';
import SearchInput from '../../Global/SearchInput';

const RenderTableBody = ({ isLoading, isError, data, productSize }) => {
  if (isError) {
    return (
      <tr>
        <td colSpan={7}>
          <Stack
            w="100%"
            h="18vh"
            align="center"
            justify="center"
            bg={COLORS.grayish}>
            <IconFaceIdError color={COLORS.error} size={45} />
            <Text size="md" color="#272729" weight="bold">
              Sorry, Assigned orders could not be fetched at this time
            </Text>
          </Stack>
        </td>
      </tr>
    );
  }

  if (isLoading) {
    return (
      <tr>
        <td colSpan={7}>
          <Stack
            w="100%"
            h="18vh"
            align="center"
            justify="center"
            bg={COLORS.grayish}>
            <Loader size="xl" variant="oval" />
            <Text size="md" color="#272729" weight="bold">
              Getting assigned orders please wait
            </Text>
          </Stack>
        </td>
      </tr>
    );
  }

  if (!Array.isArray(data) || data?.length === 0) {
    return (
      <tr>
        <td colSpan={12}>
          <Stack
            w="100%"
            h="18vh"
            align="center"
            justify="center"
            bg={COLORS.grayish}>
            <IconFaceIdError color={COLORS.primary} size={45} />
            <Text size="md" color="#272729" weight="bold">
              No assigned orders found
            </Text>
          </Stack>
        </td>
      </tr>
    );
  }
  return data.map((order, index) => (
    <tr key={order.id}>
      <td>{order.orderNumber}</td>
      <td>
        <Badge color={orderStatusBadgeColors[order?.orderStatus]}>
          {order?.orderStatus}
        </Badge>
      </td>
      <td>{dayjs(order?.createdAt).format('MMM DD, YYYY')}</td>
      <td>{order.orderType}</td>
      <td>{order.quantity}</td>
      <td>{(order.quantity * productSize) / 1000}</td>
      <td>
        <HoverCard key={index} width={280} shadow="md">
          <HoverCard.Target>
            <Stack
              spacing="xs"
              sx={{
                maxWidth: '120px',
              }}>
              <Badge color="cyan">{order?.sourceInformation?.name}</Badge>
            </Stack>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="md"> {order?.sourceInformation?.name}</Text>
            <Text size="sm">
              {order?.sourceInformation?.district || 'N/A'},{' '}
              {order?.sourceInformation?.division || 'N/A'}
            </Text>
            <Text size="sm">{order?.sourceInformation?.address}</Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </td>
      <td>
        <HoverCard key={index} width={280} shadow="md">
          <HoverCard.Target>
            <Stack
              spacing="xs"
              sx={{
                maxWidth: '120px',
              }}>
              <Badge color="orange">
                {order?.destinationInformation?.name}
              </Badge>
            </Stack>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="md"> {order?.destinationInformation?.name}</Text>
            <Text size="sm">
              {order?.destinationInformation?.district || 'N/A'},{' '}
              {order?.destinationInformation?.division || 'N/A'}
            </Text>
            <Text size="sm">{order?.destinationInformation?.address}</Text>
          </HoverCard.Dropdown>
        </HoverCard>
      </td>
    </tr>
  ));
};
const AssignedOrdersList = ({ uid, productSize }) => {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);
  const [orderType, setOrderType] = useState('');
  const [orderStatus, setOrderStatus] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [
      'assignedOrders',
      uid,
      searchKey,
      orderType,
      orderStatus,
      startDate,
      endDate,
    ],
    queryFn: () =>
      fetchProductAssignedOrders(uid, {
        searchKey,
        orderType,
        orderStatus,
        startDate,
        endDate,
      }),
  });

  const handleRefresh = () => {
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setOrderType('');
    setOrderStatus('');
    setStartDate('');
    setEndDate('');
  };

  const handleOrderSearch = (value) => {
    setSearchKey(value.trim());
  };

  return (
    <Card
      shadow="xs"
      padding="md"
      sx={{
        width: '100%',
        height: '100%',
        marginTop: '1rem',
      }}
      radius="sm"
      withBorder>
      <Flex justify="space-between" mb="sm" align="center" wrap="wrap">
        <Text size="lg" weight="bold" style={{ minWidth: '10em' }} mb="sm">
          Assigned Orders
        </Text>
        <Flex w="max-content" gap="sm">
          <SearchInput
            placeholder="Search Orders"
            handleRefresh={handleRefresh}
            handleSearch={handleOrderSearch}
            invokeRefresh={invokingRefreshForSearchInput}
            refreshBtn={false}
          />
          <Select
            placeholder="Order Type"
            data={['SO', 'STO']}
            w="60"
            value={orderType}
            onChange={(value) => setOrderType(value)}
          />
          <Select
            value={orderStatus}
            placeholder="Order Status"
            data={Object.keys(orderStatusConst).map((key) => ({
              label: orderStatusConst[key],
              value: key,
            }))}
            onChange={(value) => setOrderStatus(value)}
          />
          <DateInput
            placeholder="Start Date"
            value={startDate}
            onChange={setStartDate}
            weekendDays={[5, 6]}
          />
          <DateInput
            placeholder="End Date"
            value={endDate}
            onChange={setEndDate}
            weekendDays={[5, 6]}
          />
          <ActionIcon
            size="lg"
            onClick={handleRefresh}
            sx={{
              backgroundColor: COLORS.orange,
            }}
            variant="filled">
            <IconRefresh size={18} />
          </ActionIcon>
        </Flex>
      </Flex>
      <ScrollArea h="25vh">
        <Table highlightOnHover>
          <thead
            style={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#fff',
            }}>
            <tr>
              <th>Order Number</th>
              <th>Status</th>
              <th>Order Date</th>
              <th>Order Type</th>
              <th>Total CBC</th>
              <th>Total Quantity - TH (sticks)</th>
              <th>From</th>
              <th>To</th>
            </tr>
          </thead>
          <tbody>
            <RenderTableBody
              productSize={productSize}
              isError={isError}
              isLoading={isLoading}
              data={data?.data?.data?.orders}
            />
          </tbody>
        </Table>
      </ScrollArea>
      <Flex justify="space-between" align="center">
        <Badge size="lg">Total: {data?.data?.data?.total || 0}</Badge>
        {/* <Pagination
          mt="20px"
          value={page}
          onChange={setPage}
          total={Math.ceil(data?.data?.data?.total / 10)}
        /> */}
      </Flex>
    </Card>
  );
};

export default AssignedOrdersList;
