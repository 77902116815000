import { useIdle } from '@mantine/hooks';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../services/auth';
import { authActions } from '../store/reducers/authReducer';
import { NotificationUtil } from '../utils/notifications';
import { OrderFilterActions } from '../store/reducers/orderFiltersReducer';

const IdleWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const idle = useIdle(60 * 60 * 1000, { initialState: false });

  const logoutUser = async () => {
    try {
      const response = await logout();
      if (response.status === 200) {
        window.location.href = '/';
        dispatch(authActions.signout());
        dispatch(OrderFilterActions.clearFilters());
        // NotificationUtil({
        //   title: 'Sorry!',
        //   message: 'You have been logged out due to inactivity',
        //   success: false,
        // });
        alert('You have been logged out due to inactivity');
      }
    } catch (err) {
      window.location.href = '/';
      dispatch(authActions.signout());
      dispatch(OrderFilterActions.clearFilters());

      //   NotificationUtil({
      //     title: 'Sorry!',
      //     message: 'You have been logged out due to inactivity',
      //     success: false,
      //   });
      alert('You have been logged out due to inactivity');
    }
  };

  useEffect(() => {
    if (idle === true) {
      if (window.location.pathname !== '/') {
        logoutUser();
        queryClient.invalidateQueries();
      }
    }
  }, [idle]);

  return <div>{children}</div>;
};

export default IdleWrapper;
