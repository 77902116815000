import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AddRegionSchema, UpdateRegionSchema } from '../../validators/region';
import { openConfirmModal } from '@mantine/modals';
import {
  createRegion,
  toggleRegion,
  updateRegion,
} from '../../services/region';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { NotificationUtil } from '../../utils/notifications';
import { Button, Flex, Text, TextInput } from '@mantine/core';
import COLORS from '../../constants/colors';

const RegionForm = ({ defaultValues, update, onUpdate }) => {
  const queryClient = useQueryClient();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: defaultValues ? defaultValues : {},
    resolver: yupResolver(update ? UpdateRegionSchema : AddRegionSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    reset();
  }, []);

  const onSubmit = (data) => {
    ConfirmModal(data);
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you {update ? 'update' : 'create'} this region?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        if (update) {
          updateMutate({
            id: defaultValues.id,
            ...values,
          });
        } else {
          createMutate(values);
        }
      },
    });
  };

  const ConfirmDeleteModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you deactivate this region?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        deleteMutate(values);
      },
    });
  };

  const { mutate: createMutate, isLoading: isCreating } = useMutation({
    mutationFn: async (values) => await createRegion(values),
    onSuccess: () => {
      onUpdate();
      callRefetch();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (values) => await updateRegion(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation({
    mutationFn: async (values) => await toggleRegion(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const callRefetch = async () => {
    await queryClient.refetchQueries({
      queryKey: ['region-pagelessData'],
      type: 'active',
    });
    await queryClient.refetchQueries({
      queryKey: ['area-pagelessData'],
      type: 'active',
    });
    await queryClient.refetchQueries({
      queryKey: ['distributor-pagelessData'],
      type: 'active',
    });
  };

  return (
    <div
      style={{
        height: '90vh',
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="accounts-input">
          <TextInput
            {...register('name')}
            label="Name"
            placeholder="Enter name"
            error={errors?.name?.message}
          />
        </div>

        <Flex gap={15} py={8} mt={10}>
          {update && (
            <Button
              disabled={isSubmitting}
              sx={{
                flex: 1,
              }}
              onClick={() => {
                ConfirmDeleteModal({
                  status: !defaultValues.isActive,
                  id: defaultValues.id,
                });
              }}
              color={defaultValues.isActive ? 'red' : 'green'}>
              {defaultValues.isActive ? 'Deactivate' : 'Activate'}
            </Button>
          )}
          <Button
            type="submit"
            loading={isSubmitting || isCreating || isUpdating || isDeleting}
            disabled={isSubmitting || !isValid}
            sx={{
              flex: 1,
              backgroundColor: COLORS.primary,
            }}>
            {update ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default RegionForm;
