import axios from './axios';

export const fetchDistributor = async (context) => {
  const response = await axios().get('/distributor', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      regionId: context.queryKey[3],
      areaId: context.queryKey[4],
      pageLess: context.queryKey[5],
    },
  });
  return response;
};

export const createDistributor = async (values) => {
  const response = await axios().post('/distributor', values);
  return response;
};

export const updateDistributor = async (values) => {
  const response = await axios().patch('/distributor', values);
  return response;
};

export const toggleDistributor = async (values) => {
  const response = await axios().patch(`/distributor/${values.uid}`, {
    status: values.status,
  });
  return response;
};
