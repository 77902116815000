import { Box, Card, Title } from '@mantine/core';
import { ResponsivePie } from '@nivo/pie';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import React from 'react';

import { IconChartPieOff } from '@tabler/icons-react';
import COLORS from '../../../constants/colors';
import { isArrayAndHasContent } from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';

ChartJS.register(ArcElement, Tooltip, Legend);

const GDPieChart = ({ pieChartData, isLoading }) => {
  if (!pieChartData) {
    return (
      <Box style={{ height: '20em', width: '100%' }}>
        <Card
          shadow="sm"
          margin="sm"
          radius="md"
          withBorder
          sx={{
            height: 'auto',
            cursor: 'pointer',
          }}>
          <NoDataPlaceholder
            title="No Chart Data"
            subtext="No data available at the moment"
            icon={<IconChartPieOff size={70} color={COLORS.blue} />}
          />
        </Card>
      </Box>
    );
  }

  return (
    <Box style={{ height: '20em', width: '100%' }}>
      <Card
        shadow="sm"
        margin="sm"
        radius="md"
        withBorder
        sx={{
          height: 'auto',
          cursor: 'pointer',
        }}>
        {isLoading ? (
          <div style={{ height: '27.1em' }}>
            <Title size="sm">Intact CBC Stock</Title>
          </div>
        ) : (
          <div style={{ height: '27.1em' }}>
            <Title size="sm">Intact CBC Stock</Title>

            {isArrayAndHasContent(pieChartData) ? (
              <ResponsivePie
                data={pieChartData.map((item) => {
                  return {
                    id: item.shipmentFormatName || item.id,
                    value: item.value,
                  };
                })}
                fit={true}
                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                innerRadius={0.5}
                padAngle={0.7}
                cornerRadius={3}
                activeOuterRadiusOffset={8}
                borderWidth={1}
                borderColor={{
                  from: 'color',
                  modifiers: [['darker', 0.2]],
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor="#333333"
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                  from: 'color',
                  modifiers: [['darker', 2]],
                }}
                defs={[
                  {
                    id: 'dots',
                    type: 'patternDots',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: 'lines',
                    type: 'patternLines',
                    background: 'inherit',
                    color: 'rgba(255, 255, 255, 0.3)',
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 10,
                  },
                ]}
                fill={[
                  {
                    match: {
                      id: 'ruby',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'c',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'go',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'python',
                    },
                    id: 'dots',
                  },
                  {
                    match: {
                      id: 'scala',
                    },
                    id: 'lines',
                  },
                  {
                    match: {
                      id: 'lisp',
                    },
                    id: 'lines',
                  },
                  {
                    match: {
                      id: 'elixir',
                    },
                    id: 'lines',
                  },
                  {
                    match: {
                      id: 'javascript',
                    },
                    id: 'lines',
                  },
                ]}
              />
            ) : (
              <NoDataPlaceholder
                title="No Chart Data"
                subtext="No data available at the moment"
                icon={<IconChartPieOff size={70} color={COLORS.blue} />}
              />
            )}
          </div>
        )}
      </Card>
    </Box>
  );
};

export default GDPieChart;
