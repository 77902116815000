import { Box, Button, Flex, Modal, Text } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import React, { useEffect, useState } from 'react';

const DateSelectModal = ({
  opened,
  open,
  close,
  changeStartDate,
  changeEndDate,
  startDate,
  endDate,
  exportFunc,
  exportBtnTitle,
  handleResetFunc,
}) => {
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);

  useEffect(() => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  }, [startDate, endDate]);

  const handleStartDateChange = (e) => {
    setTempStartDate(e);
  };

  const handleEndDateChange = (e) => {
    setTempEndDate(e);
  };

  const handleSubmit = () => {
    changeStartDate(tempStartDate);
    changeEndDate(tempEndDate);
    close();
  };

  const handleReset = () => {
    if (handleResetFunc) {
      handleResetFunc();
    } else {
      setTempStartDate(null);
      setTempEndDate(null);
      changeStartDate(null);
      changeEndDate(null);
    }
  };

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={close}
      title="Select Date"
      zIndex={1000}>
      <Flex justify="space-evenly">
        <Flex align="center" direction="column" justify="center">
          <Text size="sm" align="center" py="md">
            Select Start Date
          </Text>

          <DatePicker
            value={tempStartDate}
            onChange={handleStartDateChange}
            style={{ border: '1px solid #e1e1e1', borderRadius: '8px' }}
            weekendDays={[5, 6]}
          />
        </Flex>

        {tempStartDate && (
          <Flex align="center" direction="column" justify="center">
            <Text size="sm" align="center" py="md">
              Select End Date
            </Text>
            <DatePicker
              //allowDeselect
              defaultValue={new Date()}
              value={tempEndDate}
              onChange={handleEndDateChange}
              style={{ border: '1px solid #e1e1e1', borderRadius: '8px' }}
              minDate={tempStartDate ? tempStartDate : new Date()}
              weekendDays={[5, 6]}
            />
          </Flex>
        )}
      </Flex>
      <Flex py="md" justify="flex-end" gap={5}>
        <Button onClick={() => handleReset()} color="red">
          Reset
        </Button>
        <Button onClick={handleSubmit} disabled={!tempStartDate}>
          Filter
        </Button>
      </Flex>
    </Modal>
  );
};

export default DateSelectModal;
