import * as yup from 'yup';

export const AddLocationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  referenceLabel: yup.string(),
  district: yup.string().required('District is required'),
  division: yup.string().required('Division is required'),
  address: yup.string().required('Address is required'),
  symphonyReferenceId: yup.string(),
  latitude: yup.string(),
  longitude: yup.string(),
  distributerId: yup.string().nullable(true),
  regionId: yup.number(),
  areaId: yup.number(),
  territoryId: yup.number(),
});

export const UpdateLocationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  referenceLabel: yup.string(),
  district: yup.string().required('District is required'),
  division: yup.string().required('Division is required'),
  address: yup.string().required('Address is required'),
  symphonyReferenceId: yup.string(),
  latitude: yup.string(),
  longitude: yup.string(),
  distributerId: yup.string().nullable(true),
  regionId: yup.number(),
  areaId: yup.number(),
  territoryId: yup.number(),
  baseValue: yup.number(),
});
