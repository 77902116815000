import {
  IconAdjustments,
  IconArticle,
  IconBoxSeam,
  IconBug,
  IconBuildingWarehouse,
  IconChartInfographic,
  IconDeviceMobileDown,
  IconLayoutDashboard,
  IconMapPin,
  IconPackages,
  IconStackPop,
  IconStackPush,
  IconUsers,
} from '@tabler/icons-react';
import * as urls from './app-urls';
import { userWeight } from './const';

const navBarList = [
  {
    label: 'Dashboard',
    icon: IconLayoutDashboard,
    link: urls.DASHBOARD,
    accessWeight: 2,
    adminsAccess: true,
    onlySuperAdminAccess: false,
  },
  {
    label: 'Orders',
    icon: IconBoxSeam,
    link: urls.ORDERS,
    accessWeight: 8,
    adminsAccess: true,
    onlySuperAdminAccess: false,
  },
  {
    label: 'Inbound Orders',
    icon: IconStackPush,
    link: urls.INBOUND_ORDERS,
    accessWeight: 4,
    adminsAccess: false,
    onlySuperAdminAccess: false,
  },
  {
    label: 'Outbound Orders',
    icon: IconStackPop,
    link: urls.OUTBOUND_ORDERS,
    accessWeight: 4,
    adminsAccess: false,
    onlySuperAdminAccess: false,
  },
  {
    label: 'Inventory',
    icon: IconBuildingWarehouse,
    link: urls.INVENTORY,
    accessWeight: 4,
    adminsAccess: true,
    onlySuperAdminAccess: false,
  },
  // {
  //   label: 'Users',
  //   icon: IconUsers,
  //   link: urls.USERS,
  //   accessWeight: 4,
  //   adminsAccess: true,
  //   onlySuperAdminAccess: false,
  // },
  // {
  //   label: 'Points',
  //   icon: IconMapPin,
  //   link: urls.LOCATIONS,
  //   accessWeight: 8,
  //   adminsAccess: true,
  //   onlySuperAdminAccess: false,
  // },
  // {
  //   label: 'Products',
  //   icon: IconPackages,
  //   link: urls.PRODUCTS_ALL,
  //   accessWeight: 8,
  //   adminsAccess: true,
  // },
  // {
  //   label: 'Logs',
  //   icon: IconArticle,
  //   link: urls.LOGS,
  //   accessWeight: 8,
  //   adminsAccess: true,
  //   onlySuperAdminAccess: false,
  // },

  // {
  //   label: 'Error Logs',
  //   icon: IconBug,
  //   link: urls.ERROR_LOGS,
  //   accessWeight: userWeight['SUPER ADMIN'],
  //   adminsAccess: true,
  //   onlySuperAdminAccess: true,
  // },
  {
    label: 'Reports',
    icon: IconChartInfographic,
    link: urls.REPORTS,
    accessWeight: userWeight.MANAGER,
    adminsAccess: true,
    onlySuperAdminAccess: false,
  },
  // {
  //   label: 'Mobile Applications',
  //   icon: IconDeviceMobileDown,
  //   link: urls.MOBILE_APPLICATIONS,
  //   accessWeight: userWeight.ADMIN,
  //   adminsAccess: true,
  //   onlySuperAdminAccess: false,
  // },
  {
    label: 'Settings',
    icon: IconAdjustments,
    link: urls.SETTINGS,
    accessWeight: userWeight.ADMIN,
    adminsAccess: true,
    onlySuperAdminAccess: false,
  },
];

export default navBarList;
