import axios from './axios';

export const fetchTerritory = async (context) => {
  const response = await axios().get('/territories', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      regionId: context.queryKey[3],
      areaId: context.queryKey[4],
      distributorId: context.queryKey[5],
      pageLess: context.queryKey[6],
    },
  });
  return response;
};

export const createTerritory = async (values) => {
  const response = await axios().post('/territories', values);
  return response;
};

export const updateTerritory = async (values) => {
  const response = await axios().patch('/territories', values);
  return response;
};

export const toggleTerritory = async (values) => {
  const response = await axios().patch(`/territories/${values.id}`, {
    status: values.status,
  });
  return response;
};
