import { Tooltip, UnstyledButton } from '@mantine/core';
import React from 'react';
import useStyles from '../../styles/nav-styles';
import { Link } from 'react-router-dom';

const NavbarLink = ({ icon: Icon, label, active, onClick, link }) => {
  const { classes, cx } = useStyles();

  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <Link to={link}>
        <UnstyledButton
          onClick={onClick}
          className={cx(classes.link, { [classes.active]: active })}>
          <Icon size="1.2rem" stroke={1.5} />
        </UnstyledButton>
      </Link>
    </Tooltip>
  );
};

export default NavbarLink;
