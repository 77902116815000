import {
  Badge,
  Box,
  Flex,
  HoverCard,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { IconBoxOff } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useState } from 'react';
import { fetchCbcHistory } from '../../../services/orders';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  scanActionBadgeColorMapper,
} from '../../../utils/utils';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';

const CbcHistoryTable = ({
  uid,
  isLoading,
  error,
  cbcHistory,
  total,
  page,
  setPage,
}) => {
  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const {
    isFetching: cbcHistoryPagelessFetching,
    refetch: cbcHistoryPagelessDataRefetch,
  } = useQuery({
    queryKey: ['cbc-history-pageless', uid, 1, 1, true],
    enabled: exportToggler,
    queryFn: fetchCbcHistory,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      console.log(response);
      const fileName = `CBC-History-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = response?.data?.data?.histories?.history.map((cbc) => {
        return {
          Date: dayjs(cbc.createdAt).format('DD-MM-YYYY'),
          Status: cbc.cbcStatus,
          'Order Number': cbc.orderNumber,
          'Order Type': cbc.orderType,
          Location: cbc.locationName,
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      cbcHistoryPagelessDataRefetch();
    } else {
      cbcHistoryPagelessDataRefetch();
    }
  };

  if (isLoading)
    return (
      <div>
        <Skeleton height="40vh" />
        <Flex justify="space-between">
          <Stack justify="end">
            <ExportCsvXls
              setExportType={setExportType}
              exportData={exportData}
              btnLoader={cbcHistoryPagelessFetching}
            />
          </Stack>
          <Pagination
            mt="20px"
            value={page}
            onChange={setPage}
            total={Math.ceil(total / 10)}
          />
        </Flex>
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  return (
    <div>
      {isArrayAndHasContent(cbcHistory) ? (
        <ScrollArea h="40vh">
          <Table highlightOnHover>
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
              }}>
              <tr>
                <th>Date</th>
                <th>Status</th>
                <th>Order Number</th>
                <th>Order Type</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              {cbcHistory.map((cbc, i) => (
                <tr key={i}>
                  <td>
                    <Text>
                      {dayjs(cbc.createdAt).format('MMM DD - hh:mm A ')}
                    </Text>
                  </td>
                  <td>
                    <Badge color={scanActionBadgeColorMapper(cbc?.cbcStatus)}>
                      {cbc?.cbcStatus}
                    </Badge>
                  </td>
                  <td>
                    <Box>
                      <Text>{cbc.orderNumber}</Text>
                    </Box>
                  </td>
                  <td>
                    <Box>
                      <Text>{cbc.orderType}</Text>
                    </Box>
                  </td>

                  <td>
                    <HoverCard width={280} shadow="md">
                      <HoverCard.Target>
                        <Text fz="sm">{cbc.locationName}</Text>
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Text size="md">{cbc.locationName}</Text>
                        <Text size="sm">
                          {cbc.district || 'N/A'}, {cbc.division || 'N/A'}
                        </Text>
                        <Text size="sm">{cbc.address}</Text>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollArea>
      ) : (
        <div>
          <NoDataPlaceholder
            title="No CBC History found"
            subtext={' '}
            icon={<IconBoxOff size={70} color="#4562f7" />}
          />
        </div>
      )}
      <Flex justify="space-between">
        <Stack justify="end">
          <ExportCsvXls
            setExportType={setExportType}
            exportData={exportData}
            btnLoader={cbcHistoryPagelessFetching}
          />
        </Stack>
        <Pagination
          mt="20px"
          value={page}
          onChange={setPage}
          total={Math.ceil(total / 10)}
        />
      </Flex>
    </div>
  );
};

export default CbcHistoryTable;
