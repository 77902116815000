// UNPROTECTED ROUTES
export const SIGNIN = '/';
export const DOWNLOAD_APP = '/download-app';
export const FORGOT_PASSWORD = '/forgot-password';
export const VERIFY_OTP = '/verify-otp';

//PROTECTED ROUTES
export const DASHBOARD = '/dashboard';

//user management routes
export const USERS = '/settings/users';
export const USER_LOGOUT = '/users/logout';

//location management routes
export const LOCATIONS = '/settings/locations';
////location manage management routes
export const LOCATIONS_MANAGE = '/locations/manage';
export const LOCATIONS_ROUTE_SECTION = '/locations/route-section';

//order management routes
export const ORDERS = '/orders';
export const ORDER_DETAILS = '/orders/order-details/:uid';
export const INBOUND_ORDERS = '/inbound-orders';
export const OUTBOUND_ORDERS = '/outbound-orders';
export const INBOUND_ORDER_DETAILS = '/inbound-orders/order-details/:uid';
export const OUTBOUND_ORDER_DETAILS = '/outbound-orders/order-details/:uid';

//inventory management routes
export const INVENTORY = '/inventory';
export const BLOCKCBC = '/inventory/block/cbc';
////Product routes
export const PRODUCTS = '/inventory/product-details/:uid';
export const PRODUCTS_ALL = '/settings/products';

//logs management routes
export const LOGS = '/settings/logs';

//error logs
export const ERROR_LOGS = '/settings/error-logs';

//mobile applications
export const MOBILE_APPLICATIONS = '/settings/mobile-applications';

//reports
export const REPORTS = '/reports';
export const SO_IGT_Scan_Performance_Report =
  '/reports/so-igt-scan-performance-report';
export const Daily_Open_CBC_Scan_Performance_Report =
  '/reports/daily-open-cbc-scan-performance-report';
export const OneViewReport = '/reports/one-view-report';
export const GODOWN_KEEPER_APP_VERSION_LIST =
  '/reports/godown-keeper-app-version-list';
export const EmailReport = '/reports/email-report';
export const OPEN_CBC_REPORT = '/reports/open-cbc-report';

//DAY ZERO RECONCILIATION
export const DAY_ZERO_RECONCILIATION = '/inventory-reconciliation';
export const DAY_ZERO_RECONCILIATION_DETAILS =
  '/inventory-reconciliation/details/:uid';

//SETTINGS
export const SETTINGS = '/settings';
