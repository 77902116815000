import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  Grid,
  HoverCard,
  Loader,
  Modal,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  SimpleGrid,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import {
  IconArrowLeft,
  IconArticle,
  IconEdit,
  IconEye,
  IconLogout,
  IconMapPin,
  IconPlus,
  IconRefresh,
  IconUserOff,
  IconX,
} from '@tabler/icons-react';
import { useQueries, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import AssignLocationModal from '../../components/User/AssignLocationModal';
import UserForm from '../../components/User/UserForm';
import COLORS from '../../constants/colors';
import {
  actionLoggerReferenceTypes,
  userBadgeBackgroundColors,
  userDesignations,
  userRoles,
  userWeight,
} from '../../constants/const';
import { fetchUsers } from '../../services/user';
import {
  canThisUserEditThisUser,
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
  userDesignationAccordingToRoleMapper,
  userRoleLabelsAccordingToWeight,
  userWiseAutocompleteLocationDataWithId,
} from '../../utils/utils';
import LogsDrawer from '../../components/Global/LogsDrawer';
import ExportCsvXls from '../../components/Global/ExportCsvXlsx';
import exportFromJSON from 'export-from-json';
import dayjs from 'dayjs';
import { fetchLocations } from '../../services/location';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import { fetchTerritory } from '../../services/territories';
import { fetchDistributor } from '../../services/distributor';
import { fetchArea } from '../../services/area';
import { fetchRegion } from '../../services/region';
import FilterBadgeClearable from '../../components/Global/FilterBadgeClearable';
import OtherDetailsModal from '../../components/User/OtherDetailsModal';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Users';

const UserManagement = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [searchByStatus, setSearchByStatus] = useState(null);
  const [searchByRole, setSearchByRole] = useState(null);
  const [searchByLocation, setSearchByLocation] = useState(null);
  const [locationSearchKey, setLocationSearchKey] = useState(null);

  const [searchByRegion, setSearchByRegion] = useState(null);
  const [regionSearchKey, setRegionSearchKey] = useState(null);
  const [searchByArea, setSearchByArea] = useState(null);
  const [areaSearchKey, setAreaSearchKey] = useState(null);
  const [searchByDistributor, setSearchByDistributor] = useState(null);
  const [distributorSearchKey, setDistributorSearchKey] = useState(null);
  const [searchByTerritory, setSearchByTerritory] = useState(null);
  const [territorySearchKey, setTerritorySearchKey] = useState(null);

  const [searchByDesignation, setSearchByDesignation] = useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [addUserDrawer, setAddUserDrawer] = useState(false);
  const [logsDrawer, setLogsDrawer] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const [assignLocationModal, setAssignLocationModal] = useState(false);

  const [otherDetailsModal, setOtherDetailsModal] = useState(false);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const appUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    document.title = 'Users | Armada';
  }, []);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'users',
      page,
      searchKey,
      searchByStatus,
      searchByRole,
      searchByLocation || null,
      null,
      searchByRegion,
      searchByArea,
      searchByDistributor,
      searchByTerritory,
      searchByDesignation,
    ],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: ['location', 1, null, null, null, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const [
    { data: regionPagelessdata },
    { data: areaPagelessData },
    { data: distributorPagelessdata },
    { data: territoryPagelessData },
  ] = useQueries({
    queries: [
      {
        queryKey: ['region-pagelessData', null, null, true],
        queryFn: fetchRegion,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['area-pagelessData', null, null, null, true],
        queryFn: fetchArea,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['distributor-pagelessData', null, null, null, null, true],
        queryFn: fetchDistributor,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: [
          'terriytory-pagelessData',
          null,
          null,
          null,
          null,
          null,
          true,
        ],
        queryFn: fetchTerritory,
        refetchOnWindowFocus: false,
        retry: false,
      },
    ],
  });

  const {
    data: userPagelessData,
    isLoading: userPagelessDataLoading,
    isFetching: userPagelessDataFetching,
    refetch: userPagelessDataRefetch,
    error: userPagelessDataError,
  } = useQuery({
    queryKey: ['users-pageless', null, null, null, null, null, true],
    queryFn: fetchUsers,
    enabled: exportToggler,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `USERS-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = response.data.data.users.map((user) => {
        return {
          Name: user.name,
          Email: user.email,
          'Phone number': user.phone,
          Designation: user.designation,
          Role: userRoles[user?.role],
          Status: user.status,
          Regions: user.userRegions.map((region) => region.name).join(', '),
          Areas: user.userAreas.map((area) => area.name).join(', '),
          Distributors: user.userDistributors
            .map((distributor) => distributor.name)
            .join(', '),
          Territories: user.userTerritories

            .map((territory) => territory.name)
            .join(', '),
          Points: user.userLocations
            .map((location) => location.name)
            .join(', '),
        };
      });

      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const handleRefreshLocal = () => {
    setSearchByRole(null);
    setSearchByStatus(null);
    setSearchByLocation(null);
    setLocationSearchKey(null);
    setDistributorSearchKey(null);
    setSearchByDistributor(null);
    setSearchByArea(null);
    setAreaSearchKey(null);
    setSearchByRegion(null);
    setRegionSearchKey(null);
    setTerritorySearchKey(null);
    setSearchByTerritory(null);
    setSearchByDesignation(null);
    handleRefresh();
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      userPagelessDataRefetch();
    } else {
      userPagelessDataRefetch();
    }
  };

  const { users, total } = data.data.data;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={addUserDrawer}
        onClose={() => {
          setSelectedUser(null);
          setAddUserDrawer(false);
        }}
        title={
          isObjectAndHasProperties(selectedUser) ? 'Edit User' : 'Add User'
        }
        position="right">
        <UserForm
          update={isObjectAndHasProperties(selectedUser)}
          defaultValues={selectedUser}
          onUpdate={() => {
            refetch();
            setSelectedUser(null);
            setAddUserDrawer(false);
          }}
        />
      </Drawer>
      <Drawer
        opened={logsDrawer}
        onClose={() => {
          setLogsDrawer(false);
        }}
        title="User Logs"
        position="right">
        <LogsDrawer
          referenceType={actionLoggerReferenceTypes.USER_MANAGEMENT}
          singleOrderFetch={false}
        />
      </Drawer>
      <Modal
        opened={assignLocationModal}
        onClose={() => setAssignLocationModal(false)}
        title="Assign Location"
        size="md"
        centered={true}>
        <AssignLocationModal
          user={selectedUser}
          onClose={() => setAssignLocationModal(false)}
          onUpdate={({ close }) => {
            refetch();
            if (close) {
              setAssignLocationModal(false);
            }
          }}
        />
      </Modal>
      <Modal
        opened={otherDetailsModal}
        onClose={() => setOtherDetailsModal(false)}
        title="Other Details"
        size="md"
        centered={true}>
        <OtherDetailsModal
          user={selectedUser}
          onClose={() => setOtherDetailsModal(false)}
          onUpdate={({ close }) => {
            refetch();
            if (close) {
              setOtherDetailsModal(false);
            }
          }}
        />
      </Modal>
      {/* CUSTOM MODAL AND DRAWER END */}
      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Flex align="center" gap={10}>
          {appUser.userWeight > userWeight['GODOWN MANAGER'] ? (
            <Button
              onClick={() => setLogsDrawer(true)}
              color="violet"
              disabled={userRoles[appUser.role] === userRoles.MANAGER}
              leftIcon={<IconArticle />}>
              Logs
            </Button>
          ) : null}
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Grid py="sm">
          <Grid.Col xl={10} lg={10} md={12} sm={12} xs={12}>
            <Flex wrap="wrap" gap={8}>
              <div className="common_width">
                <SearchInput
                  handleRefresh={() => handleRefresh()}
                  handleSearch={handleSearch}
                  placeholder="Search Users"
                  invokeRefresh={invokingRefreshForSearchInput}
                  refreshBtn={false}
                />
              </div>

              <div className="common_width">
                <Select
                  value={searchByStatus}
                  onChange={(value) => {
                    setSearchByStatus(value);
                  }}
                  placeholder="Filter by status"
                  data={[
                    {
                      value: 'ACTIVE',
                      label: 'Active',
                    },
                    {
                      value: 'BANNED',
                      label: 'Banned',
                    },
                  ]}
                />
              </div>

              <div className="common_width">
                <Select
                  value={searchByRole}
                  onChange={(value) => {
                    setSearchByRole(value);
                  }}
                  placeholder="Filter by user privilege"
                  data={userRoleLabelsAccordingToWeight(appUser.userWeight)}
                />
              </div>

              <div className="common_width">
                <Select
                  value={searchByDesignation}
                  onChange={(value) => {
                    setSearchByDesignation(value);
                  }}
                  placeholder="Filter by designation"
                  data={userDesignationAccordingToRoleMapper(appUser.role)?.map(
                    (key) => ({
                      value: key,
                      label: key,
                    }),
                  )}
                />
              </div>

              {appUser.userWeight > userWeight['GODOWN MANAGER'] ? (
                <div className="common_width">
                  <Autocomplete
                    placeholder="Search by location"
                    onChange={(value) => {
                      if (locationSearchKey && locationSearchKey.length === 0) {
                        setLocationSearchKey(null);
                      } else {
                        setLocationSearchKey(value);
                      }
                    }}
                    limit={locationData?.data?.data?.locations.length || 0}
                    maxDropdownHeight="200px"
                    sx={{
                      maxWidth: '200px',
                      minWidth: '13em',
                    }}
                    dropdownPosition="bottom"
                    nothingFound="No options"
                    value={locationSearchKey || ''}
                    onItemSubmit={(e) => {
                      setSearchByLocation(e.id);
                    }}
                    rightSection={
                      searchByLocation ? (
                        <UnstyledButton
                          sx={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                          }}
                          onClick={() => {
                            setLocationSearchKey(null);
                            setSearchByLocation(null);
                          }}>
                          <IconX size={15} />
                        </UnstyledButton>
                      ) : null
                    }
                    data={
                      userWiseAutocompleteLocationDataWithId({
                        appUser: appUser,
                        locationData: locationData?.data?.data?.locations || [],
                      }) || []
                    }
                  />
                </div>
              ) : null}

              <div className="common_width">
                <Autocomplete
                  placeholder="Search by region"
                  sx={{
                    maxWidth: '200px',
                    minWidth: '13em',
                  }}
                  onChange={(value) => {
                    if (regionSearchKey && regionSearchKey.length === 0) {
                      setRegionSearchKey(null);
                    } else {
                      setRegionSearchKey(value);
                    }
                  }}
                  limit={regionPagelessdata?.data?.data?.regions?.length || 0}
                  maxDropdownHeight="200px"
                  dropdownPosition="bottom"
                  nothingFound="No options"
                  value={regionSearchKey || ''}
                  onItemSubmit={(e) => {
                    setSearchByRegion(e.id);
                  }}
                  rightSection={
                    searchByRegion ? (
                      <UnstyledButton
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() => {
                          setRegionSearchKey(null);
                          setSearchByRegion(null);
                        }}>
                        <IconX size={15} />
                      </UnstyledButton>
                    ) : null
                  }
                  data={
                    regionPagelessdata?.data?.data?.regions?.map(
                      (location) => ({
                        id: location.id,
                        value: location.name,
                      }),
                    ) || []
                  }
                />
              </div>

              <div className="common_width">
                <Autocomplete
                  placeholder="Search by area"
                  sx={{
                    maxWidth: '200px',
                    minWidth: '13em',
                  }}
                  onChange={(value) => {
                    if (areaSearchKey && areaSearchKey.length === 0) {
                      setAreaSearchKey(null);
                    } else {
                      setAreaSearchKey(value);
                    }
                  }}
                  limit={areaPagelessData?.data?.data?.areas?.length || 0}
                  maxDropdownHeight="200px"
                  dropdownPosition="bottom"
                  nothingFound="No options"
                  value={areaSearchKey || ''}
                  onItemSubmit={(e) => {
                    setSearchByArea(e.id);
                  }}
                  rightSection={
                    searchByArea ? (
                      <UnstyledButton
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() => {
                          setAreaSearchKey(null);
                          setSearchByArea(null);
                        }}>
                        <IconX size={15} />
                      </UnstyledButton>
                    ) : null
                  }
                  data={
                    areaPagelessData?.data?.data?.areas?.map((location) => ({
                      id: location.id,
                      value: location.name,
                    })) || []
                  }
                />
              </div>

              <div className="common_width">
                <Autocomplete
                  placeholder="Search by distributor"
                  sx={{
                    maxWidth: '200px',
                    minWidth: '13em',
                  }}
                  onChange={(value) => {
                    if (
                      distributorSearchKey &&
                      distributorSearchKey.length === 0
                    ) {
                      setDistributorSearchKey(null);
                    } else {
                      setDistributorSearchKey(value);
                    }
                  }}
                  limit={
                    distributorPagelessdata?.data?.data?.distributors.length ||
                    0
                  }
                  maxDropdownHeight="200px"
                  dropdownPosition="bottom"
                  nothingFound="No options"
                  value={distributorSearchKey || ''}
                  onItemSubmit={(e) => {
                    setSearchByDistributor(e.id);
                  }}
                  rightSection={
                    searchByDistributor ? (
                      <UnstyledButton
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() => {
                          setDistributorSearchKey(null);
                          setSearchByDistributor(null);
                        }}>
                        <IconX size={15} />
                      </UnstyledButton>
                    ) : null
                  }
                  data={
                    distributorPagelessdata?.data?.data?.distributors.map(
                      (location) => ({
                        id: location.id,
                        value: location.name,
                      }),
                    ) || []
                  }
                />
              </div>

              <Flex className="common_width" gap={5}>
                <Autocomplete
                  placeholder="Search by territory"
                  sx={{
                    maxWidth: '200px',
                    minWidth: '13em',
                  }}
                  onChange={(value) => {
                    if (territorySearchKey && territorySearchKey.length === 0) {
                      setTerritorySearchKey(null);
                    } else {
                      setTerritorySearchKey(value);
                    }
                  }}
                  limit={
                    territoryPagelessData?.data?.data?.territories.length || 0
                  }
                  maxDropdownHeight="200px"
                  dropdownPosition="bottom"
                  nothingFound="No options"
                  value={territorySearchKey || ''}
                  onItemSubmit={(e) => {
                    setSearchByTerritory(e.id);
                  }}
                  rightSection={
                    searchByTerritory ? (
                      <UnstyledButton
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                          display: 'flex',
                        }}
                        onClick={() => {
                          setTerritorySearchKey(null);
                          setSearchByTerritory(null);
                        }}>
                        <IconX size={15} />
                      </UnstyledButton>
                    ) : null
                  }
                  data={
                    territoryPagelessData?.data?.data?.territories.map(
                      (location) => ({
                        id: location.id,
                        value: location.name,
                      }),
                    ) || []
                  }
                />
                <Flex align="center" justify="center">
                  <Tooltip label="Refresh">
                    <ActionIcon
                      size="lg"
                      onClick={handleRefreshLocal}
                      sx={{
                        backgroundColor: COLORS.orange,
                      }}
                      variant="filled">
                      <IconRefresh size={18} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>
            </Flex>
          </Grid.Col>
          <Grid.Col xl={2} lg={2} md={12} sm={12} xs={12}>
            <Flex justify="flex-end" align="center" gap={10}>
              {appUser.userWeight === userWeight['SUPER ADMIN'] && (
                <Button
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  onClick={() => {
                    navigate('/users/logout');
                  }}
                  leftIcon={<IconLogout />}>
                  logout users
                </Button>
              )}
              <Button
                sx={{
                  backgroundColor: COLORS.primary,
                }}
                onClick={() => {
                  setSelectedUser(null);
                  setAddUserDrawer(true);
                }}
                disabled={
                  userRoles[appUser.role] === userRoles['GODOWN MANAGER']
                }
                leftIcon={<IconPlus />}>
                Add User
              </Button>
            </Flex>
          </Grid.Col>
        </Grid>

        {/* badge filters */}
        <Flex my={5} gap={5} align="center">
          <Text fz="xs">Showing {total || 0} orders</Text>
          {searchKey ||
          searchByStatus ||
          searchByRole ||
          locationSearchKey ||
          regionSearchKey ||
          areaSearchKey ||
          distributorSearchKey ||
          territorySearchKey ||
          searchByDesignation ? (
            <Text fz="xs">with filters</Text>
          ) : null}
          {searchKey && (
            <FilterBadgeClearable
              text={`search: ${searchKey}`}
              func={() => {
                setInvokingRefreshForSearchInput(
                  !invokingRefreshForSearchInput,
                );
                setSearchKey('');
              }}
              color="blue"
            />
          )}
          {searchByDesignation && (
            <FilterBadgeClearable
              text={`designation: ${searchByDesignation}`}
              func={() => {
                setInvokingRefreshForSearchInput(
                  !invokingRefreshForSearchInput,
                );
                setSearchByDesignation('');
              }}
              color="cyan"
            />
          )}
          {searchByStatus && (
            <FilterBadgeClearable
              text={`status: ${searchByStatus}`}
              func={() => {
                setSearchByStatus(null);
              }}
              color="orange"
            />
          )}
          {searchByRole && (
            <FilterBadgeClearable
              text={`role type: ${searchByRole}`}
              func={() => {
                setSearchByRole(null);
              }}
              color="grape"
            />
          )}

          {locationSearchKey && (
            <FilterBadgeClearable
              text={`location: ${locationSearchKey}`}
              func={() => {
                setSearchByLocation(null);
                setLocationSearchKey(null);
              }}
              color="yellow"
            />
          )}

          {regionSearchKey && (
            <FilterBadgeClearable
              text={`region: ${regionSearchKey}`}
              func={() => {
                setRegionSearchKey(null);
                setSearchByRegion(null);
              }}
              color="pink"
            />
          )}

          {areaSearchKey && (
            <FilterBadgeClearable
              text={`area: ${areaSearchKey}`}
              func={() => {
                setAreaSearchKey(null);
                setSearchByArea(null);
              }}
              color="green"
            />
          )}

          {distributorSearchKey && (
            <FilterBadgeClearable
              text={`distributor: ${distributorSearchKey}`}
              func={() => {
                setDistributorSearchKey(null);
                setSearchByDistributor(null);
              }}
              color="lime"
            />
          )}

          {territorySearchKey && (
            <FilterBadgeClearable
              text={`territory: ${territorySearchKey}`}
              func={() => {
                setTerritorySearchKey(null);
                setSearchByTerritory(null);
              }}
              color="violet"
            />
          )}
        </Flex>

        {/* badge filters */}

        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(users) ? (
              <ScrollArea h="65vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Designation</th>
                      <th>Privilege</th>
                      <th>Status</th>
                      <th>Points</th>
                      <th>Regions</th>
                      <th>Areas</th>
                      <th>Distributors</th>
                      <th>Territories</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.email || <Text c="dimmed">N/A</Text>}</td>
                        <td>{user.phone}</td>
                        <td>{user.designation}</td>
                        <td>
                          <Badge
                            sx={{
                              background:
                                userBadgeBackgroundColors[
                                  userRoles[user?.role]
                                ],
                              color: COLORS[user?.role],
                            }}>
                            {userRoles[user?.role]}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            color={user.status === 'ACTIVE' ? 'green' : 'red'}>
                            {user.status}
                          </Badge>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            {isArrayAndHasContent(user.userLocations) ? (
                              <Flex gap={5} align="center" wrap="wrap">
                                {user.userLocations.map((location, index) => (
                                  <HoverCard
                                    key={index}
                                    width={280}
                                    shadow="md">
                                    <HoverCard.Target>
                                      <Badge color="grape">
                                        {location.name}
                                      </Badge>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                      <Text size="md">{location.name}</Text>
                                      <Text size="sm">
                                        {location.district || 'N/A'},{' '}
                                        {location.division || 'N/A'}
                                      </Text>
                                      <Text size="sm">{location.address}</Text>
                                    </HoverCard.Dropdown>
                                  </HoverCard>
                                ))}
                              </Flex>
                            ) : (
                              <Text c="dimmed">No location</Text>
                            )}
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userRegions?.map((region, index) => (
                                <Badge key={index} color="cyan">
                                  {region.name}
                                </Badge>
                              ))}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userAreas?.map((area, index) => (
                                <Badge key={index} color="green">
                                  {area.name}
                                </Badge>
                              ))}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userDistributors?.map(
                                (distributor, index) => (
                                  <Badge key={index} color="yellow">
                                    {distributor.name}
                                  </Badge>
                                ),
                              )}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userTerritories?.map(
                                (territory, index) => (
                                  <Badge key={index}>{territory.name}</Badge>
                                ),
                              )}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Edit user'}>
                              <ActionIcon
                                variant="light"
                                color="teal"
                                disabled={
                                  !canThisUserEditThisUser(appUser, user)
                                }
                                onClick={() => {
                                  setSelectedUser(user);
                                  setAddUserDrawer(true);
                                }}>
                                <IconEdit size={16} />
                              </ActionIcon>
                            </Tooltip>
                            {appUser.userWeight >
                            userWeight['GODOWN MANAGER'] ? (
                              <Stack>
                                {userRoles[user?.role] === 'ADMIN' ||
                                userRoles[user?.role] ===
                                  'SUPER ADMIN' ? null : (
                                  <Tooltip label={'Location'}>
                                    <ActionIcon
                                      variant="light"
                                      color="pink"
                                      onClick={() => {
                                        setSelectedUser(user);
                                        setAssignLocationModal(true);
                                      }}
                                      disabled={
                                        user.status === 'BANNED' ? true : false
                                      }>
                                      <IconMapPin size={16} />
                                    </ActionIcon>
                                  </Tooltip>
                                )}
                              </Stack>
                            ) : null}
                            {appUser.userWeight ===
                            userWeight['SUPER ADMIN'] ? (
                              <Tooltip label={'Details'}>
                                <ActionIcon
                                  variant="light"
                                  color="cyan"
                                  onClick={() => {
                                    setSelectedUser(user);
                                    setOtherDetailsModal(true);
                                  }}>
                                  <IconEye size={16} />
                                </ActionIcon>
                              </Tooltip>
                            ) : null}
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No users found"
                  subtext={' '}
                  icon={<IconUserOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={userPagelessDataFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default UserManagement;
