import React from 'react';
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  HoverCard,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Space,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  Box,
} from '@mantine/core';
import {
  IconArticle,
  IconEdit,
  IconMapPin,
  IconPlus,
  IconRefresh,
  IconUserOff,
  IconX,
  IconBoxOff,
  IconBox,
} from '@tabler/icons-react';
import COLORS from '../../constants/colors';
import SearchInput from '../Global/SearchInput';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchOrders } from '../../services/orders';
import { handleErrorResponse, isArrayAndHasContent } from '../../utils/utils';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';
import { NotificationUtil } from '../../utils/notifications';
import OrderDetailsOtpCard from './Cards/OrderDetailsOtpCard';
import OtpGeneratingCard from './Cards/OtpGeneratingCard';

const GenerateOtp = () => {
  const [searchKey, setSearchKey] = useState('null');
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
  const [disableSearch, setDisableSearch] = useState(false);

  const handleSearch = (value) => {
    if (value) {
      setSearchKey(value.trim());
    } else {
      setSearchKey(null);
    }
  };

  const changeSelectedOrder = (orderDetails) => {
    setSelectedOrderDetails(orderDetails);
    setDisableSearch(true);
  };

  const cancelSelectedOrder = () => {
    setSelectedOrderDetails(null);
    setDisableSearch(false);
  };

  const handleRefresh = () => {
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey(null);
  };

  const {
    data: searchedOrderData,
    isLoading: searchOrderLoading,
    isFetching: isSearchedOrderLoading,
    refetch,
    error,
    isFetched,
  } = useQuery({
    queryKey: ['searching-orders', null, searchKey],
    queryFn: fetchOrders,
    enabled: searchKey ? true : false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  return (
    <ScrollArea.Autosize mah="90vh">
      <Stack
        sx={{
          flex: 1,
        }}>
        {!disableSearch ? (
          <SearchInput
            handleRefresh={handleRefresh}
            handleSearch={handleSearch}
            placeholder="Search Order No."
            invokeRefresh={invokingRefreshForSearchInput}
            refreshBtn={false}
            disable={disableSearch ? true : false}
          />
        ) : (
          <></>
        )}
      </Stack>
      {isSearchedOrderLoading ? (
        <Stack
          sx={{
            minHeight: '50vh',
          }}
          justify="center"
          align="center">
          <Loader size="xl" />
        </Stack>
      ) : (
        <Stack>
          {isArrayAndHasContent(searchedOrderData?.data?.data?.orders) &&
          !disableSearch ? (
            <Flex justify="flex-start" align="flex-start" direction="column">
              {searchedOrderData?.data?.data?.orders.map((order) => {
                return (
                  <OrderDetailsOtpCard
                    key={order?.id}
                    orderNumber={order?.orderNumber}
                    orderStatus={order?.orderStatus}
                    sourceName={order?.sourceInformation?.name}
                    sourceAddress={order?.sourceInformation?.address}
                    destinationName={order?.destinationInformation?.name}
                    destinationAddress={order?.destinationInformation?.address}
                    inventory={order?.inventory}
                    orderDate={order?.orderDate}
                    orderType={order?.orderType}
                    orderDetails={order}
                    changeSelectedOrder={changeSelectedOrder}
                  />
                );
              })}
            </Flex>
          ) : !disableSearch && isFetched ? (
            <div>
              <NoDataPlaceholder
                title="No orders found"
                subtext={' '}
                icon={<IconBoxOff size={70} color="#4562f7" />}
              />
            </div>
          ) : (
            <></>
          )}
        </Stack>
      )}

      <Stack>
        {selectedOrderDetails?.id ? (
          <>
            <OtpGeneratingCard
              key={selectedOrderDetails?.id}
              orderNumber={selectedOrderDetails?.orderNumber}
              orderStatus={selectedOrderDetails?.orderStatus}
              sourceName={selectedOrderDetails?.sourceInformation?.name}
              sourceAddress={selectedOrderDetails?.sourceInformation?.address}
              destinationName={
                selectedOrderDetails?.destinationInformation?.name
              }
              destinationAddress={
                selectedOrderDetails?.destinationInformation?.address
              }
              inventory={selectedOrderDetails?.inventory}
              orderDate={selectedOrderDetails?.orderDate}
              orderType={selectedOrderDetails?.orderType}
              orderDetails={selectedOrderDetails}
              cancelSelectedOrder={cancelSelectedOrder}
            />
          </>
        ) : (
          <></>
        )}
      </Stack>
    </ScrollArea.Autosize>
  );
};

export default GenerateOtp;
