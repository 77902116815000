import { yupResolver } from '@hookform/resolvers/yup';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Input,
  Paper,
  PasswordInput,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { IconAlertTriangle, IconMail } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import COLORS from '../../constants/colors';
import { SignInApi } from '../../services/auth';
import { authActions } from '../../store/reducers/authReducer';
import { NotificationUtil } from '../../utils/notifications';
import { encryptPasswordTemporarily } from '../../utils/utils';
import { SignInSchema } from '../../validators/SignIn';

const SignInButton = (props) => {
  // This component is used for performance, otherwise the whole page will re-render on each keystroke
  // Because of useWatch, which is a stupid design from react-hooks-form
  // Because of this, only this component will re-render on keystroke, not the whole page

  const { control, isSubmitting } = props;

  const formVal = useWatch({ control });

  return (
    <Button
      radius="xs"
      type="submit"
      size="md"
      disabled={
        true
        // isSubmitting || !formVal.email || !formVal.password
        // This is intentional, I do not wish to use isValid here
      }
      sx={{
        backgroundColor: COLORS.primary,
      }}
      loading={isSubmitting}>
      Login
    </Button>
  );
};

const SignIn = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [signInError, setSignInError] = useState(false);
  const [isPasswordResetRequired, setisPasswordResetRequiredfirst] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(SignInSchema),
  });

  const handleSignIn = async (values) => {
    try {
      const response = await SignInApi(values.email, values.password);
      if (response.status === 200) {
        if (response.data.sessionId) {
          NotificationUtil({
            success: true,
            title: 'Login Successful!',
            message: 'Please verify OTP',
          });
          dispatch(
            authActions.setTempData({
              sessionId: response.data.sessionId,
              identifier: values.email,
              ptag: encryptPasswordTemporarily(values.password),
            }),
          );
          navigate('verify-otp');
        } else {
          dispatch(
            authActions.signin({
              user: response.data.user,
              accessToken: response.data.token,
              refreshToken: response.data.refreshToken,
            }),
          );
          navigate('/dashboard');
        }
      }
    } catch (err) {
      setSignInError(true);

      if (err.response.status === 403) {
        if (err.response.data.isPasswordResetRequired) {
          setisPasswordResetRequiredfirst(true);
          setErrorMessage(err.response.data.message);
          navigate('/forgot-password?context=first-time');
        }
        if (err.response.data.hasPasswordExpired) {
          setisPasswordResetRequiredfirst(true);
          setErrorMessage(err.response.data.message);
          navigate('/forgot-password?context=expired');
        }
      }
      if (err.response.status === 401) {
        setisPasswordResetRequiredfirst(true);
        setErrorMessage(err.response.data.message);
      }
    }
  };

  return (
    <Stack
      align="center"
      justify="center"
      sx={{
        height: '100vh',
      }}>
      <Alert
        variant="light"
        color="red"
        title="Attention"
        icon={<IconAlertTriangle />}>
        Armada services have been halted. Please contact your manager for
        further details.
      </Alert>
      <Paper p="lg" radius="xs" shadow="md">
        <form onSubmit={handleSubmit(handleSignIn)}>
          <Stack
            sx={{
              width: 310,
            }}>
            <Stack
              justify="center"
              align="center"
              sx={{
                width: '100%',
              }}>
              <Box
                component="img"
                src="/logo.png"
                sx={{
                  width: '70%',
                  flex: 1,
                }}
              />
            </Stack>
            <Title order={2} align="center">
              Sign In
            </Title>
            <Text align="center">
              BAT Bangladesh <br /> <b>Armada</b>
            </Text>
            {signInError && (
              <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title=""
                color="red">
                {isPasswordResetRequired
                  ? errorMessage
                  : 'Invalid Login Credentials'}
              </Alert>
            )}
            <div>
              <Input
                {...register('email')}
                type="text"
                rightSection={<IconMail />}
                placeholder="Your Email"
                size="md"
              />
              {errors && errors.email && <p>{errors.email.message}</p>}
            </div>
            <div>
              <PasswordInput
                {...register('password')}
                withAsterisk
                placeholder="Your Password"
                size="md"
              />
              {errors && errors.password && <p>{errors.password.message}</p>}
            </div>

            <ActionIcon
              onClick={() => navigate('/forgot-password')}
              variant="transparent"
              color="indigo"
              disabled={true}
              sx={{
                width: 'max-content',
              }}>
              <Text align="left">Forgot Password?</Text>
            </ActionIcon>

            <SignInButton isSubmitting={isSubmitting} control={control} />
            <Text
              align="center"
              component={Link}
              sx={{
                textDecoration: 'underline',
              }}
              to="#"
              // to="/download-app"
            >
              Download App
            </Text>
          </Stack>
        </form>
      </Paper>
    </Stack>
  );
};

export default SignIn;
