import { Card, Flex, Space, Stack, Title } from '@mantine/core';
import React, { useEffect } from 'react';
import {
  IconClipboardCheck,
  IconClipboardData,
  IconClipboardList,
  IconEye,
  IconMailForward,
} from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import {
  Daily_Open_CBC_Scan_Performance_Report,
  EmailReport,
  GODOWN_KEEPER_APP_VERSION_LIST,
  OPEN_CBC_REPORT,
  OneViewReport,
  SO_IGT_Scan_Performance_Report,
} from '../../constants/app-urls';
import COLORS from '../../constants/colors';
import { useSelector } from 'react-redux';
import { userRoles } from '../../constants/const';

const PAGE_TITLE = 'Reports';

const tabs = [
  {
    label: 'SO Scan Performance',
    link: `${SO_IGT_Scan_Performance_Report}/SO`,
    icon: IconClipboardData,
    disabled: false,
    onlyForAdmin: false,
  },
  {
    label: 'IGT Scan Performance',
    link: `${SO_IGT_Scan_Performance_Report}/IGT`,
    icon: IconClipboardData,
    disabled: false,
    onlyForAdmin: false,
  },
  {
    label: 'Daily Open CBC',
    link: Daily_Open_CBC_Scan_Performance_Report,
    icon: IconClipboardCheck,
    disabled: false,
    onlyForAdmin: false,
  },
  {
    label: 'Open CBC',
    link: OPEN_CBC_REPORT,
    icon: IconClipboardList,
    disabled: false,
    onlyForAdmin: false,
  },
  // {
  //   label: 'GK App Version List',
  //   link: GODOWN_KEEPER_APP_VERSION_LIST,
  //   icon: IconClipboardList,
  //   disabled: false,
  //   onlyForAdmin: false,
  // },
  {
    label: 'One View',
    link: OneViewReport,
    icon: IconEye,
    disabled: false,
    onlyForAdmin: false,
  },
  // {
  //   label: 'Email Reports',
  //   link: EmailReport,
  //   icon: IconMailForward,
  //   bgColor: COLORS.tertiary,
  //   textColor: COLORS.white,
  //   disabled: true,
  //   onlyForAdmin: true,
  // },
];

const Reports = () => {
  const appUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    document.title = 'Reports | Armada';
  }, []);

  return (
    <div>
      <Title order={1} size="h2">
        {PAGE_TITLE}
      </Title>

      <Space h="lg" />

      <Flex
        wrap="wrap"
        gap={25}
        sx={{
          padding: '0 15%',
        }}>
        {tabs.map((tab, index) => (
          <Link
            to={tab.disabled ? '#' : tab.link}
            key={index}
            style={{
              textDecoration: 'none',
              flexBasis: '300px',
              flexGrow: 1,
            }}>
            <Card
              key={index}
              shadow="xs"
              padding="xl"
              radius="md"
              withBorder
              sx={{
                height: '150px',
                transition: 'transform 0.3s', // Add a smooth transition
                ':hover': {
                  transform: 'scale(1.1)', // Scale to 1.1 on hover
                  backgroundColor: COLORS.primary,
                  color: COLORS.white,
                },
                color: tab.textColor ? tab.textColor : COLORS.black,
                backgroundColor: tab.bgColor ? tab.bgColor : COLORS.white,
              }}>
              <Flex
                justify="space-between"
                align="center"
                gap={20}
                sx={{
                  height: '100%',
                  position: 'relative',
                }}>
                <tab.icon size="4em" />

                <Stack spacing="xs">
                  <Title order={1} size="h3">
                    {tab.label}
                  </Title>
                </Stack>
              </Flex>
            </Card>
          </Link>
        ))}
        {(appUser?.role === userRoles.ADMIN ||
          appUser?.role === userRoles['SUPER ADMIN']) && (
          <Link
            to={GODOWN_KEEPER_APP_VERSION_LIST}
            style={{
              textDecoration: 'none',
              flexBasis: '300px',
              flexGrow: 1,
            }}>
            <Card
              shadow="xs"
              padding="xl"
              radius="md"
              withBorder
              sx={{
                height: '150px',
                transition: 'transform 0.3s', // Add a smooth transition
                ':hover': {
                  transform: 'scale(1.1)', // Scale to 1.1 on hover
                  backgroundColor: COLORS.primary,
                  color: COLORS.white,
                },
                color: COLORS.black,
                backgroundColor: COLORS.white,
              }}>
              <Flex
                justify="space-between"
                align="center"
                gap={20}
                sx={{
                  height: '100%',
                  position: 'relative',
                }}>
                <IconClipboardList size="4em" />

                <Stack spacing="xs">
                  <Title order={1} size="h3">
                    GK App Version List
                  </Title>
                </Stack>
              </Flex>
            </Card>
          </Link>
        )}
        {(appUser?.role === userRoles.ADMIN ||
          appUser?.role === userRoles['SUPER ADMIN']) && (
          <Link
            to={EmailReport}
            style={{
              textDecoration: 'none',
              flexBasis: '300px',
              flexGrow: 1,
            }}>
            <Card
              shadow="xs"
              padding="xl"
              radius="md"
              withBorder
              sx={{
                height: '150px',
                transition: 'transform 0.3s', // Add a smooth transition
                ':hover': {
                  transform: 'scale(1.1)', // Scale to 1.1 on hover
                  backgroundColor: COLORS.primary,
                  color: COLORS.white,
                },
                color: COLORS.white,
                backgroundColor: COLORS.tertiary,
              }}>
              <Flex
                justify="space-between"
                align="center"
                gap={20}
                sx={{
                  height: '100%',
                  position: 'relative',
                }}>
                <IconMailForward size="4em" />

                <Stack spacing="xs">
                  <Title order={1} size="h3">
                    Email Reports
                  </Title>
                </Stack>
              </Flex>
            </Card>
          </Link>
        )}
      </Flex>
    </div>
  );
};

export default Reports;
