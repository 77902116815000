export const createUniqueLocationName = (data) => {
  return Array.from(new Set(data.map((item) => item.locationName)));
};

export const getCountByStatus = (locationName, status, data) => {
  const matchingItem = data.find(
    (item) => item.locationName === locationName && item.cbcStatus === status,
  );
  return matchingItem ? matchingItem.count : 0;
};

export function convertTimeToFormattedString(time) {
  const date = new Date(time);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const parts = formattedDate.split('/');
  const formattedString = parts[2] + '-' + parts[0] + '-' + parts[1];
  return formattedString;
}

export function getLocationIdList(array) {
  return array.map((item) => item.id);
}

export function formatStatValue(value) {
  if (value >= 1000) {
    let suffix = '';
    let divisor = 1;

    if (value < 1000000) {
      suffix = 'K';
      divisor = 1000;
    } else if (value < 1000000000) {
      suffix = 'M';
      divisor = 1000000;
    } else if (value < 1000000000000) {
      suffix = 'B';
      divisor = 1000000000;
    } else {
      suffix = 'T';
      divisor = 1000000000000;
    }

    return (value / divisor).toFixed(1) + suffix;
  }

  return value.toString();
}

export function formatNumberWithCommas(number) {
  return number.toLocaleString();
}
