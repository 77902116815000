import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Flex,
  NumberInput,
  Select,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation, useQueries } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Districts from '../../constants/64-disctricts';
import COLORS from '../../constants/colors';
import Divisions from '../../constants/division';
import { fetchArea } from '../../services/area';
import { fetchDistributor } from '../../services/distributor';
import {
  createLocation,
  deleteLocation,
  updateLocation,
} from '../../services/location';
import { fetchRegion } from '../../services/region';
import { fetchTerritory } from '../../services/territories';
import { NotificationUtil } from '../../utils/notifications';
import {
  AddLocationSchema,
  UpdateLocationSchema,
} from '../../validators/location';

const LocationForm = ({ defaultValues, update, onUpdate }) => {
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    defaultValues: defaultValues ? defaultValues : {},
    resolver: yupResolver(update ? UpdateLocationSchema : AddLocationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    reset();
  }, []);

  const division = useWatch({ name: 'division', control });
  const regionId = useWatch({ name: 'regionId', control });
  const areaId = useWatch({ name: 'areaId', control });
  const distributerId = useWatch({ name: 'distributerId', control });
  const territoryId = useWatch({ name: 'territoryId', control });
  const baseValue = useWatch({ name: 'baseValue', control });

  const [
    { data: regionPagelessdata },
    { data: areaPagelessData },
    { data: distributorPagelessdata },
    { data: territoryPagelessData },
  ] = useQueries({
    queries: [
      {
        queryKey: ['region-pagelessData', null, null, true],
        queryFn: fetchRegion,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['area-pagelessData', null, null, null, true],
        queryFn: fetchArea,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['distributor-pagelessData', null, null, null, null, true],
        queryFn: fetchDistributor,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: [
          'terriytory-pagelessData',
          null,
          null,
          null,
          null,
          null,
          true,
        ],
        queryFn: fetchTerritory,
        refetchOnWindowFocus: false,
        retry: false,
      },
    ],
  });

  useEffect(() => {
    if (defaultValues?.distributerId && update) {
      setValue('distributerId', parseInt(defaultValues?.distributerId));
    } else {
      reset();
    }
  }, [distributorPagelessdata]);

  const onSubmit = (data) => {
    ConfirmModal(data);
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you {update ? 'update' : 'create'} this location?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        if (update) {
          updateMutate({
            uid: defaultValues.uid,
            id: defaultValues.id,
            ...values,
          });
        } else {
          createMutate(values);
        }
      },
    });
  };

  const ConfirmDeleteModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you inactivate this location?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        deleteMutate(values);
      },
    });
  };

  const { mutate: createMutate, isLoading: isCreating } = useMutation({
    mutationFn: async (values) => await createLocation(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (values) => await updateLocation(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: deleteMutate, isLoading: isDeleting } = useMutation({
    mutationFn: async (uid) => await deleteLocation(uid),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <div
      style={{
        height: '90vh',
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="accounts-input">
          <TextInput
            {...register('name')}
            label="Name"
            placeholder="Enter name"
            error={errors?.name?.message}
          />
        </div>
        <div className="accounts-input">
          <TextInput
            {...register('referenceLabel')}
            label={
              <Flex gap={3}>
                <Text
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  Reference Label
                </Text>
                <Text
                  c="dimmed"
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  (optional)
                </Text>
              </Flex>
            }
            placeholder="Enter reference label"
            error={errors?.referenceLabel?.message}
          />
        </div>
        <div className="accounts-input">
          <Controller
            name="division"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={Divisions.map((item) => ({
                  label: item.name,
                  value: item.name,
                }))}
                label="Division"
                searchable
                placeholder="Select division"
                dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.division && <p>{errors.division.message}</p>}
        </div>
        <div className="accounts-input">
          <Controller
            name="district"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  Districts.filter(
                    (item) =>
                      item.division_id ===
                      Divisions.find((d) => d.name === division)?.id,
                  )?.map((item) => {
                    return {
                      label: item.name,
                      value: item.name,
                    };
                  }) || []
                }
                searchable
                label="District"
                placeholder="Select district"
                dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.district && <p>{errors.district.message}</p>}
        </div>

        <div className="accounts-input">
          <Textarea
            {...register('address')}
            label="Address"
            placeholder="Enter address"
            minRows={3}
            autosize
            error={errors?.address?.message}
          />
        </div>
        <div className="accounts-input">
          <TextInput
            {...register('symphonyReferenceId')}
            label={
              <Flex gap={3}>
                <Text
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  Symphony Reference Id
                </Text>
                <Text
                  c="dimmed"
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  (optional)
                </Text>
              </Flex>
            }
            placeholder="Enter symphony reference id"
            error={errors?.symphonyReferenceId?.message}
          />
        </div>

        <div className="accounts-input">
          <TextInput
            {...register('longitude')}
            label={
              <Flex gap={3}>
                <Text
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  Longitude
                </Text>
                <Text
                  c="dimmed"
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  (optional)
                </Text>
              </Flex>
            }
            placeholder="Enter longitude"
            error={errors?.longitude?.message}
          />
        </div>
        <div className="accounts-input">
          <TextInput
            {...register('latitude')}
            label={
              <Flex gap={3}>
                <Text
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  Latitude
                </Text>
                <Text
                  c="dimmed"
                  sx={{
                    fontSize: '0.875rem',
                    fontWeight: 500,
                  }}>
                  (optional)
                </Text>
              </Flex>
            }
            placeholder="Enter latitude"
            error={errors?.latitude?.message}
          />
        </div>
        {update && (
          <div className="accounts-input">
            <NumberInput
              value={baseValue}
              onChange={(value) => {
                setValue('baseValue', value, {
                  shouldDirty: true,
                });
              }}
              label="Base Value"
              placeholder="Enter Base Value"
              error={errors?.baseValue?.message}
            />
          </div>
        )}
        <div className="accounts-input">
          <Controller
            name="regionId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  regionPagelessdata?.data?.data?.regions.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  }) || []
                }
                onChange={(value) => {
                  field.onChange(value);
                  if (areaId) {
                    setValue('areaId', null, {
                      shouldDirty: true,
                    });
                  }
                  if (distributerId) {
                    setValue('distributerId', null, {
                      shouldDirty: true,
                    });
                  }
                  if (territoryId) {
                    setValue('territoryId', null, {
                      shouldDirty: true,
                    });
                  }
                }}
                searchable
                label={
                  <Flex gap={3}>
                    <Text
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      Region
                    </Text>
                    <Text
                      c="dimmed"
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      (optional)
                    </Text>
                  </Flex>
                }
                placeholder="Select region"
                dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.regionId && <p>{errors?.regionId?.message}</p>}
        </div>
        <div className="accounts-input">
          <Controller
            name="areaId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  areaPagelessData?.data?.data?.areas
                    ?.filter((item) => item?.regionData?.id === regionId)
                    ?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }) || []
                }
                onChange={(value) => {
                  field.onChange(value);

                  if (distributerId) {
                    setValue('distributerId', null, {
                      shouldDirty: true,
                    });
                  }
                  if (territoryId) {
                    setValue('territoryId', null, {
                      shouldDirty: true,
                    });
                  }
                }}
                searchable
                label={
                  <Flex gap={3}>
                    <Text
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      Area
                    </Text>
                    <Text
                      c="dimmed"
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      (optional)
                    </Text>
                  </Flex>
                }
                placeholder="Select area"
                // dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.areaId && <p>{errors?.areaId?.message}</p>}
        </div>
        <div className="accounts-input">
          <Controller
            name="distributerId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  distributorPagelessdata?.data?.data?.distributors
                    .filter((item) => item?.areaData?.id === areaId)
                    ?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }) || []
                }
                onChange={(value) => {
                  field.onChange(value);
                  if (territoryId) {
                    setValue('territoryId', null, {
                      shouldDirty: true,
                    });
                  }
                }}
                searchable
                label={
                  <Flex gap={3}>
                    <Text
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      Distributor
                    </Text>
                    <Text
                      c="dimmed"
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      (optional)
                    </Text>
                  </Flex>
                }
                placeholder="Select distributor"
                // dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.distributorId && (
            <p>{errors?.distributorId?.message}</p>
          )}
        </div>
        <div className="accounts-input">
          <Controller
            name="territoryId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  territoryPagelessData?.data?.data?.territories
                    ?.filter(
                      (item) => item?.distributorData?.id === distributerId,
                    )
                    ?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id,
                      };
                    }) || []
                }
                searchable
                label={
                  <Flex gap={3}>
                    <Text
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      Territory
                    </Text>
                    <Text
                      c="dimmed"
                      sx={{
                        fontSize: '0.875rem',
                        fontWeight: 500,
                      }}>
                      (optional)
                    </Text>
                  </Flex>
                }
                placeholder="Select territory"
                // dropdownPosition="bottom"
              />
            )}
          />
          {errors && errors.territoryId && (
            <p>{errors?.territoryId?.message}</p>
          )}
        </div>
        <Flex gap={15} py={8} mt={10}>
          {update && (
            <Button
              disabled={isSubmitting}
              sx={{
                flex: 1,
              }}
              onClick={() => {
                if (!defaultValues.isActive) {
                  ConfirmModal({
                    id: defaultValues.id,
                    uid: defaultValues.uid,
                    isActive: true,
                  });
                } else {
                  ConfirmDeleteModal(defaultValues.uid);
                }
              }}
              color={defaultValues.isActive ? 'red' : 'green'}>
              {defaultValues.isActive ? 'Deactivate' : 'Activate'}
            </Button>
          )}
          <Button
            type="submit"
            loading={isSubmitting || isCreating || isUpdating || isDeleting}
            disabled={isSubmitting || !isValid || !isDirty}
            sx={{
              flex: 1,
              backgroundColor: COLORS.primary,
            }}>
            {update ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default LocationForm;
