import React from 'react';
import env from '../constants/env';
import IdleWrapper from './IdleWrapper';
import QueryWrapper from './QueryWrapper';
import ReduxWrapper from './ReduxWrapper';
import RouteWrapper from './RouteWrapper';
import ThemeWrapper from './ThemeWrapper';
import { SocketProvider } from '../context/SocketContext';

const App = () => {
  return (
    <ReduxWrapper>
      <QueryWrapper>
        <ThemeWrapper>
          <IdleWrapper>
            <SocketProvider>
              <RouteWrapper />
            </SocketProvider>
          </IdleWrapper>
        </ThemeWrapper>
      </QueryWrapper>
    </ReduxWrapper>
  );
};

export default App;

export const appEnv = env();
