import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { AddMobileApplicationSchema } from '../../validators/mobileApplicationAdd';
import {
  Button,
  Flex,
  Group,
  Select,
  Space,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea,
  rem,
} from '@mantine/core';
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {
  IconDeviceMobileUp,
  IconPhoto,
  IconUpload,
  IconX,
} from '@tabler/icons-react';
import COLORS from '../../constants/colors';
import { openConfirmModal } from '@mantine/modals';
import { uploadApk } from '../../services/app-dist';
import { NotificationUtil } from '../../utils/notifications';
import { useMutation } from '@tanstack/react-query';

const UploadApplication = ({ onUpdate }) => {
  const [apkFile, setApkFile] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(AddMobileApplicationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    reset();
  }, []);

  const isLatest = useWatch({
    control,
    name: 'isLatest',
    defaultValue: false,
  });

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('apk', apkFile, apkFile.name);
    formData.append('buildName', data.buildName);
    formData.append('buildNumber', data.buildNumber);
    formData.append('variant', process.env.REACT_APP_ENVIRONMENT);
    formData.append('isLatest', data.isLatest);
    formData.append('changelogs', data.changelogs);

    ConfirmDeleteModal(formData);
  };

  const ConfirmDeleteModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to upload this version?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        uploadMutate(values);
      },
    });
  };

  const { mutate: uploadMutate, isLoading: isUploading } = useMutation({
    mutationFn: async (values) => await uploadApk(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="accounts-input">
          <TextInput
            {...register('buildName')}
            label="Build Name"
            placeholder="Enter build name"
            error={errors?.buildName?.message}
          />
        </div>
        <div className="accounts-input">
          <TextInput
            {...register('buildNumber')}
            label="Build Number"
            placeholder="Enter build number"
            error={errors?.buildNumber?.message}
          />
        </div>
        {/* <div className="accounts-input">
          <Controller
            name="variant"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={[
                  { value: 'stage', label: 'Stage' },
                  { value: 'prod', label: 'Production' },
                  { value: 'dev', label: 'Development' },
                ]}
                label="Variant"
                placeholder="Select variant"
                error={errors?.variant?.message}
              />
            )}
          />
        </div> */}
        <div className="accounts-input">
          <Textarea
            {...register('changelogs')}
            label="Change logs"
            placeholder="Enter change logs"
            error={errors?.changelogs?.message}
          />
        </div>
        <div className="accounts-input">
          <Flex justify="space-between" py={10}>
            <Text>Latest?</Text>
            <Switch
              {...register('isLatest')}
              labelPosition="left"
              label={isLatest ? 'Yes' : 'No'}
              defaultChecked={isLatest}
            />
          </Flex>
        </div>
        <Space h="md" />
        <Dropzone
          onDrop={(files) => {
            if (files[0].type !== 'application/vnd.android.package-archive') {
              NotificationUtil({
                success: false,
                title: 'Error',
                message: 'Invalid file type',
              });
              setApkFile(null);
              return;
            }
            setApkFile(files[0]);
          }}
          onReject={(files) => {
            NotificationUtil({
              success: false,
              title: 'Error',
              message: 'Invalid file type',
            });
            setApkFile(null);
          }}
          accept="[application/vnd.android.package-archive]">
          <Group
            position="center"
            spacing="xs"
            style={{ minHeight: rem(220), pointerEvents: 'none' }}>
            <Dropzone.Accept>
              <IconUpload size="3.2rem" stroke={1.5} color={'dark'} />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size="3.2rem" stroke={1.5} color={'dark'} />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconDeviceMobileUp size="3.2rem" stroke={1.5} />
            </Dropzone.Idle>

            <div>
              {apkFile ? (
                <Text size="xl" inline>
                  File selected: {apkFile.name}
                </Text>
              ) : (
                <Text size="xl" inline>
                  Drag APK here or click to select file
                </Text>
              )}
            </div>
          </Group>
        </Dropzone>
        {apkFile && (
          <Stack justify="center" align="center" mt={5}>
            <Button
              compact
              onClick={() => {
                setApkFile(null);
              }}>
              clear selection
            </Button>
          </Stack>
        )}
        <Space h="md" />
        <Stack>
          <Button
            type="submit"
            loading={isUploading}
            sx={{
              backgroundColor: COLORS.primary,
            }}>
            Upload
          </Button>
        </Stack>
      </form>
    </div>
  );
};

export default UploadApplication;
