import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Group,
  HoverCard,
  PasswordInput,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import { ForgotPasswordInputSchema } from '../../validators/ForgotPassword';
import { IconInfoCircle } from '@tabler/icons-react';
import PasswordHint from '../Global/PasswordHint';
import { resetPassword } from '../../services/auth';
import { NotificationUtil } from '../../utils/notifications';
import { authActions } from '../../store/reducers/authReducer';

const SubmitButton = (props) => {
  // This component is used for performance, otherwise the whole page will re-render on each keystroke
  // Because of useWatch, which is a stupid design from react-hooks-form
  // Because of this, only this component will re-render on keystroke, not the whole page

  const { control, isSubmitting } = props;

  const formVal = useWatch({ control });

  return (
    <Button
      radius="xs"
      type="submit"
      size="md"
      sx={{
        backgroundColor: COLORS.primary,
      }}
      disabled={
        isSubmitting || !formVal.newPassword || !formVal.confirmNewPassword
        // This is intentional, I do not wish to use isValid here
      }
      loading={isSubmitting}>
      Submit
    </Button>
  );
};

const PasswordInputComponent = ({ onSuccess, otpHasGoneBack, otpValue }) => {
  const tempData = useSelector((state) => state.auth.tempData);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(ForgotPasswordInputSchema(tempData?.role)),
  });

  const password = useWatch({ control, name: 'newPassword' });

  const handlePasswordInput = async (values) => {
    try {
      const response = await resetPassword(
        tempData?.sessionId,
        otpValue,
        values.newPassword,
      );

      if (response.status === 200) {
        NotificationUtil({
          success: true,
          title: 'Success',
          message: 'Password reset successfully',
        });
        onSuccess(values.newPassword);
      }
    } catch (error) {
      //do nothing
    }
  };
  return (
    <form onSubmit={handleSubmit(handlePasswordInput)}>
      <Stack
        sx={{
          width: 310,
        }}>
        <Text fz={22} align="center">
          Reset your password
        </Text>
        <div>
          <PasswordInput
            {...register('newPassword')}
            placeholder="Your New Password"
            size="md"
            rightSection={
              <Group position="center">
                <HoverCard width={280} shadow="md" position="bottom">
                  <HoverCard.Target>
                    <Stack justify="center" align="center">
                      <IconInfoCircle color="gray" />
                    </Stack>
                  </HoverCard.Target>
                  <HoverCard.Dropdown>
                    <PasswordHint role={tempData?.role} password={password} />
                  </HoverCard.Dropdown>
                </HoverCard>
              </Group>
            }
          />
          {errors && errors.newPassword && (
            <p
              style={{
                fontSize: 12,
                color: 'red',
              }}>
              {errors.newPassword.message}
            </p>
          )}
        </div>
        <div>
          <PasswordInput
            {...register('confirmNewPassword')}
            placeholder="Confirm New Password"
            size="md"
          />
          {errors && errors.confirmNewPassword && (
            <p
              style={{
                fontSize: 12,
                color: 'red',
              }}>
              {errors.confirmNewPassword.message}
            </p>
          )}
        </div>

        <SubmitButton isSubmitting={isSubmitting} control={control} />
      </Stack>
    </form>
  );
};

export default PasswordInputComponent;
