import { Center, List, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import React from 'react';
import { userRoles } from '../../constants/const';

const PasswordHint = ({ password, role }) => {
  return (
    <div>
      <Text fz="xs">Your password should contain</Text>
      {userRoles[role] === userRoles.OPERATOR ? (
        <List size="xs">
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password
                      ? /[0-9]/.test(password)
                        ? 'green'
                        : 'red'
                      : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    /[0-9]/.test(password) ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            all digits
          </List.Item>
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password ? (password.length >= 6 ? 'green' : 'red') : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    password.length >= 6 ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            6 characters long
          </List.Item>
        </List>
      ) : (
        <List size="xs">
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password
                      ? /[a-z]/.test(password)
                        ? 'green'
                        : 'red'
                      : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    /[a-z]/.test(password) ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            atleast one lowercase alphabet{' '}
          </List.Item>
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password
                      ? /[A-Z]/.test(password)
                        ? 'green'
                        : 'red'
                      : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    /[A-Z]/.test(password) ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            atleast one uppercase alphabet
          </List.Item>
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password
                      ? /[0-9]/.test(password)
                        ? 'green'
                        : 'red'
                      : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    /[0-9]/.test(password) ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            atleast one digit
          </List.Item>
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password
                      ? /[^\da-zA-Z]/.test(password)
                        ? 'green'
                        : 'red'
                      : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    /[^\da-zA-Z]/.test(password) ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            atleast one special character
          </List.Item>
          <List.Item
            icon={
              <Center>
                <ThemeIcon
                  color={
                    password
                      ? password.length >=
                        (role === userRoles['SUPER ADMIN'] ||
                        role === userRoles.ADMIN
                          ? 16
                          : 12)
                        ? 'green'
                        : 'red'
                      : 'red'
                  }
                  size={15}
                  radius="xl">
                  {password ? (
                    password.length >=
                    (role === userRoles['SUPER ADMIN'] ||
                    role === userRoles.ADMIN
                      ? 16
                      : 12) ? (
                      <IconCheck />
                    ) : (
                      <IconX />
                    )
                  ) : (
                    <IconX />
                  )}
                </ThemeIcon>
              </Center>
            }>
            {role === userRoles['SUPER ADMIN'] || role === userRoles.ADMIN
              ? '16 characters long'
              : '12 characters long'}
          </List.Item>
        </List>
      )}
    </div>
  );
};

export default PasswordHint;
