import { Card, Flex, Space, Stack, Title } from '@mantine/core';
import {
  IconArticle,
  IconBug,
  IconDeviceMobileDown,
  IconMapPin,
  IconPackages,
  IconUsers,
} from '@tabler/icons-react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as urls from '../../constants/app-urls';
import COLORS from '../../constants/colors';

const PAGE_TITLE = 'Settings';

const tabs = [
  {
    label: 'Users',
    icon: IconUsers,
    link: urls.USERS,
    disabled: false,
  },
  {
    label: 'Points',
    icon: IconMapPin,
    link: urls.LOCATIONS,
    disabled: false,
  },
  {
    label: 'Products',
    icon: IconPackages,
    link: urls.PRODUCTS_ALL,
    disabled: false,
  },
  {
    label: 'Logs',
    icon: IconArticle,
    link: urls.LOGS,
    disabled: false,
  },

  {
    label: 'Error Logs',
    icon: IconBug,
    link: urls.ERROR_LOGS,
    disabled: false,
  },
  {
    label: 'App Distributions',
    icon: IconDeviceMobileDown,
    link: urls.MOBILE_APPLICATIONS,
    disabled: false,
  },
];

const Settings = () => {
  useEffect(() => {
    document.title = 'Settings | Armada';
  }, []);
  return (
    <div>
      <Title order={1} size="h2">
        {PAGE_TITLE}
      </Title>
      <Space h="lg" />

      <Flex
        wrap="wrap"
        gap={25}
        sx={{
          padding: '0 15%',
        }}>
        {tabs.map((tab, index) => {
          return (
            <Link
              to={tab.disabled ? '#' : tab.link}
              key={index}
              style={{
                textDecoration: 'none',
                flexBasis: '300px',
                flexGrow: 1,
              }}>
              <Card
                key={index}
                shadow="xs"
                padding="xl"
                radius="md"
                withBorder
                sx={{
                  height: '150px',
                  transition: 'transform 0.3s', // Add a smooth transition
                  backgroundColor: tab.disabled ? COLORS.gray : COLORS.white,
                  ':hover': {
                    transform: 'scale(1.1)', // Scale to 1.1 on hover
                    backgroundColor: tab.disabled
                      ? COLORS.gray
                      : COLORS.primary,
                    color: tab.disabled ? COLORS.black : COLORS.white,
                  },
                }}>
                <Flex
                  justify="space-between"
                  align="center"
                  gap={20}
                  sx={{
                    height: '100%',
                  }}>
                  <tab.icon size="4em" />
                  <Stack spacing="xs">
                    <Title order={1} size="h2">
                      {tab.label}
                    </Title>
                  </Stack>
                </Flex>
              </Card>
            </Link>
          );
        })}
      </Flex>
    </div>
  );
};

export default Settings;
