import {
  Accordion,
  Badge,
  Box,
  Flex,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  Timeline,
  TypographyStylesProvider,
} from '@mantine/core';
import { IconMoodSadSquint } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import {
  actionLoggerActionsBadgeColor,
  actionLoggerIcons,
  actionLoggerIconsGradients,
} from '../../constants/const';
import {
  fetchActionLogs,
  fetchSingleOrderLogs,
} from '../../services/actionLogs';
import { handleErrorResponse, isArrayAndHasContent } from '../../utils/utils';

const LogsDrawer = ({ referenceType, singleOrderFetch, uid }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['actionLogs', 1, null, referenceType, null, null, null, true],
    enabled: !singleOrderFetch,
    queryFn: fetchActionLogs,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const {
    data: singleOrderlogs,
    isLoading: singleOrderlogsLoading,
    error: singleOrderlogsError,
  } = useQuery({
    queryKey: ['order-details-logs', uid],
    enabled: !!uid,
    queryFn: fetchSingleOrderLogs,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  if (singleOrderFetch ? singleOrderlogsLoading : isLoading) {
    return (
      <Stack justify="center" align="center" sx={{ height: '100vh' }}>
        <LoadingOverlay
          visible={true}
          loader={
            <Stack justify="center" align="center">
              <Text>Fetching Logs. Please wait.</Text>
              <Loader />
            </Stack>
          }
        />
      </Stack>
    );
  }

  if (singleOrderFetch ? singleOrderlogsError : error) {
    return (
      <Stack justify="center" align="center" sx={{ height: '80vh' }}>
        <IconMoodSadSquint size={70} />
        <Text align="center">Something went wrong.</Text>
        <Text aling="center">Please refresh the page or try again later.</Text>
      </Stack>
    );
  }

  const { actionLogs } = singleOrderFetch
    ? singleOrderlogs.data.data
    : data.data.data;
  return (
    <div>
      <ScrollArea.Autosize mah="90vh">
        <Stack gap={10}>
          {isArrayAndHasContent(actionLogs) ? (
            <Timeline
              active={actionLogs?.length - 1}
              bulletSize={24}
              lineWidth={1}
              color="gray">
              {actionLogs?.map((d, i) => (
                <Timeline.Item
                  key={i}
                  title={
                    <Accordion variant="filled" radius="xs">
                      <Accordion.Item value="customization">
                        <Accordion.Control>
                          <Flex justify="space-between" align="center">
                            <Box>
                              <Text fw={600} size="0.9rem">
                                {d?.user?.name || 'Symphony'}
                              </Text>
                              <Text size="0.8rem" fw={600}>
                                {d?.remarks}
                              </Text>
                            </Box>
                            <Flex direction="column">
                              <Text
                                color="dimmed"
                                size="xs"
                                style={{ marginBottom: '0.2rem' }}>
                                {dayjs(d?.createdAt).format(
                                  'DD MMM, YYYY, hh:mm A',
                                )}
                              </Text>
                              <Badge
                                color={actionLoggerActionsBadgeColor[d.action]}>
                                {d?.action}
                              </Badge>
                            </Flex>
                          </Flex>
                        </Accordion.Control>

                        {/* <Space h="xs" /> */}
                        <Accordion.Panel>
                          <Stack
                            sx={{
                              backgroundColor: 'transparent',
                              padding: '0.5em',
                              borderRadius: '8px',
                            }}>
                            <TypographyStylesProvider>
                              <div
                                style={{
                                  fontSize: 'small',
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: d?.actionDescription,
                                }}
                              />
                            </TypographyStylesProvider>
                          </Stack>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </Accordion>
                    // <Badge color={actionLoggerActionsBadgeColor[d.action]}>
                    //   {d?.action}
                    // </Badge>
                  }
                  bulletSize={24}
                  bullet={
                    <ThemeIcon
                      size={22}
                      variant="gradient"
                      gradient={actionLoggerIconsGradients[d.action]}
                      radius="xl">
                      {actionLoggerIcons[d.action]}
                    </ThemeIcon>
                  }></Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <Text align="center">No logs found</Text>
          )}
        </Stack>
      </ScrollArea.Autosize>
    </div>
  );
};

export default LogsDrawer;
