import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  Loader,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { IconEdit, IconPlus, IconRefresh, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useState } from 'react';
import COLORS from '../../../constants/colors';
import { fetchTerritory } from '../../../services/territories';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../../utils/utils';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import SearchInput from '../../Global/SearchInput';
import TerritoriesForm from '../../Territories/TerritoriesForm';

const TerritoriesTab = ({
  regionPagelessdata,
  areaPagelessData,
  distributorPagelessdata,
}) => {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);

  const [searchByRegion, setSearchByRegion] = useState(null);
  const [regionSearchKey, setRegionSearchKey] = useState(null);
  const [searchByArea, setSearchByArea] = useState(null);
  const [areaSearchKey, setAreaSearchKey] = useState(null);
  const [searchByDistributor, setSearchByDistributor] = useState(null);
  const [distributorSearchKey, setDistributorSearchKey] = useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [addTerritoryDrawer, setAddTerritoryDrawer] = useState(false);

  const [selectedTerritory, setSelectedTerritory] = useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'distributor',
      page,
      searchKey,
      searchByRegion,
      searchByArea,
      searchByDistributor,
      null,
    ],
    queryFn: fetchTerritory,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const {
    isFetching: territoryPagelessFetching,
    refetch: territoryPagelessRefetch,
  } = useQuery({
    queryKey: [
      'territory-pageless',
      page,
      searchKey,
      searchByRegion,
      searchByArea,
      searchByDistributor,
      true,
    ],
    queryFn: fetchTerritory,
    refetchOnWindowFocus: false,
    enabled: exportToggler,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `TERRITORIES-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = response.data.data.territories.map((territory) => {
        return {
          Name: territory.name,
          Status: territory.isActive ? 'Active' : 'Inactive',
          Region: territory.regionData?.name,
          Area: territory.areaData?.name,
          Distributor: territory.distributorData?.name,
          CreatedAt: dayjs(territory.createdAt).format('MMM DD, YYYY'),
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      territoryPagelessRefetch();
    } else {
      territoryPagelessRefetch();
    }
  };

  const { territories, total } = data.data.data;

  return (
    <div>
      {' '}
      <Drawer
        opened={addTerritoryDrawer}
        onClose={() => {
          setSelectedTerritory(null);
          setAddTerritoryDrawer(false);
        }}
        title={
          isObjectAndHasProperties(selectedTerritory)
            ? 'Edit Distributor'
            : 'Add Distributor'
        }
        position="right">
        <TerritoriesForm
          update={isObjectAndHasProperties(selectedTerritory)}
          defaultValues={selectedTerritory}
          onUpdate={() => {
            refetch();
            setAddTerritoryDrawer(false);
          }}
          regionPagelessdata={regionPagelessdata}
          areaPagelessData={areaPagelessData}
          distributorPagelessdata={distributorPagelessdata}
        />
      </Drawer>
      {/* CUSTOM MODAL AND DRAWER END */}
      <Stack>
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={handleRefresh}
                handleSearch={handleSearch}
                placeholder="Search territory"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>
            <Autocomplete
              placeholder="Search by region"
              onChange={(value) => {
                if (regionSearchKey && regionSearchKey.length === 0) {
                  setRegionSearchKey(null);
                } else {
                  setRegionSearchKey(value);
                }
              }}
              limit={regionPagelessdata?.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={regionSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByRegion(e.id);
              }}
              rightSection={
                searchByRegion ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setRegionSearchKey(null);
                      setSearchByRegion(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                regionPagelessdata?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
            />
            <Autocomplete
              placeholder="Search by area"
              onChange={(value) => {
                if (areaSearchKey && areaSearchKey.length === 0) {
                  setAreaSearchKey(null);
                } else {
                  setAreaSearchKey(value);
                }
              }}
              limit={areaPagelessData?.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={areaSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByArea(e.id);
              }}
              rightSection={
                searchByArea ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setAreaSearchKey(null);
                      setSearchByArea(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                areaPagelessData?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
            />

            <Autocomplete
              placeholder="Search by distributor"
              onChange={(value) => {
                if (distributorSearchKey && distributorSearchKey.length === 0) {
                  setDistributorSearchKey(null);
                } else {
                  setDistributorSearchKey(value);
                }
              }}
              limit={distributorPagelessdata?.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={distributorSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByDistributor(e.id);
              }}
              rightSection={
                searchByDistributor ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setDistributorSearchKey(null);
                      setSearchByDistributor(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                distributorPagelessdata?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
            />

            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                    setSearchByArea(null);
                    setSearchByRegion(null);
                    setRegionSearchKey(null);
                    setAreaSearchKey(null);
                    setSearchByDistributor(null);
                    setDistributorSearchKey(null);
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <div>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => setAddTerritoryDrawer(true)}
              leftIcon={<IconPlus />}>
              Add Territory
            </Button>
          </div>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(territories) ? (
              <ScrollArea h="60vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Name</th>
                      <th>Region</th>
                      <th>Area</th>
                      <th>Distributor</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {territories.map((territory, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            maxWidth: '120px',
                          }}>
                          {territory?.name}
                        </td>
                        <td>{territory?.regionData?.name}</td>
                        <td>{territory?.areaData?.name}</td>
                        <td>{territory?.distributorData?.name}</td>

                        <td>
                          <Badge color={territory?.isActive ? 'green' : 'red'}>
                            {territory?.isActive ? 'Active' : 'Inactive'}
                          </Badge>
                        </td>
                        <td>
                          {dayjs(territory?.createdAt).format('MMM DD, YYYY')}
                        </td>
                        <td>{territory?.createdByUser?.name}</td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Edit location'}>
                              <ActionIcon
                                variant="light"
                                color="teal"
                                onClick={() => {
                                  setSelectedTerritory(territory);
                                  setAddTerritoryDrawer(true);
                                }}>
                                <IconEdit size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No territories found"
                  subtext={' '}
                  //   icon={<IconMapPinOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={territoryPagelessFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default TerritoriesTab;
