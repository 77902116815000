import * as yup from 'yup';

const formSchema = {
  product: yup.object().required('Product is required'),
  cbcQuantity: yup
    .number()
    .min(1, 'CBC quantity must be atleast 1')
    .typeError('CBC quantity must be number')
    .required('CBC Quantity is required'),
};

export const AddIgtOrderSchema = yup.object().shape({
  source: yup.object().required('Source is required'),
  destination: yup.object().required('Destination is required'),
  orderDate: yup.string().required('Order Date is required'),
  orderProducts: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Please add product')
    .min(1, 'Please add atleast 1 product'),
});

export const UpdateIgtOrderSchema = yup.object().shape({
  source: yup.object().required('Source is required'),
  destination: yup.object().required('Destination is required'),
  orderDate: yup.string().required('Order Date is required'),
  orderProducts: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Must have fields')
    .min(1, 'Minimum of 1 field'),
});

export const UpdateTruckNumberSchema = yup.object().shape({
  truckNumber: yup.string().required('Truck Number is required'),
});
