import {
  Button,
  Drawer,
  Flex,
  Paper,
  Tabs,
  Title,
  Box,
  Tooltip,
} from '@mantine/core';
import { IconClipboardData } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import InventoryTab from '../../components/Inventory/Tabs/InventoryTab';
import ReportsDrawer from '../../components/Inventory/Tabs/ReportsDrawer';
import StockMonitoringTab from '../../components/Inventory/Tabs/StockMonitoringTab';
import COLORS from '../../constants/colors';
import { Link, useNavigate } from 'react-router-dom';
import { BLOCKCBC, DAY_ZERO_RECONCILIATION } from '../../constants/app-urls';
import OpenedCBC from '../../components/Inventory/Tabs/OpenedCBC';
import BlockedCbcTab from '../../components/Inventory/Tabs/BlockedCbcTab';
import AllCbcTab from '../../components/Inventory/Tabs/AllCbcTab';
import { useQuery } from '@tanstack/react-query';
import { fetchLocations } from '../../services/location';
import { handleErrorResponse } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { TabActions } from '../../store/reducers/tabsReducer';
import { IconHammer } from '@tabler/icons-react';

const PAGE_TITLE = 'Inventory';

const InventoryManagement = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Inventory | Armada';
  }, []);

  const inventoryTabReduxState = useSelector(
    (state) => state.tabs.inventoryTab,
  );

  const [activeTab, setActiveTab] = useState(
    inventoryTabReduxState || 'inventory',
  );
  const [reportsDrawer, setReportsDrawer] = useState(false);
  const [tabValues, setTabValues] = useState({});

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: ['location', 1, null, null, null, true, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const tabList = [
    {
      label: 'Global Inventory',
      value: 'inventory',
      element: (
        <InventoryTab
          locationData={locationData?.data?.data?.locations}
          currentTab={activeTab}
          requiredTab="inventory"
        />
      ),
    },
    {
      label: 'Stock Monitoring',
      value: 'stockMonitoring',
      element: (
        <StockMonitoringTab
          locationData={locationData?.data?.data?.locations}
          currentTab={activeTab}
          requiredTab="stockMonitoring"
        />
      ),
    },
    {
      label: 'Intact CBCs',
      value: 'allCbcs',
      element: (
        <AllCbcTab
          locationData={locationData?.data?.data?.locations}
          currentTab={activeTab}
          requiredTab="allCbcs"
        />
      ),
    },
    {
      label: 'Opened CBCs',
      value: 'openedCbcs',
      element: (
        <OpenedCBC
          locationData={locationData?.data?.data?.locations}
          currentTab={activeTab}
          requiredTab="openedCbcs"
        />
      ),
    },
    {
      label: 'Blocked CBCs',
      value: 'blockedCbcs',
      element: (
        <BlockedCbcTab
          locationData={locationData?.data?.data?.locations}
          currentTab={activeTab}
          requiredTab="blockedCbcs"
        />
      ),
    },
  ];

  useEffect(() => {
    let tempData = {};
    tabList.forEach((tab) => {
      tempData[tab.value] = tab.value;
    });
    setTabValues(tempData);
  }, []);

  const handleTabChange = (value) => {
    setActiveTab(value);
    dispatch(
      TabActions.setInventoryTab({
        inventoryTab: value,
      }),
    );
  };

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={reportsDrawer}
        onClose={() => {
          setReportsDrawer(false);
        }}
        title="Generate Reports"
        position="right">
        <ReportsDrawer />
      </Drawer>
      {/* CUSTOM MODAL AND DRAWER END */}
      <Flex align="center" justify="space-between">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Flex gap={5}>
          <Button
            onClick={() => navigate(DAY_ZERO_RECONCILIATION)}
            leftIcon={<IconHammer />}
            sx={{ backgroundColor: COLORS.primary }}>
            Inventory Reconciliation
          </Button>
          <Button
            onClick={() => navigate(BLOCKCBC)}
            leftIcon={<IconClipboardData />}
            sx={{ backgroundColor: COLORS.primary }}>
            Manage CBCs
          </Button>
          {/* <Button
            onClick={() => {
              setReportsDrawer(true);
            }}
            leftIcon={<IconClipboardData />}
            color="pink">
            Reports
          </Button> */}
        </Flex>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Tabs
          variant="outline"
          defaultValue="gallery"
          value={activeTab}
          onTabChange={handleTabChange}>
          <Tabs.List>
            {tabList.map((tab) => (
              <Tabs.Tab
                value={tab.value}
                key={tab.value}
                sx={{
                  backgroundColor:
                    activeTab === tabValues[tab.value]
                      ? COLORS.primary
                      : COLORS.white,
                  color:
                    activeTab === tabValues[tab.value]
                      ? COLORS.white
                      : COLORS.black,
                }}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {tabList.map((tab) => (
            <Tabs.Panel value={tab.value} key={tab.value} p="xs">
              {tab.element}
            </Tabs.Panel>
          ))}
        </Tabs>
      </Paper>
    </div>
  );
};

export default InventoryManagement;
