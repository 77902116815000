import axios from './axios';

export const fetchErrorLogs = async (context) => {
  const response = await axios().get('/error-logs', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      pageLess: context.queryKey[3],
      startDate: context.queryKey[4],
      endDate: context.queryKey[5],
    },
  });
  return response;
};
