import axios from './axios';

export const fetchActionLogs = async (context) => {
  const response = await axios().get('/action-logs', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      referenceType: context.queryKey[3],
      action: context.queryKey[4],
      startDate: context.queryKey[5],
      endDate: context.queryKey[6],
      pageLess: context.queryKey[7],
    },
  });
  return response;
};

export const fetchSingleOrderLogs = async (context) => {
  const response = await axios().get(
    `/action-logs/single-order/${context.queryKey[1]}`,
  );
  return response;
};
