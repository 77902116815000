import { yupResolver } from '@hookform/resolvers/yup';
import {
  ActionIcon,
  Autocomplete,
  Avatar,
  Badge,
  Button,
  Collapse,
  createStyles,
  Divider,
  Flex,
  Grid,
  Menu,
  NumberInput,
  rem,
  ScrollArea,
  Space,
  Stack,
  Text,
  TextInput,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { openConfirmModal } from '@mantine/modals';
import { IconEqual, IconTrashX, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import { userRoles } from '../../constants/const';
import { fetchLocations } from '../../services/location';
import { createIGTOrders, updateIGTOrder } from '../../services/orders';
import { fetchProductsbyLocation } from '../../services/product';
import { NotificationUtil } from '../../utils/notifications';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../utils/utils';
import {
  AddIgtOrderSchema,
  UpdateIgtOrderSchema,
} from '../../validators/order';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';

const useStyles = createStyles((theme, { floating }) => ({
  root: {
    position: 'relative',
  },

  label: {
    position: 'absolute',
    zIndex: 2,
    top: rem(7),
    left: theme.spacing.sm,
    pointerEvents: 'none',
    color: floating
      ? theme.colorScheme === 'dark'
        ? theme.white
        : theme.black
      : theme.colorScheme === 'dark'
      ? theme.colors.dark[3]
      : theme.colors.gray[5],
    transition: 'transform 150ms ease, color 150ms ease, font-size 150ms ease',
    transform: floating
      ? `translate(-${theme.spacing.sm}, ${rem(-28)})`
      : 'none',
    fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
    fontWeight: floating ? 500 : 400,
  },

  required: {
    transition: 'opacity 150ms ease',
    opacity: floating ? 1 : 0,
  },

  input: {
    '&::placeholder': {
      transition: 'color 150ms ease',
      color: !floating ? 'transparent' : undefined,
    },
  },
}));

const IGTOrderForm = ({
  update,
  defaultValues,
  onUpdate,
  regionPagelessdata,
  areaPagelessData,
  distributorPagelessdata,
  territoryPagelessData,
}) => {
  const appUser = useSelector((state) => state.auth.user);

  const queryClient = useQueryClient();

  const data = queryClient.getQueryData([
    'assigned-products-of-order',
    defaultValues?.orderNumber,
  ]);

  const cachedOrderedProductsData = data?.data?.data?.inventory;

  const [SourceLocationSearchKey, setSourceLocationSearchKey] = useState(null);
  const [destinationLocationSearchKey, setDestinationLocationSearchKey] =
    useState(null);
  const [productSearchKey, setProductSearchKey] = useState([]);

  const [filterOpen, setFilterOpen] = useState(false);

  const [searchByRegion, setSearchByRegion] = useState(null);
  const [regionSearchKey, setRegionSearchKey] = useState(null);
  const [searchByArea, setSearchByArea] = useState(null);
  const [areaSearchKey, setAreaSearchKey] = useState(null);
  const [searchByDistributor, setSearchByDistributor] = useState(null);
  const [distributorSearchKey, setDistributorSearchKey] = useState(null);
  const [searchByTerritory, setSearchByTerritory] = useState(null);
  const [territorySearchKey, setTerritorySearchKey] = useState(null);

  useEffect(() => {
    if (update) {
      setSourceLocationSearchKey(defaultValues.source.value);
      setDestinationLocationSearchKey(defaultValues.destination.value);
      setProductSearchKey(
        defaultValues.orderProducts.map((product) => product.product.value),
      );
    }
  }, []);

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    defaultValues: defaultValues
      ? defaultValues
      : {
          orderProducts: [],
        },
    resolver: yupResolver(update ? UpdateIgtOrderSchema : AddIgtOrderSchema),
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'orderProducts',
  });

  const orderProducts = useWatch({ name: 'orderProducts', control });
  const source = useWatch({ name: 'source', control });
  const destination = useWatch({ name: 'destination', control });
  const orderDate = useWatch({ name: 'orderDate', control });

  const { classes } = useStyles({
    floating: orderProducts.length !== 0 || false,
  });

  const onSubmit = (data) => {
    ConfirmModal(data);
  };

  const {
    data: locationData,
    isLoading: locationLoading,
    error: locationError,
  } = useQuery({
    queryKey: [
      'location',
      null,
      null,
      null,
      searchByDistributor,
      true,
      null,
      searchByRegion,
      searchByArea,
      searchByTerritory,
    ],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const {
    data: inventoryData,
    isLoading: inventoryLoading,
    error: inventoryError,
  } = useQuery({
    queryKey: ['productByLocation', source?.uid],
    enabled: isObjectAndHasProperties(source),
    queryFn: fetchProductsbyLocation,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to {update ? 'update' : 'place'} this order?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        const orderItems = [];
        orderProducts.map((item) => {
          orderItems.push({
            uid: item.product.uid,
            sku: item.product.sku,
            name: item.product.name,
            category: item.product.category,
            quantity: item.cbcQuantity,
          });
        });
        if (update) {
          updateMutate({
            uid: defaultValues.uid,
            resquestData: {
              sourceLocation: source.uid,
              destinationLocation: destination.uid,
              orderItems,
              isApproved: defaultValues.isApproved,
              orderStatus: defaultValues.orderStatus,
            },
          });
        } else {
          createMutate({
            sourceLocation: source.uid,
            destinationLocation: destination.uid,
            orderItems,
          });
        }
      },
    });
  };

  const { mutate: createMutate, isLoading: isCreating } = useMutation({
    mutationFn: async (values) => await createIGTOrders(values),
    onSuccess: (response) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Order placed successfully',
      });
      onUpdate(response.data.data.order.orderNumber);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (values) => await updateIGTOrder(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Order updated successfully',
      });
      onUpdate(null);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const sourceLocationData = () => {
    if (
      userRoles[appUser?.role] === userRoles.ADMIN ||
      userRoles[appUser?.role] === userRoles['SUPER ADMIN']
    ) {
      return locationData?.data?.data?.locations.map((location) => ({
        uid: location.uid,
        value: location.name || location.referenceLabel,
      }));
    } else {
      return appUser.locations.map((location) => ({
        uid: location.uid,
        value: location.name || location.referenceLabel,
      }));
    }
  };

  const hasError = (index, fieldName) => {
    try {
      if (errors?.orderProducts[index]?.[fieldName]) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <div
      style={{
        minHeight: '75vh',
      }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack
          sx={{
            height: '80px',
          }}>
          <Flex align="start" justify="space-between" gap={20}>
            <Stack
              sx={{
                flex: 1,
              }}>
              <Autocomplete
                label="Source"
                placeholder="Search Source Location"
                limit={locationData?.data?.data?.locations.length || 0}
                maxDropdownHeight="200px"
                data={sourceLocationData() || []}
                value={SourceLocationSearchKey || ''}
                onItemSubmit={(e) => {
                  setValue('source', e, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                onChange={(e) => {
                  if (
                    SourceLocationSearchKey &&
                    SourceLocationSearchKey.length === 0
                  ) {
                    setSourceLocationSearchKey(null);
                  } else {
                    setSourceLocationSearchKey(e);
                  }
                }}
                rightSection={
                  SourceLocationSearchKey ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setSourceLocationSearchKey(null);
                        setValue('source', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                error={errors?.source?.message}
              />
            </Stack>
            <Stack
              spacing={5}
              sx={{
                flex: 1,
              }}>
              <Autocomplete
                placeholder="Search Destination Location"
                limit={locationData?.data?.data?.locations.length || 0}
                maxDropdownHeight="200px"
                data={
                  locationData?.data?.data?.locations
                    ?.filter((item) => item?.uid !== source?.uid)
                    ?.map((location) => ({
                      uid: location.uid,
                      value: location.name || location.referenceLabel,
                    })) || []
                }
                value={destinationLocationSearchKey || ''}
                onItemSubmit={(e) => {
                  setValue('destination', e, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                label={
                  <Flex justify="space-between">
                    <Text>Destination</Text>
                    <Flex gap={10} align="center">
                      {(searchByRegion ||
                        searchByArea ||
                        searchByDistributor ||
                        searchByTerritory) && (
                        <Tooltip label="Clear all filters">
                          <ActionIcon
                            onClick={() => {
                              setSearchByRegion(null);
                              setSearchByArea(null);
                              setSearchByDistributor(null);
                              setSearchByTerritory(null);
                              setRegionSearchKey(null);
                              setAreaSearchKey(null);
                              setDistributorSearchKey(null);
                              setTerritorySearchKey(null);
                            }}
                            size="xs"
                            color="blue"
                            radius="xl"
                            variant="transparent">
                            <IconX size={rem(15)} />
                          </ActionIcon>
                        </Tooltip>
                      )}
                      <Tooltip
                        label={`${
                          filterOpen ? 'Close' : 'Open'
                        } filters for destination`}>
                        <UnstyledButton
                          sx={{
                            color: COLORS.info,
                          }}
                          onClick={() => setFilterOpen(!filterOpen)}>
                          Filters
                        </UnstyledButton>
                      </Tooltip>
                    </Flex>
                  </Flex>
                }
                labelProps={{
                  style: {
                    width: '100%',
                  },
                }}
                onChange={(e) => {
                  if (
                    destinationLocationSearchKey &&
                    destinationLocationSearchKey.length === 0
                  ) {
                    setDestinationLocationSearchKey(null);
                  } else {
                    setDestinationLocationSearchKey(e);
                  }
                }}
                rightSection={
                  destinationLocationSearchKey ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setDestinationLocationSearchKey(null);
                        setValue('destination', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                error={errors?.destination?.message}
              />
              <Collapse in={filterOpen}>
                <Flex justify="space-between" align="center" gap={8}>
                  <Stack
                    sx={{
                      width: '100%',
                    }}>
                    <Menu
                      shadow="md"
                      width={300}
                      position="bottom-start"
                      withArrow
                      arrowSize={10}>
                      <Menu.Target>
                        <Stack>
                          {searchByRegion ? (
                            <Tooltip position="bottom" label={regionSearchKey}>
                              <Badge
                                variant="outline"
                                radius={4}
                                pr={3}
                                size="lg"
                                sx={{ height: '100%' }}
                                rightSection={
                                  <ActionIcon
                                    onClick={() => {
                                      setSearchByRegion(null);
                                      setRegionSearchKey(null);
                                    }}
                                    size="xs"
                                    color="blue"
                                    radius="xl"
                                    variant="transparent">
                                    <IconX size={rem(10)} />
                                  </ActionIcon>
                                }>
                                {regionSearchKey?.substring(0, 8)}
                              </Badge>
                            </Tooltip>
                          ) : (
                            <Button compact color="dark">
                              Region
                            </Button>
                          )}
                        </Stack>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Stack p={5}>
                          <Autocomplete
                            placeholder="Search by region"
                            onChange={(value) => {
                              if (
                                regionSearchKey &&
                                regionSearchKey.length === 0
                              ) {
                                setRegionSearchKey(null);
                              } else {
                                setRegionSearchKey(value);
                              }
                            }}
                            limit={regionPagelessdata?.length || 0}
                            maxDropdownHeight="200px"
                            dropdownPosition="bottom"
                            nothingFound="No options"
                            value={regionSearchKey || ''}
                            onItemSubmit={(e) => {
                              setSearchByRegion(e.id);
                            }}
                            rightSection={
                              searchByRegion ? (
                                <UnstyledButton
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  onClick={() => {
                                    setRegionSearchKey(null);
                                    setSearchByRegion(null);
                                  }}>
                                  <IconX size={15} />
                                </UnstyledButton>
                              ) : null
                            }
                            data={
                              regionPagelessdata?.map((location) => ({
                                id: location.id,
                                value: location.name,
                              })) || []
                            }
                          />
                        </Stack>
                      </Menu.Dropdown>
                    </Menu>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                    }}>
                    <Menu
                      shadow="md"
                      width={300}
                      position="bottom-start"
                      withArrow
                      arrowSize={10}>
                      <Menu.Target>
                        <Stack>
                          {searchByArea ? (
                            <Tooltip position="bottom" label={areaSearchKey}>
                              <Badge
                                variant="outline"
                                radius={4}
                                pr={3}
                                size="lg"
                                sx={{ height: '100%' }}
                                rightSection={
                                  <ActionIcon
                                    onClick={() => {
                                      setSearchByArea(null);
                                      setAreaSearchKey(null);
                                    }}
                                    size="xs"
                                    color="blue"
                                    radius="xl"
                                    variant="transparent">
                                    <IconX size={rem(10)} />
                                  </ActionIcon>
                                }>
                                {areaSearchKey?.substring(0, 8)}
                              </Badge>
                            </Tooltip>
                          ) : (
                            <Button compact color="dark">
                              Area
                            </Button>
                          )}
                        </Stack>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Stack p={5}>
                          <Autocomplete
                            placeholder="Search by area"
                            onChange={(value) => {
                              if (areaSearchKey && areaSearchKey.length === 0) {
                                setAreaSearchKey(null);
                              } else {
                                setAreaSearchKey(value);
                              }
                            }}
                            limit={areaPagelessData?.length || 0}
                            maxDropdownHeight="200px"
                            dropdownPosition="bottom"
                            nothingFound="No options"
                            value={areaSearchKey || ''}
                            onItemSubmit={(e) => {
                              setSearchByArea(e.id);
                            }}
                            rightSection={
                              searchByArea ? (
                                <UnstyledButton
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  onClick={() => {
                                    setAreaSearchKey(null);
                                    setSearchByArea(null);
                                  }}>
                                  <IconX size={15} />
                                </UnstyledButton>
                              ) : null
                            }
                            data={
                              areaPagelessData
                                ?.filter((item) => {
                                  if (searchByRegion) {
                                    return (
                                      item?.regionData?.id === searchByRegion
                                    );
                                  }
                                  return item;
                                })
                                ?.map((location) => ({
                                  id: location.id,
                                  value: location.name,
                                })) || []
                            }
                          />
                        </Stack>
                      </Menu.Dropdown>
                    </Menu>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                    }}>
                    <Menu
                      shadow="md"
                      width={300}
                      position="bottom-start"
                      withArrow
                      arrowSize={10}>
                      <Menu.Target>
                        <Stack>
                          {searchByDistributor ? (
                            <Tooltip
                              position="bottom"
                              label={distributorSearchKey}>
                              <Badge
                                variant="outline"
                                radius={4}
                                pr={3}
                                size="lg"
                                sx={{ height: '100%' }}
                                rightSection={
                                  <ActionIcon
                                    onClick={() => {
                                      setSearchByDistributor(null);
                                      setDistributorSearchKey(null);
                                    }}
                                    size="xs"
                                    color="blue"
                                    radius="xl"
                                    variant="transparent">
                                    <IconX size={rem(10)} />
                                  </ActionIcon>
                                }>
                                {distributorSearchKey?.substring(0, 8)}
                              </Badge>
                            </Tooltip>
                          ) : (
                            <Button compact color="dark">
                              Distributor
                            </Button>
                          )}
                        </Stack>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Stack p={5}>
                          <Autocomplete
                            placeholder="Search by distributor"
                            onChange={(value) => {
                              if (
                                distributorSearchKey &&
                                distributorSearchKey.length === 0
                              ) {
                                setDistributorSearchKey(null);
                              } else {
                                setDistributorSearchKey(value);
                              }
                            }}
                            limit={distributorPagelessdata?.length || 0}
                            maxDropdownHeight="200px"
                            dropdownPosition="bottom"
                            nothingFound="No options"
                            value={distributorSearchKey || ''}
                            onItemSubmit={(e) => {
                              setSearchByDistributor(e.id);
                            }}
                            rightSection={
                              searchByDistributor ? (
                                <UnstyledButton
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  onClick={() => {
                                    setDistributorSearchKey(null);
                                    setSearchByDistributor(null);
                                  }}>
                                  <IconX size={15} />
                                </UnstyledButton>
                              ) : null
                            }
                            data={
                              distributorPagelessdata
                                ?.filter((item) => {
                                  if (searchByArea) {
                                    return item?.areaData?.id === searchByArea;
                                  }
                                  return item;
                                })
                                ?.map((location) => ({
                                  id: location.id,
                                  value: location.name,
                                })) || []
                            }
                          />
                        </Stack>
                      </Menu.Dropdown>
                    </Menu>
                  </Stack>
                  <Stack
                    sx={{
                      width: '100%',
                    }}>
                    <Menu
                      shadow="md"
                      width={300}
                      position="bottom-start"
                      withArrow
                      arrowSize={10}>
                      <Menu.Target>
                        <Stack>
                          {searchByTerritory ? (
                            <Tooltip
                              position="bottom"
                              label={territorySearchKey}>
                              <Badge
                                variant="outline"
                                radius={4}
                                pr={3}
                                size="lg"
                                sx={{ height: '100%' }}
                                rightSection={
                                  <ActionIcon
                                    onClick={() => {
                                      setSearchByTerritory(null);
                                      setTerritorySearchKey(null);
                                    }}
                                    size="xs"
                                    color="blue"
                                    radius="xl"
                                    variant="transparent">
                                    <IconX size={rem(10)} />
                                  </ActionIcon>
                                }>
                                {territorySearchKey?.substring(0, 8)}
                              </Badge>
                            </Tooltip>
                          ) : (
                            <Button compact color="dark">
                              Territory
                            </Button>
                          )}
                        </Stack>
                      </Menu.Target>
                      <Menu.Dropdown>
                        <Stack p={5}>
                          <Autocomplete
                            placeholder="Search by territory"
                            onChange={(value) => {
                              if (
                                territorySearchKey &&
                                territorySearchKey.length === 0
                              ) {
                                setTerritorySearchKey(null);
                              } else {
                                setTerritorySearchKey(value);
                              }
                            }}
                            limit={territoryPagelessData?.length || 0}
                            maxDropdownHeight="200px"
                            dropdownPosition="bottom"
                            nothingFound="No options"
                            value={territorySearchKey || ''}
                            onItemSubmit={(e) => {
                              setSearchByTerritory(e.id);
                            }}
                            rightSection={
                              searchByTerritory ? (
                                <UnstyledButton
                                  sx={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  onClick={() => {
                                    setTerritorySearchKey(null);
                                    setSearchByTerritory(null);
                                  }}>
                                  <IconX size={15} />
                                </UnstyledButton>
                              ) : null
                            }
                            data={
                              territoryPagelessData
                                ?.filter((item) => {
                                  if (searchByDistributor) {
                                    return (
                                      item?.distributorData?.id ===
                                      searchByDistributor
                                    );
                                  }
                                  return item;
                                })
                                ?.map((location) => ({
                                  id: location.id,
                                  value: location.name,
                                })) || []
                            }
                          />
                        </Stack>
                      </Menu.Dropdown>
                    </Menu>
                  </Stack>
                </Flex>
              </Collapse>
            </Stack>
            <Stack
              sx={{
                flex: 1,
              }}>
              <DateInput
                minDate={new Date()}
                label="Order Date"
                placeholder="Enter Order Date"
                value={orderDate ? new Date(orderDate) : null}
                onChange={(e) => {
                  setValue('orderDate', e, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                error={errors?.orderDate?.message}
                weekendDays={[5, 6]}
              />
            </Stack>
          </Flex>
        </Stack>
        <Divider my="30px" variant="dashed" />
        <Text>Select Products</Text>
        {inventoryData?.data?.data?.inventory.length === 0 && (
          <Text size="sm" color={COLORS.error}>
            No Products Found for the source location
          </Text>
        )}
        <Space h="md" />
        <ScrollArea h={isArrayAndHasContent(fields) ? '350px' : '0px'}>
          <Stack
            sx={{
              width: '99%',
              height: '100%',
            }}>
            <ul
              style={{
                listStyleType: 'none',
                padding: 0,
                margin: 0,
              }}>
              {isArrayAndHasContent(fields) &&
                fields.map((item, index) => {
                  return (
                    <li
                      key={item.id}
                      style={{
                        marginTop: '30px',
                      }}>
                      <Grid>
                        <Grid.Col
                          span={1}
                          sx={{
                            maxWidth: '50px',
                          }}>
                          <Stack
                            justify="center"
                            align="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <Avatar
                              variant="filled"
                              color="dark"
                              radius="lg"
                              size="sm">
                              {index + 1}
                            </Avatar>
                          </Stack>
                        </Grid.Col>
                        <Grid.Col span={3}>
                          <Stack
                            justify="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <Autocomplete
                              label="Product Name"
                              placeholder="Search Product"
                              classNames={classes}
                              limit={
                                inventoryData?.data?.data?.inventory.length || 0
                              }
                              maxDropdownHeight="200px"
                              data={
                                inventoryData?.data?.data?.inventory
                                  .filter((product) => {
                                    return !orderProducts
                                      .map((item) => item?.product?.id)
                                      .includes(product.id);
                                  })
                                  .map((product) => ({
                                    value: `${
                                      product.shipmentFormatName
                                        ? product.shipmentFormatName
                                        : `${product.name} - ${product.sku}`
                                    }`,
                                    id: product.id,
                                    name: product.name,
                                    packages: product.packages,
                                    sku: product.sku,
                                    uid: product.uid,
                                    cbcquantity: product.cbcQuantity,
                                    category: product.category,
                                  })) || []
                              }
                              value={productSearchKey[index] || ''}
                              onChange={(e) => {
                                if (productSearchKey[index] && e.length === 0) {
                                  const temp = [...productSearchKey];
                                  temp.splice(index, 1);
                                  setProductSearchKey(temp);
                                  setValue(
                                    `orderProducts.${index}.product`,
                                    null,
                                    {
                                      shouldValidate: false,
                                      shouldDirty: false,
                                    },
                                  );
                                  setValue(`orderProducts.${index}.sku`, null, {
                                    shouldValidate: false,
                                    shouldDirty: false,
                                  });
                                } else {
                                  const temp = [...productSearchKey];
                                  temp[index] = e;
                                  setProductSearchKey(temp);
                                }
                              }}
                              onItemSubmit={(e) => {
                                if (
                                  orderProducts
                                    .map((item) => item.product?.id)
                                    .includes(e.id)
                                ) {
                                  NotificationUtil({
                                    success: false,
                                    message: 'This product is already selected',
                                  });
                                  setValue(
                                    `orderProducts.${index}.product`,
                                    null,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    },
                                  );
                                } else {
                                  const product = {
                                    ...e,
                                    stock: e.cbcquantity,
                                  };
                                  setValue(
                                    `orderProducts.${index}.product`,
                                    product,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    },
                                  );
                                  setValue(
                                    `orderProducts.${index}.sku`,
                                    e.sku,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    },
                                  );
                                }
                              }}
                              error={
                                hasError(index, 'product') && (
                                  <p>
                                    {
                                      errors?.orderProducts[index]?.product
                                        .message
                                    }
                                  </p>
                                )
                              }
                              rightSection={
                                orderProducts[index]?.product ? (
                                  <UnstyledButton
                                    sx={{
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    onClick={() => {
                                      const temp = [...productSearchKey];
                                      temp.splice(index, 1);
                                      setProductSearchKey(temp);
                                      setValue(
                                        `orderProducts.${index}.product`,
                                        null,
                                        {
                                          shouldValidate: false,
                                          shouldDirty: false,
                                        },
                                      );
                                      setValue(
                                        `orderProducts.${index}.sku`,
                                        null,
                                        {
                                          shouldValidate: false,
                                          shouldDirty: false,
                                        },
                                      );
                                      remove(index);
                                    }}>
                                    <IconX size={15} />
                                  </UnstyledButton>
                                ) : null
                              }
                            />
                          </Stack>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Stack
                            justify="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <TextInput
                              {...register(`orderProducts.${index}.sku`)}
                              classNames={classes}
                              label="SKU"
                              placeholder="SKU"
                              disabled={true}
                            />
                          </Stack>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Stack
                            justify="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <TextInput
                              value={
                                update
                                  ? orderProducts[index]?.product?.stock || 0
                                  : orderProducts[index]?.product
                                      ?.cbcquantity || 0
                              }
                              classNames={classes}
                              label="Stock"
                              placeholder="CBCs"
                              disabled={true}
                            />
                          </Stack>
                        </Grid.Col>
                        <Grid.Col
                          span={3}
                          sx={{
                            maxWidth: '120px',
                          }}>
                          <Stack
                            justify="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <NumberInput
                              classNames={classes}
                              label="CBC Quantity"
                              placeholder="Enter CBC Quantity"
                              value={
                                orderProducts[index]
                                  ? orderProducts[index]?.cbcQuantity
                                  : ''
                              }
                              min={
                                update
                                  ? cachedOrderedProductsData?.find(
                                      (item) =>
                                        item.productId ==
                                        orderProducts[index]?.product?.id,
                                    )?.deliveredItems
                                  : 1
                              }
                              max={
                                update
                                  ? parseInt(
                                      orderProducts[index]?.product?.stock,
                                    ) +
                                    cachedOrderedProductsData?.find(
                                      (item) =>
                                        item.productId ==
                                        orderProducts[index]?.product?.id,
                                    )?.deliveredItems
                                  : parseInt(
                                      orderProducts[index]?.product
                                        ?.cbcquantity,
                                    ) || null
                              }
                              onChange={(e) => {
                                if (update) {
                                  const min = cachedOrderedProductsData?.find(
                                    (item) =>
                                      item.productId ==
                                      orderProducts[index]?.product?.id,
                                  )?.deliveredItems;

                                  const max =
                                    parseInt(
                                      orderProducts[index]?.product?.stock,
                                    ) +
                                    cachedOrderedProductsData?.find(
                                      (item) =>
                                        item.productId ==
                                        orderProducts[index]?.product?.id,
                                    )?.deliveredItems;

                                  if (e < min) {
                                    NotificationUtil({
                                      success: false,
                                      message: `CBC Quantity (${e}) cannot be updated to a lower value than delivered quantity (${min})`,
                                    });
                                    setValue(
                                      `orderProducts.${index}.cbcQuantity`,
                                      min,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      },
                                    );

                                    return;
                                  }

                                  if (e > max) {
                                    NotificationUtil({
                                      success: false,
                                      message: `CBC Quantity (${e}) cannot be greater than delivered quantity (${min}) + stock (${parseInt(
                                        orderProducts[index]?.product?.stock,
                                      )})`,
                                    });
                                    setValue(
                                      `orderProducts.${index}.cbcQuantity`,
                                      max,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      },
                                    );

                                    return;
                                  }

                                  setValue(
                                    `orderProducts.${index}.cbcQuantity`,
                                    e,
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    },
                                  );
                                } else {
                                  if (
                                    e <=
                                    orderProducts[index]?.product?.cbcquantity
                                  ) {
                                    setValue(
                                      `orderProducts.${index}.cbcQuantity`,
                                      e,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      },
                                    );
                                  } else {
                                    NotificationUtil({
                                      success: false,
                                      message:
                                        'CBC Quantity cannot be greater than CBC Quantity in Inventory',
                                    });

                                    const value = 0;

                                    setValue(
                                      `orderProducts.${index}.cbcQuantity`,
                                      value,
                                      {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                      },
                                    );
                                  }
                                }
                              }}
                              error={
                                hasError(index, 'cbcQuantity') && (
                                  <p>
                                    {
                                      errors?.orderProducts[index]?.cbcQuantity
                                        .message
                                    }
                                  </p>
                                )
                              }
                            />
                          </Stack>
                        </Grid.Col>
                        <Grid.Col
                          span={2}
                          sx={{
                            minWidth: '350px',
                          }}>
                          <Stack
                            justify="center"
                            align="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <Flex
                              align="center"
                              justify="space-around"
                              gap={5}
                              sx={{
                                width: '100%',
                              }}>
                              <IconX />
                              <Flex gap={1}>
                                <Text fs="italic" c="dimmed">
                                  cartons (
                                </Text>
                                <Text fs="italic" c="dimmed">
                                  {orderProducts[index]?.product
                                    ? orderProducts[index]?.product?.packages
                                        ?.size / 1000
                                    : 0}
                                  TH
                                </Text>
                                <Text fs="italic" c="dimmed">
                                  )
                                </Text>
                              </Flex>
                              <IconEqual />
                              <Text>
                                {orderProducts[index]?.product &&
                                orderProducts[index]?.cbcQuantity
                                  ? (
                                      (orderProducts[index]?.product?.packages
                                        ?.size /
                                        1000) *
                                      orderProducts[index]?.cbcQuantity
                                    ).toFixed(2)
                                  : 0}{' '}
                                TH
                              </Text>
                            </Flex>
                          </Stack>
                        </Grid.Col>
                        <Grid.Col span={1}>
                          <Stack
                            justify="center"
                            align="center"
                            sx={{
                              width: '100%',
                              height: '100%',
                            }}>
                            <ActionIcon
                              color="red"
                              size="lg"
                              onClick={() => {
                                if (update) {
                                  if (
                                    cachedOrderedProductsData?.find(
                                      (item) =>
                                        item.productId ==
                                        orderProducts[index]?.product?.id,
                                    )?.deliveredItems > 0
                                  ) {
                                    NotificationUtil({
                                      success: false,
                                      message:
                                        'Cannot delete a product that has already been delivered',
                                    });
                                  } else {
                                    remove(index);
                                    const temp = [...productSearchKey];
                                    temp.splice(index, 1);
                                    setProductSearchKey(temp);
                                  }
                                } else {
                                  remove(index);
                                  const temp = [...productSearchKey];
                                  temp.splice(index, 1);
                                  setProductSearchKey(temp);
                                }
                              }}>
                              <IconTrashX size="1.625rem" />
                            </ActionIcon>
                          </Stack>
                        </Grid.Col>
                      </Grid>
                    </li>
                  );
                })}
            </ul>
          </Stack>
        </ScrollArea>

        {isArrayAndHasContent(fields) && (
          <Stack justify="end" align="center" my={25}>
            <Button
              variant="filled"
              color="yellow"
              onClick={() => {
                append({ product: null, sku: null, cbcQuantity: 0 });
              }}>
              Add Another
            </Button>
          </Stack>
        )}

        {!isObjectAndHasProperties(fields) && (
          <Stack justify="center" align="center">
            <NoDataPlaceholder
              title="No Products Added"
              subtext="Add products to create order"
            />
            <Text align="center" color={COLORS.error}>
              {errors?.orderProducts?.message}
            </Text>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => {
                if (
                  !isObjectAndHasProperties(source) ||
                  !isObjectAndHasProperties(destination)
                ) {
                  NotificationUtil({
                    success: false,
                    message: 'Please select source and destination first',
                  });
                } else {
                  append({ product: null, sku: null, cbcQuantity: 0 });
                }
              }}>
              Add Product
            </Button>
          </Stack>
        )}

        <Divider my="30px" variant="dashed" />
        <Stack justify="end" align="end" my={10}>
          <Button
            type="submit"
            sx={{
              backgroundColor: COLORS.primary,
            }}
            disabled={isSubmitting || !isValid || !isDirty}
            loading={isCreating || isUpdating}>
            {update ? 'Update' : 'Create'}
          </Button>
        </Stack>
      </form>
    </div>
  );
};

export default IGTOrderForm;
