import axios from './axios';

export const fetchProducts = async (context) => {
  const response = await axios().get('/products', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      isActive: context.queryKey[3],
      pageLess: context.queryKey[4],
      category: context.queryKey[5],
      brand: context.queryKey[6],
      brandSegment: context.queryKey[7],
      outerPackSize: context.queryKey[8],
    },
  });
  return response;
};

export const fetchProductsWithFilter = async ({
  page,
  searchKey,
  isActive,
  category,
  brand,
  brandSegment,
  outerPackSize,
  limit = 10,
}) => {
  const response = await axios().get('/products/filter', {
    params: {
      page,
      searchKey,
      isActive,
      category,
      brand,
      brandSegment,
      outerPackSize,
      limit,
    },
  });
  return response;
};

export const fetchProductDetails = async (values) => {
  const response = await axios().get(`/products/details/${values.uid}`);
  return response;
};

export const disableProduct = async (values) => {
  const response = await axios().delete(`/products/${values.uid}`);
  return response;
};

export const fetchProductsbyLocation = async (context) => {
  const response = await axios().get(
    `/products/by-location/${context.queryKey[1]}`,
  );
  return response;
};

export const fetchProductsbyLocations = async (context) => {
  const response = await axios().get('/products/by-locations', {
    params: {
      locations: context.queryKey[1],
    },
  });
  return response;
};

export const fetchInventory = async (context) => {
  const response = await axios().get('/products/inventory/v2', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      pageLess: context.queryKey[3],
      locationId: context.queryKey[4],
    },
  });
  return response;
};

export const fetchShelfLife = async (context) => {
  const response = await axios().get('/products/shelf-life/v2', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      pageLess: context.queryKey[3],
      locationId: context.queryKey[4],
    },
  });
  return response;
};

export const fetchProductExtraDetails = async (uid, queries) => {
  const response = await axios().get(`/products/details/extra/${uid}`, {
    params: queries,
  });
  return response;
};

export const fetchProductAssignedOrders = async (uid, queries) => {
  const response = await axios().get(`/products/assigned/orders/${uid}`, {
    params: queries,
  });
  return response;
};

export const fetchProductCBC = async (uid, queries) => {
  const response = await axios().get(`/products/assigned/cbcs/${uid}`, {
    params: queries,
  });
  return response;
};

export const updateProductDetailsById = async (values) => {
  const response = await axios().patch('/products/update-by-id', values);
  return response;
};

export const fetchProductsCategories = async ({
  brand,
  brandSegment,
  outerPackSize,

  sku,
}) => {
  const response = await axios().get('/products/categories', {
    params: {
      brand,
      brandSegment,
      outerPackSize,
      sku,
    },
  });
  return response;
};

export const fetchProductsBrands = async ({
  category,
  brandSegment,
  outerPackSize,
  sku,
}) => {
  const response = await axios().get('/products/brands', {
    params: {
      category,
      brandSegment,
      outerPackSize,
      sku,
    },
  });
  return response;
};

export const fetchProductsBrandSegments = async ({
  category,
  brand,
  outerPackSize,
  sku,
}) => {
  const response = await axios().get('/products/brand-segments', {
    params: {
      category,
      brand,
      outerPackSize,

      sku,
    },
  });
  return response;
};

export const fetchProductsOuterPackSize = async ({
  category,
  brand,
  brandSegment,
  sku,
}) => {
  const response = await axios().get('/products/outer-pack-size', {
    params: {
      category,
      brand,
      brandSegment,
      sku,
    },
  });
  return response;
};
