import React, { useEffect, useRef, useState } from 'react';

const OrderOtpCountdownCard = ({ seconds, timerOver }) => {
  const formatTime = () => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <div>
      <span
        style={{
          color: !timerOver ? 'green' : 'red',
          fontSize: 'small',
          fontWeight: 600,
        }}>
        {!timerOver
          ? `This OTP
        is valid for ${formatTime()}`
          : 'This OTP is no longer valid!'}
      </span>
    </div>
  );
};

export default OrderOtpCountdownCard;
