import React, { useEffect, useRef, useState } from 'react';
import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  HoverCard,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Space,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
  Box,
  TextInput,
  Input,
  CopyButton,
} from '@mantine/core';
import {
  IconArticle,
  IconEdit,
  IconMapPin,
  IconPlus,
  IconRefresh,
  IconUserOff,
  IconX,
  IconBoxOff,
  IconBox,
  IconMapPinCode,
  IconCopy,
  IconCheck,
} from '@tabler/icons-react';
import COLORS from '../../../constants/colors';
import { orderStatusBadgeColors } from '../../../constants/const';
import { IconCalendarTime } from '@tabler/icons-react';
import { IconMatchstick } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { generateOtpForOrder, sendOtpToPhone } from '../../../services/orders';
import { NotificationUtil } from '../../../utils/notifications';
import { handleErrorResponse } from '../../../utils/utils';
import { isObjectAndHasProperties } from '../../../utils/utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import OrderOtpCountdownCard from './OrderOtpCountdownCard';
import { openConfirmModal } from '@mantine/modals';
import { OtpformSchema } from '../../../validators/OtpValidator';

const OtpGeneratingCard = ({
  orderNumber,
  orderStatus,
  sourceName,
  sourceAddress,
  destinationName,
  destinationAddress,
  inventory,
  orderDate,
  orderType,
  orderDetails,
  cancelSelectedOrder,
}) => {
  const [otp, setOtp] = useState(null);

  //countdown timer states
  const [seconds, setSeconds] = useState(5 * 60); // 5 minutes in seconds
  const [timerOver, setTimerOver] = useState(true);
  const intervalRef = useRef(null);

  const resetTimer = () => {
    clearInterval(intervalRef.current);
    setSeconds(5 * 60);
    setTimerOver(false);
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalRef.current);
          setTimerOver(true);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(intervalRef.current);
          setTimerOver(true);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(intervalRef.current);
  }, []);

  //react-form-hook
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(OtpformSchema),
  });

  const { mutate: otpmutate, isLoading: isOtpLoading } = useMutation({
    mutationFn: (value) => generateOtpForOrder(value),
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      setOtp(response?.data?.data?.otp);
      NotificationUtil({
        success: true,
        title: 'OTP generted Successfully',
        message: 'OTP generated Successfully',
      });
    },
  });

  const { mutate: sendotpmutate, isLoading: sendOtpLoading } = useMutation({
    mutationFn: (value) => sendOtpToPhone(value),
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      NotificationUtil({
        success: true,
        title: 'OTP sent successfully',
        message: `${response?.data?.message}`,
      });
    },
  });

  //on Cancle selected order
  const handleCancelSelectedOrder = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you want to cancel?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        cancelSelectedOrder();
      },
    });
  };

  //generate otp
  const handleGenerateOtp = () => {
    //setCopied(false);
    resetTimer();
    const value = {
      variant: 'incoming',
      orderNumber: orderNumber,
    };
    otpmutate(value);
  };

  //sending otp
  const handleSendOtp = (data) => {
    let phoneNo = data.phone;
    phoneNo = '+88' + phoneNo;
    sendingOtp(orderNumber, phoneNo, otp);
  };

  const sendingOtp = (orderNumber, phoneNumber, otp) => {
    //setCopied(false);
    const value = {
      orderNumber: orderNumber,
      phoneNumber: phoneNumber,
      otp: otp,
    };
    sendotpmutate(value);
  };

  return (
    <Paper
      shadow="xs"
      p="md"
      mt="20px"
      radius="md"
      withBorder
      style={{ width: '100%' }}>
      <Title order={1} size="md" py="sm" style={{ textAlign: 'center' }}>
        Selected Order
      </Title>
      <Flex justify="space-between" align="center">
        <Title order={1} size="md">
          Order No: #{orderNumber}
        </Title>
        <Flex align="center">
          <Badge color="pink">{orderType}</Badge>
          <Badge color={orderStatusBadgeColors[orderStatus]}>
            {orderStatus}
          </Badge>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" py="sm">
        <Box>
          <Flex justify="flex-start" align="center" gap={5} py="0.5rem">
            <IconMapPin color={COLORS.green} />
            <Title order={1} size="small">
              Source
            </Title>
          </Flex>
          <Flex style={{ fontSize: 'small' }} direction="column" gap={2}>
            <Badge color="cyan" size="md" radius="md">
              {sourceName.length > 20
                ? `${sourceName.slice(0, 19)}...`
                : sourceName}
            </Badge>
            <span style={{ fontSize: 'smaller' }}>{sourceAddress}</span>
          </Flex>
        </Box>
        <Box>
          <Flex justify="flex-start" align="center" gap={5} py="0.5rem">
            <IconMapPin color={COLORS.redMatt} />
            <Title order={1} size="small">
              Destination
            </Title>
          </Flex>
          <Flex style={{ fontSize: 'small' }} direction="column" gap={2}>
            <Badge color="orange" size="md" radius="md">
              {destinationName.length > 20
                ? `${destinationName.slice(0, 19)}...`
                : destinationName}
            </Badge>
            <span style={{ fontSize: 'smaller' }}>{destinationAddress}</span>
          </Flex>
        </Box>
      </Flex>
      <Flex justify="space-between" align="center" py="sm">
        <Flex gap={5}>
          <Flex gap={5} align="center">
            <IconCalendarTime />
            <span style={{ fontSize: 'smaller' }}>
              {dayjs(orderDate).format('MMM DD, YYYY')}
            </span>
          </Flex>
          <Flex gap={5} align="center">
            <IconMatchstick />
            <span style={{ fontSize: 'smaller' }}>
              {inventory?.reduce(
                (acc, obj) => acc + obj.quantity * obj.product.packages.size,
                0,
              ) / 1000}{' '}
              TH
            </span>
          </Flex>
        </Flex>
      </Flex>
      <Flex gap={5} align="center" justify="flex-end" py="sm">
        <Button
          sx={{
            backgroundColor: COLORS.redMatt,
          }}
          size="xs"
          onClick={() => handleCancelSelectedOrder()}
          leftIcon={<IconX />}>
          Cancel
        </Button>

        {timerOver ? (
          <Button
            sx={{
              backgroundColor: COLORS.primary,
            }}
            size="xs"
            disabled={isOtpLoading ? true : false}
            onClick={() => handleGenerateOtp()}
            leftIcon={<IconMapPinCode />}>
            Generate OTP
          </Button>
        ) : (
          <Button
            sx={{
              backgroundColor: COLORS.primary,
            }}
            size="xs"
            disabled={true}
            leftIcon={<IconMapPinCode />}>
            Generate OTP
          </Button>
        )}
      </Flex>

      {isOtpLoading ? (
        <Flex
          justify="center"
          align="center"
          sx={{ minHeight: '20vh' }}
          shadow="sm"
          direction="column"
          gap={5}>
          <Loader variant="bars" py="sm" />
          <Title size="sm">Generating OTP ...</Title>
        </Flex>
      ) : (
        <></>
      )}

      {otp ? (
        <Flex direction="column" justify="center" align="center">
          <Flex justify="center" align="center" gap={2} sx={{ width: '100%' }}>
            <Flex direction="column" gap={2}>
              <Title
                size="3.4rem"
                // py="sm"
                sx={{ padding: '0.5rem', textAlign: 'center' }}>
                {otp}
              </Title>

              <span>
                <OrderOtpCountdownCard
                  seconds={seconds}
                  timerOver={timerOver}
                />
              </span>
            </Flex>

            <Box style={{ transform: `translateY(${-12}px` }}>
              <CopyButton value={otp}>
                {({ copied, copy }) => (
                  <Tooltip
                    label={copied ? 'Copied' : 'Copy'}
                    withArrow
                    position="right">
                    <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                      {copied ? (
                        <IconCheck
                          size="1rem"
                          style={{
                            height: 200,
                            width: 200,
                          }}
                        />
                      ) : (
                        <IconCopy
                          size="1rem"
                          style={{
                            height: 200,
                            width: 200,
                          }}
                        />
                      )}
                    </ActionIcon>
                  </Tooltip>
                )}
              </CopyButton>
            </Box>
          </Flex>

          <Flex justify="center" align="center" gap={5} sx={{ width: '100%' }}>
            <form onSubmit={handleSubmit(handleSendOtp)}>
              <Box>
                <Flex direction="column" m="1rem 0rem" gap={3}>
                  <Flex gap={3}>
                    <Input
                      placeholder="+88"
                      defaultValue="+88"
                      contentEditable={false}
                      sx={{ width: '4em' }}
                    />
                    <TextInput
                      id="phone"
                      placeholder="Enter Phone No."
                      type="text"
                      {...register('phone')}
                    />
                    <Button
                      sx={{
                        backgroundColor: COLORS.primary,
                      }}
                      disabled={sendOtpLoading ? true : false}
                      type="submit">
                      Send OTP
                    </Button>
                  </Flex>
                  <Text size="sm" color="red">
                    {errors.phone?.message}
                  </Text>
                </Flex>
              </Box>
            </form>
          </Flex>
        </Flex>
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default OtpGeneratingCard;
