import React from 'react';
import { handleErrorResponse } from '../../utils/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  passwordAndLoginStatus,
  triggerResetPassword,
} from '../../services/user';
import { Button, Flex, Skeleton, Stack, Text } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import ServerErrorBox from '../Global/ServerErrorBox';
import dayjs from 'dayjs';
import { NotificationUtil } from '../../utils/notifications';
import { openConfirmModal } from '@mantine/modals';
import COLORS from '../../constants/colors';

const OtherDetailsModal = ({ user, onClose, onUpdate }) => {
  const {
    data: statusData,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useQuery({
    queryKey: ['user-password-and-login-status', user.uid],
    queryFn: () => passwordAndLoginStatus(user.uid),
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you reset password for this user?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        triggerMutate();
      },
    });
  };

  const {
    mutate: triggerMutate,
    isLoading: triggerMutateLoading,
    isMutating: triggerMutating,
  } = useMutation({
    mutationFn: () => triggerResetPassword(user.uid),

    keepPreviousData: true,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      NotificationUtil({
        title: 'Success!',
        message: 'Password reset triggered successfully!',
        success: true,
      });
      onUpdate({ close: true });
    },
  });

  if (isLoading)
    return (
      <div>
        <Skeleton height="auto" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { data } = statusData.data;

  return (
    <Stack>
      <Flex justify="space-between" align="center">
        <Text>Is password reset required</Text>
        {data?.isPasswordResetRequired ? <IconCheck /> : <IconX />}
      </Flex>
      <Flex justify="space-between" align="center">
        <Text>Is password valid</Text>
        {data?.isPasswordValid ? <IconCheck /> : <IconX />}
      </Flex>
      <Flex justify="space-between" align="center">
        <Text>Last password change</Text>
        {dayjs(data?.lastPasswordChange).format('DD/MM/YYYY')}
      </Flex>
      <Flex justify="space-between" align="center">
        <Text>Login Attempts (max 10)</Text>
        <Text>{data?.loginAttempts}</Text>
      </Flex>
      <Flex justify="space-between" align="center">
        <Text>Password Expire Date</Text>
        {dayjs(data?.passwordExpireDate).format('DD/MM/YYYY')}
      </Flex>
      <Stack>
        <Button
          sx={{
            backgroundColor: COLORS.primary,
          }}
          onClick={ConfirmModal}>
          Reset password
        </Button>
      </Stack>
    </Stack>
  );
};

export default OtherDetailsModal;
