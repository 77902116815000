import {
  ActionIcon,
  Button,
  Flex,
  Select,
  Skeleton,
  Space,
  Stack,
  Tabs,
  Title,
  Tooltip,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import ServerErrorBox from '../../../components/Global/ServerErrorBox';

import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { IconRefresh } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import { userWeight } from '../../../constants/const';
import { removeExportFile } from '../../../services/dashboard';
import { fetchDistributor } from '../../../services/distributor';
import {
  fetchOneViewLastDayReport,
  fetchOneViewReport,
  fetchOneViewStockOutlook,
  reportGenerate,
} from '../../../services/reports';
import { NotificationUtil } from '../../../utils/notifications';
import {
  handleErrorResponse,
  userWiseMultiSelectDistributorDataWithId,
} from '../../../utils/utils';
import FirstDayTillDate from './OneView/FirstDayTillDate';
import LastDay from './OneView/LastDay';
import StockOutlook from './OneView/StockOutlook';

const PAGE_TITLE = 'One View Report';

const OneView = () => {
  const navigate = useNavigate();

  const appUser = useSelector((state) => state.auth.user);

  const token = useSelector((state) => state?.auth?.accessToken);
  const refreshToken = useSelector((state) => state?.auth?.refreshToken);

  const [selectedDistributor, setselectedDistributor] = useState(null);
  const [selectedDistributorSearchKey, setselectedDistributorSearchKey] =
    useState('');

  const [activeTab, setActiveTab] = useState('first_sheet');
  const [tabValues, setTabValues] = useState({});

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
  );
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (appUser.userWeight === userWeight.MANAGER) {
      setselectedDistributor(appUser.distributors.map((dist) => dist.id));
    }
  }, []);

  const {
    data: reportData,
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: [
      'One-view-report-first_day',
      startDate,
      endDate,
      selectedDistributor,
    ],
    queryFn: () =>
      fetchOneViewReport({
        startDate,
        endDate,
        selectedDistributor,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: reportDataLastDay,
    isLoading: isLoadingLastDay,
    isFetching: isFetchingLastDay,
    error: errorLastDay,
  } = useQuery({
    queryKey: ['One-view-report-last-day', selectedDistributor],
    queryFn: () =>
      fetchOneViewLastDayReport({
        selectedDistributor,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: reportDataStockOutlook,
    isLoading: isLoadingStockOutlook,
    isFetching: isFetchingStockOutlook,
    error: errorStockOutlook,
  } = useQuery({
    queryKey: ['One-view-report-stock-outlook', selectedDistributor],
    queryFn: () =>
      fetchOneViewStockOutlook({
        selectedDistributor,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: distributorData,
    isLoadingDistributor,
    isFetchingDistributor,
    errorDistributor,
  } = useQuery({
    queryKey: ['distributor-pagelessData', null, null, null, null, true],
    queryFn: fetchDistributor,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const { mutate: ExportMutate, isLoading: ExportLoading } = useMutation({
    mutationFn: () =>
      reportGenerate({
        reportName: 'oneview',
        startDate,
        endDate,
      }),
    keepPreviousData: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      handleDownloadImage({
        fileUrl: data.data.filePath,
        fileName: data.data.fileName,
      });
    },
  });

  const handleDownloadImage = async (file) => {
    try {
      const response = await fetch(file.fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-refresh-token': refreshToken,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifications.clean();
      NotificationUtil({
        success: true,
        title: 'File Downloaded',
        message: 'Your file has been downloaded successfully.',
      });
      removeExportFileMutate(file.fileName);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const { mutate: removeExportFileMutate, isLoading: removeExportFileLoading } =
    useMutation({
      mutationFn: (fileName) => removeExportFile(fileName),
      keepPreviousData: false,
    });

  const tabList = [
    {
      label: '1st day of month to till date',
      value: 'first_sheet',
      element: (
        <FirstDayTillDate
          reportData={reportData?.data?.data}
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
        />
      ),
    },
    {
      label: 'Last Day',
      value: 'second_sheet',
      element: (
        <LastDay
          reportData={reportDataLastDay?.data?.data}
          isLoading={isLoadingLastDay}
          isFetching={isFetchingLastDay}
          error={errorLastDay}
        />
      ),
    },
    {
      label: 'Stock Outlook',
      value: 'third_sheet',
      element: (
        <StockOutlook
          reportData={reportDataStockOutlook?.data?.data}
          isLoading={isLoadingStockOutlook}
          isFetching={isFetchingStockOutlook}
          error={errorStockOutlook}
        />
      ),
    },
  ];

  useEffect(() => {
    let tempData = {};
    tabList.forEach((tab) => {
      tempData[tab.value] = tab.value;
    });
    setTabValues(tempData);
  }, []);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Space h="lg" />

      <Tabs
        variant="outline"
        defaultValue="gallery"
        value={activeTab}
        onTabChange={handleTabChange}>
        <Tabs.List>
          <Flex
            justify="space-between"
            sx={{
              width: '100%',
            }}>
            <Flex align="center">
              {tabList.map((tab) => (
                <Tabs.Tab
                  value={tab.value}
                  key={tab.value}
                  sx={{
                    backgroundColor:
                      activeTab === tabValues[tab.value]
                        ? COLORS.primary
                        : COLORS.white,
                    color:
                      activeTab === tabValues[tab.value]
                        ? COLORS.white
                        : COLORS.black,
                  }}>
                  {tab.label}
                </Tabs.Tab>
              ))}
            </Flex>
            <Stack align="end">
              <Button
                onClick={() => {
                  ExportMutate();
                  NotificationUtil({
                    success: true,
                    title: 'Processing',
                    message:
                      'Your request is being processed. Feel free to browse the app while waiting for the download to finish.',
                    autoClose: false,
                  });
                }}
                loading={ExportLoading}
                sx={{
                  backgroundColor: COLORS.primary,
                }}>
                Download XLS
              </Button>
            </Stack>
          </Flex>
        </Tabs.List>
        <Space h="md" />
        <Flex justify="space-between">
          <Flex align="center" gap={5}>
            {activeTab === 'first_sheet' && (
              <>
                <DateInput
                  placeholder="Start Date"
                  value={startDate}
                  onChange={(value) => {
                    setStartDate(value);
                  }}
                  sx={{
                    maxWidth: '200px',
                  }}
                  clearable
                  maxDate={new Date()}
                  weekendDays={[5, 6]}
                />
                <DateInput
                  placeholder="End Date"
                  // value={endDate}
                  onChange={(value) => {
                    setEndDate(value);
                  }}
                  sx={{
                    maxWidth: '200px',
                  }}
                  clearable
                  minDate={startDate ? startDate : new Date()}
                  weekendDays={[5, 6]}
                />
              </>
            )}
            <Select
              placeholder="Filter by house"
              dropdownPosition="bottom"
              data={
                userWiseMultiSelectDistributorDataWithId({
                  appUser,
                  distributorData:
                    distributorData?.data.data.distributors || [],
                }) || []
              }
              onChange={(value) => {
                if (value) {
                  setselectedDistributor([value]);
                  setselectedDistributorSearchKey(value);
                } else {
                  if (appUser.userWeight === userWeight.MANAGER) {
                    setselectedDistributor(
                      appUser.distributors.map((dist) => dist.id),
                    );
                  } else {
                    setselectedDistributor(null);
                  }
                  setselectedDistributorSearchKey('');
                }
              }}
              value={selectedDistributorSearchKey}
              sx={{
                minWidth: '250px',
              }}
              clearable
              searchable
              withinPortal
            />
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={() => {
                  setStartDate(
                    new Date(
                      new Date().getFullYear(),
                      new Date().getMonth(),
                      1,
                    ),
                  );
                  setEndDate(null);
                  if (appUser.userWeight === userWeight.MANAGER) {
                    setselectedDistributor(
                      appUser.distributors.map((dist) => dist.id),
                    );
                  } else {
                    setselectedDistributor(null);
                  }
                  setselectedDistributorSearchKey('');
                }}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
        <Space h="md" />
        {tabList.map((tab) => (
          <Tabs.Panel value={tab.value} key={tab.value}>
            {tab.element}
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

export default OneView;
