import {
  Card,
  Center,
  Flex,
  Grid,
  Group,
  Paper,
  RingProgress,
  Stack,
  Text,
} from '@mantine/core';
import {
  IconAlertTriangle,
  IconArrowDownRight,
  IconArrowUpRight,
  IconClockHour3,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import COLORS from '../../../constants/colors';
import { HumanizeDuration } from '../../../utils/utils';

const OrderDetailsStatsCard = ({ order, quantityBreakDownForStats }) => {
  const [loadingAccuracyStat, setLoadingAccuracyStat] = useState(0);
  const [unloadingAccuracyStat, setUnloadingAccuracyStat] = useState(0);

  useEffect(() => {
    if (quantityBreakDownForStats) {
      const tempLoadingAccuracyStat =
        (quantityBreakDownForStats?.deliveredQuantity /
          quantityBreakDownForStats?.totalQuantity) *
        100;
      const tempUnloadingAccuracyStat =
        (quantityBreakDownForStats?.receivedQuantity /
          quantityBreakDownForStats?.deliveredQuantity) *
        100;

      if (!isNaN(tempLoadingAccuracyStat)) {
        setLoadingAccuracyStat(tempLoadingAccuracyStat.toFixed(2));
      }

      if (!isNaN(tempUnloadingAccuracyStat)) {
        setUnloadingAccuracyStat(tempUnloadingAccuracyStat.toFixed(2));
      }
    }
  }, [quantityBreakDownForStats]);

  return (
    <Card
      shadow="xs"
      padding="lg"
      sx={{
        width: '100%',
        height: '100%',
      }}
      radius="md"
      withBorder>
      <Stack>
        <Stack>
          <Flex justify="space-between" align="center">
            <Flex gap={5} align="center">
              <Text>Loading</Text>
              <IconClockHour3 size={18} />
            </Flex>
            <Flex gap={5} align="center">
              {dayjs(order?.scanoutEnd).diff(
                dayjs(order?.scanoutStart),
                'minute',
              ) > 60 && <IconAlertTriangle size={18} color={COLORS.error} />}
              {dayjs(order?.scanoutEnd).diff(
                dayjs(order?.scanoutStart),
                'minute',
              ) < 1 && <IconAlertTriangle size={18} color={COLORS.error} />}
              <Text fz="sm" align="center">
                {order?.scanoutEnd && order?.scanoutStart
                  ? HumanizeDuration({
                      durationData: dayjs(order?.scanoutEnd).diff(
                        dayjs(order?.scanoutStart),
                        'millisecond',
                      ),
                      format: ['hours', 'minutes', 'seconds'],
                    })
                  : '-'}
              </Text>
            </Flex>
          </Flex>
          <Flex justify="space-between" align="center">
            <Flex gap={5} align="center">
              <Text>Unloading</Text>
              <IconClockHour3 size={18} />
            </Flex>
            <Flex gap={5} align="center">
              {dayjs(order?.scaninEnd).diff(
                dayjs(order?.scaninStart),
                'minute',
              ) > 60 && <IconAlertTriangle size={18} color={COLORS.error} />}
              {dayjs(order?.scaninEnd).diff(
                dayjs(order?.scaninStart),
                'minute',
              ) < 1 && <IconAlertTriangle size={18} color={COLORS.error} />}
              <Text fz="sm" align="center">
                {order?.scaninEnd && order?.scaninStart
                  ? HumanizeDuration({
                      durationData: dayjs(order?.scaninEnd).diff(
                        dayjs(order?.scaninStart),
                        'millisecond',
                      ),
                      format: ['hours', 'minutes', 'seconds'],
                    })
                  : '-'}
              </Text>
            </Flex>
          </Flex>
        </Stack>

        <Grid>
          <Grid.Col span={6}>
            <Paper withBorder radius="md" p="1" pb={5}>
              <Group
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                spacing="xs">
                <RingProgress
                  size={80}
                  roundCaps
                  thickness={8}
                  sections={[{ value: loadingAccuracyStat, color: 'blue' }]}
                  label={
                    <Center>
                      <IconArrowUpRight size="1.4rem" stroke={1.5} />
                    </Center>
                  }
                />

                <div>
                  <Text
                    color="dimmed"
                    size="xs"
                    transform="uppercase"
                    weight={700}
                    align="center">
                    Loading
                  </Text>
                  <Text weight={700} size="xl" align="center">
                    {quantityBreakDownForStats?.deliveredQuantity > 0
                      ? `${loadingAccuracyStat}%`
                      : '-'}
                  </Text>
                </div>
              </Group>
            </Paper>
          </Grid.Col>
          <Grid.Col span={6}>
            <Paper withBorder radius="md" p="1" pb={5}>
              <Group
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                spacing="xs">
                <RingProgress
                  size={80}
                  roundCaps
                  thickness={8}
                  sections={[{ value: unloadingAccuracyStat, color: 'orange' }]}
                  label={
                    <Center>
                      <IconArrowDownRight size="1.4rem" stroke={1.5} />
                    </Center>
                  }
                />

                <div>
                  <Text
                    color="dimmed"
                    size="xs"
                    transform="uppercase"
                    weight={700}
                    align="center">
                    Unloading
                  </Text>
                  <Text weight={700} size="xl" align="center">
                    {quantityBreakDownForStats?.receivedQuantity > 0
                      ? `${unloadingAccuracyStat}%`
                      : '-'}
                  </Text>
                </div>
              </Group>
            </Paper>
          </Grid.Col>
        </Grid>
      </Stack>
    </Card>
  );
};

export default OrderDetailsStatsCard;
