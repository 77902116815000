import { Alert, Button, PasswordInput, Stack, TextInput } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { startRecon } from '../../services/dayZeroRecon';
import { NotificationUtil } from '../../utils/notifications';
import COLORS from '../../constants/colors';

const PasswordModal = ({ uid, onUpdate, onClose }) => {
  const appUser = useSelector((state) => state.auth.user);

  const [password, setPassword] = useState(null);

  const { mutate: startMutate, isLoading: isStarting } = useMutation({
    mutationFn: async () => await startRecon(uid, password),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'reconciliation started successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <Stack>
      <Alert variant="light" color="blue" icon={<IconInfoCircle size="2rem" />}>
        In order to start reconciliation please enter you password
      </Alert>

      <TextInput
        onChange={(event) => {
          setPassword(event.currentTarget.value);
        }}
        placeholder="Enter your Password"
        size="md"
      />
      <Button
        onClick={() => {
          startMutate();
        }}
        sx={{
          backgroundColor: COLORS.primary,
        }}
        loading={isStarting}
        disabled={isStarting || !password}>
        Start
      </Button>
    </Stack>
  );
};

export default PasswordModal;
