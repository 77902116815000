import * as yup from 'yup';
import { userDesignations, userRoles } from '../constants/const';

export const AddUserSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Invalid Email')
    .when('role', (role, schema) => {
      if (!userRoles[role]?.includes(userRoles.OPERATOR))
        return schema.required('Email is required');
      return schema;
    }),
  phone: yup
    .string()
    .matches(/^(?:(?:\+88)?01)?(?:\d{9}|)$/, 'Invalid format for phone number')
    .required('Must enter phone number'),
  designation: yup.string().required('Designation is required'),
  role: yup.string().required('Role is required'),
  password: yup
    .string()
    .required('Password is required')
    .when('role', (role, schema) => {
      if (userRoles[role]?.includes(userRoles.OPERATOR)) {
        return schema.min(6, 'Password must be minimum 6 characters long');
      }
      if (
        userRoles[role]?.includes(userRoles.ADMIN) ||
        userRoles[role]?.includes(userRoles['SUPER ADMIN'])
      ) {
        return schema.min(16, 'Password must be minimum 16 characters long');
      }

      return schema.min(12, 'Password must be minimum 12 characters long');
    })
    .when('role', (role, schema) => {
      if (userRoles[role]?.includes(userRoles.OPERATOR)) {
        return schema;
      } else {
        return schema.test(
          'password',
          'Your password should containt atleast one lowercase alphabet, atleast one uppercase alphabet, atleast one digit and atleast one special character',
          function (value) {
            const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).+$/;
            return regex.test(value);
          },
        );
      }
    }),
  regionId: yup
    .number()
    .typeError('Region is required')
    .when('designation', (designation, schema) => {
      if (
        designation?.includes(userDesignations['REGIONAL MANAGER']) ||
        designation?.includes(userDesignations['AREA MANAGER']) ||
        designation?.includes(userDesignations['TERRITORY OFFICER']) ||
        designation?.includes(userDesignations['BUSINESS MANAGER']) ||
        designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
        designation?.includes(userDesignations['GODOWN KEEPER'])
      ) {
        return schema.required('Region is required');
      } else {
        return schema;
      }
    }),
  areaId: yup
    .number()
    .typeError('Area is required')
    .when('designation', (designation, schema) => {
      if (
        designation?.includes(userDesignations['AREA MANAGER']) ||
        designation?.includes(userDesignations['TERRITORY OFFICER']) ||
        designation?.includes(userDesignations['BUSINESS MANAGER']) ||
        designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
        designation?.includes(userDesignations['GODOWN KEEPER'])
      ) {
        return schema.required('Area is required');
      } else {
        return schema;
      }
    }),
  distributorId: yup
    .number()
    .typeError('Distributor is required')
    .when('designation', (designation, schema) => {
      if (
        designation?.includes(userDesignations['TERRITORY OFFICER']) ||
        designation?.includes(userDesignations['BUSINESS MANAGER']) ||
        designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
        designation?.includes(userDesignations['GODOWN KEEPER'])
      ) {
        return schema.required('Distributor is required');
      } else {
        return schema;
      }
    }),
  territoryId: yup.number().when('designation', (designation, schema) => {
    if (
      designation?.includes(userDesignations['TERRITORY OFFICER']) ||
      designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
      designation?.includes(userDesignations['GODOWN KEEPER'])
    ) {
      return schema
        .typeError('Territory is required')
        .required('Territory is required');
    } else {
      return schema;
    }
  }),
  territoryIds: yup
    .array()
    .of(yup.number().typeError('Territory is required'))
    .when('designation', (designation, schema) => {
      if (designation?.includes(userDesignations['BUSINESS MANAGER'])) {
        return schema
          .min(1, 'Territory is required')
          .required('Territory is required');
      }
      return schema;
    }),
  pdaDeviceModel: yup.string(),
  isMfaEnabled: yup.boolean(),
});

export const UpdateUserSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .email('Invalid Email')
    .when('role', (role, schema) => {
      if (!userRoles[role]?.includes(userRoles.OPERATOR))
        return schema.required('Email is required');
      return schema;
    }),
  phone: yup
    .string()
    .matches(/^(?:(?:\+88)?01)?(?:\d{9}|)$/, 'Invalid format for phone number')
    .required('Must enter phone number'),
  designation: yup.string().required('Designation is required'),
  role: yup.string().required('Role is required'),
  regionId: yup
    .number()
    .typeError('Region is required')
    .when('designation', (designation, schema) => {
      if (
        designation?.includes(userDesignations['REGIONAL MANAGER']) ||
        designation?.includes(userDesignations['AREA MANAGER']) ||
        designation?.includes(userDesignations['TERRITORY OFFICER']) ||
        designation?.includes(userDesignations['BUSINESS MANAGER']) ||
        designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
        designation?.includes(userDesignations['GODOWN KEEPER'])
      ) {
        return schema.required('Region is required');
      } else {
        return schema;
      }
    }),
  areaId: yup
    .number()
    .typeError('Area is required')
    .when('designation', (designation, schema) => {
      if (
        designation?.includes(userDesignations['AREA MANAGER']) ||
        designation?.includes(userDesignations['TERRITORY OFFICER']) ||
        designation?.includes(userDesignations['BUSINESS MANAGER']) ||
        designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
        designation?.includes(userDesignations['GODOWN KEEPER'])
      ) {
        return schema.required('Area is required');
      } else {
        return schema;
      }
    }),
  distributorId: yup
    .number()
    .typeError('Distributor is required')
    .when('designation', (designation, schema) => {
      if (
        designation?.includes(userDesignations['TERRITORY OFFICER']) ||
        designation?.includes(userDesignations['BUSINESS MANAGER']) ||
        designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
        designation?.includes(userDesignations['GODOWN KEEPER'])
      ) {
        return schema.required('Distributor is required');
      } else {
        return schema;
      }
    }),
  territoryId: yup.number().when('designation', (designation, schema) => {
    if (
      designation?.includes(userDesignations['TERRITORY OFFICER']) ||
      designation?.includes(userDesignations['COMPUTER OPERATOR']) ||
      designation?.includes(userDesignations['GODOWN KEEPER'])
    ) {
      return schema
        .typeError('Territory is required')
        .required('Territory is required');
    } else {
      return schema;
    }
  }),
  territoryIds: yup
    .array()
    .of(yup.number().typeError('Territory is required'))
    .when('designation', (designation, schema) => {
      if (designation?.includes(userDesignations['BUSINESS MANAGER'])) {
        return schema
          .min(1, 'Territory is required')
          .required('Territory is required');
      }
      return schema;
    }),
  pdaDeviceModel: yup.string(),
  isMfaEnabled: yup.boolean(),
});
