import {
  ActionIcon,
  Autocomplete,
  Box,
  Flex,
  HoverCard,
  LoadingOverlay,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { IconBoxOff, IconRefresh, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import COLORS from '../../../constants/colors';
import { fetchShelfLife } from '../../../services/product';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  userWiseAutocompleteLocationDataWithId,
} from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import SearchInput from '../../Global/SearchInput';
import exportFromJSON from 'export-from-json';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import dayjs from 'dayjs';

const StockMonitoringTab = ({ locationData, currentTab, requiredTab }) => {
  const appUser = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [searchByLocation, setSearchByLocation] = useState(null);
  const [locationSearchKey, setLocationSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['inventory-shelf-life', page, searchKey, null, searchByLocation],
    queryFn: fetchShelfLife,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    // refetchInterval: 5 * 60 * 1000,
    // refetchIntervalInBackground: true,
    enabled: currentTab == requiredTab ? true : false,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { isFetching: pagelessFetching, refetch: pagelessRefetch } = useQuery({
    queryKey: ['inventory-shelf-life-pageless', null, null, true, null],
    queryFn: fetchShelfLife,
    enabled: exportToggler,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `STOCK-MONITORING-${dayjs(new Date()).format(
        'DD-MM-YYYY',
      )}`;
      const data = response.data.data.products.map((product) => {
        return {
          SKU: product.sku,
          'Product Name': `
          ${
            product.shipmentFormatName
              ? product.shipmentFormatName
              : product.name
          }`,
          'Distribution Point': product.locationName,
          //Address: product.address,
          'SL 0 - 29 Days Quantity - TH': product.stickCount29Day / 1000,
          'SL 30 - 59 Days Quantity - TH': product.stickCount59Day / 1000,
          'SL 60 - 120 Days Quantity - TH': product.stickCount120Day / 1000,
          'Total Quantity - TH': product.stickCount / 1000,
        };
      });
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setLocationSearchKey(null);
    setSearchByLocation(null);
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      pagelessRefetch();
    } else {
      pagelessFetching();
    }
  };

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const { products, total } = data.data.data;

  return (
    <div>
      <Flex justify="space-between" align="center" py="sm">
        <Flex gap={20}>
          <div
            style={{
              minWidth: '300px',
            }}>
            <SearchInput
              handleRefresh={handleRefresh}
              handleSearch={handleSearch}
              placeholder="Search SKU, Name"
              invokeRefresh={invokingRefreshForSearchInput}
              refreshBtn={false}
            />
          </div>
          <Autocomplete
            placeholder="Search by Point"
            sx={{
              minWidth: '250px',
            }}
            onChange={(value) => {
              if (locationSearchKey && locationSearchKey.length === 0) {
                setLocationSearchKey(null);
              } else {
                setLocationSearchKey(value);
              }
            }}
            limit={locationData?.length || 0}
            maxDropdownHeight="200px"
            dropdownPosition="bottom"
            nothingFound="No options"
            value={locationSearchKey || ''}
            onItemSubmit={(e) => {
              setSearchByLocation(e.id);
            }}
            rightSection={
              searchByLocation ? (
                <UnstyledButton
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setLocationSearchKey(null);
                    setSearchByLocation(null);
                  }}>
                  <IconX size={15} />
                </UnstyledButton>
              ) : null
            }
            data={
              userWiseAutocompleteLocationDataWithId({
                appUser: appUser,
                locationData: locationData,
              }) || []
            }
          />

          <Flex gap={20} align="center" justify="center">
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={() => {
                  handleRefresh();
                }}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Stack
        sx={{
          minHeight: '50vh',
        }}>
        {isArrayAndHasContent(products) ? (
          <ScrollArea h="65vh" w="90vw">
            <Table highlightOnHover>
              <thead
                style={{
                  position: 'sticky',
                  top: '0px',
                  zIndex: '1',
                  backgroundColor: 'white',
                }}>
                <tr>
                  <th
                    style={{
                      // position: 'sticky',
                      backgroundColor: 'white',
                      width: '100px',
                      minWidth: '100px',
                      maxWidth: '100px',
                      // left: '0px',
                    }}>
                    SKU
                  </th>
                  <th
                    style={{
                      // position: 'sticky',
                      backgroundColor: 'white',
                      width: '200px',
                      minWidth: '200px',
                      maxWidth: '200px',
                      // left: '100px',
                    }}>
                    Product name
                  </th>
                  <th
                    style={{
                      // position: 'sticky',
                      backgroundColor: 'white',
                      width: '200px',
                      minWidth: '200px',
                      maxWidth: '200px',
                      // left: '300px',
                    }}>
                    Current Location
                  </th>
                  <th
                    style={{
                      backgroundColor: COLORS.redMatt,
                      width: '200px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}>
                    SL 0 - 29 Days Quantity - TH
                  </th>
                  <th
                    style={{
                      backgroundColor: COLORS.orange,
                      width: '200px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}>
                    SL 30 - 59 Days Quantity - TH
                  </th>
                  <th
                    style={{
                      backgroundColor: COLORS.green,
                      width: '200px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}>
                    SL 60 - 120 Days Quantity - TH
                  </th>
                  {/* <th
                    style={{
                      backgroundColor: COLORS.green,
                      width: '200px',
                      minWidth: '200px',
                      maxWidth: '200px',
                    }}>
                    Shelf life 60 - 120 Days Quantity - TH
                  </th> */}
                  <th
                    style={{
                      // position: 'sticky',
                      backgroundColor: 'white',
                      width: '150px',
                      minWidth: '150px',
                      maxWidth: '150px',
                      // right: '0px',
                    }}>
                    Total Quantity - TH
                  </th>
                </tr>
              </thead>
              <tbody>
                <LoadingOverlay visible={isFetching} />
                {products.map((product) => (
                  <tr key={product.uid}>
                    <td
                      style={{
                        // position: 'sticky',
                        backgroundColor: 'white',
                        width: '100px',
                        minWidth: '100px',
                        maxWidth: '100px',
                        // left: '0px',
                      }}>
                      <Box
                        //component={Link}
                        //to={`/inventory/product-details/${product.productUid}`}
                        sx={{
                          textDecoration: 'none',
                        }}>
                        {product.sku}
                      </Box>
                    </td>
                    <td
                      style={{
                        // position: 'sticky',
                        backgroundColor: 'white',
                        width: '200px',
                        minWidth: '200px',
                        maxWidth: '200px',
                        // left: '100px',
                      }}>
                      <Box
                        //component={Link}
                        //to={`/inventory/product-details/${product.productUid}`}
                        sx={{
                          textDecoration: 'none',
                        }}>
                        {product.shipmentFormatName
                          ? product.shipmentFormatName
                          : product.name}
                      </Box>
                    </td>
                    <td
                      style={{
                        // position: 'sticky',
                        backgroundColor: 'white',
                        width: '200px',
                        minWidth: '200px',
                        maxWidth: '200px',
                        // left: '200px',
                      }}>
                      <HoverCard width={280} shadow="md" withinPortal>
                        <HoverCard.Target>
                          <UnstyledButton>
                            <Text fz="sm">{product.locationName}</Text>
                          </UnstyledButton>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Text size="md">{product.locationName}</Text>
                          <Text size="sm">
                            {product.district || 'N/A'},{' '}
                            {product.division || 'N/A'}
                          </Text>
                          <Text size="sm">{product.address}</Text>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </td>
                    <td>{product.stickCount29Day / 1000}</td>
                    <td>{product.stickCount59Day / 1000}</td>
                    <td>{product.stickCount120Day / 1000}</td>
                    {/* <td>{product.stickCount120Day}</td> */}
                    <td
                      style={{
                        // position: 'sticky',
                        backgroundColor: 'white',
                        width: '100px',
                        minWidth: '100px',
                        maxWidth: '100px',
                        // right: '0px',
                      }}>
                      {product.stickCount / 1000}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        ) : (
          <Flex align="center" justify="center" style={{ height: '68vh' }}>
            <NoDataPlaceholder
              title="No Inventory found"
              subtext={' '}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Flex>
        )}
        <Flex justify="space-between">
          <Stack justify="end">
            <ExportCsvXls
              setExportType={setExportType}
              exportData={exportData}
              btnLoader={pagelessFetching}
            />
          </Stack>
          <Pagination
            mt="20px"
            value={page}
            onChange={setPage}
            total={Math.ceil(total / 10)}
          />
        </Flex>
      </Stack>
    </div>
  );
};

export default StockMonitoringTab;
