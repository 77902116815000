import { Button, Flex, Skeleton, Space, Stack, Title } from '@mantine/core';
import { IconArrowLeft, IconBoxOff } from '@tabler/icons-react';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchGodownKeeperAppVersionList,
  reportGenerate,
} from '../../../services/reports';
import { useMutation, useQuery } from '@tanstack/react-query';
import ServerErrorBox from '../../../components/Global/ServerErrorBox';
import {
  handleErrorResponse,
  isArrayAndHasContent,
} from '../../../utils/utils';
import { MantineReactTable } from 'mantine-react-table';
import NoDataPlaceholder from '../../../components/Global/NoDataPlaceholder';
import ExportCsvXls from '../../../components/Global/ExportCsvXlsx';
import exportFromJSON from 'export-from-json';
import dayjs from 'dayjs';
import COLORS from '../../../constants/colors';
import { useSelector } from 'react-redux';
import { notifications } from '@mantine/notifications';
import { NotificationUtil } from '../../../utils/notifications';
import { removeExportFile } from '../../../services/dashboard';

const PAGE_TITLE = 'Godown Keeper App Version List ';

const GODOWN_KEEPER_APP_VERSION_LIST = () => {
  const navigate = useNavigate();

  const token = useSelector((state) => state?.auth?.accessToken);
  const refreshToken = useSelector((state) => state?.auth?.refreshToken);

  const {
    data: reportData,
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: ['GODOWN_KEEPER_APP_VERSION_LIST'],
    queryFn: fetchGodownKeeperAppVersionList,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const { mutate: ExportMutate, isLoading: ExportLoading } = useMutation({
    mutationFn: () =>
      reportGenerate({
        reportName: 'godown',
        startDate: new Date(),
        endDate: new Date(),
      }),
    keepPreviousData: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      handleDownloadImage({
        fileUrl: data.data.filePath,
        fileName: data.data.fileName,
      });
    },
  });

  const handleDownloadImage = async (file) => {
    try {
      const response = await fetch(file.fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-refresh-token': refreshToken,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifications.clean();
      NotificationUtil({
        success: true,
        title: 'File Downloaded',
        message: 'Your file has been downloaded successfully.',
      });
      removeExportFileMutate(file.fileName);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const { mutate: removeExportFileMutate, isLoading: removeExportFileLoading } =
    useMutation({
      mutationFn: (fileName) => removeExportFile(fileName),
      keepPreviousData: false,
    });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'designation',
        header: 'Designation',
      },
      {
        accessorKey: 'location',
        header: 'Location',
      },
      {
        accessorKey: 'app_version',
        header: 'App Version',
      },
    ],
    [],
  );

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Space h="lg" />
      <Stack>
        <Stack align="end">
          <Button
            onClick={() => {
              ExportMutate();
              NotificationUtil({
                success: true,
                title: 'Processing',
                message:
                  'Your request is being processed. Feel free to browse the app while waiting for the download to finish.',
                autoClose: false,
              });
            }}
            loading={ExportLoading}
            sx={{
              backgroundColor: COLORS.primary,
            }}>
            Download XLS
          </Button>
        </Stack>
        <MantineReactTable
          columns={columns}
          data={reportData.data.data}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          // enableColumnVirtualization
          enablePagination={false}
          // enableColumnActions={false}
          enableRowVirtualization
          mantineTableContainerProps={{
            sx: { height: '80vh' },
          }}
          mantineTableProps={{
            withColumnBorders: true,
          }}
          state={{ isLoading: isLoading || isFetching }}
          rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
          // columnVirtualizerProps={{ overscan: 2 }}
          getRowId={(originalRow) => originalRow.id}
          mantineTableHeadCellProps={{
            sx: {
              backgroundColor: COLORS.primary,
              color: 'white !important',
            },
          }}
          mantineFilterTextInputProps={{
            styles: {
              input: { color: 'white' },
            },
          }}
          renderEmptyRowsFallback={() => (
            <Stack
              justify="center"
              align="center"
              sx={{
                height: '51vh',
              }}>
              <NoDataPlaceholder
                title="No report data found"
                subtext={' '}
                icon={<IconBoxOff size={70} color="#4562f7" />}
              />
            </Stack>
          )}
        />
      </Stack>
    </div>
  );
};

export default GODOWN_KEEPER_APP_VERSION_LIST;
