import { Button, Drawer, Flex, Paper, Stack, Tabs, Title } from '@mantine/core';
import { IconArticle, IconChevronLeft } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LogsDrawer from '../../../components/Global/LogsDrawer';
import AreasTab from '../../../components/Location/ManageTabs/AreasTab';
import DistributorsTab from '../../../components/Location/ManageTabs/DistributorsTab';
import RegionsTab from '../../../components/Location/ManageTabs/RegionsTab';
import TerritoriesTab from '../../../components/Location/ManageTabs/TerritoriesTab';
import COLORS from '../../../constants/colors';
import { actionLoggerReferenceTypes } from '../../../constants/const';
import { fetchArea } from '../../../services/area';
import { handleErrorResponse } from '../../../utils/utils';
import { useQueries, useQuery } from '@tanstack/react-query';
import { fetchRegion } from '../../../services/region';
import { fetchDistributor } from '../../../services/distributor';

const PAGE_TITLE = 'Manage Locations';

const LocationsManage = () => {
  const navigate = useNavigate();

  const [logsDrawer, setLogsDrawer] = useState(false);

  const [activeTab, setActiveTab] = useState('regions');
  const [tabValues, setTabValues] = useState({});

  const [regionPagelessdata, areaPagelessData, distributorPagelessdata] =
    useQueries({
      queries: [
        {
          queryKey: ['region-pagelessData', null, null, true],
          queryFn: fetchRegion,
          refetchOnWindowFocus: false,
          retry: false,
        },
        {
          queryKey: ['area-pagelessData', null, null, null, true],
          queryFn: fetchArea,
          refetchOnWindowFocus: false,
          retry: false,
        },
        {
          queryKey: ['distributor-pagelessData', null, null, null, null, true],
          queryFn: fetchDistributor,
          refetchOnWindowFocus: false,
          retry: false,
        },
      ],
    });

  const tabList = [
    {
      label: 'Regions',
      value: 'regions',
      element: <RegionsTab />,
    },
    {
      label: 'Areas',
      value: 'areas',
      element: (
        <AreasTab
          regionPagelessdata={
            regionPagelessdata?.data?.data?.data?.regions || []
          }
        />
      ),
    },
    {
      label: 'Distribution Houses',
      value: 'distributors',
      element: (
        <DistributorsTab
          regionPagelessdata={
            regionPagelessdata?.data?.data?.data?.regions || []
          }
          areaPagelessData={areaPagelessData?.data?.data?.data?.areas || []}
        />
      ),
    },
    {
      label: 'Territories',
      value: 'territories',
      element: (
        <TerritoriesTab
          regionPagelessdata={
            regionPagelessdata?.data?.data?.data?.regions || []
          }
          areaPagelessData={areaPagelessData?.data?.data?.data?.areas || []}
          distributorPagelessdata={
            distributorPagelessdata?.data?.data?.data?.distributors || []
          }
        />
      ),
    },
  ];

  useEffect(() => {
    let tempData = {};
    tabList.forEach((tab) => {
      tempData[tab.value] = tab.value;
    });
    setTabValues(tempData);
  }, []);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}

      <Drawer
        opened={logsDrawer}
        onClose={() => {
          setLogsDrawer(false);
        }}
        title="Distribor Logs"
        position="right">
        <LogsDrawer
          referenceType={actionLoggerReferenceTypes.DISTRIBUTOR_MANAGEMENT}
          singleOrderFetch={false}
        />
      </Drawer>

      {/* CUSTOM MODAL AND DRAWER END */}
      <Stack justify="start" align="start" mb={5}>
        <Button
          color="gray"
          onClick={() => {
            navigate(-1);
          }}
          leftIcon={<IconChevronLeft />}>
          Back
        </Button>
      </Stack>
      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Flex gap={10}>
          <Button
            onClick={() => setLogsDrawer(true)}
            color="violet"
            leftIcon={<IconArticle />}>
            Logs
          </Button>
        </Flex>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Tabs
          variant="outline"
          defaultValue="gallery"
          value={activeTab}
          onTabChange={handleTabChange}>
          <Tabs.List>
            {tabList.map((tab) => (
              <Tabs.Tab
                value={tab.value}
                key={tab.value}
                sx={{
                  backgroundColor:
                    activeTab === tabValues[tab.value]
                      ? COLORS.primary
                      : COLORS.white,
                  color:
                    activeTab === tabValues[tab.value]
                      ? COLORS.white
                      : COLORS.black,
                }}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {tabList.map((tab) => (
            <Tabs.Panel value={tab.value} key={tab.value} p="xs">
              {tab.element}
            </Tabs.Panel>
          ))}
        </Tabs>
      </Paper>
    </div>
  );
};

export default LocationsManage;
