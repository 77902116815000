import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Card,
  Flex,
  Loader,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import COLORS from '../../constants/colors';
import { userRoles } from '../../constants/const';
import { fetchLocations } from '../../services/location';
import {
  assignLocationToUser,
  removeLocationFromUser,
} from '../../services/user';
import { NotificationUtil } from '../../utils/notifications';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../utils/utils';

const AssignLocationModal = ({ user, onClose, onUpdate }) => {
  const [searchKey, setSearchKey] = useState(null);

  const [selectedLocations, setSelectedLocations] = useState(null);

  const [assignedLocations, setAssignedLocations] = useState([]);

  useEffect(() => {
    if (isObjectAndHasProperties(user)) {
      setAssignedLocations(user.userLocations);
    }
  }, [user]);

  const { data, isLoading, error } = useQuery({
    queryKey: ['location', 1, null, null, null, true],
    queryFn: fetchLocations,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const handleSelectedLocations = (value) => {
    if (isArrayAndHasContent(selectedLocations)) {
      if (userRoles[user?.role] === userRoles.OPERATOR) {
        if (selectedLocations.length === 1) {
          NotificationUtil({
            success: false,
            title: 'Error',
            message: 'You can only assign one location to this user',
          });
          return;
        }
      }
      if (selectedLocations.find((item) => item.id === value.id)) return;

      const tempData = [];
      tempData.push(...selectedLocations);
      tempData.push(value);
      setSelectedLocations(tempData);
    } else {
      if (userRoles[user?.role] === userRoles.OPERATOR) {
        if (assignedLocations.length === 1) {
          NotificationUtil({
            success: false,
            title: 'Error',
            message: 'This user already has a location assigned',
          });
          return;
        }
      }
      const tempData = [];
      tempData.push(value);
      setSelectedLocations(tempData);
    }
  };

  const handleOmitLocation = (location) => {
    const tempData = selectedLocations.filter(
      (item) => item.id !== location.id,
    );
    setSelectedLocations(tempData);
  };

  const handleSubmit = () => {
    const locationIds = [];
    selectedLocations.forEach((location) => {
      locationIds.push(location.id);
    });
    assignMutate({
      uid: user.uid,
      locations: locationIds,
    });
  };

  const updateAssignedLocations = ({ locationUid }) => {
    const tempData = assignedLocations.filter(
      (item) => item.uid !== locationUid,
    );
    setAssignedLocations(tempData);
  };

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to assign this location to this user?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        handleSubmit();
      },
    });
  };

  const ConfirmDeleteModal = (location) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to remove this location from this user?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        removeMutate({
          locationUid: location.uid,
          userUid: user.uid,
        });
      },
    });
  };

  const { mutate: assignMutate, isLoading: isAssigning } = useMutation({
    mutationFn: async (values) => await assignLocationToUser(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Assigned',
        message: 'Location assigned successfully',
      });
      onUpdate({ close: true });
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: removeMutate, isLoading: isRemoving } = useMutation({
    mutationFn: async (values) => await removeLocationFromUser(values),
    onSuccess: (data, variables, context) => {
      NotificationUtil({
        success: true,
        title: 'Removed',
        message: 'Location removed successfully',
      });
      onUpdate({ close: false });
      updateAssignedLocations(variables);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  if (error) {
    onClose();
    return;
  }

  return (
    <Stack
      sx={{
        height: '500px',
        justifyContent: 'space-between',
      }}>
      <LoadingOverlay
        visible={isLoading}
        overlayBlur={2}
        loader={
          <Stack justify="center" align="center">
            <Text>Fetching locations. Please wait</Text>
            <Loader />
          </Stack>
        }
      />
      <Stack>
        <Autocomplete
          label="Search and select locations"
          placeholder="Pick a location"
          onChange={(value) => {
            if (searchKey && searchKey.length === 0) {
              setSearchKey(null);
            } else {
              setSearchKey(value.trim());
            }
          }}
          limit={data?.data?.data?.locations.length || 0}
          maxDropdownHeight="200px"
          dropdownPosition="bottom"
          nothingFound="No options"
          value={searchKey || ''}
          onItemSubmit={handleSelectedLocations}
          onDropdownClose={() => {
            setSearchKey(null);
          }}
          data={
            data?.data?.data?.locations.map((location) => ({
              id: location.uid,
              value: location.name,
            })) || []
          }
        />
        {isObjectAndHasProperties(selectedLocations) ? (
          <Card my={10} shadow="xs" padding="md">
            <Flex gap={5} align="center" wrap="wrap">
              {isObjectAndHasProperties(selectedLocations)
                ? selectedLocations.map((location, index) => (
                    <Badge key={index}>
                      <Flex gap={5}>
                        <Text>{location.value}</Text>
                        <ActionIcon
                          color="orange"
                          size="xs"
                          radius="xs"
                          variant="transparent"
                          onClick={() => {
                            handleOmitLocation(location);
                          }}>
                          <IconX size={12} />
                        </ActionIcon>
                      </Flex>
                    </Badge>
                  ))
                : null}
            </Flex>
          </Card>
        ) : null}
        <Stack>
          <Card my={10} shadow="xs" padding="xs">
            <Text size="sm">Assigned locations</Text>
            <Stack p="xs">
              <Flex gap={5} align="center" my={5} wrap="wrap">
                {isArrayAndHasContent(assignedLocations) ? (
                  assignedLocations.map((location, index) => (
                    <Badge key={index} color="grape">
                      <Flex gap={5}>
                        <Text>{location.name}</Text>
                        <ActionIcon
                          color="orange"
                          size="xs"
                          radius="xs"
                          variant="transparent"
                          onClick={() => {
                            ConfirmDeleteModal(location);
                          }}>
                          <IconX size={12} />
                        </ActionIcon>
                      </Flex>
                    </Badge>
                  ))
                ) : (
                  <Stack sx={{ width: '100%' }}>
                    <Text c="dimmed" align="center">
                      No location assigned yet
                    </Text>
                  </Stack>
                )}
              </Flex>
            </Stack>
          </Card>
        </Stack>
      </Stack>
      <Stack justify="end">
        <Button
          sx={{
            backgroundColor: COLORS.primary,
          }}
          onClick={() => {
            ConfirmModal();
          }}
          loading={isAssigning || isRemoving}
          disabled={!isObjectAndHasProperties(selectedLocations)}>
          Assign
        </Button>
      </Stack>
    </Stack>
  );
};

export default AssignLocationModal;
