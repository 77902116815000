import { Box, Button, Loader, Paper, Stack, Text, Title } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import ReCAPTCHA from 'react-google-recaptcha';

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import COLORS from '../../constants/colors';
import { getLatestDistribution } from '../../services/app-dist';
import { useQuery } from '@tanstack/react-query';

const DownloadApp = () => {
  const [captchaSolvedToken, setcaptchaSolvedToken] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const { refetch: latestDownloadRefetch } = useQuery({
    queryKey: ['mobile-applications-latest-download'],
    queryFn: getLatestDistribution,
    enabled: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    refetchIntervalInBackground: true,
    retry: false,
    onSuccess: (data) => {
      const fileName = data?.data?.data?.appUrl;
      setDownloading(true);
      handleDownloadImage(fileName);
    },
  });

  const handleDownloadImage = (fileName) => {
    fetch(`${process.env.REACT_APP_SERVER_URL}/${fileName}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setDownloading(false);
      })
      .catch((error) => {
        setDownloading(false);
        console.error('Error downloading image:', error);
      });
  };
  return (
    <Stack
      align="center"
      justify="center"
      sx={{
        height: '100vh',
      }}>
      <Paper p="lg" radius="xs" shadow="md">
        <Stack
          sx={{
            width: 310,
          }}>
          <Box
            component="img"
            src="/logo.png"
            sx={{
              flex: 1,
            }}
          />
          <Title order={3} align="center">
            Download the Armada app
          </Title>
          {/* <ReCAPTCHA
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
            onChange={(token) => setcaptchaSolvedToken(token)}
          /> */}

          {captchaSolvedToken ? (
            <Button
              fontSize="md"
              fontWeight="bold"
              mt="15px"
              w="100%"
              color="white"
              disabled={downloading}
              download={true}
              target="_blank"
              onClick={latestDownloadRefetch}>
              <Stack justify="center" align="center">
                {downloading ? (
                  <Loader />
                ) : (
                  <IconDownload color={COLORS.white} />
                )}
              </Stack>
            </Button>
          ) : (
            <Text align="center" fz="sm" c="dimmed">
              Solve captcha to download
            </Text>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default DownloadApp;
