import {
  Badge,
  Button,
  Divider,
  Flex,
  Group,
  Loader,
  LoadingOverlay,
  MultiSelect,
  Select,
  Stack,
  Stepper,
  Text,
  TextInput,
  TypographyStylesProvider,
} from '@mantine/core';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { emailReportSlots, userWeight } from '../../constants/const';
import { fetchDistributor } from '../../services/distributor';
import { fetchUsersEmails } from '../../services/user';
import {
  handleErrorResponse,
  userWiseMultiSelectDistributorDataWithId,
} from '../../utils/utils';
import COLORS from '../../constants/colors';
import {
  createEmailReportSettings,
  liveCheckIfReportGroupExists,
  updateEmailReportSettings,
} from '../../services/reports';
import { NotificationUtil } from '../../utils/notifications';
import { openConfirmModal } from '@mantine/modals';

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const days = [
  { value: 'Sunday', label: 'Sunday' },
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
  { value: 'Saturday', label: 'Saturday' },
];

const AddSettingsModal = ({ onClose, onUpdate, defaultValues, update }) => {
  const appUser = useSelector((state) => state.auth.user);
  const queryClient = useQueryClient();

  const emailInputRef = useRef(null);
  const emailInputRef2 = useRef(null);

  const [active, setActive] = useState(0);

  const [errors, setErrors] = useState({
    error1: false,
    error2: false,
    error3: false,
  });

  const [reportName, setReportName] = useState('');
  const [selectedDistributor, setselectedDistributor] = useState([]);
  const [selectedReports, setselectedReports] = useState([]);

  const [emailData, setEmailData] = useState([]);
  const [selectedEmailsTo, setSelectedEmailsTo] = useState([]);
  const [selectedEmailsCc, setSelectedEmailsCc] = useState([]);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedSlot, setselectedSlot] = useState(null);

  useEffect(() => {
    if (update) {
      setReportName(defaultValues.reportName);
      setselectedReports(defaultValues.reportFilters.reports);
      setselectedDistributor(
        defaultValues.reportFilters.locations.map((item) => item.id),
      );
      setSelectedEmailsTo(defaultValues.emails.to);
      setSelectedEmailsCc(defaultValues.emails.cc);
      setEmailSubject(defaultValues.emailSubject);
      setEmailBody(defaultValues.emailBody);
      setSelectedDays(defaultValues.schedule.map((item) => item.day));
      setselectedSlot(defaultValues.schedule[0].slot);
    }
  }, []);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content: defaultValues?.emailBody || emailBody,
    onUpdate: ({ editor }) => {
      setEmailBody(editor.getHTML());
    },
  });

  useEffect(() => {
    if (appUser.userWeight === userWeight.MANAGER) {
      setselectedDistributor(appUser.distributors.map((dist) => dist.id));
    }
  }, []);

  const nextStep = () => {
    if (
      active === 0 &&
      (!reportName ||
        selectedReports.length === 0 ||
        selectedDistributor.length === 0)
    ) {
      setErrors({
        ...errors,
        error1: true,
      });
      return;
    } else {
      setErrors({
        ...errors,
        error1: false,
      });
    }
    if (active === 1 && (selectedEmailsTo.length === 0 || !emailSubject)) {
      setErrors({
        ...errors,
        error2: true,
      });
      return;
    } else {
      setErrors({
        ...errors,
        error2: false,
      });
    }
    if (active === 2 && (selectedDays.length === 0 || !selectedSlot)) {
      setErrors({
        ...errors,
        error3: true,
      });
      return;
    } else {
      setErrors({
        ...errors,
        error3: false,
      });
    }
    if (active === 3) {
      const data = {
        reportName: reportName,
        reportFilters: {
          reports: selectedReports,
          locations: selectedDistributor,
        },
        emails: {
          to: selectedEmailsTo,
          cc: selectedEmailsCc,
        },
        emailSubject: emailSubject,
        emailBody: emailBody,
        schedule: selectedDays.map((day) => ({
          day: day,
          slot: selectedSlot,
        })),
      };
      ConfirmModal(data);
    } else {
      if (active === 0) {
        if (defaultValues && defaultValues.reportName === reportName) {
          setActive((current) => (current < 3 ? current + 1 : current));
          return;
        }
        checkReportNameMutate(reportName);
        return;
      }
      setActive((current) => (current < 3 ? current + 1 : current));
    }
  };
  const prevStep = () => {
    if (active === 0) {
      onClose();
    } else {
      setActive((current) => (current > 0 ? current - 1 : current));
    }
  };

  const distributorData = queryClient.getQueryData([
    'distributor-pagelessData',
    null,
    null,
    null,
    null,
    true,
  ]);

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you {update ? 'update' : 'create'} this email report?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        if (update) {
          updateMutate(values);
        } else {
          createMutate(values);
        }
      },
    });
  };

  const callLocation = useQuery({
    queryKey: ['distributor-pagelessData', null, null, null, null, true],
    enabled: distributorData == undefined,
    queryFn: fetchDistributor,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { data: userData } = useQuery({
    queryKey: ['user-emails'],
    queryFn: fetchUsersEmails,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { mutate: createMutate, isLoading: isCreating } = useMutation({
    mutationFn: async (values) => await createEmailReportSettings(values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (values) =>
      await updateEmailReportSettings(defaultValues.uid, values),
    onSuccess: () => {
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: checkReportNameMutate, isLoading: isChecking } = useMutation({
    mutationFn: async (reportName) =>
      await liveCheckIfReportGroupExists(reportName),
    onSuccess: () => {
      setActive((current) => (current < 3 ? current + 1 : current));
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  useEffect(() => {
    if (userData) {
      if (!update) {
        setEmailData(
          userData.data.data.users.map((item) => ({
            value: item.email,
            label: item.email,
            name: item.name,
            designation: item.designation,
            house: item.userDistributors.map((item) => item.name).join(', '),
          })),
        );
      } else {
        let temp = [];

        userData.data.data.users.map((item) => {
          temp.push({
            value: item.email,
            label: item.email,
            name: item.name,
            designation: item.designation,
            house: item.userDistributors.map((item) => item.name).join(', '),
          });
        });

        if (defaultValues.emails.to.length > 0) {
          defaultValues.emails.to.forEach((item) => {
            if (
              !userData.data.data.users.find((email) => email.value === item)
            ) {
              temp.push({
                value: item,
                label: item,
                name: item,
                designation: 'New Email',
                house: null,
              });
            }
          });
        }

        if (defaultValues.emails.cc.length > 0) {
          defaultValues.emails.cc.forEach((item) => {
            if (
              !userData.data.data.users.find((email) => email.value === item)
            ) {
              temp.push({
                value: item,
                label: item,
                name: item,
                designation: 'New Email',
                house: null,
              });
            }
          });
        }

        setEmailData(temp);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (selectedEmailsTo.length > 0 && emailInputRef.current) {
      emailInputRef.current.value = '';
    }
  }, [selectedEmailsTo]);

  useEffect(() => {
    if (selectedEmailsCc.length > 0 && emailInputRef2.current) {
      emailInputRef2.current.value = '';
    }
  }, [selectedEmailsCc]);

  return (
    <div>
      <LoadingOverlay
        visible={isCreating || isUpdating}
        loader={
          <Stack justify="center" align="center">
            <Text>{update ? 'Updating' : 'Creating'} email report</Text>
            <Loader />
          </Stack>
        }
      />
      <Stepper active={active} onStepClick={null} breakpoint="sm">
        <Stepper.Step label="Configure your report">
          <Stack
            sx={{
              minHeight: '350px',
            }}>
            <TextInput
              placeholder="Enter your report name"
              label="Report name"
              value={reportName}
              onChange={(event) => {
                setReportName(event.target.value);
              }}
              error={
                errors.error1 && !reportName ? (
                  <span>Please give your report a name</span>
                ) : (
                  false
                )
              }
            />
            <MultiSelect
              data={[
                {
                  value: 'SO SCAN PERFORMANCE REPORT',
                  label: 'SO SCAN PERFORMANCE REPORT',
                },
                {
                  value: 'IGT SCAN PERFORMANCE REPORT',
                  label: 'IGT SCAN PERFORMANCE REPORT',
                },
                {
                  value: 'GODOWN KEEPER APP VERSION LIST',
                  label: 'GODOWN KEEPER APP VERSION LIST',
                },
                {
                  value: 'DAILY OPEN CBC PERFORMANCE REPORT',
                  label: 'DAILY OPEN CBC PERFORMANCE REPORT',
                },
                {
                  value: 'ONE VIEW REPORT',
                  label: 'ONE VIEW REPORT',
                },
              ]}
              label="Reports"
              placeholder="Pick the reports you want to send"
              dropdownPosition="bottom"
              onChange={setselectedReports}
              value={selectedReports}
              error={
                errors.error1 && selectedReports.length === 0 ? (
                  <span>Please select atleast one report</span>
                ) : (
                  false
                )
              }
            />
            <MultiSelect
              label="Distribution House"
              placeholder="Select distribution house"
              dropdownPosition="bottom"
              data={
                userWiseMultiSelectDistributorDataWithId({
                  appUser,
                  distributorData:
                    distributorData?.data.data.distributors || [],
                }) || []
              }
              onChange={setselectedDistributor}
              value={selectedDistributor}
              clearable
              searchable
              withinPortal
              error={
                errors.error1 && selectedDistributor.length === 0 ? (
                  <span>Please select atleast one distribution house</span>
                ) : (
                  false
                )
              }
            />
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Configure your email">
          <Stack
            sx={{
              minHeight: '350px',
            }}>
            <MultiSelect
              ref={emailInputRef}
              label="To"
              placeholder="Enter email address"
              dropdownPosition="bottom"
              data={emailData}
              itemComponent={({
                name,
                label,
                designation,
                email,
                house,
                ...others
              }) => (
                <div {...others}>
                  <Group noWrap>
                    <div>
                      <Text>{name}</Text>
                      <Text size="xs" color="dimmed">
                        {label} | {designation} | {house || ''}
                      </Text>
                    </div>
                  </Group>
                </div>
              )}
              clearable
              searchable
              withinPortal
              creatable
              onChange={(value) => {
                if (value.length === 0) {
                  setSelectedEmailsTo([]);
                  return;
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value[value.length - 1])) {
                  return;
                }
                setSelectedEmailsTo(value);
              }}
              value={selectedEmailsTo}
              getCreateLabel={(query) => `+ add ${query}`}
              filter={(value, selected, item) =>
                !selected &&
                (item?.label
                  .toLowerCase()
                  .includes(value.toLowerCase().trim()) ||
                  item?.name.toLowerCase().includes(value.toLowerCase().trim()))
              }
              onCreate={(query) => {
                const item = {
                  value: query,
                  label: query,
                  name: query,
                  designation: 'New Email',
                  house: null,
                };
                setEmailData((current) => [...current, item]);
                return item;
              }}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === 'Enter') {
                  if (event.target.value === '') return;
                  if (
                    emailData.find((item) => item.value === event.target.value)
                  )
                    return;

                  // Email validation using regular expression

                  if (!emailRegex.test(event.target.value)) {
                    return;
                  }
                  const item = {
                    value: event.target.value,
                    label: event.target.value,
                    name: event.target.value,
                    designation: 'New Email',
                    house: null,
                  };
                  setEmailData((current) => [...current, item]);
                  setSelectedEmailsTo((current) => [
                    ...current,
                    event.target.value,
                  ]);
                  return item;
                }
              }}
              error={
                errors.error2 && selectedEmailsTo.length === 0 ? (
                  <span>Please select atleast one email address</span>
                ) : (
                  false
                )
              }
            />
            <MultiSelect
              ref={emailInputRef2}
              label="Cc"
              placeholder="Enter email address"
              dropdownPosition="bottom"
              data={emailData.filter(
                (item) => !selectedEmailsTo.includes(item.value),
              )}
              itemComponent={({
                name,
                label,
                designation,
                email,
                ...others
              }) => (
                <div {...others}>
                  <Group noWrap>
                    <div>
                      <Text>{name}</Text>
                      <Text size="xs" color="dimmed">
                        {label} | {designation}
                      </Text>
                    </div>
                  </Group>
                </div>
              )}
              clearable
              searchable
              withinPortal
              creatable
              onChange={(value) => {
                if (value.length === 0) {
                  setSelectedEmailsCc([]);
                  return;
                }

                if (!emailRegex.test(value[value.length - 1])) {
                  return;
                }
                setSelectedEmailsCc(value);
              }}
              value={selectedEmailsCc}
              getCreateLabel={(query) => `+ add ${query}`}
              filter={(value, selected, item) =>
                !selected &&
                (item?.label
                  .toLowerCase()
                  .includes(value.toLowerCase().trim()) ||
                  item?.name.toLowerCase().includes(value.toLowerCase().trim()))
              }
              onCreate={(query) => {
                const item = {
                  value: query,
                  label: query,
                  name: query,
                  designation: 'New Email',
                  house: null,
                };
                setEmailData((current) => [...current, item]);
                return item;
              }}
              onKeyDown={(event) => {
                if (event.nativeEvent.code === 'Enter') {
                  if (event.target.value === '') return;
                  if (
                    emailData.find((item) => item.value === event.target.value)
                  )
                    return;

                  // Email validation using regular expression
                  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                  if (!emailRegex.test(event.target.value)) {
                    return;
                  }
                  const item = {
                    value: event.target.value,
                    label: event.target.value,
                    name: event.target.value,
                    designation: 'New Email',
                    house: null,
                  };
                  setEmailData((current) => [...current, item]);
                  setSelectedEmailsCc((current) => [
                    ...current,
                    event.target.value,
                  ]);
                  return item;
                }
              }}
            />
            <TextInput
              placeholder="Enter your email subject"
              label="Email subject"
              value={emailSubject}
              onChange={(event) => {
                setEmailSubject(event.target.value);
              }}
              error={
                errors.error2 && !emailSubject ? (
                  <span>Please give your email a subject</span>
                ) : (
                  false
                )
              }
            />
            <Stack
              sx={{
                minHeight: '50px',
              }}
              spacing={0}>
              <Text
                sx={{
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: '#212529',
                }}>
                Email body
              </Text>
              <RichTextEditor editor={editor}>
                <RichTextEditor.Toolbar sticky stickyOffset={60}>
                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Underline />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Highlight />
                    <RichTextEditor.Code />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Blockquote />
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                    <RichTextEditor.Subscript />
                    <RichTextEditor.Superscript />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                  </RichTextEditor.ControlsGroup>

                  <RichTextEditor.ControlsGroup>
                    <RichTextEditor.AlignLeft />
                    <RichTextEditor.AlignCenter />
                    <RichTextEditor.AlignJustify />
                    <RichTextEditor.AlignRight />
                  </RichTextEditor.ControlsGroup>
                </RichTextEditor.Toolbar>

                <RichTextEditor.Content />
              </RichTextEditor>
            </Stack>
          </Stack>
        </Stepper.Step>
        <Stepper.Step label="Set your schedule">
          <Stack
            sx={{
              minHeight: '350px',
            }}>
            <Stack>
              <Text>Pick Days</Text>
              <Flex gap={10} align="center">
                {days.map((day, index) => (
                  <Button
                    key={index}
                    sx={{
                      borderRadius: '50%',
                      width: '50px',
                      height: '50px',
                      border: '1px solid #ccc',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: selectedDays.includes(day.value)
                        ? COLORS.yellow
                        : COLORS.grayish,
                      color: COLORS.black,
                      padding: '0px',
                      margin: '0px',
                    }}
                    onClick={() => {
                      if (selectedDays.includes(day.value)) {
                        setSelectedDays((current) =>
                          current.filter((item) => item !== day.value),
                        );
                      } else {
                        setSelectedDays((current) => [...current, day.value]);
                      }
                    }}>
                    <Text align="center">{day.label[0]}</Text>
                  </Button>
                ))}
              </Flex>
              {errors.error3 && selectedDays.length === 0 ? (
                <span
                  style={{
                    color: '#fa5252',
                    fontSize: 'calc(0.875rem - 0.125rem)',
                    lineHeight: '1.2',
                  }}>
                  Please select atleast one day
                </span>
              ) : (
                false
              )}
            </Stack>
            <Select
              label="Select mailing slot"
              placeholder="Slot"
              data={emailReportSlots}
              sx={{
                width: '100%',
              }}
              value={selectedSlot}
              onChange={setselectedSlot}
              error={
                errors.error3 && !selectedSlot ? (
                  <span>Please select a slot</span>
                ) : (
                  false
                )
              }
            />
          </Stack>
        </Stepper.Step>
        <Stepper.Completed>
          <Stack
            sx={{
              minHeight: '350px',
            }}>
            <Text>Review your settings</Text>
            <Divider />
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Report name
              </Text>
              <Text>{reportName}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Reports
              </Text>
              <Flex gap={5} wrap="wrap" align="center">
                {selectedReports.map((item, index) => (
                  <Badge key={index} color="cyan" radius="sm">
                    {item}
                  </Badge>
                ))}
              </Flex>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Distribution House
              </Text>
              <Flex gap={5} wrap="wrap" align="center">
                {selectedDistributor.map((item, index) => (
                  <Badge key={index} color="orange" radius="sm">
                    {
                      distributorData?.data.data.distributors.find(
                        (d) => d.id === item,
                      ).name
                    }
                  </Badge>
                ))}
              </Flex>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                To
              </Text>
              <Text>{selectedEmailsTo.join(', ')}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Cc
              </Text>
              <Text>{selectedEmailsCc.join(', ')}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Email Subject
              </Text>
              <Text>{emailSubject}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Email Body
              </Text>
              <TypographyStylesProvider>
                <div
                  style={{
                    fontSize: 'small',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: emailBody,
                  }}
                />
              </TypographyStylesProvider>
            </Stack>
            <Stack spacing={0}>
              <Text fz="xs" c="dimmed">
                Schedule
              </Text>

              <Flex gap={8} wrap="wrap">
                <Flex gap={10} align="center">
                  {days.map((day, index) => (
                    <Stack
                      key={index}
                      sx={{
                        borderRadius: '50%',
                        width: '30px',
                        height: '30px',
                        border: '1px solid #ccc',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: selectedDays.find(
                          (item) => item === day.value,
                        )
                          ? COLORS.yellow
                          : COLORS.grayish,
                        color: COLORS.black,
                        padding: '0px',
                        margin: '0px',
                      }}>
                      <Text align="center">{day.label[0]}</Text>
                    </Stack>
                  ))}
                  <Text>-</Text>
                  <Text>
                    {
                      emailReportSlots.find(
                        (item) => item.value === selectedSlot,
                      )?.label
                    }
                  </Text>
                </Flex>
              </Flex>
            </Stack>
          </Stack>
        </Stepper.Completed>
      </Stepper>

      <Group position="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          {active === 0 ? 'Cancel' : 'Back'}
        </Button>
        <Button onClick={nextStep}>
          {active === 3 ? 'Confirm' : 'Next step'}
        </Button>
      </Group>
    </div>
  );
};

export default AddSettingsModal;
