import {
  Badge,
  Button,
  Flex,
  Loader,
  LoadingOverlay,
  NumberInput,
  Select,
  Space,
  Stack,
  Text,
  Textarea,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MantineReactTable } from 'mantine-react-table';
import React, { useMemo, useRef, useState } from 'react';
import COLORS from '../../constants/colors';
import { acceptOrder, fetchScannedCbcsForApp } from '../../services/orders';
import { NotificationUtil } from '../../utils/notifications';
import { handleErrorResponse, isArrayAndHasContent } from '../../utils/utils';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';

const ReceiveOrderModal = ({
  order,
  onUpdate,
  isFetching,
  closeModal,
  acceptBtnDisabled,
  quantityBreakDownForStats,
}) => {
  const rowVirtualizerInstanceRef = useRef(null);

  const queryClient = useQueryClient();

  const [isPartiallyAccepting, setIsPartiallyAccepting] = useState(false);

  const [receivedAmount, setReceivedAmount] = useState(0);
  const [partialReceiveReason, setPartialReceiveReason] = useState(null);
  const [historyNote, setHistoryNote] = useState(null);

  const columns = useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name',
      Cell: ({ cell }) => <Text fz="xs">{cell.getValue()}</Text>,
    },
    {
      accessorKey: 'barcode',
      header: 'Barcode',
      Cell: ({ cell }) => <Text fz="xs">{cell.getValue()}</Text>,
    },
    {
      accessorKey: 'packcode',
      header: 'Packcode',
      Cell: ({ cell }) => <Text fz="xs">{cell.getValue()}</Text>,
    },
    {
      accessorKey: 'sku',
      header: 'SKU',
      Cell: ({ cell }) => <Text fz="xs">{cell.getValue()}</Text>,
    },
    // {
    //   accessorKey: 'cbcStatus',
    //   header: 'Status',
    //   Cell: ({ cell }) => <Badge color="red">{cell.getValue()}</Badge>,
    // },
  ]);

  const {
    data,
    isLoading,
    refetch,
    isFetching: scannedCbcsFetching,
    error,
  } = useQuery({
    queryKey: ['fetch-scanned-cbcs-for-app', order?.orderNumber],
    queryFn: fetchScannedCbcsForApp,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { mutate: orderAcceptMutate, isLoading: isAccepting } = useMutation({
    mutationFn: async (values) => await acceptOrder(values),
    onSuccess: () => {
      onUpdate();
      closeModal();
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Order Accepted successfully',
      });
    },
    onError: (error) => {
      closeModal();
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const ConfirmScanModal = (order) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to accept this order?</Text>
      ),
      confirmProps: { color: 'green' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        orderAcceptMutate({
          orderNumber: order?.orderNumber,
          isPartiallyReceived: isPartiallyAccepting,
          receivedAmount: receivedAmount || null,
          historyNote: historyNote || null,
          partialReceiveReason: partialReceiveReason || null,
        });
      },
    });
  };

  if (isLoading)
    return (
      <Stack
        justify="center"
        align="center"
        sx={{
          width: '60vw',
          height: '75vh',
        }}>
        <Text>Fetching CBCs. Please wait...</Text>
        <Loader size="xl" variant="dots" />
      </Stack>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const { cbcs } = data.data.data;

  if (isPartiallyAccepting === true)
    return (
      <div style={{ minHeight: '75vh', minWidth: '80vw' }}>
        <Text align="center" fz="lg">
          Please fill out the form
        </Text>
        <Space h="lg" />
        <Stack justify="center" align="center">
          <Stack
            sx={{
              width: '50%',
            }}>
            <NumberInput
              placeholder="Enter the amount you received"
              label="Received Amount"
              value={receivedAmount}
              onChange={(value) => {
                if (value > quantityBreakDownForStats.totalQuantity) {
                  NotificationUtil({
                    success: false,
                    title: 'Error',
                    message:
                      'Received amount cannot be greater than total order quantity',
                  });
                  setReceivedAmount(0);
                  return;
                }
                setReceivedAmount(value);
              }}
              max={quantityBreakDownForStats.totalQuantity}
              min={1}
            />
            <Select
              label="Partial Receive Reason"
              placeholder="Pick a reason"
              data={[
                { value: 'Damaged CBC', label: 'Damaged CBC' },
                { value: 'Missing CBC', label: 'Missing CBC' },
                { value: 'Other', label: 'Other' },
              ]}
              value={partialReceiveReason}
              onChange={(value) => setPartialReceiveReason(value)}
            />
            {partialReceiveReason === 'Other' && (
              <Textarea
                placeholder="Your reason"
                label="Your reason"
                value={historyNote}
                onChange={(event) => setHistoryNote(event.target.value)}
              />
            )}
          </Stack>
          <Space h="lg" />
          <Button
            sx={{
              backgroundColor: COLORS.primary,
            }}
            disabled={
              isAccepting ||
              !receivedAmount ||
              !partialReceiveReason ||
              (partialReceiveReason === 'Other' && !historyNote)
            }
            loading={isAccepting}
            onClick={() => {
              ConfirmScanModal(order);
            }}>
            Partially Accept
          </Button>
        </Stack>
      </div>
    );

  return (
    <div style={{ minHeight: '75vh', minWidth: '80vw' }}>
      {isArrayAndHasContent(cbcs) ? (
        <>
          {/* <ScrollArea.Autosize mah="70vh">
            
          </ScrollArea.Autosize> */}
          <LoadingOverlay visible={scannedCbcsFetching || isAccepting} />
          <MantineReactTable
            columns={columns}
            data={cbcs}
            enableTopToolbar={false}
            enableBottomToolbar={true}
            enableColumnVirtualization
            enablePagination={true}
            enableColumnActions={false}
            enableRowVirtualization
            mantineTableContainerProps={{
              sx: { maxHeight: '70vh', maxWidth: '100%' },
            }}
            state={{ isLoading, isFetching }}
            rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
            rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
            columnVirtualizerProps={{ overscan: 2 }}
          />
          <Flex py="sm" justify="space-between" align="center">
            <Text fw="600" fz="sm" color="dimmed">
              Total
              <span style={{ color: COLORS.orange }}> {cbcs.length} </span>
              Products
            </Text>
            <Flex gap={10}>
              <Button
                size="sm"
                disabled={isAccepting}
                sx={{
                  backgroundColor: COLORS.tertiary,
                }}
                onClick={() => {
                  setIsPartiallyAccepting(true);
                }}>
                Partially Accept
              </Button>

              {isAccepting ? (
                <Button size="xs" loading={true}>
                  Accepting...
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled={isAccepting || acceptBtnDisabled}
                  sx={{
                    backgroundColor: COLORS.primary,
                  }}
                  onClick={() => {
                    ConfirmScanModal(order);
                  }}>
                  Accept
                </Button>
              )}
            </Flex>
          </Flex>
        </>
      ) : (
        <Flex justify="center" align="center" style={{ height: '75vh' }}>
          <NoDataPlaceholder />
        </Flex>
      )}
    </div>
  );
};

export default ReceiveOrderModal;
