import {
  Button,
  Stack,
  Box,
  Flex,
  Title,
  Space,
  Tabs,
  Paper,
} from '@mantine/core';
import { IconArrowLeft, IconChevronLeft } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import BlockCbcBox from './BlockCbcBox';
import UnblockCbcBox from './UnblockCbcBox';
import { useDispatch, useSelector } from 'react-redux';
import { TabActions } from '../../../store/reducers/tabsReducer';
import COLORS from '../../../constants/colors';

const PAGE_TITLE = 'Manage CBCs';

const BlockCbc = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Manage CBCs | Armada';
  }, []);

  const manageCbcTabReduxState = useSelector(
    (state) => state.tabs.manageCbcTab,
  );

  const [activeTab, setActiveTab] = useState(
    manageCbcTabReduxState || 'blockedcbc',
  );
  const [tabValues, setTabValues] = useState({});

  const tabList = [
    {
      label: 'Blockable CBCs',
      value: 'blockedcbc',
      element: <BlockCbcBox currentTab={activeTab} requiredTab="blockedcbc" />,
    },
    {
      label: 'Blocked CBCs',
      value: 'unblockedcbc',
      element: (
        <UnblockCbcBox currentTab={activeTab} requiredTab="unblockedcbc" />
      ),
    },
  ];

  useEffect(() => {
    let tempData = {};
    tabList.forEach((tab) => {
      tempData[tab.value] = tab.value;
    });
    setTabValues(tempData);
  }, []);

  const handleTabChange = (value) => {
    setActiveTab(value);
    dispatch(
      TabActions.setManageCbcTab({
        manageCbcTab: value,
      }),
    );
  };

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>

      {/* <Box><BlockCbcBox title="Block CBCs" /></Box>
      <Box>
        <UnblockCbcBox title="Unblock CBCs" />
      </Box> */}

      <Paper shadow="xs" p="md" mt="20px">
        <Tabs
          variant="outline"
          defaultValue="gallery"
          value={activeTab}
          onTabChange={handleTabChange}>
          <Tabs.List>
            {tabList.map((tab) => (
              <Tabs.Tab
                value={tab.value}
                key={tab.value}
                sx={{
                  backgroundColor:
                    activeTab === tabValues[tab.value]
                      ? COLORS.primary
                      : COLORS.white,
                  color:
                    activeTab === tabValues[tab.value]
                      ? COLORS.white
                      : COLORS.black,
                }}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {tabList.map((tab) => (
            <Tabs.Panel value={tab.value} key={tab.value} p="xs">
              {tab.element}
            </Tabs.Panel>
          ))}
        </Tabs>
      </Paper>
    </div>
  );
};

export default BlockCbc;
