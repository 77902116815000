import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Stack, Text, TextInput } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import COLORS from '../../../constants/colors';
import { updateTruckNumber } from '../../../services/orders';
import { NotificationUtil } from '../../../utils/notifications';
import { UpdateTruckNumberSchema } from '../../../validators/order';

const EditTruckNumberCard = ({ order, onUpdate }) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isValid, isDirty },
  } = useForm({
    defaultValues: {
      truckNumber: order?.truckNumber,
    },
    resolver: yupResolver(UpdateTruckNumberSchema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    ConfirmModal(data);
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you edit the truck number?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        updateMutate({
          orderNumber: order?.orderNumber,
          truckNumber: values?.truckNumber,
        });
      },
    });
  };

  const { mutate: updateMutate, isLoading: isUpdating } = useMutation({
    mutationFn: async (values) => await updateTruckNumber(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Truck Number updated successfully',
      });
      onUpdate(null);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack>
        <TextInput
          label="Edit Truck Number"
          placeholder="Truck Number"
          size="xs"
          {...register('truckNumber')}
          error={errors?.truckNumber?.message}
        />
        <Stack>
          <Button
            type="submit"
            sx={{
              backgroundColor: COLORS.primary,
            }}
            size="xs"
            loading={isUpdating || isSubmitting}
            disabled={isSubmitting || !isValid || !isDirty}>
            Edit
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default EditTruckNumberCard;
