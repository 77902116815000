import {
  ActionIcon,
  Alert,
  Badge,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  Flex,
  Loader,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import {
  IconAlertCircle,
  IconArrowLeft,
  IconArticleOff,
  IconBoxOff,
  IconCheck,
  IconCornerRightDown,
  IconRefresh,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { MantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PasswordModal from '../../components/DayZeroRecon/PasswordModal';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import COLORS from '../../constants/colors';
import {
  cancelRecon,
  dayZeroCloseSession,
  dayZeroFetchCBCs,
  dayZeroFetchCurrentCBCs,
  dayZeroOpenCBC,
  dayZeroReconCBCs,
  dayZeroRejectScan,
  fetchReconReport,
  fetchSession,
} from '../../services/dayZeroRecon';
import { NotificationUtil } from '../../utils/notifications';
import {
  dayZeroReconciliationSessionStatusesColorMapper,
  isArrayAndHasContent,
  isObjectAndHasProperties,
} from '../../utils/utils';
import dayjs from 'dayjs';
import ExportCsvXls from '../../components/Global/ExportCsvXlsx';
import exportFromJSON from 'export-from-json';

const PAGE_TITLE = 'Reconcile';

const DayZeroreconciliationDetails = () => {
  const { uid } = useParams();

  const navigate = useNavigate();

  const [openPasswordmodal, setOpenPasswordmodal] = useState(false);

  const [rowSelectionFloating, setRowSelectionFloating] = useState({});
  const [checkedCbcsFloating, setCheckedCbcsFloating] = useState([]);

  const [rowSelectionReconciled, setRowSelectionReconciled] = useState({});
  const [checkedCbcsReconciled, setCheckedCbcsReconciled] = useState([]);

  const [exportType, setExportType] = useState('csv');

  const {
    data: sessionData,
    refetch: sessionDataRefetch,
    isLoading: sessionDataIsLoading,
    isFetching: sessionDataIsFetching,
    error: sessionDataError,
  } = useQuery({
    queryKey: ['day-zero-recon-session-details', uid],
    queryFn: () => fetchSession(uid),
    enabled: !!uid,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // refetchInterval: 5 * 1000 * 60,
  });

  const { data, refetch, isLoading, isFetching, error } = useQuery({
    queryKey: ['day-zero-recon-details', uid],
    queryFn: () => dayZeroFetchCBCs(uid),
    enabled: !!uid,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    refetchInterval: 5 * 1000 * 60,
  });

  const {
    data: reportData,
    refetch: reportDataRefetch,
    isLoading: reportDataIsLoading,
    isFetching: reportDataIsFetching,
    error: reportDataError,
  } = useQuery({
    queryKey: ['day-zero-recon-report', uid],
    queryFn: () => fetchReconReport(uid),
    enabled:
      !!uid && sessionData?.data?.data?.session?.sessionStatus === 'CLOSED',
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // refetchInterval: 5 * 1000 * 60,
  });

  const {
    data: currentCbcData,
    refetch: currentCbcRefetch,
    isLoading: currentCbcIsLoading,
    isFetching: currentCbcIsFetching,
    error: currentCbcError,
  } = useQuery({
    queryKey: ['day-zero-recon-current-cbcs', uid],
    queryFn: () => dayZeroFetchCurrentCBCs(uid),
    enabled: !!uid,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    refetchInterval: 5 * 1000 * 60,
  });

  useEffect(() => {
    const tempData = Object.keys(rowSelectionFloating);

    const insertData = [];

    tempData.forEach((item) => {
      if (!isNaN(parseInt(item))) {
        insertData.push(item);
      }
    });
    setCheckedCbcsFloating(insertData);
  }, [rowSelectionFloating]);

  useEffect(() => {
    const tempData = Object.keys(rowSelectionReconciled);

    const insertData = [];

    tempData.forEach((item) => {
      if (!isNaN(parseInt(item))) {
        insertData.push(item);
      }
    });
    setCheckedCbcsReconciled(insertData);
  }, [rowSelectionReconciled]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'barcode',
        header: 'Barcode',
        size: '120',
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} position="bottom" withinPortal>
            <Text fz="xs">{cell.getValue()}</Text>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row) =>
          row?.cbcInfo.product.shipmentFormatName
            ? row?.cbcInfo.product.shipmentFormatName
            : row?.cbcInfo.product.name,
        header: 'Product',
        size: '200',
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} position="bottom" withinPortal>
            <Text fz="xs">{cell.getValue()}</Text>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row) => row?.cbcInfo.packcode,
        header: 'Pack Code',
        size: '100',
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} position="bottom" withinPortal>
            <Text fz="xs">{cell.getValue()}</Text>
          </Tooltip>
        ),
      },
    ],
    [],
  );

  const reconciledColumns = useMemo(
    () => [
      {
        accessorKey: 'barcode',
        header: 'Barcode',
        size: '120',
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} position="bottom" withinPortal>
            <Text fz="xs">{cell.getValue()}</Text>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row) =>
          row?.product.shipmentFormatName
            ? row?.product.shipmentFormatName
            : row?.product.name,
        header: 'Product',
        size: '300',
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} position="bottom" withinPortal>
            <Text fz="xs">{cell.getValue()}</Text>
          </Tooltip>
        ),
      },
      {
        accessorFn: (row) => row?.packcode,
        header: 'Pack Code',
        size: '100',
        Cell: ({ cell }) => (
          <Tooltip label={cell.getValue()} position="bottom" withinPortal>
            <Text fz="xs">{cell.getValue()}</Text>
          </Tooltip>
        ),
      },
    ],
    [],
  );

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to open the selected CBCs?</Text>
      ),
      confirmProps: { color: 'blue', loading: isOpenCbcing },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        const barcodes = checkedCbcsFloating.map((item) => {
          return tempCBCs[item].barcode;
        });

        openCBCMutate(barcodes);
      },
    });
  };

  const ConfirmReconModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to reconcile the selected CBCs?
        </Text>
      ),
      confirmProps: { color: 'blue', loading: isReconing },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        const barcodes = checkedCbcsFloating.map((item) => {
          return tempCBCs[item].barcode;
        });

        reconCBCMutate(barcodes);
      },
    });
  };

  const ConfirmCloseModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Do you accept the changes you made?</Text>,

      confirmProps: { color: 'green', loading: isClosing },
      labels: { confirm: 'I agree', cancel: 'Cancel' },
      onConfirm: () => {
        // closeSessionMutate();
        openConfirmModal({
          title: 'Confirm',
          styles: () => ({
            title: {
              fontSize: '22px',
              fontWeight: 'bold',
            },
          }),
          children: (
            <Text size="sm">Are you sure you want to close this session?</Text>
          ),

          confirmProps: { color: 'blue', loading: isClosing },
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          onConfirm: () => {
            closeSessionMutate();
          },
        });
      },
    });
  };

  const ConfirmRejectModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to reject the selected CBCs?
        </Text>
      ),
      confirmProps: { color: 'blue', loading: isRejecting },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        const barcodes = checkedCbcsReconciled.map((item) => {
          return currentCbcs[item].barcode;
        });

        RejectCbcMutate(barcodes);
      },
    });
  };

  const ConfirmCancelModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">All your changes will be cancelled. Do you agree?</Text>
      ),
      confirmProps: { color: 'green', loading: isCancelling },
      labels: { confirm: 'I agree', cancel: 'Cancel' },
      onConfirm: () => {
        // CancelMutate();
        openConfirmModal({
          title: 'Confirm',
          styles: () => ({
            title: {
              fontSize: '22px',
              fontWeight: 'bold',
            },
          }),
          children: (
            <Text size="sm">
              Are you sure you want to cancel this reconciliation session?
            </Text>
          ),
          confirmProps: { color: 'blue', loading: isCancelling },
          labels: { confirm: 'Confirm', cancel: 'Cancel' },
          onConfirm: () => {
            CancelMutate();
          },
        });
      },
    });
  };

  const { mutate: openCBCMutate, isLoading: isOpenCbcing } = useMutation({
    mutationFn: async (data) => await dayZeroOpenCBC(uid, data),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'CBCs are now open',
      });
      // sessionDataRefetch();
      refetch();
      currentCbcRefetch();
      setCheckedCbcsFloating([]);
      setRowSelectionFloating({});
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: reconCBCMutate, isLoading: isReconing } = useMutation({
    mutationFn: async (data) => await dayZeroReconCBCs(uid, data),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'CBCs are now reconciled',
      });
      // sessionDataRefetch();
      refetch();
      currentCbcRefetch();
      setCheckedCbcsFloating([]);
      setRowSelectionFloating({});
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: closeSessionMutate, isLoading: isClosing } = useMutation({
    mutationFn: async () => await dayZeroCloseSession(uid),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Session is now closed',
      });
      sessionDataRefetch();
      // refetch();
      // currentCbcRefetch();
      setCheckedCbcsFloating([]);
      setRowSelectionFloating({});
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: RejectCbcMutate, isLoading: isRejecting } = useMutation({
    mutationFn: async (data) => await dayZeroRejectScan(uid, data),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'CBCs are now rejected',
      });
      // sessionDataRefetch();
      refetch();
      currentCbcRefetch();
      setCheckedCbcsReconciled([]);
      setRowSelectionReconciled({});
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: CancelMutate, isLoading: isCancelling } = useMutation({
    mutationFn: async (data) => await cancelRecon(uid),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Session cancelled succesfully',
      });
      sessionDataRefetch();
      refetch();
      currentCbcRefetch();
      setCheckedCbcsReconciled([]);
      setRowSelectionReconciled({});
      // navigate(-1);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  if (
    isLoading ||
    currentCbcIsLoading ||
    sessionDataIsLoading ||
    sessionDataIsFetching
  )
    return (
      <div>
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Stack
          justify="center"
          align="center"
          sx={{
            width: '100%',
            height: '80vh',
          }}>
          <Loader />
          <Text>Fetching data. Please wait...</Text>
        </Stack>
      </div>
    );

  if (error || currentCbcError || sessionDataError)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { session } = sessionData.data.data;
  const { tempCBCs } = data.data.data;
  const { currentCbcs } = currentCbcData.data.data;

  const exportData = () => {
    const fileName = `RECONCILE-REPORT-${dayjs(
      new Date(session?.createdAt),
    ).format('DD-MM-YYYY')}-${session?.uid}-${session?.location?.name}`;
    const data = reportData.data.data.report.map((rep) => {
      return {
        SKU: rep.sku,
        Description: rep.productName,
        'Reconciliation Date': dayjs(rep?.reconcileDate).format('DD MMM, YYYY'),
        Location: rep?.locationName,
        'Record Count': rep?.before,
        'Physical Count': rep?.after,
        Delta: rep?.difference,
        Reconciled: rep?.isReconciled ? 'Yes' : 'No',
      };
    });

    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Modal
        opened={openPasswordmodal}
        onClose={() => {
          setOpenPasswordmodal(false);
        }}
        title="Start reconciliation"
        size="lg"
        centered={true}>
        <PasswordModal
          uid={uid}
          onUpdate={() => {
            sessionDataRefetch();
            refetch();
            currentCbcRefetch();
            setOpenPasswordmodal(false);
          }}
          onClose={() => {
            setOpenPasswordmodal(false);
          }}
        />
      </Modal>
      {/* CUSTOM MODAL AND DRAWER END */}

      <Flex align="center" justify="space-between">
        <Flex gap={10}>
          <Title order={1} size="h4">
            {PAGE_TITLE} - {session?.name} - {session?.location?.name}
          </Title>
          <Divider orientation="vertical" />
          <Badge
            color={dayZeroReconciliationSessionStatusesColorMapper(
              session.sessionStatus,
            )}
            size="lg">
            {session.sessionStatus}
          </Badge>
        </Flex>
        <Flex align="center" gap={10}>
          {session?.sessionStatus === 'RECONCILING' && (
            <Button
              onClick={() => {
                ConfirmCancelModal();
              }}
              sx={{
                backgroundColor: COLORS.redMatt,
              }}>
              Cancel Rconcilation
            </Button>
          )}
          {session?.sessionStatus === 'ACTIVE' && (
            <Button
              onClick={() => {
                setOpenPasswordmodal(true);
              }}
              sx={{
                backgroundColor: COLORS.primary,
              }}
              disabled={session?.sessionStatus === 'RECONCILING'}>
              Start Reconciliation
            </Button>
          )}
          {session?.sessionStatus === 'RECONCILING' && (
            <Button
              onClick={() => {
                ConfirmCloseModal();
              }}
              sx={{
                backgroundColor: COLORS.primary,
              }}
              disabled={session?.sessionStatus === 'CLOSED'}>
              Close Rconcilation
            </Button>
          )}
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
      </Flex>
      <Space h="lg" />
      <LoadingOverlay visible={isCancelling || isClosing} zIndex={1} />
      {session?.sessionStatus === 'CLOSED' ? (
        <Paper shadow="xs" p="md" mt="20px">
          <Flex justify="space-between" align="center">
            <Title order={3}>Reconciliation Report</Title>
            <ExportCsvXls
              setExportType={setExportType}
              exportData={exportData}
              btnLoader={false}
            />
          </Flex>
          <Space h="md" />
          {reportDataIsLoading ? (
            <Stack
              sx={{
                minHeight: '50vh',
              }}
              justify="center"
              align="center">
              <Loader size="xl" />
            </Stack>
          ) : (
            <Stack
              sx={{
                minHeight: '50vh',
              }}>
              {isArrayAndHasContent(reportData?.data?.data?.report) ? (
                <ScrollArea h="70vh">
                  <Table highlightOnHover>
                    <thead
                      style={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        backgroundColor: 'white',
                      }}>
                      <tr>
                        <th>SKU</th>
                        <th>Description</th>
                        <th>Reconciliation Date</th>
                        <th>Location</th>
                        <th>
                          <Tooltip
                            position="top"
                            label="What you had in your armada system">
                            <Text>Record Count</Text>
                          </Tooltip>
                        </th>
                        <th>Physical Count</th>
                        <th>
                          <Tooltip
                            position="top"
                            label="Difference between physical count and inventory record">
                            <Text>Delta</Text>
                          </Tooltip>
                        </th>
                        <th>Reconciled</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData?.data?.data?.report.map((result, index) => (
                        <tr key={index}>
                          <td>{result.sku}</td>
                          <td>{result.productName}</td>
                          <td>
                            {dayjs(result?.reconcileDate).format(
                              'DD MMM, YYYY',
                            )}
                          </td>
                          <td>{result?.locationName}</td>
                          <td>{result?.before}</td>
                          <td>{result?.after}</td>
                          <td>{result?.difference}</td>
                          <td>
                            {result?.isReconciled ? (
                              <IconCheck color="green" />
                            ) : (
                              <IconX color="red" />
                            )}
                          </td>
                        </tr>
                      ))}

                      <tr
                        style={{
                          position: 'sticky',
                          bottom: 0,
                          zIndex: 1,
                          backgroundColor: 'white',
                        }}>
                        <td>
                          <Text fw={700}>Total</Text>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <Text fw={700}>
                            {reportData?.data?.data?.report?.reduce(
                              (a, b) => a + b.before,
                              0,
                            )}
                          </Text>
                        </td>
                        <td>
                          <Text fw={700}>
                            {reportData?.data?.data?.report?.reduce(
                              (a, b) => a + b.after,
                              0,
                            )}
                          </Text>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </ScrollArea>
              ) : (
                <div>
                  <NoDataPlaceholder
                    title="No session report found"
                    subtext={' '}
                    icon={<IconArticleOff size={70} color="#4562f7" />}
                  />
                </div>
              )}
            </Stack>
          )}
        </Paper>
      ) : (
        <Flex justify="space-between" align="center" gap={20}>
          <Card
            padding="xs"
            radius="md"
            withBorder
            sx={{
              width: '100%',
            }}>
            <Stack>
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <Text size="lg">Floating Inventory</Text>
                  <IconCornerRightDown size={20} color="#4562f7" />
                  <Stack
                    sx={{
                      marginLeft: '10px',
                    }}>
                    <Tooltip label="Refresh">
                      <ActionIcon
                        variant="filled"
                        color="dark"
                        onClick={() => {
                          refetch();
                        }}>
                        <IconRefresh
                          style={{ width: '70%', height: '70%' }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </Tooltip>
                  </Stack>
                </Flex>
                <Flex gap={5} align="center">
                  <Text>Total</Text>
                  <Chip
                    defaultChecked={false}
                    checked={false}
                    icon={null}
                    radius="sm"
                    size="md">
                    {tempCBCs.length}
                  </Chip>
                </Flex>
              </Flex>
              <MantineReactTable
                columns={columns}
                data={tempCBCs.filter((item) => item.isReconciled === false)}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                // enableColumnVirtualization
                enablePagination={false}
                enableColumnActions={false}
                enableRowVirtualization
                positionToolbarAlertBanner="bottom"
                mantineTableContainerProps={{ sx: { height: '75vh' } }}
                state={{
                  isLoading:
                    isLoading || isFetching || isReconing || isOpenCbcing,
                  rowSelection: rowSelectionFloating,
                }}
                rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
                // columnVirtualizerProps={{ overscan: 2 }}
                enableMultiRowSelection
                enableRowSelection
                enableSelectAll
                onRowSelectionChange={setRowSelectionFloating}
                // getRowId={(originalRow) => originalRow.id}
                enableGrouping={true}
                initialState={{
                  density: 'xs',
                  expanded: false,
                  grouping: ['Product'],
                  sorting: [{ id: 'Product', desc: false }],
                }}
                mantineTableHeadCellProps={{
                  sx: {
                    backgroundColor: COLORS.primary,
                    color: 'white !important',
                  },
                }}
                renderEmptyRowsFallback={() => (
                  <Stack
                    justify="center"
                    align="center"
                    sx={{
                      height: '65vh',
                    }}>
                    <NoDataPlaceholder
                      title="No CBCs found"
                      // subtext={'Please start reconciliation to view CBCs'}
                      icon={<IconBoxOff size={70} color="#4562f7" />}
                    />
                  </Stack>
                )}
              />
              <Flex justify="space-between" align="center">
                <Stack>
                  {isObjectAndHasProperties(rowSelectionFloating) && (
                    <Text>
                      {checkedCbcsFloating.length} of {tempCBCs.length} CBCs
                      selected
                    </Text>
                  )}
                </Stack>

                <Flex align="center" justify="end" gap={10}>
                  <Button
                    onClick={() => {
                      ConfirmReconModal();
                    }}
                    color="pink"
                    disabled={
                      !isArrayAndHasContent(checkedCbcsFloating) ||
                      session?.sessionStatus === 'CLOSED' ||
                      session?.sessionStatus === 'ACTIVE' ||
                      session?.sessionStatus === 'CANCELLED'
                    }>
                    Reconcile Selected
                  </Button>
                  {/* <Button
                    onClick={() => {
                      ConfirmModal();
                    }}
                    color="cyan"
                    disabled={
                      !isArrayAndHasContent(checkedCbcsFloating) ||
                      session?.sessionStatus === 'CLOSED' ||
                      session?.sessionStatus === 'ACTIVE' ||
                      session?.sessionStatus === 'CANCELLED'
                    }>
                    Open CBCs
                  </Button> */}
                </Flex>
              </Flex>
            </Stack>
          </Card>
          <Card
            padding="xs"
            radius="md"
            withBorder
            sx={{
              width: '100%',
            }}>
            <Stack>
              <Flex justify="space-between" align="center">
                <Flex align="center">
                  <Text size="lg">
                    {session?.sessionStatus === 'RECONCILING'
                      ? 'Reconciled Inventory'
                      : 'Existing Inventory'}
                  </Text>
                  <IconCornerRightDown size={20} color="#4562f7" />
                  <Stack
                    sx={{
                      marginLeft: '10px',
                    }}>
                    <Tooltip label="Refresh">
                      <ActionIcon
                        variant="filled"
                        color="dark"
                        onClick={() => {
                          currentCbcRefetch();
                        }}>
                        <IconRefresh
                          style={{ width: '70%', height: '70%' }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </Tooltip>
                  </Stack>
                </Flex>

                <Flex gap={5} align="center">
                  <Text>Total</Text>
                  <Chip
                    defaultChecked={false}
                    checked={false}
                    icon={null}
                    radius="sm"
                    size="md">
                    {currentCbcs.length}
                  </Chip>
                </Flex>
              </Flex>
              <MantineReactTable
                columns={reconciledColumns}
                data={currentCbcs}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                // enableColumnVirtualization
                enablePagination={false}
                enableColumnActions={false}
                enableRowVirtualization
                positionToolbarAlertBanner="bottom"
                mantineTableContainerProps={{ sx: { height: '75vh' } }}
                state={{
                  isLoading:
                    currentCbcIsLoading || currentCbcIsFetching || isRejecting,
                  rowSelection: rowSelectionReconciled,
                }}
                rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
                // columnVirtualizerProps={{ overscan: 2 }}
                enableMultiRowSelection
                enableRowSelection
                enableSelectAll
                onRowSelectionChange={setRowSelectionReconciled}
                // getRowId={(originalRow) => originalRow.id}
                enableGrouping={true}
                initialState={{
                  density: 'xs',
                  expanded: false,
                  grouping: ['Product'],
                  sorting: [{ id: 'Product', desc: false }],
                }}
                mantineTableHeadCellProps={{
                  sx: {
                    backgroundColor: COLORS.primary,
                    color: 'white !important',
                  },
                }}
                renderEmptyRowsFallback={() => (
                  <Stack
                    justify="center"
                    align="center"
                    sx={{
                      height: '65vh',
                    }}>
                    <NoDataPlaceholder
                      title="No CBCs reconciled yet"
                      // subtext={'Please start reconciliation to view CBCs'}
                      icon={<IconBoxOff size={70} color="#4562f7" />}
                    />
                  </Stack>
                )}
              />

              <Flex justify="space-between" align="center">
                <Stack>
                  {isObjectAndHasProperties(rowSelectionReconciled) && (
                    <Text>
                      {checkedCbcsReconciled.length} of {currentCbcs.length}{' '}
                      CBCs selected
                    </Text>
                  )}
                </Stack>
                <Stack align="end">
                  <Button
                    onClick={() => {
                      ConfirmRejectModal();
                    }}
                    disabled={
                      !isArrayAndHasContent(checkedCbcsReconciled) ||
                      session?.sessionStatus === 'CLOSED' ||
                      session?.sessionStatus === 'ACTIVE' ||
                      session?.sessionStatus === 'CANCELLED'
                    }
                    color="violet">
                    Reject selected
                  </Button>
                </Stack>
              </Flex>
            </Stack>
          </Card>
        </Flex>
      )}
    </div>
  );
};

export default DayZeroreconciliationDetails;
