import {
  Alert,
  Button,
  Card,
  Flex,
  Loader,
  LoadingOverlay,
  Select,
  Stack,
  Text,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import PinInput from 'react-pin-input';
import { useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import {
  createDayZeroReconSession,
  generateDayZeroSessionOTP,
  validateSessionCreation,
} from '../../services/dayZeroRecon';
import { NotificationUtil } from '../../utils/notifications';
import {
  isObjectAndHasProperties,
  userWiseMultiSelectLocationDataWithId,
} from '../../utils/utils';

const SessionForm = ({
  defaultValues,
  update,
  onUpdate,
  setIsAskingForOtp,
}) => {
  const appUser = useSelector((state) => state.auth.user);

  const queryClient = useQueryClient();

  const [selectedLocation, setselectedLocation] = useState(null);

  const [createdSessionDetails, setCreatedSessionDetails] = useState(null);

  const [otpToken, setOtpToken] = useState(null);

  useEffect(() => {
    if (isObjectAndHasProperties(defaultValues)) {
      generateOtpMutate(defaultValues.uid);
      setCreatedSessionDetails(defaultValues);
    }
  }, []);

  const locationData = queryClient.getQueryData([
    'location',
    1,
    null,
    null,
    null,
    true,
    true,
  ]);

  const { mutate: createMutate, isLoading: isCreating } = useMutation({
    mutationFn: async (values) =>
      await createDayZeroReconSession({
        locationId: selectedLocation,
      }),
    onSuccess: (data) => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'Session created successfully',
      });
      callRefetch();
      setCreatedSessionDetails(data.data.data.session);
      generateOtpMutate(data.data.data.session.uid);
      setIsAskingForOtp(true);
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: generateOtpMutate, isLoading: isgenerateOtping } =
    useMutation({
      mutationFn: async (uid) => await generateDayZeroSessionOTP(uid),
      onSuccess: (data) => {
        setOtpToken(data.data.data.token);
      },
      onError: (error) => {
        NotificationUtil({
          success: false,
          title: 'Error',
          message: error.response.data.message,
        });
      },
    });

  const { mutate: validateOtpMutate, isLoading: isValidateOtping } =
    useMutation({
      mutationFn: async (otpValue) =>
        await validateSessionCreation({
          uid: createdSessionDetails.uid,
          token: otpToken,
          otp: otpValue,
        }),
      onSuccess: (data) => {
        NotificationUtil({
          success: true,
          title: 'OTP Verified',
          message: 'OTP Verification Complete. Your session is now ACTIVE',
        });
        onUpdate();
      },
      onError: (error) => {
        NotificationUtil({
          success: false,
          title: 'Error',
          message: error.response.data.message,
        });
      },
    });

  const callRefetch = async () => {
    await queryClient.refetchQueries({
      queryKey: ['day-zero-recon'],
      type: 'active',
    });
  };

  return (
    <Stack
      sx={{
        height: '90vh',
      }}>
      <LoadingOverlay
        visible={isCreating}
        loader={
          <Stack justify="center" align="center">
            <Text>Creating session. Please wait.</Text>
            <Loader />
          </Stack>
        }
      />
      <LoadingOverlay
        visible={isgenerateOtping}
        loader={
          <Stack justify="center" align="center">
            <Text>Session created successfully.</Text>
            <Text>Sending OTP. Please wait.</Text>
            <Loader />
          </Stack>
        }
      />
      <LoadingOverlay
        visible={isValidateOtping}
        loader={
          <Stack justify="center" align="center">
            <Text>Verifying OTP. Please wait.</Text>
            <Loader />
          </Stack>
        }
      />
      {!isObjectAndHasProperties(createdSessionDetails) ? (
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Stack>
            <Select
              label="Select a location"
              placeholder="Search location"
              dropdownPosition="bottom"
              data={
                userWiseMultiSelectLocationDataWithId({
                  appUser: appUser,
                  locationData: locationData?.data?.data?.locations || [],
                }) || []
              }
              onChange={(value) => {
                setselectedLocation(value);
              }}
              value={selectedLocation}
              clearable
              searchable
              withinPortal
            />
            <Stack>
              <Button
                sx={{
                  backgroundColor: COLORS.primary,
                }}
                onClick={() => {
                  createMutate();
                }}
                loading={isCreating}
                disabled={!selectedLocation}>
                Create session
              </Button>
            </Stack>
          </Stack>
        </Card>
      ) : (
        <Card shadow="sm" padding="lg" radius="md" withBorder>
          <Stack justify="center" align="center">
            <Text fz={22} align="center">
              Enter OTP
            </Text>

            <Text align="center" size="xs">
              To activate your session please enter the OTP sent to your mobile
              number.
            </Text>
            <PinInput
              focus={true}
              length={4}
              type="numeric"
              initialValue=""
              inputMode="number"
              autoSelect={false}
              inputStyle={{
                borderColor: 'silver',
                height: 60,
                width: 60,
                fontSize: 24,
                fontWeight: 700,
              }}
              inputFocusStyle={{ borderColor: 'brown' }}
              onComplete={(value) => {
                validateOtpMutate(value);
              }}
            />
            <Countdown
              onComplete={() => {
                NotificationUtil({
                  success: false,
                  title: 'Otp Expired!',
                  message: 'Please try to resend OTP',
                });
              }}
              date={
                new Date(Date.now() + 1000 * 60 * 30) // 30 minutes from now
              }
              key={''}
              renderer={({ minutes, seconds }) => (
                <Flex gap={5} align="center">
                  <Text fontSize={14} fontWeight={500} color={COLORS.darkGray}>
                    OTP expires in
                  </Text>
                  <Text fontWeight="bold" color={COLORS.blue}>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                  </Text>
                </Flex>
              )}
            />
            <Flex aling="center" gap={10}>
              <Button
                onClick={() => {
                  generateOtpMutate(createdSessionDetails.uid);
                }}
                radius="xs"
                size="md"
                compact
                loading={isValidateOtping}
                sx={{
                  backgroundColor: COLORS.primary,
                }}>
                Resend OTP
              </Button>
            </Flex>
          </Stack>
        </Card>
      )}
      {!update && (
        <Stack justify="center" align="center">
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Important information">
            There can only be one active session per point
          </Alert>
        </Stack>
      )}
    </Stack>
  );
};

export default SessionForm;
