import * as yup from 'yup';
import { userRoles } from '../constants/const';

export const ForgotPasswordEmailSchema = yup.object().shape({
  number: yup.string().required('Mobile number is required'),
});

export const ForgotPasswordInputSchema = (role) =>
  yup.object().shape({
    newPassword: yup
      .string()
      .required('New Password is required')
      .test(
        'password',
        'Your password should containt atleast one lowercase alphabet, atleast one uppercase alphabet, atleast one digit and atleast one special character',
        function (value) {
          const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).+$/;
          return regex.test(value);
        },
      )
      .when((_, schema) => {
        if (userRoles[role].includes(userRoles.OPERATOR)) {
          return schema.min(6, 'Password must be minimum 6 characters long');
        }
        if (
          userRoles[role].includes(userRoles.ADMIN) ||
          userRoles[role].includes(userRoles.SUPER_ADMIN)
        ) {
          return schema.min(16, 'Password must be minimum 16 characters long');
        }

        return schema.min(12, 'Password must be minimum 12 characters long');
      }),
    confirmNewPassword: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm New Password is required'),
  });
