import {
  Button,
  Flex,
  Loader,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React, { useState } from 'react';
import Countdown from 'react-countdown';
import PinInput from 'react-pin-input';
import { useDispatch, useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import {
  SignInApi,
  forgotPassword,
  forgotPasswordValidateNumber,
  verifyOtp,
} from '../../services/auth';
import { authActions } from '../../store/reducers/authReducer';
import { NotificationUtil } from '../../utils/notifications';
import { decryptPasswordTemporarily } from '../../utils/utils';

const OtpInput = ({
  passwordReset = false,
  onSuccess,
  passwordValue,
  onBack,
}) => {
  const dispatch = useDispatch();

  const [otpStartTime, setotpStartTime] = useState(Date.now());

  const [verifying, setVerifying] = useState(false);
  const [resending, setresending] = useState(false);

  const { sessionId, identifier, ptag } = useSelector(
    (state) => state.auth.tempData,
  );

  const onSubmit = async (value) => {
    const response = await verifyOtp(sessionId, value);
    if (response.status === 200) {
      onSuccess(response.data);
    } else {
      NotificationUtil({
        success: false,
        title: 'Invalid OTP',
        message: 'Please try again with the correct OTP',
      });
    }
  };

  const resendOtp = async () => {
    setresending(true);
    const response = await SignInApi(
      identifier,
      decryptPasswordTemporarily(ptag),
    );
    if (response.status === 200) {
      if (response?.data?.sessionId) {
        NotificationUtil({
          success: true,
          title: 'Resend Successful!',
          message: 'Please verify OTP',
        });

        setotpStartTime(Date.now());
        dispatch(
          authActions.setTempData({
            sessionId: response?.data?.sessionId,
            identifier: identifier,
            ptag: ptag,
          }),
        );
      }
    }
    setresending(false);
  };

  const resendOtpForPasswordReset = async () => {
    setresending(true);
    const response = await forgotPassword(identifier);

    if (response.status === 200) {
      NotificationUtil({
        success: true,
        title: 'Resend Successful!',
        message: 'Please verify OTP',
      });

      dispatch(
        authActions.setTempData({
          sessionId: response.data.token,
          identifier: identifier,
          role: response.data.role,
        }),
      );
    }
    setresending(false);
  };

  const handlePasswordInput = async (otp) => {
    setVerifying(true);
    try {
      const response = await forgotPasswordValidateNumber({
        identifier: identifier,
        token: sessionId,
        otp: otp,
      });
      if (response.status === 200) {
        NotificationUtil({
          success: true,
          title: 'Verified',
          message: 'OTP Verified',
        });
        setVerifying(false);
        onSuccess(otp);
      }
    } catch (error) {
      setVerifying(false);
      // do nothing
    }
  };

  return (
    <Stack
      sx={{
        width: 310,
      }}
      align="center">
      <LoadingOverlay
        visible={verifying}
        overlayBlur={3}
        loader={
          <Stack justify="center" align="center">
            <Text>Verifying OTP. Please wait.</Text>
            <Loader />
          </Stack>
        }
      />

      <Text fz={22} align="center">
        Enter OTP
      </Text>

      <PinInput
        focus={true}
        length={4}
        type="numeric"
        initialValue=""
        inputMode="number"
        autoSelect={false}
        inputStyle={{
          borderColor: 'silver',
          height: 60,
          width: 60,
          fontSize: 24,
          fontWeight: 700,
        }}
        inputFocusStyle={{ borderColor: 'brown' }}
        onComplete={(value) => {
          if (passwordReset) {
            handlePasswordInput(value);
          } else {
            onSubmit(value);
          }
        }}
      />
      <Countdown
        onComplete={() => {
          NotificationUtil({
            success: false,
            title: 'Otp Expired!',
            message: 'Please try to resend OTP',
          });
        }}
        date={
          passwordReset
            ? new Date(otpStartTime + 1000 * 60 * 30)
            : new Date(otpStartTime + 1000 * 60 * 5)
        }
        key={''}
        renderer={({ minutes, seconds }) => (
          <Flex gap={5} align="center">
            <Text fontSize={14} fontWeight={500} color={COLORS.darkGray}>
              OTP expires in
            </Text>
            <Text fontWeight="bold" color={COLORS.blue}>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </Text>
          </Flex>
        )}
      />
      <Flex aling="center" gap={10}>
        {passwordReset && (
          <Button
            onClick={() => {
              onBack();
            }}
            radius="xs"
            size="md"
            compact
            loading={resending}
            sx={{
              backgroundColor: COLORS.primary,
            }}>
            Back
          </Button>
        )}
        <Button
          onClick={() => {
            if (passwordReset) {
              resendOtpForPasswordReset();
            } else {
              resendOtp();
            }
          }}
          radius="xs"
          size="md"
          compact
          loading={resending}
          sx={{
            backgroundColor: COLORS.primary,
          }}>
          Resend OTP
        </Button>
      </Flex>
    </Stack>
  );
};

export default OtpInput;
