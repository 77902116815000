import {
  ActionIcon,
  Badge,
  Button,
  Flex,
  Loader,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconAlertCircleFilled,
  IconBoxOff,
  IconListDetails,
  IconRefresh,
} from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { convertToDropDownFormat } from '../../components/Global/HelperFunctions';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import SearchInput from '../../components/Global/SearchInput';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import COLORS from '../../constants/colors';
import {
  fetchProductsBrandSegments,
  fetchProductsBrands,
  fetchProductsCategories,
  fetchProductsOuterPackSize,
  fetchProductsWithFilter,
} from '../../services/product';
import { handleErrorResponse, isArrayAndHasContent } from '../../utils/utils';
import ProductDetailsModal from './modals/ProductDetailsModal';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

const PAGE_TITLE = 'Products';
const PAGE_LIMIT = 10;
const Products = () => {
  const navigate = useNavigate();

  const [opened, { open: openModal, close }] = useDisclosure(false);

  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [category, setCategory] = useState(null);
  const [isActive, setIsActive] = useState(null);
  const [brand, setBrand] = useState(null);
  const [brandSegment, setBrandSegment] = useState(null);
  const [outerPackSize, setOuterPackSize] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    document.title = 'Products | Armada';
  }, []);

  const handleSkuSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handelCloseModal = () => {
    setSelectedProduct(null);
    close();
  };

  const handleRefresh = () => {
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey(null);
  };

  const handleModalOpening = (productDetails) => {
    setSelectedProduct(productDetails);
    openModal();
  };

  //fetching products list
  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'products-list',
      page,
      searchKey,
      category,
      isActive,
      brand,
      brandSegment,
      outerPackSize,
    ],
    queryFn: () =>
      fetchProductsWithFilter({
        page,
        searchKey,
        category,
        isActive,
        brand,
        brandSegment,
        outerPackSize,
        limit: PAGE_LIMIT,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  //fetching products categories
  const {
    data: categoryData,
    isLoading: categoyLoading,
    isFetching: categoryFetching,
    error: categoryError,
  } = useQuery({
    queryKey: ['product-categories', brand, brandSegment, outerPackSize],
    queryFn: () =>
      fetchProductsCategories({
        brand,
        brandSegment,
        outerPackSize,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  //fetching products brands
  const {
    data: brandData,
    isLoading: brandLoading,
    isFetching: brandFetching,
    error: brandError,
  } = useQuery({
    queryKey: ['product-brand', category, brandSegment, outerPackSize],
    queryFn: () =>
      fetchProductsBrands({
        category,
        brandSegment,
        outerPackSize,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  //fetching products brand segments
  const {
    data: brandSegmentData,
    isLoading: brandSegmentLoading,
    isFetching: brandSegmentFetching,
    error: brandSegmentError,
  } = useQuery({
    queryKey: ['product-brand-segment', category, brand, outerPackSize],
    queryFn: () =>
      fetchProductsBrandSegments({
        category,
        brand,
        outerPackSize,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  //fetching products outer pack size
  const {
    data: outerPackSizeData,
    isLoading: outerPackSizeLoading,
    isFetching: outerPackSizeFetching,
    error: outerPackSizeError,
  } = useQuery({
    queryKey: ['product-outer-pack-size', category, brand, brandSegment],
    queryFn: () =>
      fetchProductsOuterPackSize({
        category,
        brand,
        brandSegment,
      }),
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  if (isLoading) {
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );
  }

  const { products, total } = data.data.data;

  return (
    <div>
      {selectedProduct ? (
        <ProductDetailsModal
          opened={opened}
          close={handelCloseModal}
          productDetails={selectedProduct}
        />
      ) : (
        <></>
      )}
      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          Products
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex gap={20}>
          <div>
            <SearchInput
              placeholder="Search SKU"
              refreshBtn={false}
              handleRefresh={handleRefresh}
              handleSearch={handleSkuSearch}
              invokeRefresh={invokingRefreshForSearchInput}
            />
          </div>

          <div>
            <Select
              value={category}
              onChange={(value) => {
                setPage(1);
                setCategory(value);
              }}
              placeholder="Filter by Brand Family"
              data={
                categoryData?.data?.data
                  ? convertToDropDownFormat(categoryData?.data?.data)
                  : []
              }
            />
          </div>
          <div>
            <Select
              value={brand}
              onChange={(value) => {
                setPage(1);
                setBrand(value);
              }}
              placeholder="Filter by Brand"
              data={
                brandData?.data?.data
                  ? convertToDropDownFormat(brandData?.data?.data)
                  : []
              }
            />
          </div>
          <div>
            <Select
              value={brandSegment}
              onChange={(value) => {
                setPage(1);
                setBrandSegment(value);
              }}
              placeholder="Filter by Brand Segment"
              data={
                brandSegmentData?.data?.data
                  ? convertToDropDownFormat(brandSegmentData?.data?.data)
                  : []
              }
            />
          </div>
          <div>
            <Select
              value={outerPackSize}
              onChange={(value) => {
                setPage(1);
                setOuterPackSize(value);
              }}
              placeholder="Filter by Pack Size"
              data={
                outerPackSizeData?.data?.data
                  ? convertToDropDownFormat(outerPackSizeData?.data?.data)
                  : []
              }
            />
          </div>
          <div>
            <Select
              value={isActive}
              onChange={(value) => {
                setPage(1);
                setIsActive(value);
              }}
              placeholder="Filter by Status"
              data={[
                { label: 'Active', value: true },
                { label: 'Inactive', value: false },
              ]}
            />
          </div>
          <Flex gap={20} align="center" justify="center">
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={() => {
                  handleRefresh();

                  setPage(1);
                  setCategory(null);
                  setIsActive(null);
                  setBrand(null);
                  setBrandSegment(null);
                  setOuterPackSize(null);
                }}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            py="md">
            {isArrayAndHasContent(products) ? (
              <ScrollArea h="70vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>SKU</th>
                      <th>Name</th>
                      <th>Shipment Format Name</th>
                      <th>Brand Family</th>
                      <th>Brand</th>
                      <th>Brand Segment</th>
                      <th>Pack Size</th>
                      {/* <th>Package</th> */}
                      <th>CBC Size</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      verticalAlign: 'baseline',
                    }}>
                    {products.map((product, index) => (
                      <tr
                        key={index}
                        style={{
                          backgroundColor: product.hasError
                            ? 'rgba(255, 0, 0, 0.2)'
                            : 'transparent',
                        }}>
                        <td>{product?.sku}</td>
                        <td>
                          {product?.name ? (
                            product?.name
                          ) : (
                            <Tooltip
                              multiline
                              width={220}
                              withArrow
                              transitionProps={{ duration: 200 }}
                              color="red"
                              label="Some information related to packages are missing!">
                              <Flex justify="center" align="center">
                                <IconAlertCircleFilled />
                              </Flex>
                            </Tooltip>
                          )}
                        </td>
                        <td>
                          {product?.shipmentFormatName || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>
                        <td>
                          <Badge color={'blue'}>{product?.category}</Badge>
                        </td>
                        {/* <td>
                          {product?.symphonyReferenceId ? (
                            <Badge color="orange">
                              {product?.symphonyReferenceId}
                            </Badge>
                          ) : (
                            <Tooltip
                              multiline
                              width={220}
                              withArrow
                              transitionProps={{ duration: 200 }}
                              color="red"
                              label="Some information related to packages are missing!">
                              <Flex justify="center" align="center">
                                <IconAlertCircleFilled />
                              </Flex>
                            </Tooltip>
                          )}
                        </td> */}
                        <td>{product?.brand || <Text c="dimmed">N/A</Text>}</td>
                        <td>
                          {product?.brandSegment || <Text c="dimmed">N/A</Text>}
                        </td>
                        <td>
                          {product?.outerPackSize || (
                            <Text c="dimmed">N/A</Text>
                          )}
                        </td>
                        {/* <td>
                          <HoverCard
                            key={index}
                            width={280}
                            shadow="md"
                            styles={{
                              cursor: 'pointer',
                            }}>
                            <HoverCard.Target>
                              <Stack
                                spacing="xs"
                                sx={{
                                  maxWidth: '120px',
                                }}>
                                {product?.packages?.name ? (
                                  <Badge color="cyan">
                                    {product?.packages?.name}
                                  </Badge>
                                ) : (
                                  <Flex justify="center">
                                    <IconAlertCircleFilled
                                      sx={{ color: 'red' }}
                                    />
                                  </Flex>
                                )}
                              </Stack>
                            </HoverCard.Target>
                            <HoverCard.Dropdown
                              shadow="sm"
                              styles={{
                                cursor: 'pointer',
                                border: '2px solid black !important',
                              }}>
                              <Flex py="xs">
                                <Text size="xs" fw={600}>
                                  Package ID: {product?.packages?.id}
                                </Text>
                              </Flex>
                              <Flex>
                                <Badge color="cyan">
                                  Name: {product?.packages?.name}
                                </Badge>
                              </Flex>
                              <Flex gap={2} py="xs">
                                <Badge color="red">
                                  Size : {product?.packages?.size}
                                </Badge>
                              </Flex>
                              <Flex>
                                <Badge color="yellow">
                                  Type: {product?.packages?.type}
                                </Badge>
                              </Flex>
                              <Flex py="xs">
                                <Badge color="green">
                                  Size Unit: {product?.packages?.sizeUnit}
                                </Badge>
                              </Flex>
                            </HoverCard.Dropdown>
                          </HoverCard>
                        </td> */}
                        <td>
                          {product?.packages?.size ? (
                            <Badge color="red">{product?.packages?.size}</Badge>
                          ) : (
                            <Tooltip
                              multiline
                              width={220}
                              withArrow
                              transitionProps={{ duration: 200 }}
                              color="red"
                              label="Some information related to packages are missing!">
                              <Flex justify="center" align="center">
                                <IconAlertCircleFilled />
                              </Flex>
                            </Tooltip>
                          )}
                        </td>
                        <td>
                          <Text>{product?.isActive}</Text>
                          <Badge color={product?.isActive ? 'green' : 'red'}>
                            {product?.isActive ? 'Active' : 'Deactive'}
                          </Badge>
                        </td>

                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Details'}>
                              <ActionIcon
                                variant="light"
                                color="cyan"
                                onClick={() => handleModalOpening(product)}>
                                <IconListDetails size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No Products found"
                  subtext={' '}
                  icon={<IconBoxOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end"></Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / PAGE_LIMIT)}
              />
            </Flex>
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default Products;
