import {
  Button,
  Divider,
  Flex,
  Grid,
  Space,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { IconPrinter } from '@tabler/icons-react';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import Barcode from 'react-barcode';
import { useReactToPrint } from 'react-to-print';
import COLORS from '../../constants/colors';
import { isArrayAndHasContent } from '../../utils/utils';

const BarcodeModal = ({ order, barcode }) => {
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      margin: 0;
      margin-top: 30px;
    }
    
    @page :first {
      margin-top: 0;
    }
    `,
  });

  return (
    <div>
      <Stack align="end">
        <Button
          sx={{
            backgroundColor: COLORS.primary,
          }}
          onClick={handlePrint}
          leftIcon={<IconPrinter />}>
          Print
        </Button>
      </Stack>
      <div ref={printRef}>
        <Stack spacing="0" py="md" px="xl">
          <Space h="sm" />
          <Grid>
            <Grid.Col span={6}>
              <Stack
                spacing={0}
                justify="start"
                align="start"
                sx={{
                  width: '100%',
                  height: '100%',
                }}>
                <Stack
                  spacing={0}
                  justify="center"
                  align="center"
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <Title order={3} align="center">
                    {order?.sourceInformation?.distributorInfo?.name}
                  </Title>
                  <Text>
                    {order?.sourceInformation?.regionInfo?.name || 'N/A'} -{' '}
                    {order?.sourceInformation?.areaInfo?.name || 'N/A'} -{' '}
                    {order?.sourceInformation?.territoryInfo?.name || 'N/A'}
                  </Text>
                  <Text>IGT # {order?.orderNumber}</Text>
                </Stack>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack
                spacing={0}
                justify="end"
                align="end"
                sx={{
                  width: '100%',
                  height: '100%',
                }}>
                <Stack
                  spacing={0}
                  justify="center"
                  align="center"
                  sx={{
                    width: '100%',
                    height: '100%',
                  }}>
                  <Title order={4} align="center">
                    {order?.orderNumber}
                  </Title>
                  <Stack justify="center" align="center">
                    <Barcode value={barcode} />
                  </Stack>
                </Stack>
              </Stack>
            </Grid.Col>
          </Grid>

          <Space h="sm" />
          <Grid>
            <Grid.Col span={6}>
              <Stack spacing={2}>
                <Title order={5}>Source Location</Title>
                <Stack justify="start" align="start">
                  <Text fz="sm">{order?.sourceInformation.name}</Text>
                </Stack>
                <Text fz="sm">
                  {order?.sourceInformation.division || 'N/A'},{' '}
                  {order?.sourceInformation.district || 'N/A'}
                </Text>
                <Text fz="sm">{order?.sourceInformation.address}</Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack spacing={2}>
                <Title order={5}>Destination Location</Title>
                <Stack justify="start" align="start">
                  <Text fz="sm">{order?.destinationInformation.name}</Text>
                </Stack>
                <Text fz="sm">
                  {order?.destinationInformation.division || 'N/A'},{' '}
                  {order?.destinationInformation.district || 'N/A'}
                </Text>
                <Text fz="sm">{order?.destinationInformation.address}</Text>
              </Stack>
            </Grid.Col>
          </Grid>
          <Space h="sm" />

          <Flex gap={5}>
            <Text>Truck Number:</Text>
            <Text fw={600}>{order?.truckNumber}</Text>
          </Flex>
          {/* <Grid>
            <Grid.Col span={3}></Grid.Col>
            <Grid.Col span={6}>
            </Grid.Col>
            <Grid.Col span={3}></Grid.Col>
          </Grid> */}

          <Space h="sm" />
          <Divider />
          <Grid>
            <Grid.Col span={6}>
              <Stack spacing={2}>
                <Text fz="sm" fw={600}>
                  Order Date: {dayjs(order.orderDate).format('MMM DD, YYYY')}
                </Text>
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack spacing={2}>
                <Text fz="sm" fw={600}>
                  Print Date: {dayjs(new Date()).format('MMM DD, YYYY HH:MM')}
                </Text>
              </Stack>
            </Grid.Col>
          </Grid>

          <Divider />
          <Space h="sm" />
          <Title order={5}>Products</Title>
          {isArrayAndHasContent(order?.inventory) ? (
            <Table>
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Order Quantity</th>
                </tr>
              </thead>
              <tbody
                style={{
                  verticalAlign: 'baseline',
                }}>
                {order?.inventory?.map((product, index) => (
                  <tr key={index}>
                    <td>{product.sku}</td>
                    <td
                      style={{
                        maxWidth: '200px',
                      }}>
                      {product.product?.shipmentFormatName
                        ? product.product?.shipmentFormatName
                        : product.name}
                    </td>
                    <td>
                      <Flex gap={5}>
                        <Text>{product.quantity}</Text>
                        <Text c="dimmed">x</Text>
                      </Flex>
                      <Flex gap={5}>
                        <Text c="dimmed">Cartons</Text>
                        <Text c="dimmed" fs="italic">
                          (
                          {(product?.product?.packages?.size / 1000).toFixed(2)}{' '}
                          TH)
                        </Text>
                      </Flex>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td>
                    <Text fw={600}>Total</Text>
                  </td>
                  <td></td>
                  <td>
                    <Text>
                      {order?.inventory?.reduce(
                        (acc, obj) => acc + obj.quantity,
                        0,
                      )}{' '}
                      CBCs
                    </Text>
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : null}
        </Stack>
      </div>
    </div>
  );
};

export default BarcodeModal;
