import {
  Box,
  Button,
  Flex,
  Select,
  Skeleton,
  Space,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconBoxOff } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { MantineReactTable } from 'mantine-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import NoDataPlaceholder from '../../../components/Global/NoDataPlaceholder';
import ServerErrorBox from '../../../components/Global/ServerErrorBox';
import COLORS from '../../../constants/colors';
import { removeExportFile } from '../../../services/dashboard';
import {
  fetchSoIgtScanPerformanceReports,
  reportGenerate,
} from '../../../services/reports';
import { NotificationUtil } from '../../../utils/notifications';
import {
  handleErrorResponse,
  userWiseMultiSelectDistributorDataWithId,
} from '../../../utils/utils';
import { fetchDistributor } from '../../../services/distributor';
import { userWeight } from '../../../constants/const';

const PAGE_TITLE = ' Scan Performance Report';

const SO_IGT_Scan_Performance_Report = () => {
  const appUser = useSelector((state) => state.auth.user);

  const { orderType } = useParams();

  const navigate = useNavigate();

  const rowVirtualizerInstanceRef = useRef(null);

  const token = useSelector((state) => state?.auth?.accessToken);
  const refreshToken = useSelector((state) => state?.auth?.refreshToken);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [selectedDistributor, setselectedDistributor] = useState(null);
  const [selectedDistributorSearchKey, setselectedDistributorSearchKey] =
    useState('');

  useEffect(() => {
    if (appUser.userWeight === userWeight.MANAGER) {
      setselectedDistributor(appUser.distributors.map((dist) => dist.id));
    }
  }, []);

  const {
    data: reportData,
    isLoading,
    isFetching,
    error,
  } = useQuery({
    queryKey: [
      'SO_IGT_Scan_Performance_Report',
      orderType,
      startDate,
      endDate,
      selectedDistributor,
    ],
    queryFn: () =>
      fetchSoIgtScanPerformanceReports({
        orderType,
        startDate,
        endDate,
        selectedDistributor,
      }),
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const {
    data: distributorData,
    isLoadingDistributor,
    isFetchingDistributor,
    errorDistributor,
  } = useQuery({
    queryKey: ['distributor-pagelessData', null, null, null, null, true],
    queryFn: fetchDistributor,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    // onError: (error) => {
    //   handleErrorResponse(error);
    // },
  });

  const { mutate: ExportMutate, isLoading: ExportLoading } = useMutation({
    mutationFn: () =>
      reportGenerate({
        reportName: orderType === 'IGT' ? 'igt' : 'so',
        startDate,
        endDate,
        selectedDistributor,
      }),
    keepPreviousData: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      handleDownloadImage({
        fileUrl: data.data.filePath,
        fileName: data.data.fileName,
      });
    },
  });

  const handleDownloadImage = async (file) => {
    try {
      const response = await fetch(file.fileUrl, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'x-refresh-token': refreshToken,
        },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = file.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifications.clean();
      NotificationUtil({
        success: true,
        title: 'File Downloaded',
        message: 'Your file has been downloaded successfully.',
      });
      removeExportFileMutate(file.fileName);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
    }
  };

  const { mutate: removeExportFileMutate, isLoading: removeExportFileLoading } =
    useMutation({
      mutationFn: (fileName) => removeExportFile(fileName),
      keepPreviousData: false,
    });

  const Igtcolumns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        size: 110,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'source_house',
        header: 'Source House',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue() || 'N/A'}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'source',
        header: 'Source Point',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue() || 'N/A'}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'destination_house',
        header: 'Destination House',
        size: 140,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue() || 'N/A'}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'destination',
        header: 'Destination Point',
        size: 140,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'order_number',
        header: 'Order Number',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'order_status',
        header: 'Order Status',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'order_qty',
        header: 'Order Quantity',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanout',
        header: 'Scan Out',
        size: 110,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanout_percentage',
        header: 'Scan Out %',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanin',
        header: 'Scan In',
        size: 110,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanin_percentage',
        header: 'Scan In %',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
    ],
    [],
  );

  const SoColumns = useMemo(
    () => [
      {
        accessorKey: 'date',
        header: 'Date',
        size: 110,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'region',
        header: 'Region',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'area',
        header: 'Area',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'house',
        header: 'House',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'territory',
        header: 'Terrirtory',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'source',
        header: 'Source',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'order_number',
        header: 'Order Number',
        size: 140,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'order_status',
        header: 'Order Status',
        size: 120,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'order_qty',
        header: 'Order Quantity',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanin',
        header: 'Scan In',
        size: 110,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanin_percentage',
        header: 'Scan In %',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanout',
        header: 'Scan Out',
        size: 110,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
      {
        accessorKey: 'scanout_percentage',
        header: 'Scan Out %',
        size: 130,
        Header: ({ column }) => (
          <div>
            <Text
              fz="xs"
              sx={{
                whiteSpace: 'normal',
              }}>
              {column.columnDef.header}
            </Text>
          </div>
        ),
        Cell: ({ cell }) => (
          <Box>
            <Text fz="xs">{cell.getValue()}</Text>
          </Box>
        ),
      },
    ],
    [],
  );

  if (isLoading)
    return (
      <div>
        <Flex justify="space-between" align="center">
          <Title order={1} size="h2">
            {orderType} {PAGE_TITLE}
          </Title>
          <Button
            onClick={() => navigate(-1)}
            size="sm"
            color="dark"
            leftIcon={<IconArrowLeft />}>
            Go Back
          </Button>
        </Flex>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  return (
    <div>
      <Flex justify="space-between" align="center">
        <Title order={1} size="h2">
          {orderType} {PAGE_TITLE}
        </Title>
        <Button
          onClick={() => navigate(-1)}
          size="sm"
          color="dark"
          radius="xs"
          leftIcon={<IconArrowLeft />}>
          Go Back
        </Button>
      </Flex>
      <Space h="lg" />
      <Flex justify="space-between">
        <Flex align="center" gap={5}>
          {/* <Select
            sx={{
              maxWidth: '200px',
            }}
            value={searchByorderType}
            onChange={(value) => {
              setSearchByorderType(value);
            }}
            placeholder="Filter by order type"
            data={[
              { label: 'IGT', value: 'IGT' },
              { label: 'SO', value: 'SO' },
            ]}
          /> */}
          <DateInput
            placeholder="Start Date"
            value={startDate}
            onChange={(value) => {
              setStartDate(value);
            }}
            sx={{
              maxWidth: '200px',
            }}
            clearable
            maxDate={new Date()}
            weekendDays={[5, 6]}
          />
          <DateInput
            placeholder="End Date"
            // value={endDate}
            onChange={(value) => {
              if (dayjs(value).format('HH:mm') === '00:00') {
                setEndDate(dayjs(value).endOf('day'));
              } else {
                setEndDate(value);
              }
            }}
            sx={{
              maxWidth: '200px',
            }}
            clearable
            minDate={startDate ? startDate : new Date()}
            weekendDays={[5, 6]}
          />
          <Select
            placeholder="Filter by house"
            dropdownPosition="bottom"
            data={
              userWiseMultiSelectDistributorDataWithId({
                appUser,
                distributorData: distributorData?.data.data.distributors || [],
              }) || []
            }
            onChange={(value) => {
              if (value) {
                setselectedDistributor([value]);
                setselectedDistributorSearchKey(value);
              } else {
                if (appUser.userWeight === userWeight.MANAGER) {
                  setselectedDistributor(
                    appUser.distributors.map((dist) => dist.id),
                  );
                } else {
                  setselectedDistributor(null);
                }
                setselectedDistributorSearchKey('');
              }
            }}
            value={selectedDistributorSearchKey}
            sx={{
              minWidth: '250px',
            }}
            clearable
            searchable
            withinPortal
          />
        </Flex>
        <Stack align="end">
          <Button
            onClick={() => {
              ExportMutate();
              NotificationUtil({
                success: true,
                title: 'Processing',
                message:
                  'Your request is being processed. Feel free to browse the app while waiting for the download to finish.',
                autoClose: false,
              });
            }}
            loading={ExportLoading}
            sx={{
              backgroundColor: COLORS.primary,
            }}>
            Download XLS
          </Button>
        </Stack>
      </Flex>
      <Space h="lg" />
      <Stack>
        <MantineReactTable
          columns={orderType === 'IGT' ? Igtcolumns : SoColumns}
          data={reportData.data.data}
          enableTopToolbar={false}
          enableBottomToolbar={false}
          enablePagination={false}
          enableRowVirtualization
          mantineTableContainerProps={{
            sx: { height: '80vh' },
          }}
          mantineTableProps={{
            withColumnBorders: true,
          }}
          state={{ isLoading: isLoading || isFetching }}
          rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
          rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
          mantineTableHeadCellProps={{
            sx: {
              backgroundColor: COLORS.primary,
              color: 'white !important',
            },
          }}
          mantineFilterTextInputProps={{
            styles: {
              input: { color: 'white' },
            },
          }}
          renderEmptyRowsFallback={() => (
            <Stack
              justify="center"
              align="center"
              sx={{
                height: '51vh',
              }}>
              <NoDataPlaceholder
                title="No report data found"
                subtext={'Please select a valid date range first.'}
                icon={<IconBoxOff size={70} color="#4562f7" />}
              />
            </Stack>
          )}
        />
      </Stack>
    </div>
  );
};

export default SO_IGT_Scan_Performance_Report;
