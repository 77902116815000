import {
  Button,
  LoadingOverlay,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { scanAction } from '../../services/orders';
import COLORS from '../../constants/colors';
import { cbcTabNames } from '../../constants/const';
import { NotificationUtil } from '../../utils/notifications';
import { isArrayAndHasContent } from '../../utils/utils';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';

const UnscannedCbcsModal = ({
  order,
  notScannedInCBC,
  onUpdate,
  scanActionData,
  isFetching,
}) => {
  const [barcodeInput, setBarcodeInput] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const ConfirmScanModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">Are you sure you want to scan this order?</Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        scanMutate({
          orderNumber: order?.orderNumber,
          barcode: values?.barcode,
          scanAction: values.scanAction,
        });
        setIsConfirmModalOpen(false);
      },
      onCancel: () => {
        setIsConfirmModalOpen(false);
      },
    });
  };

  const { mutate: scanMutate, isLoading: isScanning } = useMutation({
    mutationFn: async (values) => await scanAction(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'CBC scanned successfully',
      });
      onUpdate({
        close: scanActionData === cbcTabNames.scanOut ? true : false,
      });
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  if (scanActionData === cbcTabNames.scanOut) {
    return (
      <Stack justify="center" align="center" p={10}>
        <Title order={4}>Enter Barcode</Title>
        <TextInput
          placeholder="Enter Barcode"
          size="lg"
          onChange={(e) => {
            setBarcodeInput(e.target.value);
          }}
          value={barcodeInput}
        />
        <Button
          sx={{
            backgroundColor: COLORS.primary,
          }}
          onClick={() => {
            ConfirmScanModal({
              barcode: barcodeInput,
              scanAction: scanActionData,
            });
          }}
          disabled={!order?.isApproved}>
          Scan Out
        </Button>
      </Stack>
    );
  }

  return (
    <div
      style={{
        minWidth: '50vh',
      }}>
      {isArrayAndHasContent(notScannedInCBC) ? (
        <ScrollArea.Autosize mah="35vh">
          <LoadingOverlay
            visible={isFetching || isConfirmModalOpen || isScanning}
          />
          <Table highlightOnHover>
            <thead
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Barcode</th>
                <th>Pack Code</th>
                <th>Production Date</th>
                <th>Quantity</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody
              style={{
                verticalAlign: 'baseline',
              }}>
              {notScannedInCBC.map((cbc, index) => (
                <tr key={index}>
                  <td>{cbc?.sku}</td>
                  <td
                    style={{
                      maxWidth: '200px',
                    }}>
                    {cbc.shipmentFormatName
                      ? cbc.shipmentFormatName
                      : cbc?.name}
                  </td>
                  <td>{cbc.barcode}</td>
                  <td>{cbc.packcode}</td>
                  <td>{dayjs(cbc.createdAt).format('DD-MM-YYYY')}</td>
                  <td>{parseInt(cbc.stickCount)}</td>
                  <td>
                    <Button
                      compact
                      sx={{
                        backgroundColor: COLORS.primary,
                      }}
                      onClick={() => {
                        ConfirmScanModal({
                          barcode: cbc.barcode,
                          scanAction: scanActionData,
                        });
                        setIsConfirmModalOpen(true);
                      }}
                      loading={isScanning}>
                      Scan In
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollArea.Autosize>
      ) : (
        <NoDataPlaceholder />
      )}
    </div>
  );
};

export default UnscannedCbcsModal;
