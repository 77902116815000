import React, { useEffect, useState } from 'react';
import {
  canThisUserEditThisUser,
  handleErrorResponse,
  isArrayAndHasContent,
  userDesignationAccordingToRoleMapper,
  userRoleLabelsAccordingToWeight,
} from '../../utils/utils';
import { fetchUsers, logoutUserByRole } from '../../services/user';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Flex,
  Grid,
  HoverCard,
  Loader,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Stack,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import ServerErrorBox from '../../components/Global/ServerErrorBox';
import SearchInput from '../../components/Global/SearchInput';
import { useSelector } from 'react-redux';
import NoDataPlaceholder from '../../components/Global/NoDataPlaceholder';
import { IconEdit, IconUserOff } from '@tabler/icons-react';
import {
  userBadgeBackgroundColors,
  userRoles,
  userWeight,
} from '../../constants/const';
import COLORS from '../../constants/colors';
import { openConfirmModal } from '@mantine/modals';
import { NotificationUtil } from '../../utils/notifications';

const PAGE_TITLE = 'Users Logout';

const UsersLogout = () => {
  const [searchByRole, setSearchByRole] = useState(null);

  const [passwordResetTrigger, setPasswordResetTrigger] = useState(false);

  const appUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    document.title = 'Users Logout | Armada';
  }, []);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: [
      'users-pageless',
      1,
      null,
      null,
      searchByRole,
      null,
      true,
      null,
      null,
      null,
      null,
      null,
    ],
    queryFn: fetchUsers,

    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const ConfirmModal = () => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you want to logout {searchByRole}{' '}
          {passwordResetTrigger ? 'with password reset trigger ' : ''}
          users?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        logoutMutate();
      },
    });
  };

  const {
    mutate: logoutMutate,
    isLoading: logoutMutateLoading,
    isMutating: isMutating,
  } = useMutation({
    mutationFn: () => logoutUserByRole(searchByRole, passwordResetTrigger),

    keepPreviousData: true,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: () => {
      NotificationUtil({
        title: 'Success',
        message: 'Succesfully logged out users',
        success: true,
      });
      refetch();
    },
  });

  if (isLoading)
    return (
      <div>
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <ServerErrorBox apiError={true} />
      </div>
    );

  const { users, total } = data.data.data;

  return (
    <div>
      <Flex justify="space-between" pr="md">
        <Title order={1} size="h2">
          {PAGE_TITLE}
        </Title>
      </Flex>
      <Paper shadow="xs" p="md" mt="20px">
        <Flex py="sm" justify="space-between" align="center">
          <div className="common_width">
            <Select
              value={searchByRole}
              onChange={(value) => {
                setSearchByRole(value);
              }}
              clearable
              placeholder="Filter by user privilege"
              data={userRoleLabelsAccordingToWeight(appUser.userWeight)}
            />
          </div>
          <Flex align="center" gap={10}>
            <Checkbox
              label="Reset password"
              value={passwordResetTrigger}
              onChange={(event) =>
                setPasswordResetTrigger(event.currentTarget.checked)
              }
            />
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={ConfirmModal}
              disabled={!searchByRole}>
              Logout {searchByRole}
            </Button>
          </Flex>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(users) ? (
              <ScrollArea h="65vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Designation</th>
                      <th>Privilege</th>
                      <th>Status</th>
                      <th>Points</th>
                      <th>Regions</th>
                      <th>Areas</th>
                      <th>Distributors</th>
                      <th>Territories</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <td>{user.name}</td>
                        <td>{user.email || <Text c="dimmed">N/A</Text>}</td>
                        <td>{user.phone}</td>
                        <td>{user.designation}</td>
                        <td>
                          <Badge
                            sx={{
                              background:
                                userBadgeBackgroundColors[
                                  userRoles[user?.role]
                                ],
                              color: COLORS[user?.role],
                            }}>
                            {userRoles[user?.role]}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            color={user.status === 'ACTIVE' ? 'green' : 'red'}>
                            {user.status}
                          </Badge>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            {isArrayAndHasContent(user.userLocations) ? (
                              <Flex gap={5} align="center" wrap="wrap">
                                {user.userLocations.map((location, index) => (
                                  <HoverCard
                                    key={index}
                                    width={280}
                                    shadow="md">
                                    <HoverCard.Target>
                                      <Badge color="grape">
                                        {location.name}
                                      </Badge>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                      <Text size="md">{location.name}</Text>
                                      <Text size="sm">
                                        {location.district || 'N/A'},{' '}
                                        {location.division || 'N/A'}
                                      </Text>
                                      <Text size="sm">{location.address}</Text>
                                    </HoverCard.Dropdown>
                                  </HoverCard>
                                ))}
                              </Flex>
                            ) : (
                              <Text c="dimmed">No location</Text>
                            )}
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userRegions?.map((region, index) => (
                                <Badge key={index} color="cyan">
                                  {region.name}
                                </Badge>
                              ))}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userAreas?.map((area, index) => (
                                <Badge key={index} color="green">
                                  {area.name}
                                </Badge>
                              ))}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userDistributors?.map(
                                (distributor, index) => (
                                  <Badge key={index} color="yellow">
                                    {distributor.name}
                                  </Badge>
                                ),
                              )}
                            </Flex>
                          </Stack>
                        </td>
                        <td>
                          <Stack
                            sx={{
                              width: '150px',
                            }}>
                            <Flex gap={5} align="center" wrap="wrap">
                              {user?.userTerritories?.map(
                                (territory, index) => (
                                  <Badge key={index}>{territory.name}</Badge>
                                ),
                              )}
                            </Flex>
                          </Stack>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No users found"
                  subtext={' '}
                  icon={<IconUserOff size={70} color="#4562f7" />}
                />
              </div>
            )}
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export default UsersLogout;
