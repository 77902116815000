import axios from './axios';

export const fetchUsers = async (context) => {
  const response = await axios().get('/users', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      status: context.queryKey[3],
      role: context.queryKey[4],
      location: context.queryKey[5],
      pageLess: context.queryKey[6],
      regionId: context.queryKey[7],
      areaId: context.queryKey[8],
      distributorId: context.queryKey[9],
      territoryId: context.queryKey[10],
      designation: context.queryKey[11],
    },
  });
  return response;
};

export const fetchUsersEmails = async (context) => {
  const response = await axios().get('/users/emails');
  return response;
};

export const createUser = async (values) => {
  const response = await axios().post('/users/create', values);
  return response;
};

export const updateUser = async (values) => {
  const response = await axios().patch('/users/update', values);
  return response;
};

export const assignLocationToUser = async (values) => {
  const response = await axios().post(`/users/assign/location/${values.uid}`, {
    locations: values.locations,
  });
  return response;
};

export const removeLocationFromUser = async (values) => {
  const response = await axios().delete(
    `/users/assign/remove/${values.locationUid}/${values.userUid}`,
  );
  return response;
};

export const passwordAndLoginStatus = async (uid) => {
  const response = await axios().get(`/users/passwordAndLoginStatus/${uid}`);
  return response;
};

export const logoutUserByRole = async (role, passwordResetTrigger) => {
  const response = await axios().post(`/users/logoutUserByRole/${role}`, {
    passwordResetTrigger,
  });
  return response;
};

export const triggerResetPassword = async (uid) => {
  const response = await axios().post(`/users/passwordResetTrigger/${uid}`);
  return response;
};
