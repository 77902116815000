import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fetchCbcs: false,
  cbcs: [],
};

const cbcSlice = createSlice({
  name: 'cbc',
  initialState,
  reducers: {
    controller: (state, action) => {
      state.fetchCbcs = action.payload.fetchCbcs || state.fetchCbcs;
    },
    setCbcs: (state, action) => {
      state.fetchCbcs = false;
      state.cbcs = action.payload.data;
    },
    reset: (state) => {
      state.fetchCbcs = false;
      state.cbcs = [];
    },
  },
});

export const cbcActions = cbcSlice.actions;
export default cbcSlice.reducer;
