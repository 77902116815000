import {
  Box,
  Flex,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
} from '@mantine/core';
import { IconBoxOff } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useState } from 'react';
import { fetchCbcHistory } from '../../../services/orders';
import {
  handleErrorResponse,
  isArrayAndHasContent,
} from '../../../utils/utils';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';

const OpenCbcHistoryTable = ({
  uid,
  isLoading,
  error,
  cbcHistory,
  total,
  page,
  setPage,
}) => {
  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const {
    isFetching: cbcHistoryPagelessFetching,
    refetch: cbcHistoryPagelessDataRefetch,
  } = useQuery({
    queryKey: ['open-cbc-history-pageless', uid, 1, 1, true],
    enabled: exportToggler,
    queryFn: fetchCbcHistory,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `Open-CBC-History-${dayjs(new Date()).format(
        'DD-MM-YYYY',
      )}`;
      const data = response?.data?.data?.histories?.openCbcHistory.map(
        (cbc) => {
          return {
            Date: dayjs(cbc.createdAt).format('DD-MM-YYYY'),
            Amount: cbc?.amount || 'N/A',
            'Opened By': cbc?.opener?.name || 'N/A',
          };
        },
      );
      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      cbcHistoryPagelessDataRefetch();
    } else {
      cbcHistoryPagelessDataRefetch();
    }
  };

  if (isLoading)
    return (
      <div>
        <Skeleton height="40vh" />
        <Flex justify="space-between">
          <Stack justify="end">
            <ExportCsvXls
              setExportType={setExportType}
              exportData={exportData}
              btnLoader={cbcHistoryPagelessFetching}
            />
          </Stack>
          <Pagination
            mt="20px"
            value={page}
            onChange={setPage}
            total={Math.ceil(total / 10)}
          />
        </Flex>
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  return (
    <div>
      {isArrayAndHasContent(cbcHistory) ? (
        <ScrollArea h="40vh">
          <Table highlightOnHover>
            <thead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
              }}>
              <tr>
                <th>Date</th>
                {/* <th>Amount (Sticks)</th> */}
                <th>Opened By</th>
                <th>Route/Section</th>
              </tr>
            </thead>
            <tbody>
              {cbcHistory.map((cbc, i) => (
                <tr key={i}>
                  <td>
                    <Text>
                      {dayjs(cbc.createdAt).format('MMM DD - hh:mm A ')}
                    </Text>
                  </td>
                  {/* <td>
                    <Text>{cbc.amount}</Text>
                  </td> */}
                  <td>
                    <Box>
                      <Text>{cbc?.opener?.name}</Text>
                    </Box>
                  </td>
                  <td>
                    <Box>
                      {cbc.routeSection ? (
                        <Text>{cbc?.routeSection}</Text>
                      ) : (
                        <Text c="dimmed">N/A</Text>
                      )}
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ScrollArea>
      ) : (
        <div>
          <NoDataPlaceholder
            title="No CBC History found"
            subtext={' '}
            icon={<IconBoxOff size={70} color="#4562f7" />}
          />
        </div>
      )}
      <Flex justify="space-between">
        <Stack justify="end">
          <ExportCsvXls
            setExportType={setExportType}
            exportData={exportData}
            btnLoader={cbcHistoryPagelessFetching}
          />
        </Stack>
        <Pagination
          mt="20px"
          value={page}
          onChange={setPage}
          total={Math.ceil(total / 10)}
        />
      </Flex>
    </div>
  );
};

export default OpenCbcHistoryTable;
