import {
  ActionIcon,
  Alert,
  Divider,
  Flex,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import EmailInput from '../../components/ForgotPassword/EmailInput';
import OtpInput from '../../components/ForgotPassword/OtpInput';
import PasswordInputComponent from '../../components/ForgotPassword/PasswordInput';
import { authActions } from '../../store/reducers/authReducer';
import { IconAlertTriangle } from '@tabler/icons-react';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { search } = useLocation();

  const [passwordValue, setPasswordValue] = useState(null);
  const [otpValue, setOtpValue] = useState(null);
  const [otpHasGoneBack, setOtpHasGoneBack] = useState(false);
  const [context, seContext] = useState(null);

  useEffect(() => {
    if (search) {
      const temp = search.split('=');
      if (temp[0] === '?context') {
        seContext(temp[1]);
      }
    }
  }, [search]);

  const [forgotPasswordActiveStage, setforgotPasswordActiveStage] = useState({
    emailInput: true,
    otpInput: false,
    passwordInput: false,
  });

  const onSuccesfullEmailInput = () => {
    setforgotPasswordActiveStage({
      emailInput: false,
      otpInput: true,
      passwordInput: false,
    });
  };

  const onSuccesfullOtpInput = (otp) => {
    setOtpValue(otp);
    setforgotPasswordActiveStage({
      emailInput: false,
      otpInput: false,
      passwordInput: true,
    });
  };

  const onSuccesfullPasswordInput = (value) => {
    setPasswordValue(value);
    navigate('/');
    dispatch(authActions.clearTempData());
  };

  const onOtpBackBtn = () => {
    setOtpHasGoneBack(true);
    setforgotPasswordActiveStage({
      emailInput: true,
      passwordInput: false,
      otpInput: false,
    });
  };

  return (
    <Stack
      align="center"
      justify="center"
      sx={{
        height: '100vh',
      }}>
      <Paper radius="xs" shadow="md">
        <Stack py="md">
          <Title align="center" order={2}>
            Reset Password
          </Title>
          <Divider />
        </Stack>
        <Stack p="lg" align="center" justify="center">
          {forgotPasswordActiveStage.emailInput && (
            <EmailInput onSuccess={onSuccesfullEmailInput} />
          )}
          {forgotPasswordActiveStage.otpInput && (
            <OtpInput
              passwordReset={true}
              passwordValue={passwordValue}
              onSuccess={(otp) => onSuccesfullOtpInput(otp)}
              onBack={onOtpBackBtn}
            />
          )}
          {forgotPasswordActiveStage.passwordInput && (
            <PasswordInputComponent
              onSuccess={onSuccesfullPasswordInput}
              otpValue={otpValue}
              otpHasGoneBack={otpHasGoneBack}
            />
          )}
        </Stack>
        <Divider />
        {!context ? (
          <Flex py="md" justify="center" align="center" gap={5}>
            <Text align="center">Remember password?</Text>
            <ActionIcon
              onClick={() => navigate('/')}
              variant="transparent"
              color="indigo"
              sx={{
                width: 'max-content',
              }}>
              <Text align="center">Back to Sign In</Text>
            </ActionIcon>
          </Flex>
        ) : (
          <Alert icon={<IconAlertTriangle size="1rem" />} title="" color="blue">
            {context === 'first-time' ? (
              'Please Reset Your Password'
            ) : (
              <Text>
                Your password has expired. Please reset your password.
              </Text>
            )}
          </Alert>
        )}
      </Paper>
    </Stack>
  );
};

export default ForgotPassword;
