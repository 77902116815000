import { Badge, Flex, Stack, Text, UnstyledButton } from '@mantine/core';
import { IconX } from '@tabler/icons-react';
import React from 'react';

const FilterBadgeClearable = ({ text, func, color }) => {
  return (
    <Badge size="sm" align="center" variant="outline" color={color || null}>
      <Flex gap={5}>
        <Text fz="xs">{text}</Text>
        <UnstyledButton onClick={func}>
          <Stack justify="center" align="center">
            <IconX size={12} />
          </Stack>
        </UnstyledButton>
      </Flex>
    </Badge>
  );
};

export default FilterBadgeClearable;
