import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { AddUserSchema, UpdateUserSchema } from '../../validators/User';

import {
  Autocomplete,
  Button,
  Flex,
  Group,
  HoverCard,
  MultiSelect,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { useMutation, useQueries } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import COLORS from '../../constants/colors';
import {
  PdaDeviceModels,
  userDesignations,
  userRoles,
} from '../../constants/const';
import { createUser, updateUser } from '../../services/user';
import { NotificationUtil } from '../../utils/notifications';
import {
  isObjectAndHasProperties,
  userDesignationMapperAccordingToRole,
  userRoleLabelsAccordingToWeight,
} from '../../utils/utils';
import PasswordHint from '../Global/PasswordHint';
import { fetchRegion } from '../../services/region';
import { fetchArea } from '../../services/area';
import { fetchDistributor } from '../../services/distributor';
import { fetchTerritory } from '../../services/territories';

const UserForm = ({ defaultValues, update, onUpdate }) => {
  const appUser = useSelector((state) => state.auth.user);

  const [regionSearchKey, setRegionSearchKey] = useState(null);
  const [areaSearchKey, setAreaSearchKey] = useState(null);
  const [distributorSearchKey, setDistributorSearchKey] = useState(null);
  const [territorySearchKey, setTerritorySearchKey] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    defaultValues: defaultValues ? defaultValues : {},
    resolver: yupResolver(update ? UpdateUserSchema : AddUserSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    reset();
    if (update) {
      reset({
        name: defaultValues?.name,
        email: defaultValues?.email || undefined,
        phone: defaultValues?.phone,
        designation: defaultValues?.designation,
        role: defaultValues?.role,
        isMfaEnabled: defaultValues?.isMfaEnabled,
      });
    }
  }, []);

  const isMfaEnabled = useWatch({ name: 'isMfaEnabled', control });
  const role = useWatch({ name: 'role', control });
  const password = useWatch({ name: 'password', control });
  const designation = useWatch({ name: 'designation', control });
  const regionId = useWatch({ name: 'regionId', control });
  const areaId = useWatch({ name: 'areaId', control });
  const distributorId = useWatch({ name: 'distributorId', control });
  const territoryId = useWatch({ name: 'territoryId', control });
  const territoryIds = useWatch({ name: 'territoryIds', control });

  useEffect(() => {
    if (!update) {
      setValue('password', null, {
        shouldValidate: false,
        shouldDirty: true,
      });
    }
  }, [role]);

  useEffect(() => {
    if (defaultValues && update) {
      if (userRoles[defaultValues.role] === userRoles.MANAGER) {
        setValue('regionId', defaultValues?.userRegions[0]?.id);
        setRegionSearchKey(defaultValues?.userRegions[0]?.name);

        if (defaultValues.designation === userDesignations['AREA MANAGER']) {
          setValue('areaId', defaultValues?.userAreas[0]?.id);
          setAreaSearchKey(defaultValues?.userAreas[0]?.name);
        }

        if (
          defaultValues.designation === userDesignations['TERRITORY OFFICER']
        ) {
          setValue('areaId', defaultValues?.userAreas[0]?.id);
          setAreaSearchKey(defaultValues?.userAreas[0]?.name);

          setValue('distributorId', defaultValues?.userDistributors[0]?.id);
          setDistributorSearchKey(defaultValues?.userDistributors[0]?.name);

          setValue('territoryId', defaultValues?.userTerritories[0]?.id);
          setTerritorySearchKey(defaultValues?.userTerritories[0]?.name);
        }

        if (
          defaultValues.designation === userDesignations['BUSINESS MANAGER']
        ) {
          setValue('areaId', defaultValues?.userAreas[0]?.id);
          setAreaSearchKey(defaultValues?.userAreas[0]?.name);

          setValue('distributorId', defaultValues?.userDistributors[0]?.id);
          setDistributorSearchKey(defaultValues?.userDistributors[0]?.name);

          const territoryIds = defaultValues?.userTerritories.map(
            (territory) => territory.id,
          );

          setValue('territoryIds', territoryIds);
        }
      }
      if (
        userRoles[defaultValues.role] === userRoles['GODOWN MANAGER'] ||
        userRoles[defaultValues.role] === userRoles.OPERATOR
      ) {
        setValue('regionId', defaultValues?.userRegions[0]?.id);
        setRegionSearchKey(defaultValues?.userRegions[0]?.name);

        setValue('areaId', defaultValues?.userAreas[0]?.id);
        setAreaSearchKey(defaultValues?.userAreas[0]?.name);

        setValue('distributorId', defaultValues?.userDistributors[0]?.id);
        setDistributorSearchKey(defaultValues?.userDistributors[0]?.name);

        setValue('territoryId', defaultValues?.userTerritories[0]?.id);
        setTerritorySearchKey(defaultValues?.userTerritories[0]?.name);
      }
    }
  }, [defaultValues]);

  const [
    { data: regionPagelessdata },
    { data: areaPagelessData },
    { data: distributorPagelessdata },
    { data: territoryPagelessData },
  ] = useQueries({
    queries: [
      {
        queryKey: ['region-pagelessData', null, null, true],
        queryFn: fetchRegion,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['area-pagelessData', null, null, null, true],
        queryFn: fetchArea,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: ['distributor-pagelessData', null, null, null, null, true],
        queryFn: fetchDistributor,
        refetchOnWindowFocus: false,
        retry: false,
      },
      {
        queryKey: [
          'terriytory-pagelessData',
          null,
          null,
          null,
          null,
          null,
          true,
        ],
        queryFn: fetchTerritory,
        refetchOnWindowFocus: false,
        retry: false,
      },
    ],
  });

  const onSubmit = (data) => {
    ConfirmModal(data);
  };

  const ConfirmModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: (
        <Text size="sm">
          Are you sure you{' '}
          {isObjectAndHasProperties(defaultValues) ? 'update' : 'create'} this
          user?
        </Text>
      ),
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        if (update) {
          updateMutate({ uid: defaultValues.uid, ...values });
        } else {
          createMutate(values);
        }
      },
    });
  };

  const ConfirmDeleteModal = (values) => {
    openConfirmModal({
      title: 'Confirm',
      styles: () => ({
        title: {
          fontSize: '22px',
          fontWeight: 'bold',
        },
      }),
      children: <Text size="sm">Are you sure you delete this user?</Text>,
      confirmProps: { color: 'red' },
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      onConfirm: () => {
        updateMutate(values);
      },
    });
  };

  const { mutate: createMutate, isLoading: isCreatingUser } = useMutation({
    mutationFn: async (values) => await createUser(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Success',
        message: 'User created successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  const { mutate: updateMutate, isLoading: isUpdatingUser } = useMutation({
    mutationFn: async (values) => await updateUser(values),
    onSuccess: () => {
      NotificationUtil({
        success: true,
        title: 'Updated',
        message: 'User updated successfully',
      });
      onUpdate();
    },
    onError: (error) => {
      NotificationUtil({
        success: false,
        title: 'Error',
        message: error.response.data.message,
      });
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="accounts-input">
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={userRoleLabelsAccordingToWeight(appUser.userWeight)}
                label="Privilege"
                placeholder="Select user privilege"
                error={errors?.role?.message}
              />
            )}
          />
          {/* {errors && errors.role && <p>{errors.role.message}</p>} */}
        </div>
        <div className="accounts-input">
          <TextInput
            {...register('name')}
            label="Name"
            placeholder="Enter name"
            error={errors?.name?.message}
          />
        </div>
        {userRoles[role] !== userRoles.OPERATOR && (
          <div className="accounts-input">
            <TextInput
              {...register('email')}
              label="Email"
              placeholder="Enter Email"
              error={errors?.email?.message}
            />
          </div>
        )}
        <div className="accounts-input">
          <TextInput
            {...register('phone')}
            label="Phone Number"
            placeholder="Phone number  - ie - 017***"
            error={errors?.phone?.message}
            minLength={11}
            maxLength={11}
          />
        </div>

        <div className="accounts-input">
          <Controller
            name="designation"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={
                  userDesignationMapperAccordingToRole(role)?.map(
                    (designation) => {
                      return {
                        label: designation,
                        value: designation,
                      };
                    },
                  ) || []
                }
                label="Designation"
                placeholder="Select Designation"
                error={errors?.designation?.message}
              />
            )}
          />
          {/* {errors && errors.designation && <p>{errors.designation.message}</p>} */}
        </div>

        {!update && (
          <div className="accounts-input">
            <TextInput
              {...register('password')}
              label={
                userRoles[role] === userRoles.OPERATOR
                  ? 'Pin Number (digits)'
                  : 'Password'
              }
              placeholder="Enter Password"
              error={errors?.password?.message}
              type={userRoles[role] === userRoles.OPERATOR ? 'number' : 'text'}
              rightSection={
                <Group position="center">
                  <HoverCard width={280} shadow="md" position="bottom">
                    <HoverCard.Target>
                      <Stack justify="center" align="center">
                        <IconInfoCircle color="gray" />
                      </Stack>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <PasswordHint role={role} password={password} />
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Group>
              }
            />
          </div>
        )}
        {userRoles[role] === userRoles.OPERATOR && (
          <div className="accounts-input">
            <Controller
              name="pdaDeviceModel"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  data={
                    Object.keys(PdaDeviceModels).map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    }) || []
                  }
                  label="PDA Device Model"
                  placeholder="Select PDA Device Model"
                  error={errors?.pdaDeviceModel?.message}
                />
              )}
            />
          </div>
        )}
        {designation &&
          (designation === userDesignations['REGIONAL MANAGER'] ||
            designation === userDesignations['AREA MANAGER'] ||
            designation === userDesignations['TERRITORY OFFICER'] ||
            designation === userDesignations['BUSINESS MANAGER'] ||
            designation === userDesignations['COMPUTER OPERATOR'] ||
            designation === userDesignations['GODOWN KEEPER']) && (
            <div className="accounts-input">
              <Autocomplete
                placeholder="Select region"
                label="Region"
                onChange={(value) => {
                  if (regionSearchKey && regionSearchKey.length === 0) {
                    setRegionSearchKey(null);
                  } else {
                    setRegionSearchKey(value);
                  }
                }}
                limit={regionPagelessdata?.data?.data?.regions?.length || 0}
                maxDropdownHeight="200px"
                // dropdownPosition="bottom"
                nothingFound="No options"
                value={regionSearchKey || ''}
                onItemSubmit={(e) => {
                  setValue('regionId', e.id, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                rightSection={
                  regionId ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setRegionSearchKey(null);
                        setValue('regionId', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        if (areaId) {
                          setValue('areaId', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setAreaSearchKey(null);
                        }
                        if (distributorId) {
                          setValue('distributorId', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setDistributorSearchKey(null);
                        }
                        if (territoryId) {
                          setValue('territoryId', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setTerritorySearchKey(null);
                        }
                        if (territoryIds) {
                          setValue('territoryIds', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setTerritorySearchKey(null);
                        }
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                data={
                  regionPagelessdata?.data?.data?.regions?.map((location) => ({
                    id: location.id,
                    value: location.name,
                  })) || []
                }
                error={errors?.regionId?.message}
              />
              {/* {errors && errors.regionId && <p>{errors.regionId.message}</p>} */}
            </div>
          )}
        {designation &&
          (designation === userDesignations['AREA MANAGER'] ||
            designation === userDesignations['TERRITORY OFFICER'] ||
            designation === userDesignations['BUSINESS MANAGER'] ||
            designation === userDesignations['COMPUTER OPERATOR'] ||
            designation === userDesignations['GODOWN KEEPER']) && (
            <div className="accounts-input">
              <Autocomplete
                placeholder="Select area"
                label="Area"
                onChange={(value) => {
                  if (areaSearchKey && areaSearchKey.length === 0) {
                    setAreaSearchKey(null);
                  } else {
                    setAreaSearchKey(value);
                  }
                }}
                limit={areaPagelessData?.data?.data?.areas?.length || 0}
                maxDropdownHeight="200px"
                // dropdownPosition="bottom"
                nothingFound="No options"
                value={areaSearchKey || ''}
                onItemSubmit={(e) => {
                  setValue('areaId', e.id, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                rightSection={
                  areaId ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setAreaSearchKey(null);
                        setValue('areaId', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        if (distributorId) {
                          setValue('distributorId', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setDistributorSearchKey(null);
                        }
                        if (territoryId) {
                          setValue('territoryId', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setTerritorySearchKey(null);
                        }
                        if (territoryIds) {
                          setValue('territoryIds', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setTerritorySearchKey(null);
                        }
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                data={
                  areaPagelessData?.data?.data?.areas
                    ?.filter((item) => item?.regionData?.id === regionId)
                    .map((location) => ({
                      id: location.id,
                      value: location.name,
                    })) || []
                }
                error={errors?.areaId?.message}
              />
              {/* {errors && errors.areaId && <p>{errors.areaId.message}</p>} */}
            </div>
          )}
        {designation &&
          (designation === userDesignations['TERRITORY OFFICER'] ||
            designation === userDesignations['BUSINESS MANAGER'] ||
            designation === userDesignations['COMPUTER OPERATOR'] ||
            designation === userDesignations['GODOWN KEEPER']) && (
            <div className="accounts-input">
              <Autocomplete
                placeholder="Select Distributor"
                label="Distributor"
                onChange={(value) => {
                  if (
                    distributorSearchKey &&
                    distributorSearchKey.length === 0
                  ) {
                    setDistributorSearchKey(null);
                  } else {
                    setDistributorSearchKey(value);
                  }
                }}
                limit={
                  distributorPagelessdata?.data?.data?.distributors.length || 0
                }
                maxDropdownHeight="200px"
                // dropdownPosition="bottom"
                nothingFound="No options"
                value={distributorSearchKey || ''}
                onItemSubmit={(e) => {
                  setValue('distributorId', e.id, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                rightSection={
                  distributorId ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setDistributorSearchKey(null);
                        setValue('distributorId', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                        if (territoryId) {
                          setValue('territoryId', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setTerritorySearchKey(null);
                        }
                        if (territoryIds) {
                          setValue('territoryIds', null, {
                            shouldValidate: true,
                            shouldDirty: true,
                          });
                          setTerritorySearchKey(null);
                        }
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                data={
                  distributorPagelessdata?.data?.data?.distributors
                    .filter((item) => item?.areaData?.id === areaId)
                    .map((location) => ({
                      id: location.id,
                      value: location.name,
                    })) || []
                }
                error={errors?.distributorId?.message}
              />
              {/* {errors && errors.distributorId && (
                <p>{errors.distributorId.message}</p>
              )} */}
            </div>
          )}
        {designation &&
          (designation === userDesignations['TERRITORY OFFICER'] ||
            designation === userDesignations['COMPUTER OPERATOR'] ||
            designation === userDesignations['GODOWN KEEPER']) && (
            <div className="accounts-input">
              <Autocomplete
                placeholder="Select Territory"
                label="Territory"
                onChange={(value) => {
                  if (territorySearchKey && territorySearchKey.length === 0) {
                    setTerritorySearchKey(null);
                  } else {
                    setTerritorySearchKey(value);
                  }
                }}
                limit={
                  territoryPagelessData?.data?.data?.territories.length || 0
                }
                maxDropdownHeight="200px"
                // dropdownPosition="bottom"
                nothingFound="No options"
                value={territorySearchKey || ''}
                onItemSubmit={(e) => {
                  setValue('territoryId', e.id, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                rightSection={
                  territoryId ? (
                    <UnstyledButton
                      sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }}
                      onClick={() => {
                        setTerritorySearchKey(null);
                        setValue('territoryId', null, {
                          shouldValidate: true,
                          shouldDirty: true,
                        });
                      }}>
                      <IconX size={15} />
                    </UnstyledButton>
                  ) : null
                }
                data={
                  territoryPagelessData?.data?.data?.territories
                    ?.filter(
                      (item) => item?.distributorData?.id === distributorId,
                    )
                    ?.map((location) => ({
                      id: location.id,
                      value: location.name,
                    })) || []
                }
                error={errors?.territoryId?.message}
              />
              {/* {errors && errors.territoryId && (
                <p>{errors.territoryId.message}</p>
              )} */}
            </div>
          )}
        {designation &&
          designation === userDesignations['BUSINESS MANAGER'] && (
            <div className="accounts-input">
              <MultiSelect
                clearable={true}
                data={
                  territoryPagelessData?.data?.data?.territories
                    .filter(
                      (item) => item?.distributorData?.id === distributorId,
                    )
                    .map((location) => ({
                      label: location?.name,
                      value: location?.id,
                    })) || []
                }
                sx={{ zIndex: 100 }}
                placeholder="Select Territory"
                label="Territory"
                searchable
                nothingFound="Nothing found"
                dropdownPosition="bottom"
                onChange={(value) => {
                  if (value.length === 0) {
                    setValue('territoryIds', null, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  } else {
                    setValue('territoryIds', value, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                  }
                }}
                value={territoryIds || ''}
                error={errors?.territoryIds?.message}
              />
              {/* {errors && errors.territoryId && (
                <p>{errors.territoryId.message}</p>
              )} */}
            </div>
          )}
        {userRoles[role] !== userRoles.OPERATOR && (
          <div className="accounts-input">
            <Flex justify="space-between" py={10}>
              <Text>MFA Enabled?</Text>
              <Switch
                {...register('isMfaEnabled')}
                labelPosition="left"
                label={isMfaEnabled ? 'Enabled' : 'Disabled'}
                defaultChecked={isMfaEnabled}
              />
            </Flex>
          </div>
        )}
        <Flex gap={15} py={8} mt={10}>
          {update && (
            <Button
              disabled={isSubmitting}
              sx={{
                flex: 1,
              }}
              onClick={() => {
                if (defaultValues.status === 'BANNED') {
                  ConfirmDeleteModal({
                    uid: defaultValues.uid,
                    status: 'ACTIVE',
                  });
                } else {
                  ConfirmDeleteModal({
                    uid: defaultValues.uid,
                    status: 'BANNED',
                  });
                }
              }}
              color={defaultValues.status === 'BANNED' ? 'green' : 'red'}>
              {defaultValues.status === 'BANNED' ? 'Unban User' : 'Ban User'}
            </Button>
          )}
          <Button
            type="submit"
            // loading={isSubmitting || isCreatingUser || isUpdatingUser}
            // disabled={isSubmitting || !isValid}
            sx={{
              flex: 1,
              backgroundColor: COLORS.primary,
            }}>
            {update ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </form>
    </div>
  );
};

export default UserForm;
