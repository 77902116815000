import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { createUniqueLocationName, getCountByStatus } from './helper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'CBC chart list ',
    },
  },
};

const AdminDashboardChartBox = ({ data }) => {
  //creating chart data
  const uniqueLocationNames = createUniqueLocationName(data);

  const chartData = {
    labels: uniqueLocationNames,
    datasets: [
      {
        label: 'Stock in hand',
        data: uniqueLocationNames.map((location) =>
          getCountByStatus(location, 'SCANNED IN', data),
        ),
        backgroundColor: 'rgb(255, 205, 86,0.8)',
        borderColor: 'rgb(255, 205, 86,0.5)',
        borderWidth: 1,
      },
      {
        label: 'In Transit',
        data: uniqueLocationNames.map((location) =>
          getCountByStatus(location, 'SCANNED OUT', data),
        ),
        backgroundColor: 'rgba(53, 162, 235, 0.4)',
        borderColor: 'rgba(53, 162, 235, 0.8)',
        borderWidth: 1,
      },
      {
        label: 'Opened',
        data: uniqueLocationNames.map((location) =>
          getCountByStatus(location, 'OPENED', data),
        ),
        backgroundColor: 'rgb(75, 192, 192,0.4)',
        borderColor: 'rgb(75, 192, 192,0.8)',
        borderWidth: 1,
      },
      {
        label: 'Blocked',
        data: uniqueLocationNames.map((location) =>
          getCountByStatus(location, 'BLOCKED', data),
        ),
        backgroundColor: 'rgb(255, 99, 132,0.8)',
        borderColor: 'rgb(255, 99, 132,0.5)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      className="chart-container"
      style={{ position: 'relative', height: '40vh' }}>
      <Bar options={options} data={chartData} />
    </div>
  );
};

export default AdminDashboardChartBox;
