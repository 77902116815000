import {
  ActionIcon,
  Autocomplete,
  Badge,
  Button,
  Drawer,
  Flex,
  Loader,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import exportFromJSON from 'export-from-json';
import React, { useState } from 'react';
import {
  handleErrorResponse,
  isObjectAndHasProperties,
  isArrayAndHasContent,
} from '../../../utils/utils';
import { fetchArea } from '../../../services/area';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import AreaForm from '../../Area/AreaForm';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import COLORS from '../../../constants/colors';
import { IconEdit, IconPlus, IconRefresh, IconX } from '@tabler/icons-react';
import SearchInput from '../../Global/SearchInput';

const AreasTab = ({ regionPagelessdata }) => {
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);

  const [searchByRegion, setSearchByRegion] = useState(null);
  const [regionSearchKey, setRegionSearchKey] = useState(null);

  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);

  const [addAreaDrawer, setAddAreaDrawer] = useState(false);

  const [selectedArea, setSelectedArea] = useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['area', page, searchKey, searchByRegion, null],
    queryFn: fetchArea,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { isFetching: areaPagelessFetching, refetch: areaPagelessRefetch } =
    useQuery({
      queryKey: ['area-pageless', page, searchKey, searchByRegion, true],
      queryFn: fetchArea,
      refetchOnWindowFocus: false,
      enabled: exportToggler,
      keepPreviousData: true,
      retry: false,
      onError: (error) => {
        handleErrorResponse(error);
      },
      onSuccess: (response) => {
        const fileName = `AREAS-${dayjs(new Date()).format('DD-MM-YYYY')}`;
        const data = response.data.data.areas.map((area) => {
          return {
            Name: area.name,
            Status: area.isActive ? 'Active' : 'Inactive',
            CreatedAt: dayjs(area.createdAt).format('MMM DD, YYYY'),
          };
        });
        exportFromJSON({ data, fileName, exportType });
        setExportToggler(false);
      },
    });

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
  };

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      areaPagelessRefetch();
    } else {
      areaPagelessRefetch();
    }
  };

  const { areas, total } = data.data.data;

  return (
    <div>
      {/* CUSTOM MODAL AND DRAWER START */}
      <Drawer
        opened={addAreaDrawer}
        onClose={() => {
          setSelectedArea(null);
          setAddAreaDrawer(false);
        }}
        title={
          isObjectAndHasProperties(selectedArea) ? 'Edit Area' : 'Add Area'
        }
        position="right">
        <AreaForm
          update={isObjectAndHasProperties(selectedArea)}
          defaultValues={selectedArea}
          onUpdate={() => {
            refetch();
            setAddAreaDrawer(false);
          }}
          regionPagelessdata={regionPagelessdata}
        />
      </Drawer>

      {/* CUSTOM MODAL AND DRAWER END */}
      <Stack>
        <Flex justify="space-between" align="center" py="sm">
          <Flex gap={20}>
            <div>
              <SearchInput
                handleRefresh={handleRefresh}
                handleSearch={handleSearch}
                placeholder="Search Area"
                invokeRefresh={invokingRefreshForSearchInput}
                refreshBtn={false}
              />
            </div>
            <Autocomplete
              placeholder="Search by region"
              onChange={(value) => {
                if (regionSearchKey && regionSearchKey.length === 0) {
                  setRegionSearchKey(null);
                } else {
                  setRegionSearchKey(value);
                }
              }}
              limit={regionPagelessdata?.length || 0}
              maxDropdownHeight="200px"
              dropdownPosition="bottom"
              nothingFound="No options"
              value={regionSearchKey || ''}
              onItemSubmit={(e) => {
                setSearchByRegion(e.id);
              }}
              rightSection={
                searchByRegion ? (
                  <UnstyledButton
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}
                    onClick={() => {
                      setRegionSearchKey(null);
                      setSearchByRegion(null);
                    }}>
                    <IconX size={15} />
                  </UnstyledButton>
                ) : null
              }
              data={
                regionPagelessdata?.map((location) => ({
                  id: location.id,
                  value: location.name,
                })) || []
              }
            />
            <Flex gap={20} align="center" justify="center">
              <Tooltip label="Refresh">
                <ActionIcon
                  size="lg"
                  onClick={() => {
                    handleRefresh();
                    setSearchByRegion(null);
                    setRegionSearchKey(null);
                  }}
                  sx={{
                    backgroundColor: COLORS.orange,
                  }}
                  variant="filled">
                  <IconRefresh size={18} />
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>
          <div>
            <Button
              sx={{
                backgroundColor: COLORS.primary,
              }}
              onClick={() => setAddAreaDrawer(true)}
              leftIcon={<IconPlus />}>
              Add Area
            </Button>
          </div>
        </Flex>
        {isFetching ? (
          <Stack
            sx={{
              minHeight: '50vh',
            }}
            justify="center"
            align="center">
            <Loader size="xl" />
          </Stack>
        ) : (
          <Stack
            sx={{
              minHeight: '50vh',
            }}>
            {isArrayAndHasContent(areas) ? (
              <ScrollArea h="60vh">
                <Table highlightOnHover>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      zIndex: 1,
                      backgroundColor: 'white',
                    }}>
                    <tr>
                      <th>Name</th>
                      <th>Region</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Created By</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {areas.map((area, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            maxWidth: '120px',
                          }}>
                          {area?.name}
                        </td>
                        <td
                          style={{
                            maxWidth: '120px',
                          }}>
                          {area?.regionData?.name}
                        </td>

                        <td>
                          <Badge color={area?.isActive ? 'green' : 'red'}>
                            {area?.isActive ? 'Active' : 'Inactive'}
                          </Badge>
                        </td>
                        <td>{dayjs(area?.createdAt).format('MMM DD, YYYY')}</td>
                        <td>{area?.createdByUser?.name}</td>
                        <td>
                          <Flex gap={5} align="center">
                            <Tooltip label={'Edit location'}>
                              <ActionIcon
                                variant="light"
                                color="teal"
                                onClick={() => {
                                  setSelectedArea(area);
                                  setAddAreaDrawer(true);
                                }}>
                                <IconEdit size={16} />
                              </ActionIcon>
                            </Tooltip>
                          </Flex>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollArea>
            ) : (
              <div>
                <NoDataPlaceholder
                  title="No area found"
                  subtext={' '}
                  //   icon={<IconMapPinOff size={70} color="#4562f7" />}
                />
              </div>
            )}
            <Flex justify="space-between">
              <Stack justify="end">
                <ExportCsvXls
                  setExportType={setExportType}
                  exportData={exportData}
                  btnLoader={areaPagelessFetching}
                />
              </Stack>
              <Pagination
                mt="20px"
                value={page}
                onChange={setPage}
                total={Math.ceil(total / 10)}
              />
            </Flex>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default AreasTab;
