import {
  ActionIcon,
  Divider,
  Flex,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OtpInput from '../../components/ForgotPassword/OtpInput';
import COLORS from '../../constants/colors';
import { authActions } from '../../store/reducers/authReducer';
import { NotificationUtil } from '../../utils/notifications';

const Otp = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const onSuccesfullOtpInput = (data) => {
    NotificationUtil({
      success: true,
      title: 'Welcome',
      message: 'OTP verified Successful!',
    });

    dispatch(authActions.clearTempData());
    dispatch(
      authActions.signin({
        user: data.user,
        accessToken: data.token,
        refreshToken: data.refreshToken,
      }),
    );
    navigate('/dashboard');
  };
  return (
    <Stack
      align="center"
      justify="center"
      sx={{
        height: '100vh',
      }}>
      <Paper radius="xs" shadow="md">
        <Stack py="md">
          <Title align="center" order={2}>
            Verify OTP
          </Title>
          <Divider />
        </Stack>
        <Stack p="lg" sx={{ height: '270px' }} align="center" justify="center">
          <OtpInput onSuccess={(data) => onSuccesfullOtpInput(data)} />
        </Stack>
        <Divider />
        <Flex py="md" justify="center" align="center" gap={5}>
          <Text align="center">Remeber password?</Text>
          <ActionIcon
            onClick={() => navigate('/')}
            variant="transparent"
            color="indigo"
            sx={{
              width: 'max-content',
            }}>
            <Text align="center">Sign in again</Text>
          </ActionIcon>
        </Flex>
      </Paper>
    </Stack>
  );
};

export default Otp;
