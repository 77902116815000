import axios from './axios';

export const fetchAllSessions = async (query) => {
  const response = await axios().get('/day-zero-recon', {
    params: {
      page: query.page,
      searchKey: query.searchKey,
      pageLess: query.pageLess || null,
      locationId: query.locationId || null,
      status: query.status || null,
    },
  });
  return response;
};

export const fetchSession = async (uid) => {
  const response = await axios().get(`/day-zero-recon/${uid}`);
  return response;
};

export const startRecon = async (uid, password) => {
  const response = await axios().post(`/day-zero-recon/start-recon/${uid}`, {
    password,
  });
  return response;
};

export const cancelRecon = async (uid) => {
  const response = await axios().post(`/day-zero-recon/cancel-recon/${uid}`);
  return response;
};

export const createDayZeroReconSession = async (data) => {
  const response = await axios().post('/day-zero-recon/add', data);
  return response;
};

export const validateSessionCreation = async (values) => {
  const response = await axios().post(
    `/day-zero-recon/validate/${values.uid}/${values.token}`,
    {
      otp: values.otp,
    },
  );
  return response;
};

export const generateDayZeroSessionOTP = async (uid) => {
  const response = await axios().post(`/day-zero-recon/generate-otp/${uid}`);
  return response;
};

export const dayZeroFetchCBCs = async (uid) => {
  const response = await axios().get(`/day-zero-recon/cbcs/${uid}`);
  return response;
};

export const dayZeroFetchCurrentCBCs = async (uid) => {
  const response = await axios().get(`/day-zero-recon/reconciled-cbcs/${uid}`);
  return response;
};

export const dayZeroOpenCBC = async (uid, data) => {
  const response = await axios().post(`/day-zero-recon/open/${uid}`, {
    barcodes: data,
  });
  return response;
};

export const dayZeroReconCBCs = async (uid, barcodes) => {
  const response = await axios().post(`/day-zero-recon/reconcbcs/${uid}`, {
    barcodes,
  });
  return response;
};

export const dayZeroRejectScan = async (uid, barcodes) => {
  const response = await axios().post(`/day-zero-recon/reject/scan/${uid}`, {
    barcodes,
  });
  return response;
};

export const dayZeroCloseSession = async (uid) => {
  const response = await axios().post(`/day-zero-recon/close/${uid}`);
  return response;
};

export const fetchReconReport = async (uid) => {
  const response = await axios().get(`/day-zero-recon/report/${uid}`);
  return response;
};
