import axios from './axios';

export const fetchRegion = async (context) => {
  const response = await axios().get('/region', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      pageLess: context.queryKey[3],
    },
  });
  return response;
};

export const createRegion = async (values) => {
  const response = await axios().post('/region', values);
  return response;
};

export const updateRegion = async (values) => {
  const response = await axios().patch('/region', values);
  return response;
};

export const toggleRegion = async (values) => {
  const response = await axios().patch(`/region/${values.id}`, {
    status: values.status,
  });
  return response;
};
