import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input, Stack, Text } from '@mantine/core';
import { IconPhone } from '@tabler/icons-react';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import COLORS from '../../constants/colors';
import { forgotPassword } from '../../services/auth';
import { authActions } from '../../store/reducers/authReducer';
import { NotificationUtil } from '../../utils/notifications';
import { ForgotPasswordEmailSchema } from '../../validators/ForgotPassword';

const SubmitButton = (props) => {
  // This component is used for performance, otherwise the whole page will re-render on each keystroke
  // Because of useWatch, which is a stupid design from react-hooks-form
  // Because of this, only this component will re-render on keystroke, not the whole page

  const { control, isSubmitting } = props;

  const formVal = useWatch({ control });

  return (
    <Button
      radius="xs"
      type="submit"
      size="md"
      sx={{
        backgroundColor: COLORS.primary,
      }}
      disabled={
        isSubmitting || !formVal.number
        // This is intentional, I do not wish to use isValid here
      }
      loading={isSubmitting}>
      Submit
    </Button>
  );
};

const EmailInput = ({ onSuccess }) => {
  const dispatch = useDispatch();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(ForgotPasswordEmailSchema),
  });

  const handleIdentifierInput = async (values) => {
    try {
      const response = await forgotPassword(values.number);

      if (response.status === 200) {
        NotificationUtil({
          success: true,
          title: 'Verified',
          message: 'Please verify OTP',
        });
        dispatch(
          authActions.setTempData({
            sessionId: response.data.token,
            identifier: values.number,
            role: response.data.role,
          }),
        );
        onSuccess();
      }
    } catch (error) {
      //do nothing
    }
  };
  return (
    <form onSubmit={handleSubmit(handleIdentifierInput)}>
      <Stack
        sx={{
          width: 310,
        }}>
        <Text fz={22} align="center">
          Enter Mobile Number
        </Text>
        <div>
          <Input
            {...register('number')}
            type="text"
            rightSection={<IconPhone />}
            placeholder="Your Mobile Number"
            size="md"
          />
          {errors && errors.number && <p>{errors.number.message}</p>}
        </div>

        <SubmitButton isSubmitting={isSubmitting} control={control} />
      </Stack>
    </form>
  );
};

export default EmailInput;
