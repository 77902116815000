import { Badge, Card, Flex, Stack, Text, Title } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import React from 'react';
import COLORS from '../../../constants/colors';

const OrderDetailsLocationInfoCard = ({
  title,
  name,
  division,
  district,
  address,
  type,
}) => {
  return (
    <Card
      shadow="xs"
      padding="lg"
      sx={{
        width: '100%',
        height: '100%',
      }}
      radius="md"
      withBorder>
      <Stack>
        <Flex align="center" gap={5} mb={5}>
          <Title order={1} size="h3">
            {title}
          </Title>
          <IconMapPin
            color={type === 'source' ? COLORS.green : COLORS.redMatt}
          />
        </Flex>
        <Stack justify="start" align="start">
          <Text fz={18} fw={500}>
            {name}
          </Text>
        </Stack>
        <Flex gap={5}>
          {division && <Text fz={14}>{division || '-'}</Text>}
          {division && district ? <Text fz={14}>,</Text> : null}
          {district && <Text fz={14}>{district || '-'}</Text>}
        </Flex>
        <Text fz={14}>{address}</Text>
      </Stack>
    </Card>
  );
};

export default OrderDetailsLocationInfoCard;
