import { LoadingOverlay, ScrollArea, Stack, Table, Text } from '@mantine/core';
import { IconBoxOff } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import NoDataPlaceholder from '../../../../components/Global/NoDataPlaceholder';
import COLORS from '../../../../constants/colors';
import { isArrayAndHasContent } from '../../../../utils/utils';

const FirstDayTillDate = ({ reportData, isLoading, isFetching, error }) => {
  const [totals, setTotals] = useState([]);

  useEffect(() => {
    if (reportData) {
      const houseTotals = {};

      let newArray = [];

      reportData.forEach((item, index) => {
        const {
          house,
          order_quantity,
          total_scan_in,
          base,
          total_open_cbc,
          number_of_days,
        } = item;

        if (!houseTotals[house]) {
          if (index > 0) {
            newArray.push({
              ...houseTotals[reportData[index - 1]?.house],
              region: `${reportData[index - 1]?.house} Totals`,
              area: null,
              territory: null,
              totals: true,
            });
          }

          houseTotals[house] = {
            order_quantity: 0,
            total_scan_in: 0,
            base: 0,
            total_open_cbc: 0,
          };
        }

        houseTotals[house].order_quantity += parseFloat(order_quantity) || 0;
        houseTotals[house].total_scan_in += parseInt(total_scan_in) || 0;
        houseTotals[house].base += parseInt(base) || 0;
        houseTotals[house].total_open_cbc += parseInt(total_open_cbc) || 0;
        houseTotals[house].number_of_days = number_of_days;

        newArray.push({ ...item, totals: false });

        if (index === reportData.length - 1) {
          newArray.push({
            ...houseTotals[reportData[index]?.house],
            region: `${reportData[index]?.house} Totals`,
            area: null,
            territory: null,
            totals: true,
          });
        }
      });

      setTotals(newArray);
    }
  }, [reportData]);

  return (
    <div>
      <ScrollArea h="75vh">
        <LoadingOverlay visible={isLoading || isFetching} zIndex={1} />
        <Table highlightOnHover withColumnBorders={false} fontSize="xs">
          <thead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: COLORS.primary,
            }}>
            <tr>
              <th
                style={{
                  color: 'white',
                }}>
                Region
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Area
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                House
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Territory
              </th>
              <th
                style={{
                  color: 'white',
                }}>
                Distribution Point
              </th>
              <th
                style={{
                  color: 'white',
                  minWidth: '150px',
                }}>
                Order Quantity
              </th>
              <th
                style={{
                  color: 'white',
                  backgroundColor: '#00b4d8',
                  minWidth: '100px',
                }}>
                Scan In
              </th>
              <th
                style={{
                  color: 'white',
                  backgroundColor: '#00b4d8',
                  minWidth: '100px',
                }}>
                Scan In %
              </th>
              <th
                style={{
                  color: 'white',
                  backgroundColor: '#6a994e',
                  minWidth: '150px',
                }}>
                <Text>Base (CBC) x {reportData[0]?.number_of_days}</Text>
              </th>
              <th
                style={{
                  color: 'white',
                  backgroundColor: '#6a994e',
                  minWidth: '100px',
                }}>
                Open CBC
              </th>
              <th
                style={{
                  color: 'white',
                  backgroundColor: '#6a994e',
                  minWidth: '100px',
                }}>
                Open CBC %
              </th>
            </tr>
          </thead>
          <tbody>
            {totals?.map((data, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: data.totals ? '#bcd4e6' : 'white',
                }}>
                <td
                  colSpan={data.totals ? 5 : 1}
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.region}
                </td>
                {data.totals ? null : <td>{data.area}</td>}
                {data.totals ? null : <td>{data.house}</td>}
                {data.totals ? null : <td>{data.territory}</td>}
                {data.totals ? null : <td>{data.location_name}</td>}
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {Number(data.order_quantity).toFixed(0) || 0}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.total_scan_in || 0}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {isNaN(
                    (
                      (parseInt(data.total_scan_in) /
                        parseInt(data.order_quantity)) *
                      100
                    ).toFixed(2),
                  )
                    ? 0
                    : (
                        (parseInt(data.total_scan_in) /
                          parseInt(data.order_quantity)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.base * data.number_of_days}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {data.total_open_cbc || 0}
                </td>
                <td
                  style={{
                    fontWeight: data.totals ? 'bold' : 'normal',
                  }}>
                  {isNaN(
                    (
                      (parseInt(data.total_open_cbc) /
                        (parseInt(data.base) * data.number_of_days)) *
                      100
                    ).toFixed(2),
                  ) ||
                  (
                    (parseInt(data.total_open_cbc) /
                      (parseInt(data.base) * data.number_of_days)) *
                    100
                  ).toFixed(2) === 'Infinity'
                    ? 0
                    : (
                        (parseInt(data.total_open_cbc) /
                          (parseInt(data.base) * data.number_of_days)) *
                        100
                      ).toFixed(2)}
                  %
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!isArrayAndHasContent(totals) && (
          <Stack
            justify="center"
            align="center"
            sx={{
              height: '51vh',
            }}>
            <NoDataPlaceholder
              title="No report data found"
              subtext={''}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Stack>
        )}
      </ScrollArea>
    </div>
  );
};

export default FirstDayTillDate;
