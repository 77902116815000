import {
  ActionIcon,
  Autocomplete,
  Badge,
  Box,
  Flex,
  HoverCard,
  LoadingOverlay,
  Modal,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Table,
  Text,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { IconBoxOff, IconRefresh, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import COLORS from '../../../constants/colors';
import { fetchBlockableCbcs } from '../../../services/orders';
import {
  handleErrorResponse,
  isArrayAndHasContent,
  scanActionBadgeColorMapper,
  userWiseAutocompleteLocationDataWithId,
} from '../../../utils/utils';
import NoDataPlaceholder from '../../Global/NoDataPlaceholder';
import SearchInput from '../../Global/SearchInput';
import CBCHistory from '../Modal/CBCHistory';
import exportFromJSON from 'export-from-json';
import ExportCsvXls from '../../Global/ExportCsvXlsx';
import * as XLSX from 'xlsx';

const AllCbcTab = ({ locationData, currentTab, requiredTab }) => {
  const appUser = useSelector((state) => state.auth.user);
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState(null);
  const [searchByLocation, setSearchByLocation] = useState(null);
  const [locationSearchKey, setLocationSearchKey] = useState(null);
  const [invokingRefreshForSearchInput, setInvokingRefreshForSearchInput] =
    useState(null);
  const [showCbcHistoryModal, setShowCbcHistoryModal] = useState(false);
  const [selectedCbc, setSelectedCbc] = useState(null);

  const [exportType, setExportType] = useState('csv');
  const [exportToggler, setExportToggler] = useState(false);

  const { data, isLoading, error, refetch, isFetching } = useQuery({
    queryKey: [
      'allcbcs',
      null,
      null,
      null,
      null,
      null,
      true,
      page,
      searchByLocation,
      searchKey,
    ],
    queryFn: fetchBlockableCbcs,
    refetchOnWindowFocus: true,
    keepPreviousData: true,
    // refetchInterval: 5 * 60 * 1000,
    // refetchIntervalInBackground: true,
    enabled: currentTab == requiredTab ? true : false,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
  });

  const { isFetching: pagelessFetching, refetch: pagelessRefetch } = useQuery({
    queryKey: [
      'allcbcs-pageless',
      null,
      null,
      null,
      null,
      null,
      null,
      page,
      searchByLocation,
      searchKey,
    ],
    queryFn: fetchBlockableCbcs,
    refetchOnWindowFocus: false,
    enabled: exportToggler,
    keepPreviousData: true,
    refetchInterval: 5 * 60 * 1000,
    refetchIntervalInBackground: true,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (response) => {
      const fileName = `INTACT-CBCS-${dayjs(new Date()).format('DD-MM-YYYY')}`;
      const data = response.data.data.cbcs.map((cbc) => {
        return {
          Barcode: cbc.barcode,
          Packcode: String(cbc.packcode),
          SKU: cbc.sku,
          'Product Name': `
          ${cbc.shipmentFormatName ? cbc.shipmentFormatName : cbc.productName}`,
          Location: cbc.locationName,
          Address: cbc.address,
          'Quantity - TH (Sticks)': cbc.stickCount / 1000,
          'Production Date': dayjs(cbc.productionDate).format('DD-MM-YYYY'),
          'Expiry Date': dayjs(cbc.expiryDate).format('DD-MM-YYYY'),
          'CBC Status': cbc.cbcStatus,
        };
      });

      exportFromJSON({ data, fileName, exportType });
      setExportToggler(false);
    },
  });

  const exportData = () => {
    if (!exportToggler) {
      setExportToggler(true);
      pagelessRefetch();
    } else {
      pagelessFetching();
    }
  };

  const handleSearch = (value) => {
    setPage(1);
    setSearchKey(value.trim());
  };

  const handleRefresh = () => {
    setPage(1);
    setInvokingRefreshForSearchInput(!invokingRefreshForSearchInput);
    setSearchKey('');
    setLocationSearchKey(null);
    setSearchByLocation(null);
  };

  if (isLoading)
    return (
      <div>
        <Skeleton height="80vh" mt={10} />
      </div>
    );

  if (error)
    return (
      <div>
        <NoDataPlaceholder apiError={true} />
      </div>
    );

  let { cbcs, total } = data.data.data;

  return (
    <div>
      <Modal
        opened={showCbcHistoryModal}
        onClose={() => {
          setShowCbcHistoryModal(false);
          setSelectedCbc(null);
        }}
        title="CBC Details"
        size="xxl"
        centered={true}>
        <CBCHistory
          uid={selectedCbc?.uid}
          selectedCBC={selectedCbc}
          openCBC={false}
        />
      </Modal>
      <Flex justify="space-between" align="center" py="sm">
        <Flex gap={20}>
          <div
            style={{
              minWidth: '300px',
            }}>
            <SearchInput
              handleRefresh={handleRefresh}
              handleSearch={handleSearch}
              placeholder="Search Barcode, Packcode, Name"
              invokeRefresh={invokingRefreshForSearchInput}
              refreshBtn={false}
            />
          </div>
          <Autocomplete
            placeholder="Search by Point"
            sx={{
              minWidth: '250px',
            }}
            onChange={(value) => {
              if (locationSearchKey && locationSearchKey.length === 0) {
                setLocationSearchKey(null);
              } else {
                setLocationSearchKey(value);
              }
            }}
            limit={locationData?.length || 0}
            maxDropdownHeight="200px"
            dropdownPosition="bottom"
            nothingFound="No options"
            value={locationSearchKey || ''}
            onItemSubmit={(e) => {
              setSearchByLocation(e.id);
            }}
            rightSection={
              searchByLocation ? (
                <UnstyledButton
                  sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                  onClick={() => {
                    setLocationSearchKey(null);
                    setSearchByLocation(null);
                  }}>
                  <IconX size={15} />
                </UnstyledButton>
              ) : null
            }
            data={
              userWiseAutocompleteLocationDataWithId({
                appUser: appUser,
                locationData: locationData,
              }) || []
            }
          />

          <Flex gap={20} align="center" justify="center">
            <Tooltip label="Refresh">
              <ActionIcon
                size="lg"
                onClick={() => {
                  handleRefresh();
                }}
                sx={{
                  backgroundColor: COLORS.orange,
                }}
                variant="filled">
                <IconRefresh size={18} />
              </ActionIcon>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
      <Stack
        sx={{
          minHeight: '50vh',
        }}>
        {isArrayAndHasContent(cbcs) ? (
          <ScrollArea h="65vh">
            <Table highlightOnHover>
              <thead
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                  backgroundColor: 'white',
                }}>
                <tr>
                  <th>Barcode</th>
                  <th>Packcode</th>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Location</th>
                  <th>Quantity - TH (sticks)</th>
                  <th>Production Date</th>
                  <th>Expiry Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <LoadingOverlay visible={isFetching} />
                {cbcs.map((cbc, i) => (
                  <tr key={i}>
                    <td>
                      <Box>
                        <UnstyledButton
                          onClick={() => {
                            setShowCbcHistoryModal(true);
                            setSelectedCbc(cbc);
                          }}>
                          <Text color="blue">{cbc.barcode}</Text>
                        </UnstyledButton>
                      </Box>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.packcode}</Text>
                      </Box>
                    </td>

                    <td>
                      <Box>
                        <Text>{cbc.sku}</Text>
                      </Box>
                    </td>
                    <td
                      style={{
                        maxWidth: '200px',
                      }}>
                      <Box>
                        <Text>
                          {cbc?.shipmentFormatName
                            ? cbc?.shipmentFormatName
                            : cbc?.productName}
                        </Text>
                      </Box>
                    </td>

                    <td>
                      <HoverCard width={280} shadow="md">
                        <HoverCard.Target>
                          <Text fz="sm">{cbc.locationName}</Text>
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Text size="md">{cbc.locationName}</Text>
                          <Text size="sm">
                            {cbc.district || 'N/A'}, {cbc.division || 'N/A'}
                          </Text>
                          <Text size="sm">{cbc.address}</Text>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.stickCount / 1000}</Text>
                      </Box>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.productionDate} </Text>
                      </Box>
                    </td>
                    <td>
                      <Box>
                        <Text>{cbc.expiryDate}</Text>
                      </Box>
                    </td>

                    <td>
                      <Badge color={scanActionBadgeColorMapper(cbc?.cbcStatus)}>
                        {cbc?.cbcStatus}
                      </Badge>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </ScrollArea>
        ) : (
          <Flex align="center" justify="center" style={{ height: '68vh' }}>
            <NoDataPlaceholder
              title="No Intact CBCs found"
              subtext={' '}
              icon={<IconBoxOff size={70} color="#4562f7" />}
            />
          </Flex>
        )}
        <Flex justify="space-between">
          <Stack justify="end">
            <ExportCsvXls
              setExportType={setExportType}
              exportData={exportData}
              btnLoader={pagelessFetching}
            />
          </Stack>
          <Pagination
            mt="20px"
            value={page}
            onChange={setPage}
            total={Math.ceil(total / 10)}
          />
        </Flex>
      </Stack>
    </div>
  );
};

export default AllCbcTab;
