import axios from './axios';

export const fetchAdminChartStats = async (context) => {
  const response = await axios().get(
    '/dashboard/admin/stats/cbcs-by-location',
    {
      params: {
        locationIds: context.queryKey[1],
        dateRange: context.queryKey[2],
        startDate: context.queryKey[3],
        endDate: context.queryKey[4],
        defaultLocationIds: context.queryKey[5],
      },
    },
  );
  return response;
};

export const exportAdminCbcStats = async (value) => {
  const response = await axios().post(
    '/dashboard/admin/stats/cbcs-by-location/export',
    value,
  );
  return response;
};

export const fetchGdManagerBarChartStats = async (context) => {
  const response = await axios().get(
    '/dashboard/gdmanager/stats/order-in-out',
    {
      params: {
        locationId: context.queryKey[1],
        filter: context.queryKey[2],
      },
    },
  );
  return response;
};

export const fetchGdManagerCardStats = async (context) => {
  const response = await axios().get('/dashboard/gdmanager/stats/cards', {
    params: {
      locationId: context.queryKey[1],
    },
  });
  return response;
};

export const fetchAdminCardStats = async (context) => {
  const response = await axios().get('/dashboard/admin/stats/cards', {
    params: {
      locationIds: context.queryKey[1],
      defaultLocationIds: context.queryKey[2],
    },
  });
  return response;
};

export const fetchGdManagerPieChartStats = async (context) => {
  const response = await axios().get('/dashboard/gdmanager/stats/piechart', {
    params: {
      locationId: context.queryKey[1],
      defaultLocationIds: context.queryKey[2],
    },
  });
  return response;
};

export const fetchDashboardOrderStats = async ({
  startDate,
  endDate,
  orderType,
  region,
  area,
  territory,
  dh,
  location,
}) => {
  const response = await axios().get('/dashboard/stats/orders', {
    params: {
      startDate,
      endDate,
      orderType,
      region,
      area,
      territory,
      dh,
      location,
    },
  });
  return response;
};

export const fetchDashboardCbcStats = async ({
  startDate,
  endDate,
  region,
  area,
  territory,
  dh,
  location,

  segment,
  family,
  sku,
  packSize,

}) => {
  const response = await axios().get('/dashboard/stats/cbcs', {
    params: {
      startDate,
      endDate,
      region,
      area,
      territory,
      dh,
      location,
      segment,
      family,
      sku,
      packSize,
    },
  });
  return response;
};

export const fetchDashboardInventoryStats = async ({
  region,
  area,
  territory,
  dh,
  location,
  segment,
  family,
  sku,
  packSize,
}) => {
  const response = await axios().get('/dashboard/stats/inventory', {
    params: {
      region,
      area,
      territory,
      dh,
      location,
      segment,
      family,
      sku,
      packSize,
    },
  });
  return response;
};

export const fetchDashboardTrend = async ({
  startDate,
  endDate,
  region,
  area,
  territory,
  dh,
  location,
  mode,
}) => {
  const response = await axios().get('/dashboard/trend/cbc', {
    params: {
      startDate,
      endDate,
      region,
      area,
      territory,
      dh,
      location,
      mode,
    },
  });
  return response;
};

export const exportDashboardOrderStats = async ({
  startDate,
  endDate,
  orderType,
  region,
  area,
  territory,
  dh,
  location,
}) => {
  const response = await axios().get('/dashboard/export/order/stats', {
    params: {
      startDate,
      endDate,
      orderType,
      region,
      area,
      territory,
      dh,
      location,
    },
  });
  return response;
};

export const exportDashboardCbcStats = async ({
  startDate,
  endDate,
  region,
  area,
  territory,
  dh,
  location,
}) => {
  const response = await axios().get('/dashboard/export/cbc/in-out/stats', {
    params: {
      startDate,
      endDate,
      region,
      area,
      territory,
      dh,
      location,
    },
  });
  return response;
};

export const exportCbcStats = async ({
  startDate,
  endDate,
  region,
  area,
  territory,
  dh,
  location,
}) => {
  const response = await axios().get('/dashboard/export/cbc/stats', {
    params: {
      startDate,
      endDate,
      region,
      area,
      territory,
      dh,
      location,
    },
  });
  return response;
};

export const exportInventoryStats = async ({
  startDate,
  endDate,
  region,
  area,
  territory,
  dh,
  location,
}) => {
  const response = await axios().get('/dashboard/export/inventory/stats', {
    params: {
      startDate,
      endDate,
      region,
      area,
      territory,
      dh,
      location,
    },
  });
  return response;
};

export const removeExportFile = async (fileName) => {
  const response = await axios().delete('/dashboard/remove/file', {
    params: {
      fileName,
    },
  });
  return response;
};
