import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import cbcReducer from './cbcReducer';
import locationReducer from './locationReducer';
import tabsReducer from './tabsReducer';
import orderFiltersReducer from './orderFiltersReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  cbc: cbcReducer,
  selectedLocations: locationReducer,
  tabs: tabsReducer,
  orderFilters: orderFiltersReducer,
});

export default rootReducer;
