import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { React, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import { LoadingOverlay, Stack, Tabs } from '@mantine/core';
import COLORS from '../../constants/colors';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const AdminDashboardUpdatedChartBox = ({
  data,
  setTrendFilter,
  isFetching,
}) => {
  const [activeTab, setActiveTab] = useState('day');

  useEffect(() => {
    setTrendFilter(activeTab);
  }, [activeTab]);

  const tabs = [
    {
      label: 'Day',
      value: 'day',
    },
    {
      label: 'Days of Week',
      value: 'week-day',
    },
    {
      label: 'Week',
      value: 'week',
    },
    {
      label: 'Month',
      value: 'month',
    },
    {
      label: 'Year',
      value: 'year',
    },
  ];

  const options = {
    responsive: true,
    redraw: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        position: 'top',
        align: 'start',
      },
      title: {
        display: false,
        text: 'CBC chart list ',
        position: 'left',
      },
    },
  };

  const labelsArray = data?.labels;
  const inTransitSo = data?.chartData.map((item) => item.data.inTransitSo);
  const inTransitIgt = data?.chartData.map((item) => item.data.inTransitIGT);
  const intactCbcs = data?.chartData.map((item) => item.data.intactCbc);
  const openCbcs = data?.chartData.map((item) => item.data.openedCbc);
  const scannedInCbcs = data?.chartData.map((item) => item.data.scannedIn);
  const scannedOutCbcs = data?.chartData.map((item) => item.data.scannedOut);

  const chartData = {
    labels: labelsArray,
    datasets: [
      {
        label: 'In Transit - BATB',
        data: inTransitSo,
        backgroundColor: COLORS.tertiary,
        borderColor: COLORS.tertiary,
        borderWidth: 1,
        hidden: true,
      },
      {
        label: 'In Transit - IGT',
        data: inTransitIgt,
        backgroundColor: COLORS.blue,
        borderColor: COLORS.blue,
        borderWidth: 1,
        hidden: true,
      },
      {
        label: 'Intact CBCs',
        data: intactCbcs,
        backgroundColor: COLORS.orange,
        borderColor: COLORS.orange,
        borderWidth: 1,
      },
      {
        label: 'Open CBCs',
        data: openCbcs,
        backgroundColor: COLORS.yellow,
        borderColor: COLORS.yellow,
        borderWidth: 1,
        hidden: true,
      },
      {
        label: 'Scanned In CBCs',
        data: scannedInCbcs,
        backgroundColor: COLORS.green,
        borderColor: COLORS.green,
        borderWidth: 1,
        hidden: true,
      },
      {
        label: 'Scanned Out CBCs',
        data: scannedOutCbcs,
        backgroundColor: COLORS.secondary,
        borderColor: COLORS.secondary,
        borderWidth: 1,
        hidden: true,
      },
    ],
  };

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid #e1e1e1',
        borderRadius: '4px',
        padding: '0.5rem',
      }}>
      <Stack align="end">
        <Tabs
          variant="pills"
          defaultValue="day"
          value={activeTab}
          onTabChange={setActiveTab}>
          <Tabs.List>
            {tabs.map((tab) => (
              <Tabs.Tab
                key={tab.value}
                value={tab.value}
                sx={{
                  width: '100px',
                  padding: 5,
                  backgroundColor:
                    tab.value === activeTab ? COLORS.tertiary : COLORS.gray,
                  '&[data-active]': {
                    backgroundColor: COLORS.tertiary,
                    borderColor: COLORS.tertiary,
                    color: 'white',
                  },
                }}>
                {tab.label}
              </Tabs.Tab>
            ))}
          </Tabs.List>
        </Tabs>
      </Stack>
      {chartData ? (
        <div
          className="chart-container"
          style={{
            position: 'relative',
            height: '40vh',
            // width: data?.length < 10 ? '94vw' : '300vw',
            borderRadius: '8px',
          }}>
          <LoadingOverlay visible={isFetching} />
          <Bar options={options} data={chartData} />
        </div>
      ) : (
        <NoDataPlaceholder />
      )}
    </div>
  );
};

export default AdminDashboardUpdatedChartBox;
