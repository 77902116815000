import axios from './axios';

export const fetchLocations = async (context) => {
  const response = await axios().get('/locations', {
    params: {
      page: context.queryKey[1],
      searchKey: context.queryKey[2],
      activeLocations: context.queryKey[3],
      distributorId: context.queryKey[4],
      pageLess: context.queryKey[5],
      master: context.queryKey[6] || null,
      regionId: context.queryKey[7] || null,
      areaId: context.queryKey[8] || null,
      territoryId: context.queryKey[9] || null,
    },
  });
  return response;
};

export const fetchLocation = async (uid) => {
  const response = await axios().get(`/locations/${uid}`);
  return response;
};

export const createLocation = async (values) => {
  const response = await axios().post('/locations', values);
  return response;
};

export const updateLocation = async (values) => {
  const response = await axios().patch('/locations', values);
  return response;
};

export const deleteLocation = async (uid) => {
  const response = await axios().delete(`/locations/${uid}`);
  return response;
};

export const assignRouteSection = async (values) => {
  const response = await axios().post(`/locations/assign/${values.uid}`, {
    routeSections: values.routeSections,
  });
  return response;
};
