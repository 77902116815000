import {
  Flex,
  Loader,
  Paper,
  ScrollArea,
  Space,
  Stack,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { IconBoxOff } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getAssignedProducts } from '../../services/orders';
import { handleErrorResponse, isArrayAndHasContent } from '../../utils/utils';
import NoDataPlaceholder from '../Global/NoDataPlaceholder';
import SearchInput from '../Global/SearchInput';
import { orderStatusConst } from '../../constants/const';

const OrderDetailsProductsTable = ({
  order,
  setDnRmismatch,
  setQuantityBreakDownForStats,
}) => {
  const [inventory, setInventory] = useState(null);

  const { data, isLoading, isFetching, refetch, error } = useQuery({
    queryKey: ['assigned-products-of-order', order?.orderNumber],
    queryFn: getAssignedProducts,
    enabled: !!order?.orderNumber,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    retry: false,
    onError: (error) => {
      handleErrorResponse(error);
    },
    onSuccess: (data) => {
      setInventory(data?.data?.data?.inventory);
    },
  });

  useEffect(() => {
    if (data) {
      setInventory(data?.data?.data?.inventory);
      if (order.orderStatus === orderStatusConst.COMPLETE) {
        const totalDelievered = data?.data?.data?.inventory?.reduce(
          (acc, obj) => acc + obj.deliveredItems,
          0,
        );
        const totalReceived = data?.data?.data?.inventory?.reduce(
          (acc, obj) => acc + obj.receivedItems,
          0,
        );
        if (totalDelievered !== totalReceived) {
          setDnRmismatch(true);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const totalQuantity = data?.data?.data?.inventory?.reduce(
        (acc, obj) => acc + obj.quantity,
        0,
      );
      const totalDelievered = data?.data?.data?.inventory?.reduce(
        (acc, obj) => acc + obj.deliveredItems,
        0,
      );
      const totalReceived = data?.data?.data?.inventory?.reduce(
        (acc, obj) => acc + obj.receivedItems,
        0,
      );
      setQuantityBreakDownForStats({
        totalQuantity: totalQuantity,
        deliveredQuantity: totalDelievered,
        receivedQuantity: totalReceived,
      });
    }
  }, [data]);

  const handleSearch = (searchKey) => {
    if (searchKey.length === 0) {
      setInventory(data?.data?.data?.inventory);
      return;
    }
    const filteredInventory = data?.data?.data?.inventory?.filter((product) => {
      return (
        product?.sku?.toLowerCase().includes(searchKey.toLowerCase()) ||
        product?.name?.toLowerCase().includes(searchKey.toLowerCase())
      );
    });
    setInventory(filteredInventory);
  };
  const handleRefresh = () => {
    if (data) {
      setInventory(data?.data?.data?.inventory);
    }
  };

  return (
    <Paper shadow="xs" p="md" mt="20px" radius="md" withBorder>
      <Flex justify="space-between" align="center">
        <Title order={1} size="h3">
          Products
        </Title>
        <div>
          <SearchInput
            handleRefresh={handleRefresh}
            handleSearch={handleSearch}
            placeholder="Search Products"
            liveSearch={true}
          />
        </div>
      </Flex>
      <Space h="md" />
      {isLoading ? (
        <Stack
          sx={{
            minHeight: '35vh',
          }}
          justify="center"
          align="center">
          <Loader size="xl" />
        </Stack>
      ) : isArrayAndHasContent(inventory) ? (
        <ScrollArea.Autosize mah="35vh">
          <Table highlightOnHover>
            <thead
              style={{
                backgroundColor: 'white',
                position: 'sticky',
                top: 0,
              }}>
              <tr>
                <th>SKU</th>
                <th>Name</th>
                <th>Order Quantity</th>
                <th>Delivered Quantity</th>
                <th>Received Quantity</th>
              </tr>
            </thead>
            <tbody
              style={{
                verticalAlign: 'baseline',
              }}>
              {inventory?.map((product, index) => (
                <tr key={index}>
                  <td>{product.sku}</td>
                  <td
                    style={{
                      maxWidth: '200px',
                    }}>
                    {product.shipmentFormatName
                      ? product.shipmentFormatName
                      : product.name}
                  </td>
                  <td>
                    <Flex gap={5}>
                      <Text>{product.quantity}</Text>
                      <Text c="dimmed">x</Text>
                    </Flex>
                    <Flex gap={5}>
                      <Text c="dimmed">Cartons</Text>
                      <Text c="dimmed" fs="italic">
                        (
                        {(
                          +product?.packages?.size / 1000 || 10500 / 1000
                        ).toFixed(2)}{' '}
                        TH)
                      </Text>
                    </Flex>
                  </td>
                  <td>{product?.deliveredItems}</td>
                  <td>{product?.receivedItems}</td>
                </tr>
              ))}
              <tr
                style={{
                  backgroundColor: 'white',
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <td>
                  <Text fw={600}>Totals</Text>
                </td>
                <td></td>
                <td>
                  <Text fw={600}>
                    {inventory?.reduce((acc, obj) => acc + obj.quantity, 0)}{' '}
                    CBCs
                  </Text>
                </td>
                <td>
                  {' '}
                  <Text fw={600}>
                    {' '}
                    {inventory?.reduce(
                      (acc, obj) => acc + obj.deliveredItems,
                      0,
                    )}{' '}
                    CBCs
                  </Text>
                </td>
                <td>
                  <Text fw={600}>
                    {inventory?.reduce(
                      (acc, obj) => acc + obj.receivedItems,
                      0,
                    )}{' '}
                    CBCs
                  </Text>
                </td>
              </tr>
            </tbody>
          </Table>
        </ScrollArea.Autosize>
      ) : (
        <div>
          <NoDataPlaceholder
            title="No Product found"
            subtext={' '}
            icon={<IconBoxOff size={70} color="#4562f7" />}
          />
        </div>
      )}
    </Paper>
  );
};

export default OrderDetailsProductsTable;
