import { Grid } from '@mantine/core';
import OrderDetailsInfoCard from './Cards/OrderDetailsInfoCard';
import OrderDetailsLocationInfoCard from './Cards/OrderDetailsLocationInfoCard';
import OrderDetailsStatsCard from './Cards/OrderDetailsStatsCard';

const OrderInfoGridLayout = ({
  order,
  isOrderEditable,
  setSelectedOrder,
  setCreateIgtOrdersDrawer,
  setLogsDrawer,
  onUpdate,
  quantityBreakDownForStats,
  setResolveDrawer,
}) => {
  return (
    <div>
      {' '}
      <Grid>
        <Grid.Col xl={3} lg={6}>
          <OrderDetailsInfoCard
            order={order}
            isOrderEditable={isOrderEditable}
            setSelectedOrder={setSelectedOrder}
            setCreateIgtOrdersDrawer={setCreateIgtOrdersDrawer}
            setLogsDrawer={setLogsDrawer}
            onUpdate={onUpdate}
            setResolveDrawer={setResolveDrawer}
          />
        </Grid.Col>
        <Grid.Col xl={3} lg={6}>
          <OrderDetailsStatsCard
            order={order}
            quantityBreakDownForStats={quantityBreakDownForStats}
          />
        </Grid.Col>
        <Grid.Col xl={3} lg={6}>
          <OrderDetailsLocationInfoCard
            title="Source Location"
            name={order?.sourceInformation?.name}
            division={order?.sourceInformation?.division}
            district={order?.sourceInformation?.district}
            address={order?.sourceInformation?.address}
            type="source"
          />
        </Grid.Col>
        <Grid.Col xl={3} lg={6}>
          <OrderDetailsLocationInfoCard
            title="Destination Location"
            name={order?.destinationInformation?.name}
            division={order?.destinationInformation?.division}
            district={order?.destinationInformation?.district}
            address={order?.destinationInformation?.address}
            type="destination"
          />
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default OrderInfoGridLayout;
